// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: models/endpoints.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Annotation } from "./global";
import { Metadata } from "./types";

export const protobufPackage = "models";

/** EndpointType specifies the type of a notification endpoint. */
export enum EndpointType {
  /** ENDPOINT_UNSPECIFIED - Invalid notification endpoint type. */
  ENDPOINT_UNSPECIFIED = 0,
  /** EMAIL - An email notifcation endpoint. */
  EMAIL = 1,
  /** SMS - An SMS notification endpoint. */
  SMS = 2,
  /** S3 - An S3 notification endpoint. */
  S3 = 3,
  /** SLACK - A Slack notification endpoint. */
  SLACK = 4,
  /** WEBEX - A Webex notification endpoint. */
  WEBEX = 5,
  /** TEST - A test notification endpoint. */
  TEST = 6,
  /** TEAMS - A Microsoft Teams notification endpoint. */
  TEAMS = 7,
  /** PAGER_DUTY - A Pager Duty notification endpoint. */
  PAGER_DUTY = 8,
  /** GOOGLE_STORAGE - A Google Storage notifcation endpoint. */
  GOOGLE_STORAGE = 9,
  UNRECOGNIZED = -1,
}

export function endpointTypeFromJSON(object: any): EndpointType {
  switch (object) {
    case 0:
    case "ENDPOINT_UNSPECIFIED":
      return EndpointType.ENDPOINT_UNSPECIFIED;
    case 1:
    case "EMAIL":
      return EndpointType.EMAIL;
    case 2:
    case "SMS":
      return EndpointType.SMS;
    case 3:
    case "S3":
      return EndpointType.S3;
    case 4:
    case "SLACK":
      return EndpointType.SLACK;
    case 5:
    case "WEBEX":
      return EndpointType.WEBEX;
    case 6:
    case "TEST":
      return EndpointType.TEST;
    case 7:
    case "TEAMS":
      return EndpointType.TEAMS;
    case 8:
    case "PAGER_DUTY":
      return EndpointType.PAGER_DUTY;
    case 9:
    case "GOOGLE_STORAGE":
      return EndpointType.GOOGLE_STORAGE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EndpointType.UNRECOGNIZED;
  }
}

export function endpointTypeToJSON(object: EndpointType): string {
  switch (object) {
    case EndpointType.ENDPOINT_UNSPECIFIED:
      return "ENDPOINT_UNSPECIFIED";
    case EndpointType.EMAIL:
      return "EMAIL";
    case EndpointType.SMS:
      return "SMS";
    case EndpointType.S3:
      return "S3";
    case EndpointType.SLACK:
      return "SLACK";
    case EndpointType.WEBEX:
      return "WEBEX";
    case EndpointType.TEST:
      return "TEST";
    case EndpointType.TEAMS:
      return "TEAMS";
    case EndpointType.PAGER_DUTY:
      return "PAGER_DUTY";
    case EndpointType.GOOGLE_STORAGE:
      return "GOOGLE_STORAGE";
    case EndpointType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** A email notification endpoint. */
export interface EndpointEmail {
  /** The recipients email address. */
  address: string;
}

/** An SMS notification endpoint. */
export interface EndpointSms {
  /** The recipients phone number country code. */
  countryCode: string;
  /** The recipients phone number. */
  phoneNumber: string;
}

/** An S3 notification endpoint. */
export interface EndpointS3 {
  /** The endpoint FQDN. */
  fqdn: string;
  /** The port. */
  port: number;
  /** The AWS region. */
  region: string;
  /** The endpoint bucket name. */
  bucketName: string;
  /** The S3 bucket root path. */
  rootPath: string;
  /** The S3 access key id. */
  accessKeyId: string;
  /** The S3 secret access key. */
  secretAccessKey: string;
}

/** A Slack notification endpoint. */
export interface EndpointSlack {
  /** The webhook URL. */
  webhook: string;
}

/** A Webex notification endpoint. */
export interface EndpointWebex {
  /** The webhook URL. */
  webhook: string;
}

/** A Microsoft Teams notification endpoint. */
export interface EndpointTeams {
  /** The webhook URL. */
  webhook: string;
}

/** A pager duty notification endpoint.. */
export interface EndpointPagerDuty {
  /** The endpoint routing key. */
  routingKey: string;
}

/** A Google storage notification endpoint. */
export interface EndpointGoogleStorage {
  /** The storage account key. */
  accountKey: string;
  /** The endpoint bucket name. */
  bucketName: string;
  /** The endpoint root path. */
  rootPath: string;
}

/** Endpoint defines the configuration of a notification endpoint. */
export interface Endpoint {
  /** The unique id for the endpoint. */
  endpointId: string;
  /** A user provided name for the endpoint. */
  name: string;
  /** A user provided description for the endpoint. */
  description: string;
  /** The status of the endpoint. */
  enabled: boolean;
  /** A confirmation code was successfully used to verify the endpoint. */
  verified: boolean;
  /** The endpoint type. */
  type: EndpointType;
  /** One or more labels for the endpoint. */
  labels: string[];
  /** A set of annotations to store user-defined data. */
  annotations: Annotation[];
  email: EndpointEmail | undefined;
  sms: EndpointSms | undefined;
  s3: EndpointS3 | undefined;
  slack: EndpointSlack | undefined;
  webex: EndpointWebex | undefined;
  teams: EndpointTeams | undefined;
  pagerDuty: EndpointPagerDuty | undefined;
  googleStorage:
    | EndpointGoogleStorage
    | undefined;
  /**
   * Internal. When an endpoint is created a confirmation code is sent to the endpoint.
   * The user optionally can use this to verify that the endpoint is working as expected.
   * This value is not returned to the user.
   */
  confirmationCode: string;
  /** Metadata records the history of the endpoint. */
  metadata: Metadata | undefined;
}

function createBaseEndpointEmail(): EndpointEmail {
  return { address: "" };
}

export const EndpointEmail = {
  encode(message: EndpointEmail, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EndpointEmail {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEndpointEmail();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EndpointEmail {
    return { address: isSet(object.address) ? globalThis.String(object.address) : "" };
  },

  toJSON(message: EndpointEmail): unknown {
    const obj: any = {};
    if (message.address !== "") {
      obj.address = message.address;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EndpointEmail>, I>>(base?: I): EndpointEmail {
    return EndpointEmail.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EndpointEmail>, I>>(object: I): EndpointEmail {
    const message = createBaseEndpointEmail();
    message.address = object.address ?? "";
    return message;
  },
};

function createBaseEndpointSms(): EndpointSms {
  return { countryCode: "", phoneNumber: "" };
}

export const EndpointSms = {
  encode(message: EndpointSms, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.countryCode !== "") {
      writer.uint32(10).string(message.countryCode);
    }
    if (message.phoneNumber !== "") {
      writer.uint32(18).string(message.phoneNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EndpointSms {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEndpointSms();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.countryCode = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EndpointSms {
    return {
      countryCode: isSet(object.countryCode) ? globalThis.String(object.countryCode) : "",
      phoneNumber: isSet(object.phoneNumber) ? globalThis.String(object.phoneNumber) : "",
    };
  },

  toJSON(message: EndpointSms): unknown {
    const obj: any = {};
    if (message.countryCode !== "") {
      obj.countryCode = message.countryCode;
    }
    if (message.phoneNumber !== "") {
      obj.phoneNumber = message.phoneNumber;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EndpointSms>, I>>(base?: I): EndpointSms {
    return EndpointSms.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EndpointSms>, I>>(object: I): EndpointSms {
    const message = createBaseEndpointSms();
    message.countryCode = object.countryCode ?? "";
    message.phoneNumber = object.phoneNumber ?? "";
    return message;
  },
};

function createBaseEndpointS3(): EndpointS3 {
  return { fqdn: "", port: 0, region: "", bucketName: "", rootPath: "", accessKeyId: "", secretAccessKey: "" };
}

export const EndpointS3 = {
  encode(message: EndpointS3, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fqdn !== "") {
      writer.uint32(10).string(message.fqdn);
    }
    if (message.port !== 0) {
      writer.uint32(16).int32(message.port);
    }
    if (message.region !== "") {
      writer.uint32(26).string(message.region);
    }
    if (message.bucketName !== "") {
      writer.uint32(34).string(message.bucketName);
    }
    if (message.rootPath !== "") {
      writer.uint32(42).string(message.rootPath);
    }
    if (message.accessKeyId !== "") {
      writer.uint32(50).string(message.accessKeyId);
    }
    if (message.secretAccessKey !== "") {
      writer.uint32(58).string(message.secretAccessKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EndpointS3 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEndpointS3();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fqdn = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.port = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.region = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.bucketName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.rootPath = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.accessKeyId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.secretAccessKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EndpointS3 {
    return {
      fqdn: isSet(object.fqdn) ? globalThis.String(object.fqdn) : "",
      port: isSet(object.port) ? globalThis.Number(object.port) : 0,
      region: isSet(object.region) ? globalThis.String(object.region) : "",
      bucketName: isSet(object.bucketName) ? globalThis.String(object.bucketName) : "",
      rootPath: isSet(object.rootPath) ? globalThis.String(object.rootPath) : "",
      accessKeyId: isSet(object.accessKeyId) ? globalThis.String(object.accessKeyId) : "",
      secretAccessKey: isSet(object.secretAccessKey) ? globalThis.String(object.secretAccessKey) : "",
    };
  },

  toJSON(message: EndpointS3): unknown {
    const obj: any = {};
    if (message.fqdn !== "") {
      obj.fqdn = message.fqdn;
    }
    if (message.port !== 0) {
      obj.port = Math.round(message.port);
    }
    if (message.region !== "") {
      obj.region = message.region;
    }
    if (message.bucketName !== "") {
      obj.bucketName = message.bucketName;
    }
    if (message.rootPath !== "") {
      obj.rootPath = message.rootPath;
    }
    if (message.accessKeyId !== "") {
      obj.accessKeyId = message.accessKeyId;
    }
    if (message.secretAccessKey !== "") {
      obj.secretAccessKey = message.secretAccessKey;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EndpointS3>, I>>(base?: I): EndpointS3 {
    return EndpointS3.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EndpointS3>, I>>(object: I): EndpointS3 {
    const message = createBaseEndpointS3();
    message.fqdn = object.fqdn ?? "";
    message.port = object.port ?? 0;
    message.region = object.region ?? "";
    message.bucketName = object.bucketName ?? "";
    message.rootPath = object.rootPath ?? "";
    message.accessKeyId = object.accessKeyId ?? "";
    message.secretAccessKey = object.secretAccessKey ?? "";
    return message;
  },
};

function createBaseEndpointSlack(): EndpointSlack {
  return { webhook: "" };
}

export const EndpointSlack = {
  encode(message: EndpointSlack, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.webhook !== "") {
      writer.uint32(10).string(message.webhook);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EndpointSlack {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEndpointSlack();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.webhook = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EndpointSlack {
    return { webhook: isSet(object.webhook) ? globalThis.String(object.webhook) : "" };
  },

  toJSON(message: EndpointSlack): unknown {
    const obj: any = {};
    if (message.webhook !== "") {
      obj.webhook = message.webhook;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EndpointSlack>, I>>(base?: I): EndpointSlack {
    return EndpointSlack.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EndpointSlack>, I>>(object: I): EndpointSlack {
    const message = createBaseEndpointSlack();
    message.webhook = object.webhook ?? "";
    return message;
  },
};

function createBaseEndpointWebex(): EndpointWebex {
  return { webhook: "" };
}

export const EndpointWebex = {
  encode(message: EndpointWebex, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.webhook !== "") {
      writer.uint32(10).string(message.webhook);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EndpointWebex {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEndpointWebex();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.webhook = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EndpointWebex {
    return { webhook: isSet(object.webhook) ? globalThis.String(object.webhook) : "" };
  },

  toJSON(message: EndpointWebex): unknown {
    const obj: any = {};
    if (message.webhook !== "") {
      obj.webhook = message.webhook;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EndpointWebex>, I>>(base?: I): EndpointWebex {
    return EndpointWebex.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EndpointWebex>, I>>(object: I): EndpointWebex {
    const message = createBaseEndpointWebex();
    message.webhook = object.webhook ?? "";
    return message;
  },
};

function createBaseEndpointTeams(): EndpointTeams {
  return { webhook: "" };
}

export const EndpointTeams = {
  encode(message: EndpointTeams, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.webhook !== "") {
      writer.uint32(10).string(message.webhook);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EndpointTeams {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEndpointTeams();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.webhook = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EndpointTeams {
    return { webhook: isSet(object.webhook) ? globalThis.String(object.webhook) : "" };
  },

  toJSON(message: EndpointTeams): unknown {
    const obj: any = {};
    if (message.webhook !== "") {
      obj.webhook = message.webhook;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EndpointTeams>, I>>(base?: I): EndpointTeams {
    return EndpointTeams.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EndpointTeams>, I>>(object: I): EndpointTeams {
    const message = createBaseEndpointTeams();
    message.webhook = object.webhook ?? "";
    return message;
  },
};

function createBaseEndpointPagerDuty(): EndpointPagerDuty {
  return { routingKey: "" };
}

export const EndpointPagerDuty = {
  encode(message: EndpointPagerDuty, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.routingKey !== "") {
      writer.uint32(10).string(message.routingKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EndpointPagerDuty {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEndpointPagerDuty();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.routingKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EndpointPagerDuty {
    return { routingKey: isSet(object.routingKey) ? globalThis.String(object.routingKey) : "" };
  },

  toJSON(message: EndpointPagerDuty): unknown {
    const obj: any = {};
    if (message.routingKey !== "") {
      obj.routingKey = message.routingKey;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EndpointPagerDuty>, I>>(base?: I): EndpointPagerDuty {
    return EndpointPagerDuty.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EndpointPagerDuty>, I>>(object: I): EndpointPagerDuty {
    const message = createBaseEndpointPagerDuty();
    message.routingKey = object.routingKey ?? "";
    return message;
  },
};

function createBaseEndpointGoogleStorage(): EndpointGoogleStorage {
  return { accountKey: "", bucketName: "", rootPath: "" };
}

export const EndpointGoogleStorage = {
  encode(message: EndpointGoogleStorage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountKey !== "") {
      writer.uint32(10).string(message.accountKey);
    }
    if (message.bucketName !== "") {
      writer.uint32(18).string(message.bucketName);
    }
    if (message.rootPath !== "") {
      writer.uint32(26).string(message.rootPath);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EndpointGoogleStorage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEndpointGoogleStorage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountKey = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.bucketName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.rootPath = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EndpointGoogleStorage {
    return {
      accountKey: isSet(object.accountKey) ? globalThis.String(object.accountKey) : "",
      bucketName: isSet(object.bucketName) ? globalThis.String(object.bucketName) : "",
      rootPath: isSet(object.rootPath) ? globalThis.String(object.rootPath) : "",
    };
  },

  toJSON(message: EndpointGoogleStorage): unknown {
    const obj: any = {};
    if (message.accountKey !== "") {
      obj.accountKey = message.accountKey;
    }
    if (message.bucketName !== "") {
      obj.bucketName = message.bucketName;
    }
    if (message.rootPath !== "") {
      obj.rootPath = message.rootPath;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EndpointGoogleStorage>, I>>(base?: I): EndpointGoogleStorage {
    return EndpointGoogleStorage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EndpointGoogleStorage>, I>>(object: I): EndpointGoogleStorage {
    const message = createBaseEndpointGoogleStorage();
    message.accountKey = object.accountKey ?? "";
    message.bucketName = object.bucketName ?? "";
    message.rootPath = object.rootPath ?? "";
    return message;
  },
};

function createBaseEndpoint(): Endpoint {
  return {
    endpointId: "",
    name: "",
    description: "",
    enabled: false,
    verified: false,
    type: 0,
    labels: [],
    annotations: [],
    email: undefined,
    sms: undefined,
    s3: undefined,
    slack: undefined,
    webex: undefined,
    teams: undefined,
    pagerDuty: undefined,
    googleStorage: undefined,
    confirmationCode: "",
    metadata: undefined,
  };
}

export const Endpoint = {
  encode(message: Endpoint, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.endpointId !== "") {
      writer.uint32(10).string(message.endpointId);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.enabled !== false) {
      writer.uint32(32).bool(message.enabled);
    }
    if (message.verified !== false) {
      writer.uint32(40).bool(message.verified);
    }
    if (message.type !== 0) {
      writer.uint32(48).int32(message.type);
    }
    for (const v of message.labels) {
      writer.uint32(58).string(v!);
    }
    for (const v of message.annotations) {
      Annotation.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.email !== undefined) {
      EndpointEmail.encode(message.email, writer.uint32(74).fork()).ldelim();
    }
    if (message.sms !== undefined) {
      EndpointSms.encode(message.sms, writer.uint32(82).fork()).ldelim();
    }
    if (message.s3 !== undefined) {
      EndpointS3.encode(message.s3, writer.uint32(90).fork()).ldelim();
    }
    if (message.slack !== undefined) {
      EndpointSlack.encode(message.slack, writer.uint32(98).fork()).ldelim();
    }
    if (message.webex !== undefined) {
      EndpointWebex.encode(message.webex, writer.uint32(106).fork()).ldelim();
    }
    if (message.teams !== undefined) {
      EndpointTeams.encode(message.teams, writer.uint32(114).fork()).ldelim();
    }
    if (message.pagerDuty !== undefined) {
      EndpointPagerDuty.encode(message.pagerDuty, writer.uint32(122).fork()).ldelim();
    }
    if (message.googleStorage !== undefined) {
      EndpointGoogleStorage.encode(message.googleStorage, writer.uint32(130).fork()).ldelim();
    }
    if (message.confirmationCode !== "") {
      writer.uint32(162).string(message.confirmationCode);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(170).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Endpoint {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEndpoint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.endpointId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.verified = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.annotations.push(Annotation.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.email = EndpointEmail.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.sms = EndpointSms.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.s3 = EndpointS3.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.slack = EndpointSlack.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.webex = EndpointWebex.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.teams = EndpointTeams.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.pagerDuty = EndpointPagerDuty.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.googleStorage = EndpointGoogleStorage.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.confirmationCode = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Endpoint {
    return {
      endpointId: isSet(object.endpointId) ? globalThis.String(object.endpointId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      verified: isSet(object.verified) ? globalThis.Boolean(object.verified) : false,
      type: isSet(object.type) ? endpointTypeFromJSON(object.type) : 0,
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      annotations: globalThis.Array.isArray(object?.annotations)
        ? object.annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
      email: isSet(object.email) ? EndpointEmail.fromJSON(object.email) : undefined,
      sms: isSet(object.sms) ? EndpointSms.fromJSON(object.sms) : undefined,
      s3: isSet(object.s3) ? EndpointS3.fromJSON(object.s3) : undefined,
      slack: isSet(object.slack) ? EndpointSlack.fromJSON(object.slack) : undefined,
      webex: isSet(object.webex) ? EndpointWebex.fromJSON(object.webex) : undefined,
      teams: isSet(object.teams) ? EndpointTeams.fromJSON(object.teams) : undefined,
      pagerDuty: isSet(object.pagerDuty) ? EndpointPagerDuty.fromJSON(object.pagerDuty) : undefined,
      googleStorage: isSet(object.googleStorage) ? EndpointGoogleStorage.fromJSON(object.googleStorage) : undefined,
      confirmationCode: isSet(object.confirmationCode) ? globalThis.String(object.confirmationCode) : "",
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
    };
  },

  toJSON(message: Endpoint): unknown {
    const obj: any = {};
    if (message.endpointId !== "") {
      obj.endpointId = message.endpointId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.verified !== false) {
      obj.verified = message.verified;
    }
    if (message.type !== 0) {
      obj.type = endpointTypeToJSON(message.type);
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.annotations?.length) {
      obj.annotations = message.annotations.map((e) => Annotation.toJSON(e));
    }
    if (message.email !== undefined) {
      obj.email = EndpointEmail.toJSON(message.email);
    }
    if (message.sms !== undefined) {
      obj.sms = EndpointSms.toJSON(message.sms);
    }
    if (message.s3 !== undefined) {
      obj.s3 = EndpointS3.toJSON(message.s3);
    }
    if (message.slack !== undefined) {
      obj.slack = EndpointSlack.toJSON(message.slack);
    }
    if (message.webex !== undefined) {
      obj.webex = EndpointWebex.toJSON(message.webex);
    }
    if (message.teams !== undefined) {
      obj.teams = EndpointTeams.toJSON(message.teams);
    }
    if (message.pagerDuty !== undefined) {
      obj.pagerDuty = EndpointPagerDuty.toJSON(message.pagerDuty);
    }
    if (message.googleStorage !== undefined) {
      obj.googleStorage = EndpointGoogleStorage.toJSON(message.googleStorage);
    }
    if (message.confirmationCode !== "") {
      obj.confirmationCode = message.confirmationCode;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Endpoint>, I>>(base?: I): Endpoint {
    return Endpoint.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Endpoint>, I>>(object: I): Endpoint {
    const message = createBaseEndpoint();
    message.endpointId = object.endpointId ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.enabled = object.enabled ?? false;
    message.verified = object.verified ?? false;
    message.type = object.type ?? 0;
    message.labels = object.labels?.map((e) => e) || [];
    message.annotations = object.annotations?.map((e) => Annotation.fromPartial(e)) || [];
    message.email = (object.email !== undefined && object.email !== null)
      ? EndpointEmail.fromPartial(object.email)
      : undefined;
    message.sms = (object.sms !== undefined && object.sms !== null) ? EndpointSms.fromPartial(object.sms) : undefined;
    message.s3 = (object.s3 !== undefined && object.s3 !== null) ? EndpointS3.fromPartial(object.s3) : undefined;
    message.slack = (object.slack !== undefined && object.slack !== null)
      ? EndpointSlack.fromPartial(object.slack)
      : undefined;
    message.webex = (object.webex !== undefined && object.webex !== null)
      ? EndpointWebex.fromPartial(object.webex)
      : undefined;
    message.teams = (object.teams !== undefined && object.teams !== null)
      ? EndpointTeams.fromPartial(object.teams)
      : undefined;
    message.pagerDuty = (object.pagerDuty !== undefined && object.pagerDuty !== null)
      ? EndpointPagerDuty.fromPartial(object.pagerDuty)
      : undefined;
    message.googleStorage = (object.googleStorage !== undefined && object.googleStorage !== null)
      ? EndpointGoogleStorage.fromPartial(object.googleStorage)
      : undefined;
    message.confirmationCode = object.confirmationCode ?? "";
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
