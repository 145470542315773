// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: brig/bapi.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Token } from "../common/token";
import { Timestamp } from "../google/protobuf/timestamp";
import { UserRole, userRoleFromJSON, userRoleToJSON } from "../models/auth";
import { Tenant } from "../models/tenant";
import { SignUp, User, UserContext, UserPreferences } from "../models/user";

export const protobufPackage = "brig";

/** StartUserSessionRequest requests the start of a session for the user identified by email. */
export interface StartUserSessionRequest {
  /**
   * Identifies the user, mandatory.  If email identifies a new user, the UserSessionToken returned
   * may only be used with NewOrg.  If disabled user (user, tenant or organization is DISABLED),
   * permission denied is returned.  If enabled user (user, tenant and organization are ENABLED),
   * and no specific tenant is identified, preference is given to tenant the user last logged into.
   */
  email: string;
  /** The single sign on (SSO) identity provider (IDP), optional (default: CISCO). */
  provider: string;
  /** Skip checking token from IdP. */
  authBypass: boolean;
  /**
   * Identify a specific organization by name, optional.  If specified, the user identified by
   * email must be a member of a tenant that is a child of the organization identified by this name.
   * May not be specfied with tenant ID.  Must be specified with tenant name.
   */
  orgName: string;
  /**
   * Identify a specific tenant by name, optional.  If specified, the user identified by email must
   * be member of this tenant that must be a child of the organization identified by organization name.
   * May not be specfied with tenant ID. Must be specified with organization name.
   * To specify the default-tenant, set tenant name to the value of organization name.
   */
  tenantName: string;
  /**
   * Identify a specific tenant by external ID, optional.  If specified, the user identified by email
   * must be an enabled member of this tenant or an error is returned.  May not be specified with
   * organization name or tenant name.
   */
  tenantId: string;
  /**
   * Expiry, if specified, overrides the value the service creating the UserSessionToken would normally provide.
   * An example value of "now+2m" means "2 minutes past the time the UserSessionToken is created".  If empty,
   * the system value is used.  If set, any value that happens after the system value is rejected.
   */
  expiry: string;
  /**
   * A JWT named IdToken from OAUTH2 authentication sequence.  If 'auth_bypass' is false, mandatory and
   * must be considered soley (other user identifying parameters such as 'email' must be ignored).
   */
  idToken: string;
}

/**
 * StartUserSessionResponse returns the token the user client must present for every request in this session.
 * If NewUser is true, OrgId, OrgName, TenantId and TenantName will be empty and the UserSessionToken can be
 * used only with NewOrg.
 */
export interface StartUserSessionResponse {
  /** Identifies the user for which the session was started. */
  email: string;
  /** The single sign on (SSO) identity provider (IDP). */
  provider: string;
  /** Identifies the organization in which the session was started. */
  orgId: string;
  /** The name of the organization in which the session was started. */
  orgName: string;
  /**
   * Identifies the tenant in which the session was started.
   * email and tenant_id uniquely identify a user and will be included as claims in UserSessionToken.
   * If tenant_id equals org_id, then this is the default tenant of this organization.
   */
  tenantId: string;
  /** The name of the tenant in which the session was started. */
  tenantName: string;
  /** Is true if this is a new user (not associated with any organization). */
  newUser: boolean;
  /** Tokens that should be set in the cookie field of the header of the HTTP response sent back to the client. */
  tokens: Token[];
}

/**
 * RefreshUserSessionRequest replaces the UserSessionToken associated with the session with one
 * that has a later expiry.  Requires a valid UserSessionToken that is not expired.
 * The expiry is calculated at the time the UserSessionToken creator creates the new token.
 */
export interface RefreshUserSessionRequest {
  /**
   * Expiry, if specified, overrides the value the service creating the UserSessionToken would normally provide.
   * An example value of "now+2m" means "2 minutes past the time the UserSessionToken is created".  If empty,
   * the system value is used.  If set, any value that happens after the system value is rejected.
   */
  expiry: string;
}

/** The response to a request for a list of users. */
export interface UsersResponse {
  /** A list of users. */
  users: User[];
}

/** GetUserSessionRequest allows a user to get information on the current session. */
export interface GetUserSessionRequest {
}

/** DeleteUserSessionRequest allows a user to delete current session. */
export interface DeleteUserSessionRequest {
}

/** GetUserSessionResponse includes information about the user of the current session. */
export interface GetUserSessionResponse {
  /** Identifies the organization of the session to which tenant belongs.  Empty if new user. */
  orgId: string;
  /** The name of the organization of the session to which tenant belongs.  Empty if new user. */
  orgName: string;
  /**
   * Identifies the tenant of the session to which the user belongs.  If tenant_id equals org_id,
   * then the session is in the context of the default-tenant of this organization.  Sessions
   * from default-tenants have different privileges than sessions from non-default tenants.
   * Empty if new user.
   */
  tenantId: string;
  /** The name of the tenant of the session to which the user belongs.  Empty if new user. */
  tenantName: string;
  /** Email of the authenticated user.  Globally unique. */
  email: string;
  /** The single sign on (SSO) identity provider (IDP). */
  provider: string;
  /** Full name of user from identity provider. */
  name: string;
  /** Is true if this is a new user (not associated with any organization and tenant). */
  newUser: boolean;
  /** Expiration timestamp of this user session. */
  expiry:
    | Date
    | undefined;
  /**
   * Is true and new user is true, this new user is allowed to sign up.
   *
   * @deprecated
   */
  allowSignUp: boolean;
  /** Last login time */
  loginTime:
    | Date
    | undefined;
  /** Information about the user of this session. */
  user:
    | User
    | undefined;
  /** @deprecated */
  preferences:
    | UserPreferences
    | undefined;
  /** Information about the user sign up (what version of Eula was accepted, etc). */
  signUp:
    | SignUp
    | undefined;
  /**
   * Contexts in which user is enabled in an enabled tenant in an enabled org.
   *
   * @deprecated
   */
  contexts: UserContext[];
  /** This user is required to sign up if true. */
  requireSignUp: boolean;
}

/**
 * The request for a list of users matching the given search criteria.
 *
 * INTERNAL
 *
 * GetUsersRequest allows an admin to get information on tenant users matching the given
 * search criteria.
 */
export interface GetUsersRequest {
  /**
   * Only return users with these emails.
   * If set, the roles and enabled query parameters are ignored.
   */
  emails: string[];
  /**
   * The single sign on (SSO) identity provider (IDP) of emails, optional.
   * If not specified, provider value is not considered when deciding which users to return.
   */
  provider: string;
  /**
   * Identify a specific tenant, optional.  If not specified, the tenant associated with the
   * current user session making the request is used.  Only users belonging to the resulting
   * tenant are returned.  This parameter allows an admin of the default-tenant to get users
   * of a non-default tenant of the organization.
   */
  tenantId: string;
  /**
   * Only return users that are administratively enabled, otherwise include disabled users as well.
   *
   * INTERNAL
   *
   * The tenant and organization to which the users belong must be enabled regardless of the value of enabled.
   */
  enabled: boolean;
  /** Only return users with specific roles. */
  roles: UserRole[];
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

/** The request for a specific user. */
export interface GetUserRequest {
  /** The user id or email address. */
  id: string;
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

/**
 * The request to add one or more users.
 *
 * INTERNAL
 *
 * SetUsersRequest creates or updates the specified users in the context of this tenant.
 */
export interface SetUsersRequest {
  /**
   * Identify a specific tenant, optional.  If not specified, the tenant associated with the
   * current user session making the request is used.  Users will be created or updated in
   * the resulting tenant.  This parameter allows an admin of the default-tenant to create
   * or update users in a non-default tenant of the organization.
   */
  tenantId: string;
  /**
   * A list of users to be added.
   *
   * INTERNAL
   *
   * A list of users, each one representing a user to be created or updated in the tenant.
   */
  users: User[];
}

/** The request to delete a specific user. */
export interface DeleteUserRequest {
  /** The user id or email address. */
  id: string;
}

/** DeleteUsersRequest deletes users. */
export interface DeleteUsersRequest {
  /** The ids of users to be deleted. */
  ids: string[];
}

/** GetStartDataRequest allows user get */
export interface GetStartDataRequest {
  /** User preferences.  These are global and not tenant specific. */
  userPreferences: UserPreferences | undefined;
}

/**
 * A list of tenants belonging to an organization.
 *
 * INTERNAL
 *
 * Response to requests with list of tenants.
 * FIXME: models.Tenant should only be Name, Description, Tags, Annotations, Enabled, Metadata, Asn;
 *        models.ProvisionTenants should lose 'set_tenants' and 'del_tenants'.
 *        configd should use only models.ProvisionTenants.
 *        Only brig should use models.Tenant (is the only one creating/updating the tenant themselves).
 */
export interface TenantsResponse {
  /** List of up to date tenants in response to requests with list of tenants. */
  tenants: Tenant[];
}

/**
 * NewOrgRequest allows a new user to add a new organization, default-tenant of that new organization and
 * first admin of that default-tenant.  The user making the NewOrgRequest must be new to the system and
 * user_preferences indicates the UserPreferences of this new user.  If an org with this name already exists,
 * the entire operation is aborted and an error is returned.  The UserAccessToken sent with the request
 * must have values for email and sub claims, but must not have values for org_id or tenant_id claims
 * (where the value of the email claim is the email of the new user).  The UserAccessToken returned with
 * the response will have values for email, sub, org_id and tenant_id claims.
 */
export interface NewOrgRequest {
  /** Information about the company and the indvidual signing up. */
  signUp:
    | SignUp
    | undefined;
  /** User preferences of the first admin user of new default-tenant. */
  userPreferences: UserPreferences | undefined;
}

/** Response to NewOrgRequest. */
export interface NewOrgResponse {
  /** The globally unique identifier of the new organization just created. */
  id: string;
  /** Description of the first admin user of the new default-tenant. */
  user:
    | User
    | undefined;
  /** User preferences of the first admin user of the new default-tenant. */
  userPreferences: UserPreferences | undefined;
}

/** AddTenantRequest allows an admin of the default-tenant (only) to add a non-default tenant. */
export interface AddTenantRequest {
  /** Tenant specification. */
  tenant:
    | Tenant
    | undefined;
  /** Description of first admin user of the new non-default tenant.  User must be enabled with role admin. */
  user: User | undefined;
}

/** Response to AddTenantRequest. */
export interface AddTenantResponse {
  /** Tenant specification. */
  tenant:
    | Tenant
    | undefined;
  /** Description of first admin user of the add non-default tenant. */
  user: User | undefined;
}

/** UpdateTenantRequest allows admin of tenant or default-tenant to update tenant properties. */
export interface UpdateTenantRequest {
  /** The tenant id or name. */
  id: string;
  /** Tenant specification. */
  tenant: Tenant | undefined;
}

/** UpdateTenantsRequest allows admin of the default-tenant updates properties of the one or more tenants. */
export interface UpdateTenantsRequest {
  /** Tenants specifications. */
  tenants: Tenant[];
}

/**
 * GetTenantsRequest allows an admin of the default-teannt (only) to get information on org tenants matching
 * the given search criteria (including the default-tenant).
 */
export interface GetTenantsRequest {
  /** Only return tenants with these names.  If not specified, do not descriminate on tenants based on name. */
  names: string[];
  /** Only return tenants that are adminstratively enabled. */
  enabled: boolean;
}

/**
 * SwitchTenantRequest allows a user to switch session context to a different tenant.
 * Upon success, a new UserSessionToken is granted.
 */
export interface SwitchTenantRequest {
  /** Identifier of tenant to switch to. */
  tenantId: string;
  /**
   * Expiry, if specified, overrides the value the service creating the UserSessionToken would normally provide.
   * An example value of "now+2m" means "2 minutes past the time the UserSessionToken is created".  If empty,
   * the system value is used.  If set, any value that happens after the system value is rejected.
   */
  expiry: string;
}

/** DeleteTenantRequest allows an admin of the default-tenant to remove a tenant from the organization. */
export interface DeleteTenantRequest {
  /** ID of tenant to remove from organization. */
  id: string;
}

/** DeleteTenantsRequest allows an admin of the default-tenant to remove tenants from the organization. */
export interface DeleteTenantsRequest {
  /** A list of tenant IDs, each one representing a tenant to be removed from the organization. */
  ids: string[];
}

/** StartAgentSessionRequest requests the start of a session for the switch identified by switch_id. */
export interface StartAgentSessionRequest {
  /**
   * Identifies a switch, mandatory.
   * FIXME: TORT-1320: replace this with SUDI cert and get serial number from SUDI cert.
   */
  serialNumber: string;
  /**
   * Identifies a switch, mandatory.
   * FIXME: TORT-1320: remove this and get switch ID from SUDI cert.
   */
  switchId: string;
  /**
   * Expiry, if specified, overrides the value the service creating the AgentSessionToken would normally provide.
   * An example value of "now+2m" means "2 minutes past the time the AgentSessionToken is created".  If empty,
   * the system value is used.  If set, any value that happens after the system value is rejected.
   */
  expiry: string;
}

/**
 * RefreshAgentSessionRequest replaces the AgentSessionToken associated with the session with one
 * that has a later expiry.  Requires a valid AgentSessionToken that is not expired.
 * The expiry is calculated at the time the AgentSessionToken creator creates the new token.
 */
export interface RefreshAgentSessionRequest {
  /**
   * Expiry, if specified, overrides the value the service creating the AgentSessionToken would normally provide.
   * An example value of "now+2m" means "2 minutes past the time the AgentSessionToken is created".  If empty,
   * the system value is used.  If set, any value that happens after the system value is rejected.
   */
  expiry: string;
}

/** StartAgentSessionResponse returns the tokens the switch client must present for every request in this session. */
export interface StartAgentSessionResponse {
  /** Identifies the switch for which the session was started. */
  serialNumber: string;
  /** Identifies the switch for which the session was started. */
  switchId: string;
  /**
   * Identifies the organization to which the switch belongs.
   * Empty value indicates switch has not been bound to an organization.
   */
  orgId: string;
  /**
   * Identifies the fabric_id to which this switch is tied.
   * Empty value indicates switch has not been assigned to a fabric.
   */
  fabricId: string;
  /** The boolean indicate whether the switch is in parking lot org or not */
  parked: boolean;
  /** Tokens that should be set in the cookie field of the header of the HTTP response sent back to the switch. */
  tokens: Token[];
}

/** GetAgentSessionRequest allows a switch agent to get information on the current session. */
export interface GetAgentSessionRequest {
}

/** DeleteAgentSessionRequest allows a agent to delete current session. */
export interface DeleteAgentSessionRequest {
}

/** GetAgentSessionResponse includes information about the switch agent of the current session. */
export interface GetAgentSessionResponse {
  /** Identifies the switch of this session. */
  serialNumber: string;
  /** Identifies the switch of this session. */
  switchId: string;
  /**
   * Identifies the organization to which the switch belongs.
   * Empty value indicates switch has not been bound to an organization.
   */
  orgId: string;
  /**
   * The name of the fabric to which the switch is bound.
   * Empty value indicates switch has not been assigned to a fabric.
   */
  fabricId: string;
  /** The boolean indicate whether the switch is in parking lot org or not */
  parked: boolean;
  /** Expiration timestamp of this agent session. */
  expiry: Date | undefined;
}

export interface UpdateUser {
  /** The user role. */
  role: UserRole;
  /** Enable/disable the user. */
  enabled: boolean;
  /** A list of user-defined labels that can be used for grouping and filtering Users. */
  labels: string[];
}

/** The request to update a specific user. */
export interface UpdateUserRequest {
  /** The user id or email address. */
  id: string;
  /** The updated user definition. */
  user: UpdateUser | undefined;
}

function createBaseStartUserSessionRequest(): StartUserSessionRequest {
  return {
    email: "",
    provider: "",
    authBypass: false,
    orgName: "",
    tenantName: "",
    tenantId: "",
    expiry: "",
    idToken: "",
  };
}

export const StartUserSessionRequest = {
  encode(message: StartUserSessionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    if (message.provider !== "") {
      writer.uint32(18).string(message.provider);
    }
    if (message.authBypass !== false) {
      writer.uint32(24).bool(message.authBypass);
    }
    if (message.orgName !== "") {
      writer.uint32(42).string(message.orgName);
    }
    if (message.tenantName !== "") {
      writer.uint32(50).string(message.tenantName);
    }
    if (message.tenantId !== "") {
      writer.uint32(58).string(message.tenantId);
    }
    if (message.expiry !== "") {
      writer.uint32(66).string(message.expiry);
    }
    if (message.idToken !== "") {
      writer.uint32(74).string(message.idToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StartUserSessionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartUserSessionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.provider = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.authBypass = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.orgName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.tenantName = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.expiry = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.idToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StartUserSessionRequest {
    return {
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      provider: isSet(object.provider) ? globalThis.String(object.provider) : "",
      authBypass: isSet(object.authBypass) ? globalThis.Boolean(object.authBypass) : false,
      orgName: isSet(object.orgName) ? globalThis.String(object.orgName) : "",
      tenantName: isSet(object.tenantName) ? globalThis.String(object.tenantName) : "",
      tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "",
      expiry: isSet(object.expiry) ? globalThis.String(object.expiry) : "",
      idToken: isSet(object.idToken) ? globalThis.String(object.idToken) : "",
    };
  },

  toJSON(message: StartUserSessionRequest): unknown {
    const obj: any = {};
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.provider !== "") {
      obj.provider = message.provider;
    }
    if (message.authBypass !== false) {
      obj.authBypass = message.authBypass;
    }
    if (message.orgName !== "") {
      obj.orgName = message.orgName;
    }
    if (message.tenantName !== "") {
      obj.tenantName = message.tenantName;
    }
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    if (message.expiry !== "") {
      obj.expiry = message.expiry;
    }
    if (message.idToken !== "") {
      obj.idToken = message.idToken;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StartUserSessionRequest>, I>>(base?: I): StartUserSessionRequest {
    return StartUserSessionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StartUserSessionRequest>, I>>(object: I): StartUserSessionRequest {
    const message = createBaseStartUserSessionRequest();
    message.email = object.email ?? "";
    message.provider = object.provider ?? "";
    message.authBypass = object.authBypass ?? false;
    message.orgName = object.orgName ?? "";
    message.tenantName = object.tenantName ?? "";
    message.tenantId = object.tenantId ?? "";
    message.expiry = object.expiry ?? "";
    message.idToken = object.idToken ?? "";
    return message;
  },
};

function createBaseStartUserSessionResponse(): StartUserSessionResponse {
  return { email: "", provider: "", orgId: "", orgName: "", tenantId: "", tenantName: "", newUser: false, tokens: [] };
}

export const StartUserSessionResponse = {
  encode(message: StartUserSessionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    if (message.provider !== "") {
      writer.uint32(18).string(message.provider);
    }
    if (message.orgId !== "") {
      writer.uint32(26).string(message.orgId);
    }
    if (message.orgName !== "") {
      writer.uint32(34).string(message.orgName);
    }
    if (message.tenantId !== "") {
      writer.uint32(42).string(message.tenantId);
    }
    if (message.tenantName !== "") {
      writer.uint32(50).string(message.tenantName);
    }
    if (message.newUser !== false) {
      writer.uint32(56).bool(message.newUser);
    }
    for (const v of message.tokens) {
      Token.encode(v!, writer.uint32(402).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StartUserSessionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartUserSessionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.provider = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.orgName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.tenantName = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.newUser = reader.bool();
          continue;
        case 50:
          if (tag !== 402) {
            break;
          }

          message.tokens.push(Token.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StartUserSessionResponse {
    return {
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      provider: isSet(object.provider) ? globalThis.String(object.provider) : "",
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      orgName: isSet(object.orgName) ? globalThis.String(object.orgName) : "",
      tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "",
      tenantName: isSet(object.tenantName) ? globalThis.String(object.tenantName) : "",
      newUser: isSet(object.newUser) ? globalThis.Boolean(object.newUser) : false,
      tokens: globalThis.Array.isArray(object?.tokens) ? object.tokens.map((e: any) => Token.fromJSON(e)) : [],
    };
  },

  toJSON(message: StartUserSessionResponse): unknown {
    const obj: any = {};
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.provider !== "") {
      obj.provider = message.provider;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.orgName !== "") {
      obj.orgName = message.orgName;
    }
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    if (message.tenantName !== "") {
      obj.tenantName = message.tenantName;
    }
    if (message.newUser !== false) {
      obj.newUser = message.newUser;
    }
    if (message.tokens?.length) {
      obj.tokens = message.tokens.map((e) => Token.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StartUserSessionResponse>, I>>(base?: I): StartUserSessionResponse {
    return StartUserSessionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StartUserSessionResponse>, I>>(object: I): StartUserSessionResponse {
    const message = createBaseStartUserSessionResponse();
    message.email = object.email ?? "";
    message.provider = object.provider ?? "";
    message.orgId = object.orgId ?? "";
    message.orgName = object.orgName ?? "";
    message.tenantId = object.tenantId ?? "";
    message.tenantName = object.tenantName ?? "";
    message.newUser = object.newUser ?? false;
    message.tokens = object.tokens?.map((e) => Token.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRefreshUserSessionRequest(): RefreshUserSessionRequest {
  return { expiry: "" };
}

export const RefreshUserSessionRequest = {
  encode(message: RefreshUserSessionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.expiry !== "") {
      writer.uint32(10).string(message.expiry);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RefreshUserSessionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefreshUserSessionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.expiry = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RefreshUserSessionRequest {
    return { expiry: isSet(object.expiry) ? globalThis.String(object.expiry) : "" };
  },

  toJSON(message: RefreshUserSessionRequest): unknown {
    const obj: any = {};
    if (message.expiry !== "") {
      obj.expiry = message.expiry;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RefreshUserSessionRequest>, I>>(base?: I): RefreshUserSessionRequest {
    return RefreshUserSessionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RefreshUserSessionRequest>, I>>(object: I): RefreshUserSessionRequest {
    const message = createBaseRefreshUserSessionRequest();
    message.expiry = object.expiry ?? "";
    return message;
  },
};

function createBaseUsersResponse(): UsersResponse {
  return { users: [] };
}

export const UsersResponse = {
  encode(message: UsersResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.users) {
      User.encode(v!, writer.uint32(170).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UsersResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUsersResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 21:
          if (tag !== 170) {
            break;
          }

          message.users.push(User.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UsersResponse {
    return { users: globalThis.Array.isArray(object?.users) ? object.users.map((e: any) => User.fromJSON(e)) : [] };
  },

  toJSON(message: UsersResponse): unknown {
    const obj: any = {};
    if (message.users?.length) {
      obj.users = message.users.map((e) => User.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UsersResponse>, I>>(base?: I): UsersResponse {
    return UsersResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UsersResponse>, I>>(object: I): UsersResponse {
    const message = createBaseUsersResponse();
    message.users = object.users?.map((e) => User.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetUserSessionRequest(): GetUserSessionRequest {
  return {};
}

export const GetUserSessionRequest = {
  encode(_: GetUserSessionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserSessionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserSessionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetUserSessionRequest {
    return {};
  },

  toJSON(_: GetUserSessionRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserSessionRequest>, I>>(base?: I): GetUserSessionRequest {
    return GetUserSessionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserSessionRequest>, I>>(_: I): GetUserSessionRequest {
    const message = createBaseGetUserSessionRequest();
    return message;
  },
};

function createBaseDeleteUserSessionRequest(): DeleteUserSessionRequest {
  return {};
}

export const DeleteUserSessionRequest = {
  encode(_: DeleteUserSessionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteUserSessionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteUserSessionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): DeleteUserSessionRequest {
    return {};
  },

  toJSON(_: DeleteUserSessionRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteUserSessionRequest>, I>>(base?: I): DeleteUserSessionRequest {
    return DeleteUserSessionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteUserSessionRequest>, I>>(_: I): DeleteUserSessionRequest {
    const message = createBaseDeleteUserSessionRequest();
    return message;
  },
};

function createBaseGetUserSessionResponse(): GetUserSessionResponse {
  return {
    orgId: "",
    orgName: "",
    tenantId: "",
    tenantName: "",
    email: "",
    provider: "",
    name: "",
    newUser: false,
    expiry: undefined,
    allowSignUp: false,
    loginTime: undefined,
    user: undefined,
    preferences: undefined,
    signUp: undefined,
    contexts: [],
    requireSignUp: false,
  };
}

export const GetUserSessionResponse = {
  encode(message: GetUserSessionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orgId !== "") {
      writer.uint32(10).string(message.orgId);
    }
    if (message.orgName !== "") {
      writer.uint32(18).string(message.orgName);
    }
    if (message.tenantId !== "") {
      writer.uint32(26).string(message.tenantId);
    }
    if (message.tenantName !== "") {
      writer.uint32(34).string(message.tenantName);
    }
    if (message.email !== "") {
      writer.uint32(42).string(message.email);
    }
    if (message.provider !== "") {
      writer.uint32(50).string(message.provider);
    }
    if (message.name !== "") {
      writer.uint32(58).string(message.name);
    }
    if (message.newUser !== false) {
      writer.uint32(64).bool(message.newUser);
    }
    if (message.expiry !== undefined) {
      Timestamp.encode(toTimestamp(message.expiry), writer.uint32(74).fork()).ldelim();
    }
    if (message.allowSignUp !== false) {
      writer.uint32(80).bool(message.allowSignUp);
    }
    if (message.loginTime !== undefined) {
      Timestamp.encode(toTimestamp(message.loginTime), writer.uint32(90).fork()).ldelim();
    }
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(170).fork()).ldelim();
    }
    if (message.preferences !== undefined) {
      UserPreferences.encode(message.preferences, writer.uint32(178).fork()).ldelim();
    }
    if (message.signUp !== undefined) {
      SignUp.encode(message.signUp, writer.uint32(186).fork()).ldelim();
    }
    for (const v of message.contexts) {
      UserContext.encode(v!, writer.uint32(194).fork()).ldelim();
    }
    if (message.requireSignUp !== false) {
      writer.uint32(200).bool(message.requireSignUp);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserSessionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserSessionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orgName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tenantName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.email = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.provider = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.name = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.newUser = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.expiry = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.allowSignUp = reader.bool();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.loginTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.preferences = UserPreferences.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.signUp = SignUp.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.contexts.push(UserContext.decode(reader, reader.uint32()));
          continue;
        case 25:
          if (tag !== 200) {
            break;
          }

          message.requireSignUp = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUserSessionResponse {
    return {
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      orgName: isSet(object.orgName) ? globalThis.String(object.orgName) : "",
      tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "",
      tenantName: isSet(object.tenantName) ? globalThis.String(object.tenantName) : "",
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      provider: isSet(object.provider) ? globalThis.String(object.provider) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      newUser: isSet(object.newUser) ? globalThis.Boolean(object.newUser) : false,
      expiry: isSet(object.expiry) ? fromJsonTimestamp(object.expiry) : undefined,
      allowSignUp: isSet(object.allowSignUp) ? globalThis.Boolean(object.allowSignUp) : false,
      loginTime: isSet(object.loginTime) ? fromJsonTimestamp(object.loginTime) : undefined,
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      preferences: isSet(object.preferences) ? UserPreferences.fromJSON(object.preferences) : undefined,
      signUp: isSet(object.signUp) ? SignUp.fromJSON(object.signUp) : undefined,
      contexts: globalThis.Array.isArray(object?.contexts)
        ? object.contexts.map((e: any) => UserContext.fromJSON(e))
        : [],
      requireSignUp: isSet(object.requireSignUp) ? globalThis.Boolean(object.requireSignUp) : false,
    };
  },

  toJSON(message: GetUserSessionResponse): unknown {
    const obj: any = {};
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.orgName !== "") {
      obj.orgName = message.orgName;
    }
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    if (message.tenantName !== "") {
      obj.tenantName = message.tenantName;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.provider !== "") {
      obj.provider = message.provider;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.newUser !== false) {
      obj.newUser = message.newUser;
    }
    if (message.expiry !== undefined) {
      obj.expiry = message.expiry.toISOString();
    }
    if (message.allowSignUp !== false) {
      obj.allowSignUp = message.allowSignUp;
    }
    if (message.loginTime !== undefined) {
      obj.loginTime = message.loginTime.toISOString();
    }
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    if (message.preferences !== undefined) {
      obj.preferences = UserPreferences.toJSON(message.preferences);
    }
    if (message.signUp !== undefined) {
      obj.signUp = SignUp.toJSON(message.signUp);
    }
    if (message.contexts?.length) {
      obj.contexts = message.contexts.map((e) => UserContext.toJSON(e));
    }
    if (message.requireSignUp !== false) {
      obj.requireSignUp = message.requireSignUp;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserSessionResponse>, I>>(base?: I): GetUserSessionResponse {
    return GetUserSessionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserSessionResponse>, I>>(object: I): GetUserSessionResponse {
    const message = createBaseGetUserSessionResponse();
    message.orgId = object.orgId ?? "";
    message.orgName = object.orgName ?? "";
    message.tenantId = object.tenantId ?? "";
    message.tenantName = object.tenantName ?? "";
    message.email = object.email ?? "";
    message.provider = object.provider ?? "";
    message.name = object.name ?? "";
    message.newUser = object.newUser ?? false;
    message.expiry = object.expiry ?? undefined;
    message.allowSignUp = object.allowSignUp ?? false;
    message.loginTime = object.loginTime ?? undefined;
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    message.preferences = (object.preferences !== undefined && object.preferences !== null)
      ? UserPreferences.fromPartial(object.preferences)
      : undefined;
    message.signUp = (object.signUp !== undefined && object.signUp !== null)
      ? SignUp.fromPartial(object.signUp)
      : undefined;
    message.contexts = object.contexts?.map((e) => UserContext.fromPartial(e)) || [];
    message.requireSignUp = object.requireSignUp ?? false;
    return message;
  },
};

function createBaseGetUsersRequest(): GetUsersRequest {
  return { emails: [], provider: "", tenantId: "", enabled: false, roles: [], includeMetadata: false };
}

export const GetUsersRequest = {
  encode(message: GetUsersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.emails) {
      writer.uint32(10).string(v!);
    }
    if (message.provider !== "") {
      writer.uint32(18).string(message.provider);
    }
    if (message.tenantId !== "") {
      writer.uint32(26).string(message.tenantId);
    }
    if (message.enabled !== false) {
      writer.uint32(32).bool(message.enabled);
    }
    writer.uint32(42).fork();
    for (const v of message.roles) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.includeMetadata !== false) {
      writer.uint32(48).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUsersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUsersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.emails.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.provider = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 5:
          if (tag === 40) {
            message.roles.push(reader.int32() as any);

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.roles.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUsersRequest {
    return {
      emails: globalThis.Array.isArray(object?.emails) ? object.emails.map((e: any) => globalThis.String(e)) : [],
      provider: isSet(object.provider) ? globalThis.String(object.provider) : "",
      tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "",
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      roles: globalThis.Array.isArray(object?.roles) ? object.roles.map((e: any) => userRoleFromJSON(e)) : [],
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetUsersRequest): unknown {
    const obj: any = {};
    if (message.emails?.length) {
      obj.emails = message.emails;
    }
    if (message.provider !== "") {
      obj.provider = message.provider;
    }
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.roles?.length) {
      obj.roles = message.roles.map((e) => userRoleToJSON(e));
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUsersRequest>, I>>(base?: I): GetUsersRequest {
    return GetUsersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUsersRequest>, I>>(object: I): GetUsersRequest {
    const message = createBaseGetUsersRequest();
    message.emails = object.emails?.map((e) => e) || [];
    message.provider = object.provider ?? "";
    message.tenantId = object.tenantId ?? "";
    message.enabled = object.enabled ?? false;
    message.roles = object.roles?.map((e) => e) || [];
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseGetUserRequest(): GetUserRequest {
  return { id: "", includeMetadata: false };
}

export const GetUserRequest = {
  encode(message: GetUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(16).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUserRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetUserRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserRequest>, I>>(base?: I): GetUserRequest {
    return GetUserRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserRequest>, I>>(object: I): GetUserRequest {
    const message = createBaseGetUserRequest();
    message.id = object.id ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseSetUsersRequest(): SetUsersRequest {
  return { tenantId: "", users: [] };
}

export const SetUsersRequest = {
  encode(message: SetUsersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenantId !== "") {
      writer.uint32(10).string(message.tenantId);
    }
    for (const v of message.users) {
      User.encode(v!, writer.uint32(170).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetUsersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetUsersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.users.push(User.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetUsersRequest {
    return {
      tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "",
      users: globalThis.Array.isArray(object?.users) ? object.users.map((e: any) => User.fromJSON(e)) : [],
    };
  },

  toJSON(message: SetUsersRequest): unknown {
    const obj: any = {};
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    if (message.users?.length) {
      obj.users = message.users.map((e) => User.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetUsersRequest>, I>>(base?: I): SetUsersRequest {
    return SetUsersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetUsersRequest>, I>>(object: I): SetUsersRequest {
    const message = createBaseSetUsersRequest();
    message.tenantId = object.tenantId ?? "";
    message.users = object.users?.map((e) => User.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDeleteUserRequest(): DeleteUserRequest {
  return { id: "" };
}

export const DeleteUserRequest = {
  encode(message: DeleteUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteUserRequest {
    return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: DeleteUserRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteUserRequest>, I>>(base?: I): DeleteUserRequest {
    return DeleteUserRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteUserRequest>, I>>(object: I): DeleteUserRequest {
    const message = createBaseDeleteUserRequest();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseDeleteUsersRequest(): DeleteUsersRequest {
  return { ids: [] };
}

export const DeleteUsersRequest = {
  encode(message: DeleteUsersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.ids) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteUsersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteUsersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ids.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteUsersRequest {
    return { ids: globalThis.Array.isArray(object?.ids) ? object.ids.map((e: any) => globalThis.String(e)) : [] };
  },

  toJSON(message: DeleteUsersRequest): unknown {
    const obj: any = {};
    if (message.ids?.length) {
      obj.ids = message.ids;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteUsersRequest>, I>>(base?: I): DeleteUsersRequest {
    return DeleteUsersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteUsersRequest>, I>>(object: I): DeleteUsersRequest {
    const message = createBaseDeleteUsersRequest();
    message.ids = object.ids?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetStartDataRequest(): GetStartDataRequest {
  return { userPreferences: undefined };
}

export const GetStartDataRequest = {
  encode(message: GetStartDataRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userPreferences !== undefined) {
      UserPreferences.encode(message.userPreferences, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetStartDataRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetStartDataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userPreferences = UserPreferences.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetStartDataRequest {
    return {
      userPreferences: isSet(object.userPreferences) ? UserPreferences.fromJSON(object.userPreferences) : undefined,
    };
  },

  toJSON(message: GetStartDataRequest): unknown {
    const obj: any = {};
    if (message.userPreferences !== undefined) {
      obj.userPreferences = UserPreferences.toJSON(message.userPreferences);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetStartDataRequest>, I>>(base?: I): GetStartDataRequest {
    return GetStartDataRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetStartDataRequest>, I>>(object: I): GetStartDataRequest {
    const message = createBaseGetStartDataRequest();
    message.userPreferences = (object.userPreferences !== undefined && object.userPreferences !== null)
      ? UserPreferences.fromPartial(object.userPreferences)
      : undefined;
    return message;
  },
};

function createBaseTenantsResponse(): TenantsResponse {
  return { tenants: [] };
}

export const TenantsResponse = {
  encode(message: TenantsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tenants) {
      Tenant.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TenantsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTenantsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenants.push(Tenant.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TenantsResponse {
    return {
      tenants: globalThis.Array.isArray(object?.tenants) ? object.tenants.map((e: any) => Tenant.fromJSON(e)) : [],
    };
  },

  toJSON(message: TenantsResponse): unknown {
    const obj: any = {};
    if (message.tenants?.length) {
      obj.tenants = message.tenants.map((e) => Tenant.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TenantsResponse>, I>>(base?: I): TenantsResponse {
    return TenantsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TenantsResponse>, I>>(object: I): TenantsResponse {
    const message = createBaseTenantsResponse();
    message.tenants = object.tenants?.map((e) => Tenant.fromPartial(e)) || [];
    return message;
  },
};

function createBaseNewOrgRequest(): NewOrgRequest {
  return { signUp: undefined, userPreferences: undefined };
}

export const NewOrgRequest = {
  encode(message: NewOrgRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.signUp !== undefined) {
      SignUp.encode(message.signUp, writer.uint32(10).fork()).ldelim();
    }
    if (message.userPreferences !== undefined) {
      UserPreferences.encode(message.userPreferences, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NewOrgRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNewOrgRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.signUp = SignUp.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userPreferences = UserPreferences.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NewOrgRequest {
    return {
      signUp: isSet(object.signUp) ? SignUp.fromJSON(object.signUp) : undefined,
      userPreferences: isSet(object.userPreferences) ? UserPreferences.fromJSON(object.userPreferences) : undefined,
    };
  },

  toJSON(message: NewOrgRequest): unknown {
    const obj: any = {};
    if (message.signUp !== undefined) {
      obj.signUp = SignUp.toJSON(message.signUp);
    }
    if (message.userPreferences !== undefined) {
      obj.userPreferences = UserPreferences.toJSON(message.userPreferences);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NewOrgRequest>, I>>(base?: I): NewOrgRequest {
    return NewOrgRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NewOrgRequest>, I>>(object: I): NewOrgRequest {
    const message = createBaseNewOrgRequest();
    message.signUp = (object.signUp !== undefined && object.signUp !== null)
      ? SignUp.fromPartial(object.signUp)
      : undefined;
    message.userPreferences = (object.userPreferences !== undefined && object.userPreferences !== null)
      ? UserPreferences.fromPartial(object.userPreferences)
      : undefined;
    return message;
  },
};

function createBaseNewOrgResponse(): NewOrgResponse {
  return { id: "", user: undefined, userPreferences: undefined };
}

export const NewOrgResponse = {
  encode(message: NewOrgResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(18).fork()).ldelim();
    }
    if (message.userPreferences !== undefined) {
      UserPreferences.encode(message.userPreferences, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NewOrgResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNewOrgResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.userPreferences = UserPreferences.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NewOrgResponse {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      userPreferences: isSet(object.userPreferences) ? UserPreferences.fromJSON(object.userPreferences) : undefined,
    };
  },

  toJSON(message: NewOrgResponse): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    if (message.userPreferences !== undefined) {
      obj.userPreferences = UserPreferences.toJSON(message.userPreferences);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NewOrgResponse>, I>>(base?: I): NewOrgResponse {
    return NewOrgResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NewOrgResponse>, I>>(object: I): NewOrgResponse {
    const message = createBaseNewOrgResponse();
    message.id = object.id ?? "";
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    message.userPreferences = (object.userPreferences !== undefined && object.userPreferences !== null)
      ? UserPreferences.fromPartial(object.userPreferences)
      : undefined;
    return message;
  },
};

function createBaseAddTenantRequest(): AddTenantRequest {
  return { tenant: undefined, user: undefined };
}

export const AddTenantRequest = {
  encode(message: AddTenantRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenant !== undefined) {
      Tenant.encode(message.tenant, writer.uint32(10).fork()).ldelim();
    }
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddTenantRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddTenantRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenant = Tenant.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddTenantRequest {
    return {
      tenant: isSet(object.tenant) ? Tenant.fromJSON(object.tenant) : undefined,
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
    };
  },

  toJSON(message: AddTenantRequest): unknown {
    const obj: any = {};
    if (message.tenant !== undefined) {
      obj.tenant = Tenant.toJSON(message.tenant);
    }
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddTenantRequest>, I>>(base?: I): AddTenantRequest {
    return AddTenantRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddTenantRequest>, I>>(object: I): AddTenantRequest {
    const message = createBaseAddTenantRequest();
    message.tenant = (object.tenant !== undefined && object.tenant !== null)
      ? Tenant.fromPartial(object.tenant)
      : undefined;
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    return message;
  },
};

function createBaseAddTenantResponse(): AddTenantResponse {
  return { tenant: undefined, user: undefined };
}

export const AddTenantResponse = {
  encode(message: AddTenantResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenant !== undefined) {
      Tenant.encode(message.tenant, writer.uint32(10).fork()).ldelim();
    }
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddTenantResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddTenantResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenant = Tenant.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddTenantResponse {
    return {
      tenant: isSet(object.tenant) ? Tenant.fromJSON(object.tenant) : undefined,
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
    };
  },

  toJSON(message: AddTenantResponse): unknown {
    const obj: any = {};
    if (message.tenant !== undefined) {
      obj.tenant = Tenant.toJSON(message.tenant);
    }
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddTenantResponse>, I>>(base?: I): AddTenantResponse {
    return AddTenantResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddTenantResponse>, I>>(object: I): AddTenantResponse {
    const message = createBaseAddTenantResponse();
    message.tenant = (object.tenant !== undefined && object.tenant !== null)
      ? Tenant.fromPartial(object.tenant)
      : undefined;
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    return message;
  },
};

function createBaseUpdateTenantRequest(): UpdateTenantRequest {
  return { id: "", tenant: undefined };
}

export const UpdateTenantRequest = {
  encode(message: UpdateTenantRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.tenant !== undefined) {
      Tenant.encode(message.tenant, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTenantRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTenantRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tenant = Tenant.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateTenantRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      tenant: isSet(object.tenant) ? Tenant.fromJSON(object.tenant) : undefined,
    };
  },

  toJSON(message: UpdateTenantRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.tenant !== undefined) {
      obj.tenant = Tenant.toJSON(message.tenant);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateTenantRequest>, I>>(base?: I): UpdateTenantRequest {
    return UpdateTenantRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateTenantRequest>, I>>(object: I): UpdateTenantRequest {
    const message = createBaseUpdateTenantRequest();
    message.id = object.id ?? "";
    message.tenant = (object.tenant !== undefined && object.tenant !== null)
      ? Tenant.fromPartial(object.tenant)
      : undefined;
    return message;
  },
};

function createBaseUpdateTenantsRequest(): UpdateTenantsRequest {
  return { tenants: [] };
}

export const UpdateTenantsRequest = {
  encode(message: UpdateTenantsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tenants) {
      Tenant.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTenantsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTenantsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenants.push(Tenant.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateTenantsRequest {
    return {
      tenants: globalThis.Array.isArray(object?.tenants) ? object.tenants.map((e: any) => Tenant.fromJSON(e)) : [],
    };
  },

  toJSON(message: UpdateTenantsRequest): unknown {
    const obj: any = {};
    if (message.tenants?.length) {
      obj.tenants = message.tenants.map((e) => Tenant.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateTenantsRequest>, I>>(base?: I): UpdateTenantsRequest {
    return UpdateTenantsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateTenantsRequest>, I>>(object: I): UpdateTenantsRequest {
    const message = createBaseUpdateTenantsRequest();
    message.tenants = object.tenants?.map((e) => Tenant.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetTenantsRequest(): GetTenantsRequest {
  return { names: [], enabled: false };
}

export const GetTenantsRequest = {
  encode(message: GetTenantsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.names) {
      writer.uint32(10).string(v!);
    }
    if (message.enabled !== false) {
      writer.uint32(16).bool(message.enabled);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTenantsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTenantsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.names.push(reader.string());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTenantsRequest {
    return {
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
    };
  },

  toJSON(message: GetTenantsRequest): unknown {
    const obj: any = {};
    if (message.names?.length) {
      obj.names = message.names;
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTenantsRequest>, I>>(base?: I): GetTenantsRequest {
    return GetTenantsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTenantsRequest>, I>>(object: I): GetTenantsRequest {
    const message = createBaseGetTenantsRequest();
    message.names = object.names?.map((e) => e) || [];
    message.enabled = object.enabled ?? false;
    return message;
  },
};

function createBaseSwitchTenantRequest(): SwitchTenantRequest {
  return { tenantId: "", expiry: "" };
}

export const SwitchTenantRequest = {
  encode(message: SwitchTenantRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenantId !== "") {
      writer.uint32(10).string(message.tenantId);
    }
    if (message.expiry !== "") {
      writer.uint32(66).string(message.expiry);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SwitchTenantRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSwitchTenantRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.expiry = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SwitchTenantRequest {
    return {
      tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "",
      expiry: isSet(object.expiry) ? globalThis.String(object.expiry) : "",
    };
  },

  toJSON(message: SwitchTenantRequest): unknown {
    const obj: any = {};
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    if (message.expiry !== "") {
      obj.expiry = message.expiry;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SwitchTenantRequest>, I>>(base?: I): SwitchTenantRequest {
    return SwitchTenantRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SwitchTenantRequest>, I>>(object: I): SwitchTenantRequest {
    const message = createBaseSwitchTenantRequest();
    message.tenantId = object.tenantId ?? "";
    message.expiry = object.expiry ?? "";
    return message;
  },
};

function createBaseDeleteTenantRequest(): DeleteTenantRequest {
  return { id: "" };
}

export const DeleteTenantRequest = {
  encode(message: DeleteTenantRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteTenantRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteTenantRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteTenantRequest {
    return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: DeleteTenantRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteTenantRequest>, I>>(base?: I): DeleteTenantRequest {
    return DeleteTenantRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteTenantRequest>, I>>(object: I): DeleteTenantRequest {
    const message = createBaseDeleteTenantRequest();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseDeleteTenantsRequest(): DeleteTenantsRequest {
  return { ids: [] };
}

export const DeleteTenantsRequest = {
  encode(message: DeleteTenantsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.ids) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteTenantsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteTenantsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ids.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteTenantsRequest {
    return { ids: globalThis.Array.isArray(object?.ids) ? object.ids.map((e: any) => globalThis.String(e)) : [] };
  },

  toJSON(message: DeleteTenantsRequest): unknown {
    const obj: any = {};
    if (message.ids?.length) {
      obj.ids = message.ids;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteTenantsRequest>, I>>(base?: I): DeleteTenantsRequest {
    return DeleteTenantsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteTenantsRequest>, I>>(object: I): DeleteTenantsRequest {
    const message = createBaseDeleteTenantsRequest();
    message.ids = object.ids?.map((e) => e) || [];
    return message;
  },
};

function createBaseStartAgentSessionRequest(): StartAgentSessionRequest {
  return { serialNumber: "", switchId: "", expiry: "" };
}

export const StartAgentSessionRequest = {
  encode(message: StartAgentSessionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.serialNumber !== "") {
      writer.uint32(10).string(message.serialNumber);
    }
    if (message.switchId !== "") {
      writer.uint32(18).string(message.switchId);
    }
    if (message.expiry !== "") {
      writer.uint32(26).string(message.expiry);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StartAgentSessionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartAgentSessionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.switchId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.expiry = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StartAgentSessionRequest {
    return {
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
      switchId: isSet(object.switchId) ? globalThis.String(object.switchId) : "",
      expiry: isSet(object.expiry) ? globalThis.String(object.expiry) : "",
    };
  },

  toJSON(message: StartAgentSessionRequest): unknown {
    const obj: any = {};
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    if (message.switchId !== "") {
      obj.switchId = message.switchId;
    }
    if (message.expiry !== "") {
      obj.expiry = message.expiry;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StartAgentSessionRequest>, I>>(base?: I): StartAgentSessionRequest {
    return StartAgentSessionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StartAgentSessionRequest>, I>>(object: I): StartAgentSessionRequest {
    const message = createBaseStartAgentSessionRequest();
    message.serialNumber = object.serialNumber ?? "";
    message.switchId = object.switchId ?? "";
    message.expiry = object.expiry ?? "";
    return message;
  },
};

function createBaseRefreshAgentSessionRequest(): RefreshAgentSessionRequest {
  return { expiry: "" };
}

export const RefreshAgentSessionRequest = {
  encode(message: RefreshAgentSessionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.expiry !== "") {
      writer.uint32(10).string(message.expiry);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RefreshAgentSessionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefreshAgentSessionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.expiry = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RefreshAgentSessionRequest {
    return { expiry: isSet(object.expiry) ? globalThis.String(object.expiry) : "" };
  },

  toJSON(message: RefreshAgentSessionRequest): unknown {
    const obj: any = {};
    if (message.expiry !== "") {
      obj.expiry = message.expiry;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RefreshAgentSessionRequest>, I>>(base?: I): RefreshAgentSessionRequest {
    return RefreshAgentSessionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RefreshAgentSessionRequest>, I>>(object: I): RefreshAgentSessionRequest {
    const message = createBaseRefreshAgentSessionRequest();
    message.expiry = object.expiry ?? "";
    return message;
  },
};

function createBaseStartAgentSessionResponse(): StartAgentSessionResponse {
  return { serialNumber: "", switchId: "", orgId: "", fabricId: "", parked: false, tokens: [] };
}

export const StartAgentSessionResponse = {
  encode(message: StartAgentSessionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.serialNumber !== "") {
      writer.uint32(10).string(message.serialNumber);
    }
    if (message.switchId !== "") {
      writer.uint32(18).string(message.switchId);
    }
    if (message.orgId !== "") {
      writer.uint32(26).string(message.orgId);
    }
    if (message.fabricId !== "") {
      writer.uint32(42).string(message.fabricId);
    }
    if (message.parked !== false) {
      writer.uint32(56).bool(message.parked);
    }
    for (const v of message.tokens) {
      Token.encode(v!, writer.uint32(402).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StartAgentSessionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartAgentSessionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.switchId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.parked = reader.bool();
          continue;
        case 50:
          if (tag !== 402) {
            break;
          }

          message.tokens.push(Token.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StartAgentSessionResponse {
    return {
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
      switchId: isSet(object.switchId) ? globalThis.String(object.switchId) : "",
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      parked: isSet(object.parked) ? globalThis.Boolean(object.parked) : false,
      tokens: globalThis.Array.isArray(object?.tokens) ? object.tokens.map((e: any) => Token.fromJSON(e)) : [],
    };
  },

  toJSON(message: StartAgentSessionResponse): unknown {
    const obj: any = {};
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    if (message.switchId !== "") {
      obj.switchId = message.switchId;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.parked !== false) {
      obj.parked = message.parked;
    }
    if (message.tokens?.length) {
      obj.tokens = message.tokens.map((e) => Token.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StartAgentSessionResponse>, I>>(base?: I): StartAgentSessionResponse {
    return StartAgentSessionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StartAgentSessionResponse>, I>>(object: I): StartAgentSessionResponse {
    const message = createBaseStartAgentSessionResponse();
    message.serialNumber = object.serialNumber ?? "";
    message.switchId = object.switchId ?? "";
    message.orgId = object.orgId ?? "";
    message.fabricId = object.fabricId ?? "";
    message.parked = object.parked ?? false;
    message.tokens = object.tokens?.map((e) => Token.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetAgentSessionRequest(): GetAgentSessionRequest {
  return {};
}

export const GetAgentSessionRequest = {
  encode(_: GetAgentSessionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAgentSessionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAgentSessionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetAgentSessionRequest {
    return {};
  },

  toJSON(_: GetAgentSessionRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAgentSessionRequest>, I>>(base?: I): GetAgentSessionRequest {
    return GetAgentSessionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAgentSessionRequest>, I>>(_: I): GetAgentSessionRequest {
    const message = createBaseGetAgentSessionRequest();
    return message;
  },
};

function createBaseDeleteAgentSessionRequest(): DeleteAgentSessionRequest {
  return {};
}

export const DeleteAgentSessionRequest = {
  encode(_: DeleteAgentSessionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteAgentSessionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteAgentSessionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): DeleteAgentSessionRequest {
    return {};
  },

  toJSON(_: DeleteAgentSessionRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteAgentSessionRequest>, I>>(base?: I): DeleteAgentSessionRequest {
    return DeleteAgentSessionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteAgentSessionRequest>, I>>(_: I): DeleteAgentSessionRequest {
    const message = createBaseDeleteAgentSessionRequest();
    return message;
  },
};

function createBaseGetAgentSessionResponse(): GetAgentSessionResponse {
  return { serialNumber: "", switchId: "", orgId: "", fabricId: "", parked: false, expiry: undefined };
}

export const GetAgentSessionResponse = {
  encode(message: GetAgentSessionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.serialNumber !== "") {
      writer.uint32(10).string(message.serialNumber);
    }
    if (message.switchId !== "") {
      writer.uint32(18).string(message.switchId);
    }
    if (message.orgId !== "") {
      writer.uint32(26).string(message.orgId);
    }
    if (message.fabricId !== "") {
      writer.uint32(42).string(message.fabricId);
    }
    if (message.parked !== false) {
      writer.uint32(56).bool(message.parked);
    }
    if (message.expiry !== undefined) {
      Timestamp.encode(toTimestamp(message.expiry), writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAgentSessionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAgentSessionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.switchId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.parked = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.expiry = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAgentSessionResponse {
    return {
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
      switchId: isSet(object.switchId) ? globalThis.String(object.switchId) : "",
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      parked: isSet(object.parked) ? globalThis.Boolean(object.parked) : false,
      expiry: isSet(object.expiry) ? fromJsonTimestamp(object.expiry) : undefined,
    };
  },

  toJSON(message: GetAgentSessionResponse): unknown {
    const obj: any = {};
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    if (message.switchId !== "") {
      obj.switchId = message.switchId;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.parked !== false) {
      obj.parked = message.parked;
    }
    if (message.expiry !== undefined) {
      obj.expiry = message.expiry.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAgentSessionResponse>, I>>(base?: I): GetAgentSessionResponse {
    return GetAgentSessionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAgentSessionResponse>, I>>(object: I): GetAgentSessionResponse {
    const message = createBaseGetAgentSessionResponse();
    message.serialNumber = object.serialNumber ?? "";
    message.switchId = object.switchId ?? "";
    message.orgId = object.orgId ?? "";
    message.fabricId = object.fabricId ?? "";
    message.parked = object.parked ?? false;
    message.expiry = object.expiry ?? undefined;
    return message;
  },
};

function createBaseUpdateUser(): UpdateUser {
  return { role: 0, enabled: false, labels: [] };
}

export const UpdateUser = {
  encode(message: UpdateUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.role !== 0) {
      writer.uint32(8).int32(message.role);
    }
    if (message.enabled !== false) {
      writer.uint32(16).bool(message.enabled);
    }
    for (const v of message.labels) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.role = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.labels.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateUser {
    return {
      role: isSet(object.role) ? userRoleFromJSON(object.role) : 0,
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: UpdateUser): unknown {
    const obj: any = {};
    if (message.role !== 0) {
      obj.role = userRoleToJSON(message.role);
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateUser>, I>>(base?: I): UpdateUser {
    return UpdateUser.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateUser>, I>>(object: I): UpdateUser {
    const message = createBaseUpdateUser();
    message.role = object.role ?? 0;
    message.enabled = object.enabled ?? false;
    message.labels = object.labels?.map((e) => e) || [];
    return message;
  },
};

function createBaseUpdateUserRequest(): UpdateUserRequest {
  return { id: "", user: undefined };
}

export const UpdateUserRequest = {
  encode(message: UpdateUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.user !== undefined) {
      UpdateUser.encode(message.user, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = UpdateUser.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateUserRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      user: isSet(object.user) ? UpdateUser.fromJSON(object.user) : undefined,
    };
  },

  toJSON(message: UpdateUserRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.user !== undefined) {
      obj.user = UpdateUser.toJSON(message.user);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateUserRequest>, I>>(base?: I): UpdateUserRequest {
    return UpdateUserRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateUserRequest>, I>>(object: I): UpdateUserRequest {
    const message = createBaseUpdateUserRequest();
    message.id = object.id ?? "";
    message.user = (object.user !== undefined && object.user !== null)
      ? UpdateUser.fromPartial(object.user)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
