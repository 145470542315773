import {
  ReactNode,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import {
  CliCommandResponse,
  CommandAction,
  ResultFormat
} from "src/gen/schema/bilge/api";
import "./mockShellOutput.scss";
import { Card } from "@magnetic/card";
import Papa from "papaparse";
import { StringColumn } from "src/components/tables/columnConfigs/stringColumn";
import TypedTable from "src/components/typedTable";
import { Button } from "@magnetic/button";
import { copyMockshellCmds } from "./mockShellUtils";
import { Tooltip } from "@magnetic/tooltip";
import { CopySimple } from "@magnetic/icons";

interface MockShellOutputProps {
  readonly loading?: boolean;
  readonly cmdResult?: CliCommandResponse;
  readonly cmdAction?: CommandAction;
}

interface CsvData {
  [key: string]: string;
}
interface MockShellOutputCopyProps {
  readonly elementRef: RefObject<HTMLDivElement> | null;
}
// Magnetic's CopyToClipboard component expects only string as value for copying and does not maintains the formatting.
const MockShellOutputCopy = ({ elementRef }: MockShellOutputCopyProps) => {
  return (
    <Tooltip title="Copy output to clipboard">
      <Button
        name="mock-shell-copy"
        kind="tertiary"
        size="sm"
        icon={<CopySimple />}
        onClick={() => copyMockshellCmds(elementRef)}
      >
        Copy
      </Button>
    </Tooltip>
  );
};

const MockShellCsv = (props: MockShellOutputProps) => {
  const { cmdResult } = props;
  const [tableData, setTableData] = useState<CsvData[]>([]);
  const [tableColumn, setTableColumn] =
    useState<Record<string, StringColumn>>();
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cmdResult?.executionResults) {
      const resultCSV = atob(cmdResult.executionResults);
      Papa.parse<CsvData>(resultCSV, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          if (result.data && result.data.length > 0) {
            const columns = Object.keys(result.data[0] as CsvData).reduce(
              (acc: Record<string, StringColumn>, key) => {
                acc[key] = new StringColumn({
                  label: [key]
                });
                return acc;
              },
              {}
            );
            setTableColumn(columns);
            setTableData(result.data);
          }
        }
      });
    }
  }, [cmdResult?.executionResults]);

  if (!cmdResult?.executionResults) {
    return null;
  }
  return (
    <>
      <div className="mock-shell-copy">
        <MockShellOutputCopy elementRef={divRef} />
      </div>
      <div ref={divRef} className="mock-shell-output-table-container">
        {tableColumn && (
          <TypedTable
            stickyHeader={false}
            name="mock-shell-out-table"
            columns={tableColumn}
            data={tableData}
          />
        )}
      </div>
    </>
  );
};

const MockShellText = (props: MockShellOutputProps) => {
  const { cmdResult } = props;
  const [formattedText, setFormattedText] = useState<string>("");
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cmdResult?.executionResults) {
      const result = atob(cmdResult.executionResults);
      setFormattedText(result);
    }
  }, [cmdResult?.executionResults]);
  return (
    <>
      <div className="mock-shell-copy">
        <MockShellOutputCopy elementRef={divRef} />
      </div>
      <div ref={divRef} className="mock-shell-output-text-container">
        <pre className="mock-shell-output-text">{formattedText}</pre>
      </div>
    </>
  );
};

const MockShellHelpText = (props: MockShellOutputProps) => {
  const { cmdResult, cmdAction } = props;
  const formattedHelpText = () => {
    let helpResult: ReactNode[] = [];
    if (cmdResult?.commandCompletions) {
      helpResult = cmdResult?.commandCompletions.map((cmd) => {
        return (
          <div key={`${cmd.keyword}`} className="mock-shell-output-help">
            <div className="mock-shell-output-help-key">{cmd.keyword}</div>
            <div className="mock-shell-output-help">{cmd.helpString}</div>
          </div>
        );
      });
    }
    if (cmdResult?.validCommand) {
      helpResult.unshift(
        <div key="enter" className="mock-shell-output-help">
          <div className="mock-shell-output-help-key">{"<enter>"}</div>
          {!(cmdAction === CommandAction.EXPAND) && (
            <div className="mock-shell-output-help">
              {"Press enter to execute the command"}
            </div>
          )}
        </div>
      );
    }
    if (cmdAction === CommandAction.COMPLETE) {
      helpResult.unshift(
        <div key="complete-title" className="cmd-complete-title">
          Enter one of the options below to complete the keyword
        </div>
      );
    }
    return helpResult;
  };
  return (
    <Card className="mock-shell-output-help-container">
      {formattedHelpText()}
    </Card>
  );
};

const DefaultResult = () => {
  return "";
};

export const MockShellOutput = (props: MockShellOutputProps) => {
  const { cmdResult, cmdAction } = props;
  const MockShellOutputComponent = useMemo(() => {
    if (
      cmdAction === CommandAction.COMPLETE &&
      (cmdResult?.commandCompletions?.length || 0) <= 1
    ) {
      return DefaultResult;
    }
    if (
      (cmdResult?.commandCompletions &&
        cmdResult?.commandCompletions.length > 0) ||
      cmdResult?.validCommand
    ) {
      return MockShellHelpText;
    }
    switch (cmdResult?.resultFormat) {
      // Backend needs to fix the resultFormat type returned for Text format here
      case ResultFormat.TEXT:
        return MockShellText;
      case ResultFormat.CSV:
        return MockShellCsv;
      default:
        return DefaultResult;
    }
  }, [cmdResult, cmdAction]);
  return (
    <>
      {cmdResult ? (
        <MockShellOutputComponent cmdResult={cmdResult} cmdAction={cmdAction} />
      ) : (
        ""
      )}
    </>
  );
};
