// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: configd/node.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { ManagementPort, NetworkPort } from "../models/models";

export const protobufPackage = "configd";

/** The request to update a specific port of a node in a fabric. */
export interface UpdatePortRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The node id or name. */
  nodeId: string;
  /** The port name, id or index. */
  portId: string;
  /** The updated port definition. */
  port: NetworkPort | undefined;
}

/**
 * The response returned to a request for the list of management ports of a specific node in
 * a fabric.
 */
export interface ManagementPortsResponse {
  /** The list of management ports of a specific node. */
  ports: ManagementPort[];
}

/** The request for the list of management ports of a specific node in a fabric. */
export interface GetManagementPortsRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The node id or name. */
  nodeId: string;
  /**
   * The candidate configuration name. If not set the default candidate configuration values
   * are returned.
   */
  candidate: string;
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

/** The request for a specific management port of a node in a fabric. */
export interface GetManagementPortRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The node id or name. */
  nodeId: string;
  /** The management port id or name. */
  id: string;
  /**
   * The candidate configuration name. If not set the default candidate configuration values
   * are returned.
   */
  candidate: string;
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

/** The request to add one or more management ports to a specific node in a fabric. */
export interface AddManagementPortsRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The node id or name. */
  nodeId: string;
  /** A list of management ports to be added to the node. */
  ports: ManagementPort[];
}

/** The request to update a specific management port of a node in a fabric. */
export interface UpdateManagementPortRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The node id or name. */
  nodeId: string;
  /** The management port id or name. */
  id: string;
  /** The updated management port definition. */
  port: ManagementPort | undefined;
}

/** The request to reset a specific management port of a node in a fabric to its default values. */
export interface ResetManagementPortRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The node id or name. */
  nodeId: string;
  /** The management port id or name. */
  id: string;
}

function createBaseUpdatePortRequest(): UpdatePortRequest {
  return { fabricId: "", nodeId: "", portId: "", port: undefined };
}

export const UpdatePortRequest = {
  encode(message: UpdatePortRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.portId !== "") {
      writer.uint32(26).string(message.portId);
    }
    if (message.port !== undefined) {
      NetworkPort.encode(message.port, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdatePortRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdatePortRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.portId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.port = NetworkPort.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdatePortRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      portId: isSet(object.portId) ? globalThis.String(object.portId) : "",
      port: isSet(object.port) ? NetworkPort.fromJSON(object.port) : undefined,
    };
  },

  toJSON(message: UpdatePortRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.portId !== "") {
      obj.portId = message.portId;
    }
    if (message.port !== undefined) {
      obj.port = NetworkPort.toJSON(message.port);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdatePortRequest>, I>>(base?: I): UpdatePortRequest {
    return UpdatePortRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdatePortRequest>, I>>(object: I): UpdatePortRequest {
    const message = createBaseUpdatePortRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.portId = object.portId ?? "";
    message.port = (object.port !== undefined && object.port !== null)
      ? NetworkPort.fromPartial(object.port)
      : undefined;
    return message;
  },
};

function createBaseManagementPortsResponse(): ManagementPortsResponse {
  return { ports: [] };
}

export const ManagementPortsResponse = {
  encode(message: ManagementPortsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.ports) {
      ManagementPort.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ManagementPortsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseManagementPortsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ports.push(ManagementPort.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ManagementPortsResponse {
    return {
      ports: globalThis.Array.isArray(object?.ports) ? object.ports.map((e: any) => ManagementPort.fromJSON(e)) : [],
    };
  },

  toJSON(message: ManagementPortsResponse): unknown {
    const obj: any = {};
    if (message.ports?.length) {
      obj.ports = message.ports.map((e) => ManagementPort.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ManagementPortsResponse>, I>>(base?: I): ManagementPortsResponse {
    return ManagementPortsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ManagementPortsResponse>, I>>(object: I): ManagementPortsResponse {
    const message = createBaseManagementPortsResponse();
    message.ports = object.ports?.map((e) => ManagementPort.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetManagementPortsRequest(): GetManagementPortsRequest {
  return { fabricId: "", nodeId: "", candidate: "", includeMetadata: false };
}

export const GetManagementPortsRequest = {
  encode(message: GetManagementPortsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.candidate !== "") {
      writer.uint32(26).string(message.candidate);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(32).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetManagementPortsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetManagementPortsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetManagementPortsRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetManagementPortsRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetManagementPortsRequest>, I>>(base?: I): GetManagementPortsRequest {
    return GetManagementPortsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetManagementPortsRequest>, I>>(object: I): GetManagementPortsRequest {
    const message = createBaseGetManagementPortsRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.candidate = object.candidate ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseGetManagementPortRequest(): GetManagementPortRequest {
  return { fabricId: "", nodeId: "", id: "", candidate: "", includeMetadata: false };
}

export const GetManagementPortRequest = {
  encode(message: GetManagementPortRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.id !== "") {
      writer.uint32(26).string(message.id);
    }
    if (message.candidate !== "") {
      writer.uint32(34).string(message.candidate);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(40).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetManagementPortRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetManagementPortRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.id = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetManagementPortRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetManagementPortRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetManagementPortRequest>, I>>(base?: I): GetManagementPortRequest {
    return GetManagementPortRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetManagementPortRequest>, I>>(object: I): GetManagementPortRequest {
    const message = createBaseGetManagementPortRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.id = object.id ?? "";
    message.candidate = object.candidate ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseAddManagementPortsRequest(): AddManagementPortsRequest {
  return { fabricId: "", nodeId: "", ports: [] };
}

export const AddManagementPortsRequest = {
  encode(message: AddManagementPortsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    for (const v of message.ports) {
      ManagementPort.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddManagementPortsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddManagementPortsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ports.push(ManagementPort.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddManagementPortsRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      ports: globalThis.Array.isArray(object?.ports) ? object.ports.map((e: any) => ManagementPort.fromJSON(e)) : [],
    };
  },

  toJSON(message: AddManagementPortsRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.ports?.length) {
      obj.ports = message.ports.map((e) => ManagementPort.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddManagementPortsRequest>, I>>(base?: I): AddManagementPortsRequest {
    return AddManagementPortsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddManagementPortsRequest>, I>>(object: I): AddManagementPortsRequest {
    const message = createBaseAddManagementPortsRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.ports = object.ports?.map((e) => ManagementPort.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateManagementPortRequest(): UpdateManagementPortRequest {
  return { fabricId: "", nodeId: "", id: "", port: undefined };
}

export const UpdateManagementPortRequest = {
  encode(message: UpdateManagementPortRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.id !== "") {
      writer.uint32(26).string(message.id);
    }
    if (message.port !== undefined) {
      ManagementPort.encode(message.port, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateManagementPortRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateManagementPortRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.id = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.port = ManagementPort.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateManagementPortRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      port: isSet(object.port) ? ManagementPort.fromJSON(object.port) : undefined,
    };
  },

  toJSON(message: UpdateManagementPortRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.port !== undefined) {
      obj.port = ManagementPort.toJSON(message.port);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateManagementPortRequest>, I>>(base?: I): UpdateManagementPortRequest {
    return UpdateManagementPortRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateManagementPortRequest>, I>>(object: I): UpdateManagementPortRequest {
    const message = createBaseUpdateManagementPortRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.id = object.id ?? "";
    message.port = (object.port !== undefined && object.port !== null)
      ? ManagementPort.fromPartial(object.port)
      : undefined;
    return message;
  },
};

function createBaseResetManagementPortRequest(): ResetManagementPortRequest {
  return { fabricId: "", nodeId: "", id: "" };
}

export const ResetManagementPortRequest = {
  encode(message: ResetManagementPortRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.id !== "") {
      writer.uint32(26).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResetManagementPortRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResetManagementPortRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ResetManagementPortRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      id: isSet(object.id) ? globalThis.String(object.id) : "",
    };
  },

  toJSON(message: ResetManagementPortRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ResetManagementPortRequest>, I>>(base?: I): ResetManagementPortRequest {
    return ResetManagementPortRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ResetManagementPortRequest>, I>>(object: I): ResetManagementPortRequest {
    const message = createBaseResetManagementPortRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.id = object.id ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
