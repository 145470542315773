import {
  GetBgpPeersRequest,
  CommitFabricCandidateRequest,
  CommitFabricCandidateResponse,
  GetDeviceModelsRequest,
  GetDeviceModelsResponse,
  GetCableModelsRequest,
  GetCableModelsResponse,
  GetFabricCandidatesRequest,
  GetFabricCandidatesResponse,
  GetFabricConfigRequest,
  GetFabricConfigResponse,
  GetFabricInventoriesRequest,
  GetFabricInventoriesResponse,
  GetFabricsRequest,
  GetFabricsResponse,
  GetSubInterfacesRequest,
  GetNodesPortsRequest,
  GetNodesPortsResponse,
  GetNodesRequest,
  GetPerVlanStpRequest,
  GetPerVlanStpResponse,
  GetStaticRoutesRequest,
  GetVlansRequest,
  GetVnisRequest,
  GetVrfsRequest,
  GetPortChannelsRequest,
  ProvisionRequest,
  ProvisionResponse,
  RevertFabricCandidateRequest,
  ReviewFabricCandidateRequest,
  ReviewFabricCandidateResponse,
  GetFabricAssemblyRequest,
  GetFabricBomRequest,
  GetPortBreakoutsRequest,
  GetDhcpRelaysRequest,
  GetBgpPoliciesRequest,
  ClaimDevicesResponse,
  ClaimDevicesRequest
} from "src/gen/schema/configd/api";
import {
  AddManagementPortsRequest,
  GetManagementPortsRequest,
  ManagementPortsResponse,
  UpdateManagementPortRequest
} from "src/gen/schema/configd/node";
import { ConfigDClientImpl } from "src/gen/schema/configd/service";
import { ManagementPort } from "src/gen/schema/models/models";
import { Empty } from "src/gen/schema/common/empty";
import { GrpcDebugableWebImpl } from "./grpcDebugableWebImpl";

const rpc = new GrpcDebugableWebImpl("", { debug: false });
const configDClient = new ConfigDClientImpl(rpc);

/**
 * Basic access to the Raw GRPC
 * ConfigD api(s). Maybe not
 * used except in cases where you
 * need more control over generating
 * the request with custom filters
 * or other configs
 */
export const ConfigD = {
  getFabricConfig: (
    req: Partial<GetFabricConfigRequest>
  ): Promise<GetFabricConfigResponse> => {
    return configDClient.GetFabricConfig(req);
  },
  getCableModels: (
    req: Partial<GetCableModelsRequest>
  ): Promise<GetCableModelsResponse> => {
    return configDClient.GetCableModels(req);
  },
  getFabrics: (
    req: Partial<GetFabricsRequest>
  ): Promise<GetFabricsResponse> => {
    return configDClient.GetFabrics(req);
  },
  getFabricTransactions: (
    req: Partial<GetFabricCandidatesRequest>
  ): Promise<GetFabricCandidatesResponse> => {
    return configDClient.GetFabricCandidates(req);
  },
  getSwitch: (req: Partial<GetNodesRequest>) => {
    return configDClient.GetNodes(req);
  },
  getFabricInventory: (
    req: Partial<GetFabricInventoriesRequest>
  ): Promise<GetFabricInventoriesResponse> => {
    return configDClient.GetFabricInventories(req);
  },
  addManagementPort: (
    req: Partial<AddManagementPortsRequest>
  ): Promise<ManagementPortsResponse> => {
    return configDClient.AddManagementPorts(req);
  },
  updateManagementPort: (
    req: Partial<UpdateManagementPortRequest>
  ): Promise<ManagementPort> => {
    return configDClient.UpdateManagementPort(req);
  },
  getSwitchPorts: (
    req: Partial<GetNodesPortsRequest>
  ): Promise<GetNodesPortsResponse> => {
    return configDClient.GetNodesPorts(req);
  },
  getDeviceModels: (
    req: Partial<GetDeviceModelsRequest>
  ): Promise<GetDeviceModelsResponse> => {
    return configDClient.GetDeviceModels(req);
  },
  getPerVlanStpRequest: (
    req: GetPerVlanStpRequest
  ): Promise<GetPerVlanStpResponse> => {
    return configDClient.GetPerVlanStp(req);
  },
  getVnis: (req: Partial<GetVnisRequest>) => {
    return configDClient.GetVnis(req);
  },
  getDHCPRelays: (req: Partial<GetDhcpRelaysRequest>) => {
    return configDClient.GetDhcpRelays(req);
  },
  getVlans: (req: Partial<GetVlansRequest>) => {
    return configDClient.GetVlans(req);
  },
  getVRFs: (req: Partial<GetVrfsRequest>) => {
    return configDClient.GetVrfs(req);
  },
  getStaticRoutes: (req: Partial<GetStaticRoutesRequest>) => {
    return configDClient.GetStaticRoutes(req);
  },
  getPortChannels: (req: Partial<GetPortChannelsRequest>) => {
    return configDClient.GetPortChannels(req);
  },
  getBgpPeers: (req: Partial<GetBgpPeersRequest>) => {
    return configDClient.GetBgpPeers(req);
  },
  getBgpPolicies: (req: Partial<GetBgpPoliciesRequest>) => {
    return configDClient.GetBgpPolicies(req);
  },
  getSubInterfaces: (req: Partial<GetSubInterfacesRequest>) => {
    return configDClient.GetSubInterfaces(req);
  },
  getPortBreakouts: (req: Partial<GetPortBreakoutsRequest>) => {
    return configDClient.GetPortBreakouts(req);
  },
  getManagementPort: (req: Partial<GetManagementPortsRequest>) => {
    return configDClient.GetManagementPorts(req);
  },
  getFabricBOM: (req: Partial<GetFabricBomRequest>) => {
    return configDClient.GetFabricBom(req);
  },
  getFabricAssembly: (req: Partial<GetFabricAssemblyRequest>) => {
    return configDClient.GetFabricAssembly(req);
  },
  reviewFabricTransaction: (
    req: Partial<ReviewFabricCandidateRequest>
  ): Promise<ReviewFabricCandidateResponse> => {
    return configDClient.ReviewFabricCandidate(req);
  },
  // Provisioning APIs
  provision: (req: Partial<ProvisionRequest>): Promise<ProvisionResponse> => {
    return configDClient.Provision(req);
  },
  commitFabricCandidate: (
    req: Partial<CommitFabricCandidateRequest>
  ): Promise<CommitFabricCandidateResponse> => {
    return configDClient.CommitFabricCandidate(req);
  },
  claimDevices: (
    req: Partial<ClaimDevicesRequest>
  ): Promise<ClaimDevicesResponse> => {
    return configDClient.ClaimDevices(req);
  },
  revertFabricCandidate: (
    req: Partial<RevertFabricCandidateRequest>
  ): Promise<Empty> => {
    return configDClient.RevertFabricCandidate(req);
  }
};
