// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: brig/btokens.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../google/protobuf/timestamp";
import { TokenScope, tokenScopeFromJSON, tokenScopeToJSON } from "../models/auth";
import { Metadata } from "../models/types";

export const protobufPackage = "brig";

/**
 * A Bearer Token is a JSON Web Token (JWT) used for authentication and authorization against
 * the Cisco Nexus Hyperfabric REST API. The JWT is a compact, URL-safe means of representing
 * a JSON object containing a set of key-value pairs as described in RFC 7159. It is passed
 * as Bearer token in the Authentication header of every HTTP API request.
 */
export interface BearerToken {
  /** The user-defined name of the bearer token. */
  name: string;
  /** The description is a user-defined field to store notes about the bearer token. */
  description: string;
  /**
   * The scope defines the level of privilege assigned to the bearer token
   * which governs the type of operations that a bearer token can perform.
   */
  scope: TokenScope;
  /**
   * The start date and time for the validity of the bearer token in
   * [RFC3339](https://datatracker.ietf.org/doc/html/rfc3339#section-5.8) format
   * (e.g. `YYYY-MM-DDTHH:MM:SSZ`).
   */
  notBefore:
    | Date
    | undefined;
  /**
   * The end date and time for the validity of the bearer token in
   * [RFC3339](https://datatracker.ietf.org/doc/html/rfc3339#section-5.8) format
   * (e.g. `YYYY-MM-DDTHH:MM:SSZ`).
   */
  notAfter:
    | Date
    | undefined;
  /**
   * The JWT token that represent the bearer token.
   * The token is only available at the bearer token creation.
   */
  token: string;
  /** The unique identifier of the bearer token. */
  tokenId: string;
  /** A map of attributes related to the lifecycle of the bearer token. */
  metadata: Metadata | undefined;
}

/** A new bearer token definition. */
export interface NewBearerToken {
  /** The user provided name for the token. */
  name: string;
  /** A description for the token. */
  description: string;
  /** Sets the type of requests that the token can make. */
  scope: TokenScope;
  /** Sets the time at which the token can be used. */
  notBefore:
    | Date
    | undefined;
  /** Sets the time after which the token cannot be used. */
  notAfter: Date | undefined;
}

/** Returns a list of bearer tokens. */
export interface BearerTokensResponse {
  /** A list of bearer tokens. */
  tokens: BearerToken[];
}

/** A request to create new bearer tokens. */
export interface CreateBearerTokenRequest {
  /** The unique name for the token. */
  name: string;
  /** A description for the token. */
  description: string;
  /** The scope of the token which sets what operations the token can perform. */
  scope: TokenScope;
  /** Time time at which a new token can be used. */
  notBefore:
    | Date
    | undefined;
  /** Time time after which the token can no longer be used. */
  notAfter: Date | undefined;
}

/** Create a new bearer token. Currently only a single bearer token can be created at a time. */
export interface CreateBearerTokensResponse {
  /** The newly created tokens. */
  tokens: BearerToken[];
}

/** Create one or more new bearer tokens. */
export interface CreateBearerTokensRequest {
  /** The new tokens to be created. */
  tokens: NewBearerToken[];
}

/** Get a single bearer token. */
export interface GetBearerTokenRequest {
  /** The token id. */
  tokenId: string;
  /** Include metadata in the response. */
  includeMetadata: boolean;
}

/** Get all bearer tokens. */
export interface GetBearerTokensRequest {
  /** Include metadata in the response. */
  includeMetadata: boolean;
}

/** Delete a bearer token. */
export interface DeleteBearerTokenRequest {
  /** The id of the token to delete. */
  tokenId: string;
}

function createBaseBearerToken(): BearerToken {
  return {
    name: "",
    description: "",
    scope: 0,
    notBefore: undefined,
    notAfter: undefined,
    token: "",
    tokenId: "",
    metadata: undefined,
  };
}

export const BearerToken = {
  encode(message: BearerToken, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.scope !== 0) {
      writer.uint32(24).int32(message.scope);
    }
    if (message.notBefore !== undefined) {
      Timestamp.encode(toTimestamp(message.notBefore), writer.uint32(34).fork()).ldelim();
    }
    if (message.notAfter !== undefined) {
      Timestamp.encode(toTimestamp(message.notAfter), writer.uint32(42).fork()).ldelim();
    }
    if (message.token !== "") {
      writer.uint32(50).string(message.token);
    }
    if (message.tokenId !== "") {
      writer.uint32(58).string(message.tokenId);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BearerToken {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBearerToken();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.scope = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.notBefore = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.notAfter = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.token = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.tokenId = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BearerToken {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      scope: isSet(object.scope) ? tokenScopeFromJSON(object.scope) : 0,
      notBefore: isSet(object.notBefore) ? fromJsonTimestamp(object.notBefore) : undefined,
      notAfter: isSet(object.notAfter) ? fromJsonTimestamp(object.notAfter) : undefined,
      token: isSet(object.token) ? globalThis.String(object.token) : "",
      tokenId: isSet(object.tokenId) ? globalThis.String(object.tokenId) : "",
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
    };
  },

  toJSON(message: BearerToken): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.scope !== 0) {
      obj.scope = tokenScopeToJSON(message.scope);
    }
    if (message.notBefore !== undefined) {
      obj.notBefore = message.notBefore.toISOString();
    }
    if (message.notAfter !== undefined) {
      obj.notAfter = message.notAfter.toISOString();
    }
    if (message.token !== "") {
      obj.token = message.token;
    }
    if (message.tokenId !== "") {
      obj.tokenId = message.tokenId;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BearerToken>, I>>(base?: I): BearerToken {
    return BearerToken.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BearerToken>, I>>(object: I): BearerToken {
    const message = createBaseBearerToken();
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.scope = object.scope ?? 0;
    message.notBefore = object.notBefore ?? undefined;
    message.notAfter = object.notAfter ?? undefined;
    message.token = object.token ?? "";
    message.tokenId = object.tokenId ?? "";
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    return message;
  },
};

function createBaseNewBearerToken(): NewBearerToken {
  return { name: "", description: "", scope: 0, notBefore: undefined, notAfter: undefined };
}

export const NewBearerToken = {
  encode(message: NewBearerToken, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.scope !== 0) {
      writer.uint32(24).int32(message.scope);
    }
    if (message.notBefore !== undefined) {
      Timestamp.encode(toTimestamp(message.notBefore), writer.uint32(34).fork()).ldelim();
    }
    if (message.notAfter !== undefined) {
      Timestamp.encode(toTimestamp(message.notAfter), writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NewBearerToken {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNewBearerToken();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.scope = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.notBefore = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.notAfter = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NewBearerToken {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      scope: isSet(object.scope) ? tokenScopeFromJSON(object.scope) : 0,
      notBefore: isSet(object.notBefore) ? fromJsonTimestamp(object.notBefore) : undefined,
      notAfter: isSet(object.notAfter) ? fromJsonTimestamp(object.notAfter) : undefined,
    };
  },

  toJSON(message: NewBearerToken): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.scope !== 0) {
      obj.scope = tokenScopeToJSON(message.scope);
    }
    if (message.notBefore !== undefined) {
      obj.notBefore = message.notBefore.toISOString();
    }
    if (message.notAfter !== undefined) {
      obj.notAfter = message.notAfter.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NewBearerToken>, I>>(base?: I): NewBearerToken {
    return NewBearerToken.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NewBearerToken>, I>>(object: I): NewBearerToken {
    const message = createBaseNewBearerToken();
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.scope = object.scope ?? 0;
    message.notBefore = object.notBefore ?? undefined;
    message.notAfter = object.notAfter ?? undefined;
    return message;
  },
};

function createBaseBearerTokensResponse(): BearerTokensResponse {
  return { tokens: [] };
}

export const BearerTokensResponse = {
  encode(message: BearerTokensResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tokens) {
      BearerToken.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BearerTokensResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBearerTokensResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tokens.push(BearerToken.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BearerTokensResponse {
    return {
      tokens: globalThis.Array.isArray(object?.tokens) ? object.tokens.map((e: any) => BearerToken.fromJSON(e)) : [],
    };
  },

  toJSON(message: BearerTokensResponse): unknown {
    const obj: any = {};
    if (message.tokens?.length) {
      obj.tokens = message.tokens.map((e) => BearerToken.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BearerTokensResponse>, I>>(base?: I): BearerTokensResponse {
    return BearerTokensResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BearerTokensResponse>, I>>(object: I): BearerTokensResponse {
    const message = createBaseBearerTokensResponse();
    message.tokens = object.tokens?.map((e) => BearerToken.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateBearerTokenRequest(): CreateBearerTokenRequest {
  return { name: "", description: "", scope: 0, notBefore: undefined, notAfter: undefined };
}

export const CreateBearerTokenRequest = {
  encode(message: CreateBearerTokenRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.scope !== 0) {
      writer.uint32(24).int32(message.scope);
    }
    if (message.notBefore !== undefined) {
      Timestamp.encode(toTimestamp(message.notBefore), writer.uint32(34).fork()).ldelim();
    }
    if (message.notAfter !== undefined) {
      Timestamp.encode(toTimestamp(message.notAfter), writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateBearerTokenRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateBearerTokenRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.scope = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.notBefore = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.notAfter = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateBearerTokenRequest {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      scope: isSet(object.scope) ? tokenScopeFromJSON(object.scope) : 0,
      notBefore: isSet(object.notBefore) ? fromJsonTimestamp(object.notBefore) : undefined,
      notAfter: isSet(object.notAfter) ? fromJsonTimestamp(object.notAfter) : undefined,
    };
  },

  toJSON(message: CreateBearerTokenRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.scope !== 0) {
      obj.scope = tokenScopeToJSON(message.scope);
    }
    if (message.notBefore !== undefined) {
      obj.notBefore = message.notBefore.toISOString();
    }
    if (message.notAfter !== undefined) {
      obj.notAfter = message.notAfter.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateBearerTokenRequest>, I>>(base?: I): CreateBearerTokenRequest {
    return CreateBearerTokenRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateBearerTokenRequest>, I>>(object: I): CreateBearerTokenRequest {
    const message = createBaseCreateBearerTokenRequest();
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.scope = object.scope ?? 0;
    message.notBefore = object.notBefore ?? undefined;
    message.notAfter = object.notAfter ?? undefined;
    return message;
  },
};

function createBaseCreateBearerTokensResponse(): CreateBearerTokensResponse {
  return { tokens: [] };
}

export const CreateBearerTokensResponse = {
  encode(message: CreateBearerTokensResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tokens) {
      BearerToken.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateBearerTokensResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateBearerTokensResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tokens.push(BearerToken.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateBearerTokensResponse {
    return {
      tokens: globalThis.Array.isArray(object?.tokens) ? object.tokens.map((e: any) => BearerToken.fromJSON(e)) : [],
    };
  },

  toJSON(message: CreateBearerTokensResponse): unknown {
    const obj: any = {};
    if (message.tokens?.length) {
      obj.tokens = message.tokens.map((e) => BearerToken.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateBearerTokensResponse>, I>>(base?: I): CreateBearerTokensResponse {
    return CreateBearerTokensResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateBearerTokensResponse>, I>>(object: I): CreateBearerTokensResponse {
    const message = createBaseCreateBearerTokensResponse();
    message.tokens = object.tokens?.map((e) => BearerToken.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateBearerTokensRequest(): CreateBearerTokensRequest {
  return { tokens: [] };
}

export const CreateBearerTokensRequest = {
  encode(message: CreateBearerTokensRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tokens) {
      NewBearerToken.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateBearerTokensRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateBearerTokensRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tokens.push(NewBearerToken.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateBearerTokensRequest {
    return {
      tokens: globalThis.Array.isArray(object?.tokens) ? object.tokens.map((e: any) => NewBearerToken.fromJSON(e)) : [],
    };
  },

  toJSON(message: CreateBearerTokensRequest): unknown {
    const obj: any = {};
    if (message.tokens?.length) {
      obj.tokens = message.tokens.map((e) => NewBearerToken.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateBearerTokensRequest>, I>>(base?: I): CreateBearerTokensRequest {
    return CreateBearerTokensRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateBearerTokensRequest>, I>>(object: I): CreateBearerTokensRequest {
    const message = createBaseCreateBearerTokensRequest();
    message.tokens = object.tokens?.map((e) => NewBearerToken.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetBearerTokenRequest(): GetBearerTokenRequest {
  return { tokenId: "", includeMetadata: false };
}

export const GetBearerTokenRequest = {
  encode(message: GetBearerTokenRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tokenId !== "") {
      writer.uint32(10).string(message.tokenId);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(16).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetBearerTokenRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBearerTokenRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tokenId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetBearerTokenRequest {
    return {
      tokenId: isSet(object.tokenId) ? globalThis.String(object.tokenId) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetBearerTokenRequest): unknown {
    const obj: any = {};
    if (message.tokenId !== "") {
      obj.tokenId = message.tokenId;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetBearerTokenRequest>, I>>(base?: I): GetBearerTokenRequest {
    return GetBearerTokenRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetBearerTokenRequest>, I>>(object: I): GetBearerTokenRequest {
    const message = createBaseGetBearerTokenRequest();
    message.tokenId = object.tokenId ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseGetBearerTokensRequest(): GetBearerTokensRequest {
  return { includeMetadata: false };
}

export const GetBearerTokensRequest = {
  encode(message: GetBearerTokensRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.includeMetadata !== false) {
      writer.uint32(8).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetBearerTokensRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBearerTokensRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetBearerTokensRequest {
    return { includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false };
  },

  toJSON(message: GetBearerTokensRequest): unknown {
    const obj: any = {};
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetBearerTokensRequest>, I>>(base?: I): GetBearerTokensRequest {
    return GetBearerTokensRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetBearerTokensRequest>, I>>(object: I): GetBearerTokensRequest {
    const message = createBaseGetBearerTokensRequest();
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseDeleteBearerTokenRequest(): DeleteBearerTokenRequest {
  return { tokenId: "" };
}

export const DeleteBearerTokenRequest = {
  encode(message: DeleteBearerTokenRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tokenId !== "") {
      writer.uint32(10).string(message.tokenId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteBearerTokenRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteBearerTokenRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tokenId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteBearerTokenRequest {
    return { tokenId: isSet(object.tokenId) ? globalThis.String(object.tokenId) : "" };
  },

  toJSON(message: DeleteBearerTokenRequest): unknown {
    const obj: any = {};
    if (message.tokenId !== "") {
      obj.tokenId = message.tokenId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteBearerTokenRequest>, I>>(base?: I): DeleteBearerTokenRequest {
    return DeleteBearerTokenRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteBearerTokenRequest>, I>>(object: I): DeleteBearerTokenRequest {
    const message = createBaseDeleteBearerTokenRequest();
    message.tokenId = object.tokenId ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
