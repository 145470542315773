// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: models/server.proto

/* eslint-disable */

export const protobufPackage = "models";

/** BMC authentication type. */
export enum BmcAuthType {
  /** BMC_AUTH_UNSPECIFIED - Unknown authentication type */
  BMC_AUTH_UNSPECIFIED = 0,
  /** BMC_AUTH_USER_PASSWORD - User-Password based auth */
  BMC_AUTH_USER_PASSWORD = 1,
  /** BMC_AUTH_MTLS - mTLS based auth */
  BMC_AUTH_MTLS = 2,
  /** BMC_AUTH_NONE - No authentication - can be used to access /redfish/v1 without credentials */
  BMC_AUTH_NONE = 3,
  UNRECOGNIZED = -1,
}

export function bmcAuthTypeFromJSON(object: any): BmcAuthType {
  switch (object) {
    case 0:
    case "BMC_AUTH_UNSPECIFIED":
      return BmcAuthType.BMC_AUTH_UNSPECIFIED;
    case 1:
    case "BMC_AUTH_USER_PASSWORD":
      return BmcAuthType.BMC_AUTH_USER_PASSWORD;
    case 2:
    case "BMC_AUTH_MTLS":
      return BmcAuthType.BMC_AUTH_MTLS;
    case 3:
    case "BMC_AUTH_NONE":
      return BmcAuthType.BMC_AUTH_NONE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BmcAuthType.UNRECOGNIZED;
  }
}

export function bmcAuthTypeToJSON(object: BmcAuthType): string {
  switch (object) {
    case BmcAuthType.BMC_AUTH_UNSPECIFIED:
      return "BMC_AUTH_UNSPECIFIED";
    case BmcAuthType.BMC_AUTH_USER_PASSWORD:
      return "BMC_AUTH_USER_PASSWORD";
    case BmcAuthType.BMC_AUTH_MTLS:
      return "BMC_AUTH_MTLS";
    case BmcAuthType.BMC_AUTH_NONE:
      return "BMC_AUTH_NONE";
    case BmcAuthType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Product type of BMC. */
export enum BmcProductType {
  /** BMC_PRODUCT_UNSPECIFIED - Unspecified BMC Product type */
  BMC_PRODUCT_UNSPECIFIED = 0,
  /** BMC_NVIDIA_BF3 - NVidia BF-3 SmartNic BMC */
  BMC_NVIDIA_BF3 = 1,
  /** BMC_CISCO_UCS_C225 - Cisco UCS C225 Server */
  BMC_CISCO_UCS_C225 = 2,
  /** BMC_CISCO_UCS_BRONCO - Cisco UCS Bronco Server */
  BMC_CISCO_UCS_BRONCO = 3,
  /** BMC_PRODUCT_UNSUPPORTED - An unrecognized / unsupported BMC */
  BMC_PRODUCT_UNSUPPORTED = 4,
  UNRECOGNIZED = -1,
}

export function bmcProductTypeFromJSON(object: any): BmcProductType {
  switch (object) {
    case 0:
    case "BMC_PRODUCT_UNSPECIFIED":
      return BmcProductType.BMC_PRODUCT_UNSPECIFIED;
    case 1:
    case "BMC_NVIDIA_BF3":
      return BmcProductType.BMC_NVIDIA_BF3;
    case 2:
    case "BMC_CISCO_UCS_C225":
      return BmcProductType.BMC_CISCO_UCS_C225;
    case 3:
    case "BMC_CISCO_UCS_BRONCO":
      return BmcProductType.BMC_CISCO_UCS_BRONCO;
    case 4:
    case "BMC_PRODUCT_UNSUPPORTED":
      return BmcProductType.BMC_PRODUCT_UNSUPPORTED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BmcProductType.UNRECOGNIZED;
  }
}

export function bmcProductTypeToJSON(object: BmcProductType): string {
  switch (object) {
    case BmcProductType.BMC_PRODUCT_UNSPECIFIED:
      return "BMC_PRODUCT_UNSPECIFIED";
    case BmcProductType.BMC_NVIDIA_BF3:
      return "BMC_NVIDIA_BF3";
    case BmcProductType.BMC_CISCO_UCS_C225:
      return "BMC_CISCO_UCS_C225";
    case BmcProductType.BMC_CISCO_UCS_BRONCO:
      return "BMC_CISCO_UCS_BRONCO";
    case BmcProductType.BMC_PRODUCT_UNSUPPORTED:
      return "BMC_PRODUCT_UNSUPPORTED";
    case BmcProductType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** BMC state type. */
export enum BmcStateType {
  /** BMC_STATE_UNSPECIFIED - Unknown state */
  BMC_STATE_UNSPECIFIED = 0,
  /** BMC_STATE_DISCOVERED - Discovered via LLDP */
  BMC_STATE_DISCOVERED = 1,
  /** BMC_STATE_PRE_AUTHENTICATED - Successful unauthenticated connection to BMC */
  BMC_STATE_PRE_AUTHENTICATED = 2,
  /** BMC_STATE_AUTHENTICATED - Suucessful authenticated connection to BMC */
  BMC_STATE_AUTHENTICATED = 3,
  /** BMC_STATE_STALE - Discovered, but unable to connect, LLDP has not been seen recently */
  BMC_STATE_STALE = 4,
  /** BMC_STATE_NOT_SUPPORTED - Discovered, unauthenticated connect successful, but the BMC is not supported */
  BMC_STATE_NOT_SUPPORTED = 5,
  UNRECOGNIZED = -1,
}

export function bmcStateTypeFromJSON(object: any): BmcStateType {
  switch (object) {
    case 0:
    case "BMC_STATE_UNSPECIFIED":
      return BmcStateType.BMC_STATE_UNSPECIFIED;
    case 1:
    case "BMC_STATE_DISCOVERED":
      return BmcStateType.BMC_STATE_DISCOVERED;
    case 2:
    case "BMC_STATE_PRE_AUTHENTICATED":
      return BmcStateType.BMC_STATE_PRE_AUTHENTICATED;
    case 3:
    case "BMC_STATE_AUTHENTICATED":
      return BmcStateType.BMC_STATE_AUTHENTICATED;
    case 4:
    case "BMC_STATE_STALE":
      return BmcStateType.BMC_STATE_STALE;
    case 5:
    case "BMC_STATE_NOT_SUPPORTED":
      return BmcStateType.BMC_STATE_NOT_SUPPORTED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BmcStateType.UNRECOGNIZED;
  }
}

export function bmcStateTypeToJSON(object: BmcStateType): string {
  switch (object) {
    case BmcStateType.BMC_STATE_UNSPECIFIED:
      return "BMC_STATE_UNSPECIFIED";
    case BmcStateType.BMC_STATE_DISCOVERED:
      return "BMC_STATE_DISCOVERED";
    case BmcStateType.BMC_STATE_PRE_AUTHENTICATED:
      return "BMC_STATE_PRE_AUTHENTICATED";
    case BmcStateType.BMC_STATE_AUTHENTICATED:
      return "BMC_STATE_AUTHENTICATED";
    case BmcStateType.BMC_STATE_STALE:
      return "BMC_STATE_STALE";
    case BmcStateType.BMC_STATE_NOT_SUPPORTED:
      return "BMC_STATE_NOT_SUPPORTED";
    case BmcStateType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** External device connectivity status enumeration. */
export enum ConnectivityStatus {
  /** CONNECTIVITY_UNSPECIFIED - Last connection status is unknown */
  CONNECTIVITY_UNSPECIFIED = 0,
  /** CONNECTIVITY_PENDING - Connection is pending */
  CONNECTIVITY_PENDING = 1,
  /** CONNECTIVITY_OK - Last connection was successful */
  CONNECTIVITY_OK = 2,
  /** CONNECTIVITY_UNAUTHORIZED - Last connection attempt failed due to username / password or certificate based Auth failure */
  CONNECTIVITY_UNAUTHORIZED = 3,
  /** CONNECTIVITY_CANNOT_CONNECT - Last attempt could not establish a connection, also used for Connectivity Refused */
  CONNECTIVITY_CANNOT_CONNECT = 4,
  /** CONNECTIVITY_ABORTED - Last connection was aborted */
  CONNECTIVITY_ABORTED = 5,
  /** CONNECTIVITY_RESET - Last connection was reset */
  CONNECTIVITY_RESET = 6,
  /** CONNECTIVITY_DNS_ERROR - Last connection attempt failed as the hostname was not resolved */
  CONNECTIVITY_DNS_ERROR = 7,
  /** CONNECTIVITY_TIMED_OUT - Last connection attempt timed out */
  CONNECTIVITY_TIMED_OUT = 8,
  /** CONNECTIVITY_TERMINATED - Connection terminated by client as command execution exceeded a time out. */
  CONNECTIVITY_TERMINATED = 9,
  /** CONNECTIVITY_NO_MGMT_ADDR_IN_TLV - BMC detected via LLDP, but no management address specified in TLV. */
  CONNECTIVITY_NO_MGMT_ADDR_IN_TLV = 10,
  /** CONNECTIVITY_UNSUPPORTED_BMC - BMC detected via LLDP, but its not an unsupported type. */
  CONNECTIVITY_UNSUPPORTED_BMC = 11,
  /** CONNECTIVITY_NO_CREDENTIALS - No credentials available. */
  CONNECTIVITY_NO_CREDENTIALS = 12,
  /** CONNECTIVITY_INTERNAL_ERROR - Detected an internal programming error and unable to connect. */
  CONNECTIVITY_INTERNAL_ERROR = 13,
  /** CONNECTIVITY_PRE_AUTH_OK - Last pre-authorized connection was successful */
  CONNECTIVITY_PRE_AUTH_OK = 14,
  /** CONNECTIVITY_EXPIRED_CERTIFICATE - Remote side has reported the Client Certificate has expired. */
  CONNECTIVITY_EXPIRED_CERTIFICATE = 15,
  /** CONNECTIVITY_CANNOT_GET_CERTIFICATE - Unable to get Client Certificate from the Cloud. */
  CONNECTIVITY_CANNOT_GET_CERTIFICATE = 16,
  UNRECOGNIZED = -1,
}

export function connectivityStatusFromJSON(object: any): ConnectivityStatus {
  switch (object) {
    case 0:
    case "CONNECTIVITY_UNSPECIFIED":
      return ConnectivityStatus.CONNECTIVITY_UNSPECIFIED;
    case 1:
    case "CONNECTIVITY_PENDING":
      return ConnectivityStatus.CONNECTIVITY_PENDING;
    case 2:
    case "CONNECTIVITY_OK":
      return ConnectivityStatus.CONNECTIVITY_OK;
    case 3:
    case "CONNECTIVITY_UNAUTHORIZED":
      return ConnectivityStatus.CONNECTIVITY_UNAUTHORIZED;
    case 4:
    case "CONNECTIVITY_CANNOT_CONNECT":
      return ConnectivityStatus.CONNECTIVITY_CANNOT_CONNECT;
    case 5:
    case "CONNECTIVITY_ABORTED":
      return ConnectivityStatus.CONNECTIVITY_ABORTED;
    case 6:
    case "CONNECTIVITY_RESET":
      return ConnectivityStatus.CONNECTIVITY_RESET;
    case 7:
    case "CONNECTIVITY_DNS_ERROR":
      return ConnectivityStatus.CONNECTIVITY_DNS_ERROR;
    case 8:
    case "CONNECTIVITY_TIMED_OUT":
      return ConnectivityStatus.CONNECTIVITY_TIMED_OUT;
    case 9:
    case "CONNECTIVITY_TERMINATED":
      return ConnectivityStatus.CONNECTIVITY_TERMINATED;
    case 10:
    case "CONNECTIVITY_NO_MGMT_ADDR_IN_TLV":
      return ConnectivityStatus.CONNECTIVITY_NO_MGMT_ADDR_IN_TLV;
    case 11:
    case "CONNECTIVITY_UNSUPPORTED_BMC":
      return ConnectivityStatus.CONNECTIVITY_UNSUPPORTED_BMC;
    case 12:
    case "CONNECTIVITY_NO_CREDENTIALS":
      return ConnectivityStatus.CONNECTIVITY_NO_CREDENTIALS;
    case 13:
    case "CONNECTIVITY_INTERNAL_ERROR":
      return ConnectivityStatus.CONNECTIVITY_INTERNAL_ERROR;
    case 14:
    case "CONNECTIVITY_PRE_AUTH_OK":
      return ConnectivityStatus.CONNECTIVITY_PRE_AUTH_OK;
    case 15:
    case "CONNECTIVITY_EXPIRED_CERTIFICATE":
      return ConnectivityStatus.CONNECTIVITY_EXPIRED_CERTIFICATE;
    case 16:
    case "CONNECTIVITY_CANNOT_GET_CERTIFICATE":
      return ConnectivityStatus.CONNECTIVITY_CANNOT_GET_CERTIFICATE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ConnectivityStatus.UNRECOGNIZED;
  }
}

export function connectivityStatusToJSON(object: ConnectivityStatus): string {
  switch (object) {
    case ConnectivityStatus.CONNECTIVITY_UNSPECIFIED:
      return "CONNECTIVITY_UNSPECIFIED";
    case ConnectivityStatus.CONNECTIVITY_PENDING:
      return "CONNECTIVITY_PENDING";
    case ConnectivityStatus.CONNECTIVITY_OK:
      return "CONNECTIVITY_OK";
    case ConnectivityStatus.CONNECTIVITY_UNAUTHORIZED:
      return "CONNECTIVITY_UNAUTHORIZED";
    case ConnectivityStatus.CONNECTIVITY_CANNOT_CONNECT:
      return "CONNECTIVITY_CANNOT_CONNECT";
    case ConnectivityStatus.CONNECTIVITY_ABORTED:
      return "CONNECTIVITY_ABORTED";
    case ConnectivityStatus.CONNECTIVITY_RESET:
      return "CONNECTIVITY_RESET";
    case ConnectivityStatus.CONNECTIVITY_DNS_ERROR:
      return "CONNECTIVITY_DNS_ERROR";
    case ConnectivityStatus.CONNECTIVITY_TIMED_OUT:
      return "CONNECTIVITY_TIMED_OUT";
    case ConnectivityStatus.CONNECTIVITY_TERMINATED:
      return "CONNECTIVITY_TERMINATED";
    case ConnectivityStatus.CONNECTIVITY_NO_MGMT_ADDR_IN_TLV:
      return "CONNECTIVITY_NO_MGMT_ADDR_IN_TLV";
    case ConnectivityStatus.CONNECTIVITY_UNSUPPORTED_BMC:
      return "CONNECTIVITY_UNSUPPORTED_BMC";
    case ConnectivityStatus.CONNECTIVITY_NO_CREDENTIALS:
      return "CONNECTIVITY_NO_CREDENTIALS";
    case ConnectivityStatus.CONNECTIVITY_INTERNAL_ERROR:
      return "CONNECTIVITY_INTERNAL_ERROR";
    case ConnectivityStatus.CONNECTIVITY_PRE_AUTH_OK:
      return "CONNECTIVITY_PRE_AUTH_OK";
    case ConnectivityStatus.CONNECTIVITY_EXPIRED_CERTIFICATE:
      return "CONNECTIVITY_EXPIRED_CERTIFICATE";
    case ConnectivityStatus.CONNECTIVITY_CANNOT_GET_CERTIFICATE:
      return "CONNECTIVITY_CANNOT_GET_CERTIFICATE";
    case ConnectivityStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Type of managed endpoint. */
export enum ManagedEndpointType {
  /** MANAGED_ENDPOINT_UNSPECIFIED - Unspecified managed endpoint type */
  MANAGED_ENDPOINT_UNSPECIFIED = 0,
  /** MANAGED_ENDPOINT_SERVER_BMC - A server's BMC. */
  MANAGED_ENDPOINT_SERVER_BMC = 1,
  /** MANAGED_ENDPOINT_SMART_NIC_BMC - A SmartNic's BMC */
  MANAGED_ENDPOINT_SMART_NIC_BMC = 2,
  /** MANAGED_ENDPOINT_SMART_NIC_DPU - A SmartNic's DPU */
  MANAGED_ENDPOINT_SMART_NIC_DPU = 3,
  /** MANAGED_ENDPOINT_UNSUPPORTED - An unrecognized / unsupported managed endpoint */
  MANAGED_ENDPOINT_UNSUPPORTED = 4,
  UNRECOGNIZED = -1,
}

export function managedEndpointTypeFromJSON(object: any): ManagedEndpointType {
  switch (object) {
    case 0:
    case "MANAGED_ENDPOINT_UNSPECIFIED":
      return ManagedEndpointType.MANAGED_ENDPOINT_UNSPECIFIED;
    case 1:
    case "MANAGED_ENDPOINT_SERVER_BMC":
      return ManagedEndpointType.MANAGED_ENDPOINT_SERVER_BMC;
    case 2:
    case "MANAGED_ENDPOINT_SMART_NIC_BMC":
      return ManagedEndpointType.MANAGED_ENDPOINT_SMART_NIC_BMC;
    case 3:
    case "MANAGED_ENDPOINT_SMART_NIC_DPU":
      return ManagedEndpointType.MANAGED_ENDPOINT_SMART_NIC_DPU;
    case 4:
    case "MANAGED_ENDPOINT_UNSUPPORTED":
      return ManagedEndpointType.MANAGED_ENDPOINT_UNSUPPORTED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ManagedEndpointType.UNRECOGNIZED;
  }
}

export function managedEndpointTypeToJSON(object: ManagedEndpointType): string {
  switch (object) {
    case ManagedEndpointType.MANAGED_ENDPOINT_UNSPECIFIED:
      return "MANAGED_ENDPOINT_UNSPECIFIED";
    case ManagedEndpointType.MANAGED_ENDPOINT_SERVER_BMC:
      return "MANAGED_ENDPOINT_SERVER_BMC";
    case ManagedEndpointType.MANAGED_ENDPOINT_SMART_NIC_BMC:
      return "MANAGED_ENDPOINT_SMART_NIC_BMC";
    case ManagedEndpointType.MANAGED_ENDPOINT_SMART_NIC_DPU:
      return "MANAGED_ENDPOINT_SMART_NIC_DPU";
    case ManagedEndpointType.MANAGED_ENDPOINT_UNSUPPORTED:
      return "MANAGED_ENDPOINT_UNSUPPORTED";
    case ManagedEndpointType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Product type of Smart Nic DPU. */
export enum SmartNicDpuProductType {
  /** DPU_PRODUCT_UNSPECIFIED - Unspecified DPU Product type */
  DPU_PRODUCT_UNSPECIFIED = 0,
  /** DPU_NVIDIA_BF3 - NVidia BF-3 SmartNic */
  DPU_NVIDIA_BF3 = 1,
  UNRECOGNIZED = -1,
}

export function smartNicDpuProductTypeFromJSON(object: any): SmartNicDpuProductType {
  switch (object) {
    case 0:
    case "DPU_PRODUCT_UNSPECIFIED":
      return SmartNicDpuProductType.DPU_PRODUCT_UNSPECIFIED;
    case 1:
    case "DPU_NVIDIA_BF3":
      return SmartNicDpuProductType.DPU_NVIDIA_BF3;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SmartNicDpuProductType.UNRECOGNIZED;
  }
}

export function smartNicDpuProductTypeToJSON(object: SmartNicDpuProductType): string {
  switch (object) {
    case SmartNicDpuProductType.DPU_PRODUCT_UNSPECIFIED:
      return "DPU_PRODUCT_UNSPECIFIED";
    case SmartNicDpuProductType.DPU_NVIDIA_BF3:
      return "DPU_NVIDIA_BF3";
    case SmartNicDpuProductType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}
