// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: models/user.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../google/protobuf/timestamp";
import { Theme, themeFromJSON, themeToJSON, UserRole, userRoleFromJSON, userRoleToJSON } from "./auth";
import { Tenant } from "./tenant";
import { Metadata } from "./types";

export const protobufPackage = "models";

/** SignUp captures captures information about the company and the individual signing up. */
export interface SignUp {
  /** Name of the company.  The name is unique and case-insensitive. */
  companyName: string;
  /**
   * Email of the individual signing up (required).  Should be the business email
   * (email contains the domain name of the employer of the individual).
   */
  email: string;
  /**
   * Version of the EULA accepted.  A valid value EULA version indicates the user accepted the EULA
   * (a check box on the sign-up page).  Empty means the EULA was not accepted.
   */
  eulaVersion: string;
}

/**
 * A User is a Cisco.com account, identified by email, authorized to access a specific Cisco Nexus Hyperfabric Organization
 * with a specific role that represents the level of privilege of the user.
 * Only a user with administrative privileges may modify another user's user record.
 *
 * INTERNAL
 *
 * User describes a user in a tenant.  A user, identified by email, may belong to multiple tenants.
 * Each instance of a user in a tenant is represented by a separate User record.
 */
export interface User {
  /**
   * The unique identifier of a user.
   *
   * INTERNAL
   *
   * Identifier is required to update an existing User.
   */
  id: string;
  /**
   * The email address of the user.
   *
   * INTERNAL
   *
   * The canonical username of User, must be a valid email address.  Caller must be an administrator
   * and provide the email of a User in the tenant of the given context in order to update that user.
   */
  email: string;
  /** The single sign on (SSO) identity provider (IDP), optional (default: CISCO). */
  provider: string;
  /** A list of user-defined labels that can be used for grouping and filtering users. */
  labels: string[];
  /** The enabled state of the user which indicates if the user is enabled or disabled. */
  enabled: boolean;
  /** A map of attributes related to the lifecycle of the user. */
  metadata:
    | Metadata
    | undefined;
  /** The role assigned to the user that represents the level of privilege of the user. */
  role: UserRole;
  /**
   * The last recorded web interface login time of the user in
   * [RFC3339](https://datatracker.ietf.org/doc/html/rfc3339#section-5.8) format
   * (e.g. `YYYY-MM-DDTHH:MM:SSZ`).
   */
  lastLogin: Date | undefined;
}

/** UserPreferences describes preferences for a user across all tenants. */
export interface UserPreferences {
  /** The single sign on (SSO) identity provider (IDP), optional (default: CISCO). */
  provider: string;
  /** Preferred timezone. */
  timezone: string;
  /** Users preferred theme. */
  theme: Theme;
  /** Indicates whether to skip the video tour or not. */
  skipVideoTour: boolean;
  /** Indicates whether to skip the feature highlights or not. */
  skipFeatureHighlights: boolean;
}

/** UserContext describes a context in which the user is a member. */
export interface UserContext {
  /** Identifies the organization of which tenant belongs. */
  orgId: string;
  /** The name of the organization of which tenant belongs. */
  orgName: string;
  /** Current indicates this is the context of the user receiving this information. */
  current: boolean;
  /** Tenant in which user is a member. */
  tenant:
    | Tenant
    | undefined;
  /** Information about user in the context of tenant. */
  user: User | undefined;
}

function createBaseSignUp(): SignUp {
  return { companyName: "", email: "", eulaVersion: "" };
}

export const SignUp = {
  encode(message: SignUp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.companyName !== "") {
      writer.uint32(10).string(message.companyName);
    }
    if (message.email !== "") {
      writer.uint32(18).string(message.email);
    }
    if (message.eulaVersion !== "") {
      writer.uint32(82).string(message.eulaVersion);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SignUp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignUp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.companyName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.eulaVersion = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SignUp {
    return {
      companyName: isSet(object.companyName) ? globalThis.String(object.companyName) : "",
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      eulaVersion: isSet(object.eulaVersion) ? globalThis.String(object.eulaVersion) : "",
    };
  },

  toJSON(message: SignUp): unknown {
    const obj: any = {};
    if (message.companyName !== "") {
      obj.companyName = message.companyName;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.eulaVersion !== "") {
      obj.eulaVersion = message.eulaVersion;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SignUp>, I>>(base?: I): SignUp {
    return SignUp.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SignUp>, I>>(object: I): SignUp {
    const message = createBaseSignUp();
    message.companyName = object.companyName ?? "";
    message.email = object.email ?? "";
    message.eulaVersion = object.eulaVersion ?? "";
    return message;
  },
};

function createBaseUser(): User {
  return {
    id: "",
    email: "",
    provider: "",
    labels: [],
    enabled: false,
    metadata: undefined,
    role: 0,
    lastLogin: undefined,
  };
}

export const User = {
  encode(message: User, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.email !== "") {
      writer.uint32(18).string(message.email);
    }
    if (message.provider !== "") {
      writer.uint32(26).string(message.provider);
    }
    for (const v of message.labels) {
      writer.uint32(42).string(v!);
    }
    if (message.enabled !== false) {
      writer.uint32(56).bool(message.enabled);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(66).fork()).ldelim();
    }
    if (message.role !== 0) {
      writer.uint32(88).int32(message.role);
    }
    if (message.lastLogin !== undefined) {
      Timestamp.encode(toTimestamp(message.lastLogin), writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.provider = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.role = reader.int32() as any;
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.lastLogin = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      provider: isSet(object.provider) ? globalThis.String(object.provider) : "",
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      role: isSet(object.role) ? userRoleFromJSON(object.role) : 0,
      lastLogin: isSet(object.lastLogin) ? fromJsonTimestamp(object.lastLogin) : undefined,
    };
  },

  toJSON(message: User): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.provider !== "") {
      obj.provider = message.provider;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.role !== 0) {
      obj.role = userRoleToJSON(message.role);
    }
    if (message.lastLogin !== undefined) {
      obj.lastLogin = message.lastLogin.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<User>, I>>(base?: I): User {
    return User.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<User>, I>>(object: I): User {
    const message = createBaseUser();
    message.id = object.id ?? "";
    message.email = object.email ?? "";
    message.provider = object.provider ?? "";
    message.labels = object.labels?.map((e) => e) || [];
    message.enabled = object.enabled ?? false;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.role = object.role ?? 0;
    message.lastLogin = object.lastLogin ?? undefined;
    return message;
  },
};

function createBaseUserPreferences(): UserPreferences {
  return { provider: "", timezone: "", theme: 0, skipVideoTour: false, skipFeatureHighlights: false };
}

export const UserPreferences = {
  encode(message: UserPreferences, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.provider !== "") {
      writer.uint32(18).string(message.provider);
    }
    if (message.timezone !== "") {
      writer.uint32(90).string(message.timezone);
    }
    if (message.theme !== 0) {
      writer.uint32(104).int32(message.theme);
    }
    if (message.skipVideoTour !== false) {
      writer.uint32(120).bool(message.skipVideoTour);
    }
    if (message.skipFeatureHighlights !== false) {
      writer.uint32(128).bool(message.skipFeatureHighlights);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserPreferences {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserPreferences();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.provider = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.timezone = reader.string();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.theme = reader.int32() as any;
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.skipVideoTour = reader.bool();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.skipFeatureHighlights = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserPreferences {
    return {
      provider: isSet(object.provider) ? globalThis.String(object.provider) : "",
      timezone: isSet(object.timezone) ? globalThis.String(object.timezone) : "",
      theme: isSet(object.theme) ? themeFromJSON(object.theme) : 0,
      skipVideoTour: isSet(object.skipVideoTour) ? globalThis.Boolean(object.skipVideoTour) : false,
      skipFeatureHighlights: isSet(object.skipFeatureHighlights)
        ? globalThis.Boolean(object.skipFeatureHighlights)
        : false,
    };
  },

  toJSON(message: UserPreferences): unknown {
    const obj: any = {};
    if (message.provider !== "") {
      obj.provider = message.provider;
    }
    if (message.timezone !== "") {
      obj.timezone = message.timezone;
    }
    if (message.theme !== 0) {
      obj.theme = themeToJSON(message.theme);
    }
    if (message.skipVideoTour !== false) {
      obj.skipVideoTour = message.skipVideoTour;
    }
    if (message.skipFeatureHighlights !== false) {
      obj.skipFeatureHighlights = message.skipFeatureHighlights;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserPreferences>, I>>(base?: I): UserPreferences {
    return UserPreferences.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserPreferences>, I>>(object: I): UserPreferences {
    const message = createBaseUserPreferences();
    message.provider = object.provider ?? "";
    message.timezone = object.timezone ?? "";
    message.theme = object.theme ?? 0;
    message.skipVideoTour = object.skipVideoTour ?? false;
    message.skipFeatureHighlights = object.skipFeatureHighlights ?? false;
    return message;
  },
};

function createBaseUserContext(): UserContext {
  return { orgId: "", orgName: "", current: false, tenant: undefined, user: undefined };
}

export const UserContext = {
  encode(message: UserContext, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orgId !== "") {
      writer.uint32(10).string(message.orgId);
    }
    if (message.orgName !== "") {
      writer.uint32(18).string(message.orgName);
    }
    if (message.current !== false) {
      writer.uint32(24).bool(message.current);
    }
    if (message.tenant !== undefined) {
      Tenant.encode(message.tenant, writer.uint32(90).fork()).ldelim();
    }
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(170).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserContext {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserContext();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orgName = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.current = reader.bool();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.tenant = Tenant.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserContext {
    return {
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      orgName: isSet(object.orgName) ? globalThis.String(object.orgName) : "",
      current: isSet(object.current) ? globalThis.Boolean(object.current) : false,
      tenant: isSet(object.tenant) ? Tenant.fromJSON(object.tenant) : undefined,
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
    };
  },

  toJSON(message: UserContext): unknown {
    const obj: any = {};
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.orgName !== "") {
      obj.orgName = message.orgName;
    }
    if (message.current !== false) {
      obj.current = message.current;
    }
    if (message.tenant !== undefined) {
      obj.tenant = Tenant.toJSON(message.tenant);
    }
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserContext>, I>>(base?: I): UserContext {
    return UserContext.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserContext>, I>>(object: I): UserContext {
    const message = createBaseUserContext();
    message.orgId = object.orgId ?? "";
    message.orgName = object.orgName ?? "";
    message.current = object.current ?? false;
    message.tenant = (object.tenant !== undefined && object.tenant !== null)
      ? Tenant.fromPartial(object.tenant)
      : undefined;
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
