import { RefObject } from "react";
import { CliCommandCompletion } from "src/gen/schema/bilge/api";
import { Fabric, Node } from "src/gen/schema/models/models";

const getDevice = (path: string, fabric: Fabric | undefined) => {
  const [, nodeId] = new RegExp(/\/node\/([^/]+)/).exec(path) ?? [];
  return fabric?.nodes.find((node: Node) => {
    return node.id === nodeId;
  });
};

const getPort = (path: string, fabric: Fabric | undefined) => {
  const [, portId] = new RegExp(/\/port\/([^/]+)/).exec(path) ?? [];
  const device = getDevice(path, fabric);
  return device?.ports.find((port) => {
    return port.id === portId;
  });
};

export const getInitialCommand = (
  path: string,
  fabric: Fabric | undefined
): string => {
  const device = getDevice(path, fabric);
  const port = getPort(path, fabric);
  let _command = "";
  if (device) {
    _command += `show device ${device.name} `;
    if (port) {
      _command += ` ports ${port.index} `;
    }
  } else {
    _command = "show ";
  }
  return _command;
};

export const moveCursorForwardByWord = (
  inputRef: React.RefObject<HTMLInputElement>,
  command: string
) => {
  if (inputRef.current) {
    const currCursor = inputRef.current.selectionStart || 0;
    const commandFromCursorPos = command.slice(currCursor);
    const commandList = commandFromCursorPos.split(" ");
    const newCursorPos = currCursor + (commandList?.[0]?.length ?? 0) + 1;
    inputRef.current.setSelectionRange(newCursorPos, newCursorPos);
  }
};

export const moveCursorBackwardByWord = (
  inputRef: React.RefObject<HTMLInputElement>,
  command: string
) => {
  if (inputRef.current) {
    const input = inputRef.current;
    const start = input.selectionStart || 0;
    const previousSpace = command.lastIndexOf(" ", start - 2);
    const previousWordStart = previousSpace === -1 ? 0 : previousSpace + 1;
    input.setSelectionRange(previousWordStart, previousWordStart);
  }
};

/**
 * This is to provide auto complete function
 * where the word/letter at which the cursor is positioned at
 * gets autocompleted when user hits "space" if at all there is
 * any suggested word from backend. If not, its treated as
 * normal space.
 *
 */
export const getCommandCompletionData = (
  inputRef: React.RefObject<HTMLInputElement>,
  commandCompleteSuggestions: CliCommandCompletion[] | undefined,
  suggestion?: string
) => {
  const cursorPos = inputRef.current?.selectionStart || 0;
  const command = inputRef.current?.value || "";
  const textBeforeReplacementWord = command.slice(0, cursorPos - 1).split(" ");
  textBeforeReplacementWord.pop(); // Remove the first half current incomplete word
  const textAfterReplacementWord = command.slice(cursorPos).split(" ");
  textAfterReplacementWord.shift(); // Remove the next half current incomplete word
  const suggestedKeyword = suggestion
    ? suggestion
    : commandCompleteSuggestions
    ? commandCompleteSuggestions?.[0]?.keyword
    : "";
  let newValue, newCursorPos;
  if (suggestedKeyword) {
    const textTillSuggestedKeyword = `${textBeforeReplacementWord.join(
      " "
    )} ${suggestedKeyword}`.trimStart();
    newCursorPos = textTillSuggestedKeyword.length + 1;
    newValue = `${textBeforeReplacementWord.join(
      " "
    )} ${suggestedKeyword} ${textAfterReplacementWord.join(" ")}`.trimStart();
  }
  return { newValue, newCursorPos };
};
/**
 * This is to handle auto complete when there are multiple options
 * where the word/letter at which the cursor is positioned at
 * does not gets autocompleted when user hits "space", instead shows all
 * the options available that the command can take up.
 *
 * This function is to remove off the space or the keyword when user hits space,
 * to help user continue typing.
 */
export const getAmbiguousAutoComplete = (
  inputRef: React.RefObject<HTMLInputElement>
) => {
  const cursorPos = inputRef.current?.selectionStart || 0;
  const command = inputRef.current?.value || "";
  const textTillCursor = command.slice(0, cursorPos - 1);
  const textAfterReplacementWord = command.slice(cursorPos).split(" ");
  textAfterReplacementWord.shift(); // Remove the next half current incomplete word
  const newValue = `${textTillCursor} ${textAfterReplacementWord.join(" ")}`;
  const newCursorPos = textTillCursor.length;
  return { newValue, newCursorPos };
};

export const getMockShellHelp = () => {
  return {
    space: "Autocompletes if any partially typed unambiguous command",
    tab: "Shows options for next command",
    "?": "Shows options for next command with help string",
    return: "Executes command until the cursor"
  };
};

export const getMockShellCtrlKeysHelp = () => {
  return {
    a: "Move to beginning of line",
    e: "Move to end of line",
    k: "Kill (cut) text from cursor to end of line",
    y: "Yank (paste) most recently killed text",
    p: "Move to previous command in history",
    n: "Move to next command in history"
  };
};

export const getMockShellArrowKeysHelp = () => {
  return {
    "↑": "Move to previous command in history",
    "↓": "Move to next command in history"
  };
};

export const getMockShellMetaKeysHelp = () => {
  return {
    f: "Move forward 1 word",
    b: "Move backward 1 word"
  };
};

export const copyMockshellCmds = (ref: RefObject<HTMLDivElement> | null) => {
  if (ref?.current) {
    const htmlContent = ref.current.innerHTML;
    const blob = new Blob([htmlContent], { type: "text/html" });
    const clipboardItem = new ClipboardItem({ "text/html": blob });
    // Write the ClipboardItem to the clipboard
    navigator.clipboard.write([clipboardItem]);
  }
};
