// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: configd/service.proto

/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";
import { EchoRequest, EchoResponse } from "../common/echo";
import { Empty } from "../common/empty";
import { Endpoint } from "../models/endpoints";
import { FabricImageUpdate } from "../models/image";
import {
  Loopback,
  ManagementPort,
  NetworkPort,
  PortBreakout,
  PortChannel,
  StaticRoutes,
  SubInterface,
  Vni,
  Vrf,
} from "../models/models";
import { BgpPolicy } from "../models/routing";
import { Credentials, PortConnection, VlanMember } from "../models/types";
import {
  CreateRefPointRequest,
  DiffPart,
  RefPoint,
  RefPointByFabricRequest,
  RefPointByNameRequest,
  RefPointList,
} from "../refpoints/refpoints";
import {
  AddNodesToFabricRequest,
  AddNodesToFabricResponse,
  BindDeviceRequest,
  BindDeviceResponse,
  ClaimDevicesRequest,
  ClaimDevicesResponse,
  CommitFabricCandidateRequest,
  CommitFabricCandidateResponse,
  DeleteNodeFromFabricRequest,
  DeviceClaimRequest,
  DeviceClaimResponse,
  FabricCandidate,
  GetActivityEventsRequest,
  GetActivityEventsResponse,
  GetBgpPeersRequest,
  GetBgpPoliciesRequest,
  GetCableModelsRequest,
  GetCableModelsResponse,
  GetDeviceModelsRequest,
  GetDeviceModelsResponse,
  GetDevicePsuModelsRequest,
  GetDevicePsuModelsResponse,
  GetDhcpRelaysRequest,
  GetFabricAssemblyRequest,
  GetFabricAssemblyResponse,
  GetFabricBomRequest,
  GetFabricBomResponse,
  GetFabricCandidateRequest,
  GetFabricCandidatesRequest,
  GetFabricCandidatesResponse,
  GetFabricConfigRequest,
  GetFabricConfigResponse,
  GetFabricInventoriesRequest,
  GetFabricInventoriesResponse,
  GetFabricsRequest,
  GetFabricsResponse,
  GetIpmConfigsRequest,
  GetLabelsRequest,
  GetLabelsResponse,
  GetLimitsRequest,
  GetLimitsResponse,
  GetLoopbacksRequest,
  GetMaterializedVniRequest,
  GetNodeConnectionsRequest,
  GetNodeConnectionsResponse,
  GetNodesPortsRequest,
  GetNodesPortsResponse,
  GetNodesRequest,
  GetNodesResponse,
  GetOrgCertificatesRequest,
  GetOrgCertificatesResponse,
  GetPerVlanStpRequest,
  GetPerVlanStpResponse,
  GetPortBreakoutsRequest,
  GetPortBreakoutsResponse,
  GetPortChannelsRequest,
  GetPrefixListsRequest,
  GetPrefixListsResponse,
  GetStaticRoutesRequest,
  GetSubInterfacesRequest,
  GetTenantsObjectsRequest,
  GetVlansRequest,
  GetVnisRequest,
  GetVrfsRequest,
  GetWorkflowFeedbacksRequest,
  GetWorkflowFeedbacksResponse,
  ProvisionRequest,
  ProvisionResponse,
  RevertFabricCandidateRequest,
  ReviewFabricCandidateRequest,
  ReviewFabricCandidateResponse,
  SyncPortStateRequest,
  SyncPortStateResponse,
  TenantsObjectsResponse,
  UnbindDeviceRequest,
  UnclaimDeviceRequest,
} from "./api";
import { AssertionsResponse, LatchAssertionsRequest } from "./assertions";
import { GetSingleCredentialsRequest } from "./credentials";
import {
  AddEndpointsRequest,
  DeleteEndpointRequest,
  EndpointsResponse,
  GetEndpointRequest,
  GetEndpointsRequest,
  ResendCodeForEndpointRequest,
  UpdateEndpointRequest,
  VerifyEndpointRequest,
} from "./endpoints";
import {
  AssignUnknownDeviceRequest,
  AssignUnknownDeviceResponse,
  GetDevicesRequest,
  GetDevicesResponse,
} from "./fabrics";
import {
  AddFabricImageUpdateRequest,
  AddImagePackagesRequest,
  AddNodeImagePackagesRequest,
  AddNodeImagePackagesResponse,
  DeleteImagePackagesRequest,
  DeleteNodeImagePackagesRequest,
  GetFabricImageUpdatesRequest,
  GetFabricImageUpdatesResponse,
  GetImagePackagesRequest,
  GetImagePackagesResponse,
  GetNodeImagePackagesRequest,
  GetNodeImagePackagesResponse,
  GetNodeImageUpdatesRequest,
  GetNodeImageUpdatesResponse,
  ImagePackagesResponse,
  UpdateImagePackagesRequest,
  UpdateNodeImageUpdatesRequest,
  UpdateNodeImageUpdatesResponse,
} from "./image";
import {
  AddManagementPortsRequest,
  GetManagementPortRequest,
  GetManagementPortsRequest,
  ManagementPortsResponse,
  ResetManagementPortRequest,
  UpdateManagementPortRequest,
  UpdatePortRequest,
} from "./node";
import {
  AgentConfigRequest,
  AgentConfigResponse,
  GetImageManifestRequest,
  GetImageManifestResponse,
  SetAgentPlatformInfoRequest,
  SetAgentPlatformInfoResponse,
  SetDeviceInventoryRequest,
  SetDeviceInventoryResponse,
  SetWorkflowFeedbacksRequest,
  SetWorkflowFeedbacksResponse,
} from "./onprem";
import { LatchUnlatchAssertionsRequest, LatchUnlatchAssertionsResponse } from "./rest_assertions";
import {
  AddFabricBgpPoliciesRequest,
  AddFabricConnectionsRequest,
  AddFabricsRequest,
  AddFabricsResponse,
  AddPortChannelsRequest,
  DeleteFabricBgpPolicyRequest,
  DeleteFabricConnectionRequest,
  DeleteFabricConnectionsRequest,
  DeleteFabricRequest,
  DeletePortChannelRequest,
  Fabric,
  FabricBgpPoliciesResponse,
  FabricConnectionsResponse,
  FabricRevisionIdResponse,
  GetAllFabricsRequest,
  GetAllFabricsResponse,
  GetAllPortChannelsRequest,
  GetFabricBgpPoliciesRequest,
  GetFabricBgpPolicyRequest,
  GetFabricConnectionRequest,
  GetFabricConnectionsRequest,
  GetFabricRequest,
  GetFabricRevisionIdRequest,
  GetPortChannelRequest,
  PortChannelsResponse,
  SetFabricConnectionRequest,
  SetFabricConnectionsRequest,
  UpdateFabricBgpPolicyRequest,
  UpdateFabricRequest,
  UpdatePortChannelRequest,
} from "./rest_fabrics";
import {
  AddFabricStaticRoutesRequest,
  AddFabricVniMembersRequest,
  AddFabricVnisRequest,
  AddFabricVrfsRequest,
  AddNodeLoopbacksRequest,
  AddNodeSubInterfacesRequest,
  DeleteFabricStaticRouteRequest,
  DeleteFabricVniMemberRequest,
  DeleteFabricVniRequest,
  DeleteFabricVrfRequest,
  DeleteNodeLoopbackRequest,
  DeleteNodeSubInterfaceRequest,
  FabricDhcpRelaysResponse,
  FabricLoopbacksResponse,
  FabricStaticRoutesResponse,
  FabricVniMembersResponse,
  FabricVnisResponse,
  FabricVrfsResponse,
  GetFabricDhcpRelaysRequest,
  GetFabricLoopbacksRequest,
  GetFabricStaticRouteRequest,
  GetFabricStaticRoutesRequest,
  GetFabricVniMemberRequest,
  GetFabricVniMembersRequest,
  GetFabricVniRequest,
  GetFabricVnisRequest,
  GetFabricVrfRequest,
  GetFabricVrfsRequest,
  GetNodeLoopbackRequest,
  GetNodeLoopbacksRequest,
  GetNodeSubInterfaceRequest,
  GetNodeSubInterfacesRequest,
  NodeLoopbacksResponse,
  NodeSubInterfacesResponse,
  UpdateFabricStaticRouteRequest,
  UpdateFabricVniRequest,
  UpdateFabricVrfRequest,
  UpdateNodeLoopbackRequest,
  UpdateNodeSubInterfaceRequest,
} from "./rest_networks";
import {
  AddFabricNodesRequest,
  AddFabricNodesResponse,
  DeleteFabricNodeRequest,
  GetFabricNodesRequest,
  GetFabricNodesResponse,
  GetNamedFabricNodeRequest,
  Node,
  UpdateFabricNodeRequest,
} from "./rest_node";
import {
  AddNodePortBreakoutsRequest,
  DeleteNodePortBreakoutRequest,
  GetNodePortBreakoutRequest,
  GetNodePortBreakoutsRequest,
  GetPortRequest,
  GetPortsRequest,
  NodePortBreakoutsResponse,
  PortsResponse,
  ResetPortRequest,
  SetPortsRequest,
  UpdateNodePortBreakoutRequest,
} from "./rest_ports";

export const protobufPackage = "configd";

/**
 * ConfigD implements service configuration management APIs. ConfigD has only one
 * configuration create/update/delete (CRUD) gRPC API, and that is called Provision.
 */
export interface ConfigD {
  /** Echo implements service status check API. */
  Echo(request: DeepPartial<EchoRequest>, metadata?: grpc.Metadata): Promise<EchoResponse>;
  /** StreamEcho implements a streaming version of service status check API. */
  StreamEcho(request: DeepPartial<EchoRequest>, metadata?: grpc.Metadata): Observable<EchoResponse>;
  /**
   * Get the list of organization limits.
   *
   * Get the list of limits and restrictions information applied by the service for this organization.
   */
  GetLimits(request: DeepPartial<GetLimitsRequest>, metadata?: grpc.Metadata): Promise<GetLimitsResponse>;
  /**
   * Get the list of labels.
   *
   * Get the list of labels and associated object identifiers information.
   */
  GetLabels(request: DeepPartial<GetLabelsRequest>, metadata?: grpc.Metadata): Promise<GetLabelsResponse>;
  /**
   * Get the list of user activity events.
   *
   * Get the list of user activity events.
   * User activity events are generated by the service when a user modifies configuration objects.
   */
  GetActivityEvents(
    request: DeepPartial<GetActivityEventsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetActivityEventsResponse>;
  /**
   * Provision implements all create/update/delete (CRUD) operations.
   *
   * A provision request classifies various configs into different groups.
   * Provision guarantees atomic commit or rollback of config changes.
   */
  Provision(request: DeepPartial<ProvisionRequest>, metadata?: grpc.Metadata): Promise<ProvisionResponse>;
  /**
   * Get the list of discovered devices.
   *
   * Get the list of discovered devices via LLDP in a specific fabric.
   */
  GetFabricInventories(
    request: DeepPartial<GetFabricInventoriesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetFabricInventoriesResponse>;
  /**
   * Get the assembled list of parts.
   *
   * Get the assembled list of parts for every node in a specific fabric.
   *
   * INTERNAL
   *
   * GetFabricAssembly queries and returns assembled list of parts for every node in Fabric.
   */
  GetFabricAssembly(
    request: DeepPartial<GetFabricAssemblyRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetFabricAssemblyResponse>;
  /**
   * Get the Bill Of Materials.
   *
   * Get the Bill Of Materials (BOM) of the products in a specific fabric.
   *
   * INTERNAL
   *
   * GetFabricBom queries and returns BOM(Bill of materials) of the products in Fabric.
   */
  GetFabricBom(request: DeepPartial<GetFabricBomRequest>, metadata?: grpc.Metadata): Promise<GetFabricBomResponse>;
  /**
   * Get the list of tenant objects.
   *
   * Get the list of tenant objects in the organizations.
   */
  GetTenantsObjects(
    request: DeepPartial<GetTenantsObjectsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<TenantsObjectsResponse>;
  /**
   * Get the list of VLANs.
   *
   * Get the list of VLANs in the fabric.
   */
  GetVlans(request: DeepPartial<GetVlansRequest>, metadata?: grpc.Metadata): Promise<TenantsObjectsResponse>;
  /**
   * Get the list of IpmConfig objects.
   *
   * Get the list of IpmConfig objects in the fabric.
   */
  GetIpmConfigs(request: DeepPartial<GetIpmConfigsRequest>, metadata?: grpc.Metadata): Promise<TenantsObjectsResponse>;
  /**
   * Get the list of VNIs.
   *
   * Get the list of VNIs in the organization.
   */
  GetVnis(request: DeepPartial<GetVnisRequest>, metadata?: grpc.Metadata): Promise<TenantsObjectsResponse>;
  /** GetMaterializedVnis materializes a set of tabular VNIs for a given switch. */
  GetMaterializedVni(
    request: DeepPartial<GetMaterializedVniRequest>,
    metadata?: grpc.Metadata,
  ): Promise<TenantsObjectsResponse>;
  /**
   * Get the list of VRFs.
   *
   * Get the list of VRFs in the organization.
   */
  GetVrfs(request: DeepPartial<GetVrfsRequest>, metadata?: grpc.Metadata): Promise<TenantsObjectsResponse>;
  /**
   * Get the list of static routes.
   *
   * Get the list of static routes in the organization.
   */
  GetStaticRoutes(
    request: DeepPartial<GetStaticRoutesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<TenantsObjectsResponse>;
  /**
   * Get the list of DHCP relays.
   *
   * Get the list of DHCP relays in the organization.
   */
  GetDhcpRelays(request: DeepPartial<GetDhcpRelaysRequest>, metadata?: grpc.Metadata): Promise<TenantsObjectsResponse>;
  /**
   * Get the list of loopbacks.
   *
   * Get the list of loopbacks in the organization.
   */
  GetLoopbacks(request: DeepPartial<GetLoopbacksRequest>, metadata?: grpc.Metadata): Promise<TenantsObjectsResponse>;
  /**
   * Get the list of port channels.
   *
   * Get the list of port channels in a specific fabric.
   */
  GetPortChannels(
    request: DeepPartial<GetPortChannelsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<TenantsObjectsResponse>;
  /**
   * Get the list of port channels.
   *
   * Get the list of port channels in a specific fabric.
   */
  GetAllPortChannels(
    request: DeepPartial<GetAllPortChannelsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<PortChannelsResponse>;
  /**
   * Get a specific port channel.
   *
   * Get a specific port channel in a fabric.
   */
  GetPortChannel(request: DeepPartial<GetPortChannelRequest>, metadata?: grpc.Metadata): Promise<PortChannel>;
  /**
   * Add one or more port channels.
   *
   * Add one or more port channels in a specific fabric.
   */
  AddPortChannels(
    request: DeepPartial<AddPortChannelsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<PortChannelsResponse>;
  /**
   * Update a specific port channel.
   *
   * Update a specific port channel in a fabric.
   */
  UpdatePortChannel(request: DeepPartial<UpdatePortChannelRequest>, metadata?: grpc.Metadata): Promise<PortChannel>;
  /**
   * Delete a specific port channel.
   *
   * Delete a specific port channel in a fabric.
   */
  DeletePortChannel(request: DeepPartial<DeletePortChannelRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * Get the list of sub-interfaces.
   *
   * Get the list of sub-interfaces on a specific port of a node in a
   * fabric.
   */
  GetSubInterfaces(
    request: DeepPartial<GetSubInterfacesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<TenantsObjectsResponse>;
  /**
   * Get the global spanning-tree configuration.
   *
   * Get the global spanning-tree configuration of a specific fabric.
   */
  GetPerVlanStp(request: DeepPartial<GetPerVlanStpRequest>, metadata?: grpc.Metadata): Promise<GetPerVlanStpResponse>;
  /** Get the list of BGP peers of a specific VRF in a fabric. */
  GetBgpPeers(request: DeepPartial<GetBgpPeersRequest>, metadata?: grpc.Metadata): Promise<TenantsObjectsResponse>;
  /** Get the list of BGP policies of a specific fabric. */
  GetBgpPolicies(
    request: DeepPartial<GetBgpPoliciesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<TenantsObjectsResponse>;
  /**
   * Get all configured fabrics.
   *
   * Responses can be filtered by one or more query parameters, fabric id, name etc.
   */
  GetFabrics(request: DeepPartial<GetFabricsRequest>, metadata?: grpc.Metadata): Promise<GetFabricsResponse>;
  /**
   * Get the list of candidate configurations.
   *
   * Get the list of candidate configurations in a specific fabric.
   */
  GetFabricCandidates(
    request: DeepPartial<GetFabricCandidatesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetFabricCandidatesResponse>;
  /**
   * Get a specific candidate configuration.
   *
   * Get a specific candidate configuration in a fabric.
   */
  GetFabricCandidate(
    request: DeepPartial<GetFabricCandidateRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricCandidate>;
  /**
   * Commit a specific candidate configuration.
   *
   * Commit a specific candidate configuration to the running configuration in a fabric.
   */
  CommitFabricCandidate(
    request: DeepPartial<CommitFabricCandidateRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CommitFabricCandidateResponse>;
  /**
   * Discard a specific candidate configuration.
   *
   * Discard a specific candidate configuration in a fabric.
   */
  RevertFabricCandidate(request: DeepPartial<RevertFabricCandidateRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * Comment on a specific candidate configuration.
   *
   * Add a comment to a specific candidate configuration in a fabric.
   */
  ReviewFabricCandidate(
    request: DeepPartial<ReviewFabricCandidateRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ReviewFabricCandidateResponse>;
  /** Get the list of nodes configuration information. */
  GetNodes(request: DeepPartial<GetNodesRequest>, metadata?: grpc.Metadata): Promise<GetNodesResponse>;
  /** Experimental API. Do not use. */
  ClaimDevicesInternal(
    request: DeepPartial<DeviceClaimRequest>,
    metadata?: grpc.Metadata,
  ): Promise<DeviceClaimResponse>;
  /**
   * Claim one or more devices into the organization.
   *
   * Claim ownership of one or more devices and assign those devices to the organization.
   */
  ClaimDevices(request: DeepPartial<ClaimDevicesRequest>, metadata?: grpc.Metadata): Promise<ClaimDevicesResponse>;
  /**
   * Unclaim a specific device.
   *
   * Unclaim a specific device from the organization.
   * After being unclaimed, a device becomes an unknown device to the organization and can be
   * claimed by another organization.
   */
  UnclaimDevice(request: DeepPartial<UnclaimDeviceRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * GetWorkflowFeedbacks queries and returns node workflows results. Devices uploads
   * results after executing config workflows.
   */
  GetWorkflowFeedbacks(
    request: DeepPartial<GetWorkflowFeedbacksRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetWorkflowFeedbacksResponse>;
  /** Returns network ports of nodes. */
  GetNodesPorts(request: DeepPartial<GetNodesPortsRequest>, metadata?: grpc.Metadata): Promise<GetNodesPortsResponse>;
  /** Returns network port breakout configs. */
  GetPortBreakouts(
    request: DeepPartial<GetPortBreakoutsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetPortBreakoutsResponse>;
  /** GetOrgCertificates queries and returns org certificates. */
  GetOrgCertificates(
    request: DeepPartial<GetOrgCertificatesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetOrgCertificatesResponse>;
  /** GetDeviceModels queries and returns device models. */
  GetDeviceModels(
    request: DeepPartial<GetDeviceModelsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetDeviceModelsResponse>;
  /** GetCableModels queries and returns cable models. */
  GetCableModels(
    request: DeepPartial<GetCableModelsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetCableModelsResponse>;
  /** GetDevicePsuModels queries and returns psu models for a given device model. */
  GetDevicePsuModels(
    request: DeepPartial<GetDevicePsuModelsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetDevicePsuModelsResponse>;
  /**
   * SyncPortState synchronize port state to the desired state in switches.
   * SyncPortState does not update configured state of the port.
   */
  SyncPortState(request: DeepPartial<SyncPortStateRequest>, metadata?: grpc.Metadata): Promise<SyncPortStateResponse>;
  /**
   * Get all notification endpoints.
   *
   * Get all configured notification endpoints.
   */
  GetEndpoints(request: DeepPartial<GetEndpointsRequest>, metadata?: grpc.Metadata): Promise<EndpointsResponse>;
  /**
   * Get a specific notification endpoint.
   *
   * Get a specific notification endpoint.
   */
  GetEndpoint(request: DeepPartial<GetEndpointRequest>, metadata?: grpc.Metadata): Promise<Endpoint>;
  /**
   * Add new notification endpoints.
   *
   * Add one or more new notification endpoints.
   */
  AddEndpoints(request: DeepPartial<AddEndpointsRequest>, metadata?: grpc.Metadata): Promise<EndpointsResponse>;
  /**
   * Update a notification endpoint.
   *
   * Update a notification endpoint.
   */
  UpdateEndpoint(request: DeepPartial<UpdateEndpointRequest>, metadata?: grpc.Metadata): Promise<Endpoint>;
  /**
   * Verify a notification endpoint.
   *
   * When a new endpoint is created a confirmation code is sent to the new endpoint.
   * This value can then be used to verify that the endpoint works as intended.
   */
  VerifyEndpoint(request: DeepPartial<VerifyEndpointRequest>, metadata?: grpc.Metadata): Promise<Endpoint>;
  /**
   * Resend a confirmation code.
   *
   * Send a new confirmation code to the endpoint. This new value can then
   * be used to confirm that the endpoint works as intended.
   */
  ResendCodeForEndpoint(request: DeepPartial<ResendCodeForEndpointRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * Delete a notification endpoint.
   *
   * Delete a notification endpoint.
   */
  DeleteEndpoint(request: DeepPartial<DeleteEndpointRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * Get the latest revision id
   *
   * Returns the latest revision id for a fabrics candidate configuration.
   *
   * The revision id can be included in calls that commit the candidate configuration changes.
   * If provided, requests will be rejected if the provided revision id is
   * not the same than the most recent one known to the Hyperfabric service.
   *
   * If not provided then the candidate configuration changes are applied.
   */
  GetFabricRevisionId(
    request: DeepPartial<GetFabricRevisionIdRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricRevisionIdResponse>;
  /**
   * Get the list of fabrics.
   *
   * Get the list of fabrics in the organization.
   */
  GetAllFabrics(request: DeepPartial<GetAllFabricsRequest>, metadata?: grpc.Metadata): Promise<GetAllFabricsResponse>;
  /**
   * Get a specific fabric.
   *
   * Get a specific fabric.
   */
  GetFabric(request: DeepPartial<GetFabricRequest>, metadata?: grpc.Metadata): Promise<Fabric>;
  /**
   * Get the entire configuration.
   *
   * Get the entire configuration of a specific fabric.
   */
  GetFabricConfig(
    request: DeepPartial<GetFabricConfigRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetFabricConfigResponse>;
  /**
   * Add a fabric.
   *
   * Add a fabric to the organization. Currently only one fabric permitted per call.
   */
  AddFabrics(request: DeepPartial<AddFabricsRequest>, metadata?: grpc.Metadata): Promise<AddFabricsResponse>;
  /**
   * Update a specific fabric.
   *
   * Update a specific fabric.
   */
  UpdateFabric(request: DeepPartial<UpdateFabricRequest>, metadata?: grpc.Metadata): Promise<Fabric>;
  /**
   * Delete a specific fabric.
   *
   * Delete a specific fabric.
   */
  DeleteFabric(request: DeepPartial<DeleteFabricRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * Get the list of nodes.
   *
   * Get the list of nodes in a specific fabric.
   */
  GetFabricNodes(
    request: DeepPartial<GetFabricNodesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetFabricNodesResponse>;
  /**
   * Get a specific node.
   *
   * Get a specific node in a fabric.
   */
  GetNamedFabricNode(request: DeepPartial<GetNamedFabricNodeRequest>, metadata?: grpc.Metadata): Promise<Node>;
  /**
   * Add one or more nodes.
   *
   * Add one or more nodes to a specific fabric.
   */
  AddFabricNodes(
    request: DeepPartial<AddFabricNodesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AddFabricNodesResponse>;
  /**
   * Update a specific node.
   *
   * Update a specific node in a fabric.
   */
  UpdateFabricNode(request: DeepPartial<UpdateFabricNodeRequest>, metadata?: grpc.Metadata): Promise<Node>;
  /**
   * Delete a specific node.
   *
   * Delete a specific node in a fabric.
   */
  DeleteFabricNode(request: DeepPartial<DeleteFabricNodeRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * Add one or more nodes to a specific fabric.
   *
   * A node can optionally include a list of ports for the node or a default set of
   * ports will be provided.
   */
  AddNodesToFabric(
    request: DeepPartial<AddNodesToFabricRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AddNodesToFabricResponse>;
  /**
   * Delete a specific node in a fabric.
   *
   * The node must exist, belong to the referenced fabric and must not already be bound to a switch.
   */
  DeleteNodeFromFabric(request: DeepPartial<DeleteNodeFromFabricRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * Get the list of connections.
   *
   * Get the list of connections in a specific fabric.
   */
  GetFabricConnections(
    request: DeepPartial<GetFabricConnectionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricConnectionsResponse>;
  /**
   * Get a specific connection.
   *
   * Get a specific connection in a fabric.
   */
  GetFabricConnection(
    request: DeepPartial<GetFabricConnectionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<PortConnection>;
  /**
   * Delete all connections.
   *
   * Delete all connections in a specific fabric.
   */
  DeleteFabricConnections(
    request: DeepPartial<DeleteFabricConnectionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<Empty>;
  /**
   * Delete a specific connection.
   *
   * Delete a specific connection in a fabric.
   */
  DeleteFabricConnection(request: DeepPartial<DeleteFabricConnectionRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * Update the list of connections.
   *
   * Update the list of connections in a specific fabric.
   * This is a complete replacement of the connections in a fabric. If no connections
   * exist new ones will be created.
   */
  SetFabricConnections(
    request: DeepPartial<SetFabricConnectionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricConnectionsResponse>;
  /**
   * Update a specific connection.
   *
   * Update a specific connection in a fabric.
   */
  SetFabricConnection(
    request: DeepPartial<SetFabricConnectionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<PortConnection>;
  /**
   * Add one or more connections.
   *
   * Add one or more connections to a specific fabric.
   */
  AddFabricConnections(
    request: DeepPartial<AddFabricConnectionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricConnectionsResponse>;
  /**
   * Get the list of BGP policies.
   *
   * Get the list of BGP policies of a specific fabric.
   */
  GetFabricBgpPolicies(
    request: DeepPartial<GetFabricBgpPoliciesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricBgpPoliciesResponse>;
  /**
   * Get the list of IP prefix lists.
   *
   * Get the list of IP prefix lists.
   */
  GetPrefixLists(
    request: DeepPartial<GetPrefixListsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetPrefixListsResponse>;
  /**
   * Get a specific BGP policy.
   *
   * Get a specific BGP policy of a fabric.
   */
  GetFabricBgpPolicy(request: DeepPartial<GetFabricBgpPolicyRequest>, metadata?: grpc.Metadata): Promise<BgpPolicy>;
  /**
   * Add one or more BGP policies.
   *
   * Add one or more BGP policies to a specific fabric.
   */
  AddFabricBgpPolicies(
    request: DeepPartial<AddFabricBgpPoliciesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricBgpPoliciesResponse>;
  /**
   * Update a specific BGP policy.
   *
   * Update a specific BGP policy of a fabric.
   */
  UpdateFabricBgpPolicy(
    request: DeepPartial<UpdateFabricBgpPolicyRequest>,
    metadata?: grpc.Metadata,
  ): Promise<BgpPolicy>;
  /**
   * Delete a specific BGP policy.
   *
   * Delete a specific BGP policy of a fabric.
   */
  DeleteFabricBgpPolicy(request: DeepPartial<DeleteFabricBgpPolicyRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * Get the list of breakout ports.
   *
   * Get the list of breakout ports of a specific node in a fabric.
   */
  GetNodePortBreakouts(
    request: DeepPartial<GetNodePortBreakoutsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<NodePortBreakoutsResponse>;
  /**
   * Get a specific port breakout.
   *
   * Get a specific port breakout of a node in a fabric.
   */
  GetNodePortBreakout(
    request: DeepPartial<GetNodePortBreakoutRequest>,
    metadata?: grpc.Metadata,
  ): Promise<PortBreakout>;
  /**
   * Add one or more port breakouts.
   *
   * Add one or more port breakouts of a specific node in a fabric.
   */
  AddNodePortBreakouts(
    request: DeepPartial<AddNodePortBreakoutsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<NodePortBreakoutsResponse>;
  /**
   * Update a specific port breakout.
   *
   * Update a specific port breakout of a node in a fabric.
   */
  UpdateNodePortBreakout(
    request: DeepPartial<UpdateNodePortBreakoutRequest>,
    metadata?: grpc.Metadata,
  ): Promise<PortBreakout>;
  /**
   * Delete a specific port breakout.
   *
   * Delete a specific port breakout of a node in a fabric.
   */
  DeleteNodePortBreakout(request: DeepPartial<DeleteNodePortBreakoutRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * Get the list of management ports.
   *
   * Get the list of management ports for a specific node in a fabric.
   */
  GetManagementPorts(
    request: DeepPartial<GetManagementPortsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ManagementPortsResponse>;
  /**
   * Get a specific management port.
   *
   * Get a specific management port for a node in a fabric.
   */
  GetManagementPort(request: DeepPartial<GetManagementPortRequest>, metadata?: grpc.Metadata): Promise<ManagementPort>;
  /**
   * Add one or more management port.
   *
   * Add one or more management ports to a specific node in a fabric.
   */
  AddManagementPorts(
    request: DeepPartial<AddManagementPortsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ManagementPortsResponse>;
  /**
   * Update a specific management port.
   *
   * Update a specific management port for a node in a fabric.
   */
  UpdateManagementPort(
    request: DeepPartial<UpdateManagementPortRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ManagementPort>;
  /**
   * Reset a specific management port.
   *
   * Reset a specific management port for a node in a fabric to its default values.
   */
  ResetManagementPort(request: DeepPartial<ResetManagementPortRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * Get the list of connections.
   *
   * Get the list of connections of a specific node.
   */
  GetNodeConnections(
    request: DeepPartial<GetNodeConnectionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetNodeConnectionsResponse>;
  /**
   * Get the list of ports.
   *
   * Get the list of ports configuration information for a specific node in a fabric.
   */
  GetPorts(request: DeepPartial<GetPortsRequest>, metadata?: grpc.Metadata): Promise<PortsResponse>;
  /**
   * Update the list of ports.
   *
   * Update the list of ports for a specific node in a fabric.
   * This is a complete replacement of the ports for a node in a fabric.
   */
  SetPorts(request: DeepPartial<SetPortsRequest>, metadata?: grpc.Metadata): Promise<PortsResponse>;
  /**
   * Get a specific port.
   *
   * Get a specific port for a node in a fabric.
   */
  GetPort(request: DeepPartial<GetPortRequest>, metadata?: grpc.Metadata): Promise<NetworkPort>;
  /**
   * Update a specific port.
   *
   * Update a specific port for a node in a fabric.
   */
  UpdatePort(request: DeepPartial<UpdatePortRequest>, metadata?: grpc.Metadata): Promise<NetworkPort>;
  /**
   * Reset a specific port.
   *
   * Reset a specific port of a node in a fabric to its default values.
   * Fabric ports cannot be reset. Labels and annotations are not removed.
   */
  ResetPort(request: DeepPartial<ResetPortRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * Bind a specific device to a node.
   *
   * Bind a specific device to a node in a fabric.
   */
  BindDevice(request: DeepPartial<BindDeviceRequest>, metadata?: grpc.Metadata): Promise<BindDeviceResponse>;
  /**
   * Unbind the bound device from a node.
   *
   * Unbind the bound device from a specific node in a fabric.
   */
  UnbindDevice(request: DeepPartial<UnbindDeviceRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * Get the list of sub-interfaces.
   *
   * Get the list of sub-interfaces for a specific node in a fabric.
   */
  GetNodeSubInterfaces(
    request: DeepPartial<GetNodeSubInterfacesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<NodeSubInterfacesResponse>;
  /**
   * Get a specific sub-interface.
   *
   * Get a specific sub-interface for a node in a fabric.
   */
  GetNodeSubInterface(
    request: DeepPartial<GetNodeSubInterfaceRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SubInterface>;
  /**
   * Add one or more sub-interfaces.
   *
   * Add one or more sub-interfaces to a specific node in a fabric.
   */
  AddNodeSubInterfaces(
    request: DeepPartial<AddNodeSubInterfacesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<NodeSubInterfacesResponse>;
  /**
   * Update a specific sub-interface.
   *
   * Update a specific sub-interface for a node in a fabric.
   */
  UpdateNodeSubInterface(
    request: DeepPartial<UpdateNodeSubInterfaceRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SubInterface>;
  /**
   * Delete a specific sub-interface.
   *
   * Delete a specific sub-interface for a node in a fabric.
   */
  DeleteNodeSubInterface(request: DeepPartial<DeleteNodeSubInterfaceRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * Get the list of VNIs.
   *
   * Get the list of VNIs in a specific fabric.
   */
  GetFabricVnis(request: DeepPartial<GetFabricVnisRequest>, metadata?: grpc.Metadata): Promise<FabricVnisResponse>;
  /**
   * Get a specific VNI.
   *
   * Get a specific VNI in a fabric.
   */
  GetFabricVni(request: DeepPartial<GetFabricVniRequest>, metadata?: grpc.Metadata): Promise<Vni>;
  /**
   * Add one or more VNIs.
   *
   * Add one or more VNIs to a specific fabric.
   */
  AddFabricVnis(request: DeepPartial<AddFabricVnisRequest>, metadata?: grpc.Metadata): Promise<FabricVnisResponse>;
  /**
   * Update a specific VNI.
   *
   * Update a specific VNI in a fabric.
   */
  UpdateFabricVni(request: DeepPartial<UpdateFabricVniRequest>, metadata?: grpc.Metadata): Promise<Vni>;
  /**
   * Delete a specific VNI.
   *
   * Delete a specific VNI in a fabric.
   */
  DeleteFabricVni(request: DeepPartial<DeleteFabricVniRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * Get the list of VNI members.
   *
   * Get the list of members of a specific VNI in a fabric.
   */
  GetFabricVniMembers(
    request: DeepPartial<GetFabricVniMembersRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricVniMembersResponse>;
  /**
   * Get a specific VNI member.
   *
   * Get a specific member of a VNI in a fabric.
   */
  GetFabricVniMember(request: DeepPartial<GetFabricVniMemberRequest>, metadata?: grpc.Metadata): Promise<VlanMember>;
  /**
   * Add one or more VNI members.
   *
   * Add one or more members to a specific VNI in a fabric.
   */
  AddFabricVniMembers(
    request: DeepPartial<AddFabricVniMembersRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricVniMembersResponse>;
  /**
   * Delete a specific VNI member.
   *
   * Delete a specific member of a VNI in a fabric.
   */
  DeleteFabricVniMember(request: DeepPartial<DeleteFabricVniMemberRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * Get the list of VRFs.
   *
   * Get the list of VRFs in a specific fabric.
   */
  GetFabricVrfs(request: DeepPartial<GetFabricVrfsRequest>, metadata?: grpc.Metadata): Promise<FabricVrfsResponse>;
  /**
   * Get a specific VRF.
   *
   * Get a specific VRF in a fabric.
   */
  GetFabricVrf(request: DeepPartial<GetFabricVrfRequest>, metadata?: grpc.Metadata): Promise<Vrf>;
  /**
   * Add one or more VRFs.
   *
   * Add one or more VRFs to a specific fabric.
   */
  AddFabricVrfs(request: DeepPartial<AddFabricVrfsRequest>, metadata?: grpc.Metadata): Promise<FabricVrfsResponse>;
  /**
   * Update a specific VRF.
   *
   * Update a specific VRF in a fabric.
   */
  UpdateFabricVrf(request: DeepPartial<UpdateFabricVrfRequest>, metadata?: grpc.Metadata): Promise<Vrf>;
  /**
   * Delete a specific VRF.
   *
   * Delete a specific VRF in a fabric.
   */
  DeleteFabricVrf(request: DeepPartial<DeleteFabricVrfRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * Get the list of static routes.
   *
   * Get the list of static routes of a specific VRF in a fabric.
   */
  GetFabricStaticRoutes(
    request: DeepPartial<GetFabricStaticRoutesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricStaticRoutesResponse>;
  /**
   * Get a specific static route.
   *
   * Get a specific static route of a VRF in a fabric.
   */
  GetFabricStaticRoute(
    request: DeepPartial<GetFabricStaticRouteRequest>,
    metadata?: grpc.Metadata,
  ): Promise<StaticRoutes>;
  /**
   * Add one or more static routes.
   *
   * Add one or more static routes to a VRF in a fabric.
   */
  AddFabricStaticRoutes(
    request: DeepPartial<AddFabricStaticRoutesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricStaticRoutesResponse>;
  /**
   * Delete a specific static route.
   *
   * Delete a specific static route of a VRF in a fabric.
   */
  DeleteFabricStaticRoute(
    request: DeepPartial<DeleteFabricStaticRouteRequest>,
    metadata?: grpc.Metadata,
  ): Promise<Empty>;
  /**
   * Update a specific static route.
   *
   * Update a specific static route of a VRF in a fabric.
   */
  UpdateFabricStaticRoute(
    request: DeepPartial<UpdateFabricStaticRouteRequest>,
    metadata?: grpc.Metadata,
  ): Promise<StaticRoutes>;
  /**
   * Get the dhcp relays of a fabric.
   *
   * Get the list of dhcp relays in a specific fabric.
   */
  GetFabricDhcpRelays(
    request: DeepPartial<GetFabricDhcpRelaysRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricDhcpRelaysResponse>;
  /**
   * Get the loopbacks of a fabric.
   *
   * Get the list of all loopback interfaces in a specific fabric.
   */
  GetFabricLoopbacks(
    request: DeepPartial<GetFabricLoopbacksRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricLoopbacksResponse>;
  /**
   * Get the list of loopbacks.
   *
   * Get the list of loopback interfaces for a specific node in a fabric.
   */
  GetNodeLoopbacks(
    request: DeepPartial<GetNodeLoopbacksRequest>,
    metadata?: grpc.Metadata,
  ): Promise<NodeLoopbacksResponse>;
  /**
   * Get a specific loopback.
   *
   * Get a specific loopback interface for a node in a fabric.
   */
  GetNodeLoopback(request: DeepPartial<GetNodeLoopbackRequest>, metadata?: grpc.Metadata): Promise<Loopback>;
  /**
   * Add one or more loopbacks.
   *
   * Add one or more loopback interfaces to a specific node in a fabric.
   */
  AddNodeLoopbacks(
    request: DeepPartial<AddNodeLoopbacksRequest>,
    metadata?: grpc.Metadata,
  ): Promise<NodeLoopbacksResponse>;
  /**
   * Update a specific loopback.
   *
   * Update a specific loopback interface for a node in a fabric.
   */
  UpdateNodeLoopback(request: DeepPartial<UpdateNodeLoopbackRequest>, metadata?: grpc.Metadata): Promise<Loopback>;
  /**
   * Delete a specific loopback.
   *
   * Delete a specific loopback interface for a node in a fabric.
   */
  DeleteNodeLoopback(request: DeepPartial<DeleteNodeLoopbackRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /** Add the device image update configuration to a fabric. */
  AddFabricImageUpdate(
    request: DeepPartial<AddFabricImageUpdateRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricImageUpdate>;
  /** Get the configured image updates from a fabric */
  GetFabricImageUpdates(
    request: DeepPartial<GetFabricImageUpdatesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetFabricImageUpdatesResponse>;
  /** Update the node level image upgrade configuration */
  UpdateNodeImageUpdates(
    request: DeepPartial<UpdateNodeImageUpdatesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateNodeImageUpdatesResponse>;
  /** Get the node level image upgrade configuration */
  GetNodeImageUpdates(
    request: DeepPartial<GetNodeImageUpdatesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetNodeImageUpdatesResponse>;
  /** Testing API: Add a list NodeImagePackages entries */
  AddNodeImagePackages(
    request: DeepPartial<AddNodeImagePackagesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AddNodeImagePackagesResponse>;
  /** Testing API: Delete the list NodeImagePackage entries by node_id */
  DeleteNodeImagePackages(
    request: DeepPartial<DeleteNodeImagePackagesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<Empty>;
  /** Testing API: Get the list GetNodeImagePackage entries by node_id */
  GetNodeImagePackages(
    request: DeepPartial<GetNodeImagePackagesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetNodeImagePackagesResponse>;
  /** Add the global image package */
  AddImagePackages(
    request: DeepPartial<AddImagePackagesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ImagePackagesResponse>;
  /** Get the global image package under a release tag */
  GetImagePackages(
    request: DeepPartial<GetImagePackagesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetImagePackagesResponse>;
  /** Update the global image package for a release tag */
  UpdateImagePackages(
    request: DeepPartial<UpdateImagePackagesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ImagePackagesResponse>;
  /** Delete the global image package under a release tag */
  DeleteImagePackages(request: DeepPartial<DeleteImagePackagesRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * Get the list of devices.
   *
   * Get the list of devices associated with the organization, both bound and unbound.
   */
  GetDevices(request: DeepPartial<GetDevicesRequest>, metadata?: grpc.Metadata): Promise<GetDevicesResponse>;
  AssignUnknownDevice(
    request: DeepPartial<AssignUnknownDeviceRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AssignUnknownDeviceResponse>;
  /**
   * Get a specific set of credentials.
   *
   * Get a specific set of credentials.
   */
  GetSingleCredentials(
    request: DeepPartial<GetSingleCredentialsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<Credentials>;
  /** Latch/unlatch one or more assertions. */
  LatchAssertions(request: DeepPartial<LatchAssertionsRequest>, metadata?: grpc.Metadata): Promise<AssertionsResponse>;
  /**
   * Latch/unlatch one or more assertions.
   *
   * Latch or unlatch one or more assertions of a fabric.
   *
   * INTERNAL
   *
   * This API is a REST API only implementation. UI team should continue to use the
   * LatchAssertions rpc until migration to REST.
   */
  LatchUnlatchAssertions(
    request: DeepPartial<LatchUnlatchAssertionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<LatchUnlatchAssertionsResponse>;
  /** GetAgentConfig is invoked by device. Do not use. */
  GetAgentConfig(request: DeepPartial<AgentConfigRequest>, metadata?: grpc.Metadata): Promise<AgentConfigResponse>;
  /** SetDeviceInventory is invoked by devices. Do not use. */
  SetDeviceInventory(
    request: DeepPartial<SetDeviceInventoryRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SetDeviceInventoryResponse>;
  /** SetWorkflowFeedbacks is invoked by device. Do not use. */
  SetWorkflowFeedbacks(
    request: DeepPartial<SetWorkflowFeedbacksRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SetWorkflowFeedbacksResponse>;
  /** SetAgentPlatformInfo is invoked by device. Do not use. */
  SetAgentPlatformInfo(
    request: DeepPartial<SetAgentPlatformInfoRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SetAgentPlatformInfoResponse>;
  /** GetImageManifest is invoked by device. Do not use. */
  GetImageManifest(
    request: DeepPartial<GetImageManifestRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetImageManifestResponse>;
  /**
   * Get list of all reference point metadata for a fabric
   *
   * Returns all the created reference points for a fabric.
   * This may be an empty list, or a list contianing either one or both of
   * the 'reference' or 'provisional' reference points.
   */
  GetRefPoints(request: DeepPartial<RefPointByFabricRequest>, metadata?: grpc.Metadata): Promise<RefPointList>;
  /**
   * Get metadata for the named reference point
   *
   * Returns the metadata for the requested reference point.
   * The name must be either 'provisional' or 'reference'.
   *
   * Returns 'not found' error status if the requested reference point does not exist.
   */
  GetRefPoint(request: DeepPartial<RefPointByNameRequest>, metadata?: grpc.Metadata): Promise<RefPoint>;
  /**
   * Delete a reference point
   *
   * Deletes a reference point and any associated data, such as diffs.
   */
  DeleteRefPoint(request: DeepPartial<RefPointByNameRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * Create a 'provisional' reference point
   *
   * Create a reference point called 'provisional'.
   * This will immediately create and return the metadata, whilst queueing an
   * asynchronous job to collect and record the state of the fabric.
   *
   * The progress of the job can be tracked via the reference point metadata.
   *
   * Once created, a 'provisional' reference point can either be promoted to 'reference'
   * or compared with an existing 'reference'.
   */
  CreateProvisionalRefPoint(request: DeepPartial<CreateRefPointRequest>, metadata?: grpc.Metadata): Promise<RefPoint>;
  /**
   * Promote a 'provisional' reference point to 'reference'
   *
   * Convert the reference point named 'provisional' to 'reference' and return the updated
   * reference point metadata.
   *
   * This allows a new 'provisional' reference point to later be created and compared to 'reference'.
   *
   * Returns 'conflict' error status if there is already a 'reference' reference point for the fabric.
   * In this case the 'reference' must first be deleted.
   */
  PromoteProvisionalRefPoint(
    request: DeepPartial<RefPointByFabricRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RefPoint>;
  /**
   * Create a diff between 'reference' and 'provisional' reference points
   *
   * Queue and asynchrnous job to compare the current 'provisional' and 'reference'
   * reference point records.
   *
   * The progress of this job can be tracked via the 'reference' reference point metadata.
   *
   * The 'provisional' reference point metadata will be returned.
   * When the diff is ready, it can be downloaded via /api/v1/fabrics/{fabric_id}/refPoints/provisional/diff
   * The ref_point_id can be found in the refPoint metadata returned by this API.
   */
  CreateRefPointDiff(request: DeepPartial<RefPointByFabricRequest>, metadata?: grpc.Metadata): Promise<RefPoint>;
  /**
   * Get the diff data file
   *
   * Stream a diff data file that was previously created.
   *
   * This binary file will contain data that was in the reference and provisional data capture, but not in both.
   * RefPointName must be 'provisional'.
   */
  GetRefPointDiff(request: DeepPartial<RefPointByNameRequest>, metadata?: grpc.Metadata): Promise<DiffPart>;
}

export class ConfigDClientImpl implements ConfigD {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.Echo = this.Echo.bind(this);
    this.StreamEcho = this.StreamEcho.bind(this);
    this.GetLimits = this.GetLimits.bind(this);
    this.GetLabels = this.GetLabels.bind(this);
    this.GetActivityEvents = this.GetActivityEvents.bind(this);
    this.Provision = this.Provision.bind(this);
    this.GetFabricInventories = this.GetFabricInventories.bind(this);
    this.GetFabricAssembly = this.GetFabricAssembly.bind(this);
    this.GetFabricBom = this.GetFabricBom.bind(this);
    this.GetTenantsObjects = this.GetTenantsObjects.bind(this);
    this.GetVlans = this.GetVlans.bind(this);
    this.GetIpmConfigs = this.GetIpmConfigs.bind(this);
    this.GetVnis = this.GetVnis.bind(this);
    this.GetMaterializedVni = this.GetMaterializedVni.bind(this);
    this.GetVrfs = this.GetVrfs.bind(this);
    this.GetStaticRoutes = this.GetStaticRoutes.bind(this);
    this.GetDhcpRelays = this.GetDhcpRelays.bind(this);
    this.GetLoopbacks = this.GetLoopbacks.bind(this);
    this.GetPortChannels = this.GetPortChannels.bind(this);
    this.GetAllPortChannels = this.GetAllPortChannels.bind(this);
    this.GetPortChannel = this.GetPortChannel.bind(this);
    this.AddPortChannels = this.AddPortChannels.bind(this);
    this.UpdatePortChannel = this.UpdatePortChannel.bind(this);
    this.DeletePortChannel = this.DeletePortChannel.bind(this);
    this.GetSubInterfaces = this.GetSubInterfaces.bind(this);
    this.GetPerVlanStp = this.GetPerVlanStp.bind(this);
    this.GetBgpPeers = this.GetBgpPeers.bind(this);
    this.GetBgpPolicies = this.GetBgpPolicies.bind(this);
    this.GetFabrics = this.GetFabrics.bind(this);
    this.GetFabricCandidates = this.GetFabricCandidates.bind(this);
    this.GetFabricCandidate = this.GetFabricCandidate.bind(this);
    this.CommitFabricCandidate = this.CommitFabricCandidate.bind(this);
    this.RevertFabricCandidate = this.RevertFabricCandidate.bind(this);
    this.ReviewFabricCandidate = this.ReviewFabricCandidate.bind(this);
    this.GetNodes = this.GetNodes.bind(this);
    this.ClaimDevicesInternal = this.ClaimDevicesInternal.bind(this);
    this.ClaimDevices = this.ClaimDevices.bind(this);
    this.UnclaimDevice = this.UnclaimDevice.bind(this);
    this.GetWorkflowFeedbacks = this.GetWorkflowFeedbacks.bind(this);
    this.GetNodesPorts = this.GetNodesPorts.bind(this);
    this.GetPortBreakouts = this.GetPortBreakouts.bind(this);
    this.GetOrgCertificates = this.GetOrgCertificates.bind(this);
    this.GetDeviceModels = this.GetDeviceModels.bind(this);
    this.GetCableModels = this.GetCableModels.bind(this);
    this.GetDevicePsuModels = this.GetDevicePsuModels.bind(this);
    this.SyncPortState = this.SyncPortState.bind(this);
    this.GetEndpoints = this.GetEndpoints.bind(this);
    this.GetEndpoint = this.GetEndpoint.bind(this);
    this.AddEndpoints = this.AddEndpoints.bind(this);
    this.UpdateEndpoint = this.UpdateEndpoint.bind(this);
    this.VerifyEndpoint = this.VerifyEndpoint.bind(this);
    this.ResendCodeForEndpoint = this.ResendCodeForEndpoint.bind(this);
    this.DeleteEndpoint = this.DeleteEndpoint.bind(this);
    this.GetFabricRevisionId = this.GetFabricRevisionId.bind(this);
    this.GetAllFabrics = this.GetAllFabrics.bind(this);
    this.GetFabric = this.GetFabric.bind(this);
    this.GetFabricConfig = this.GetFabricConfig.bind(this);
    this.AddFabrics = this.AddFabrics.bind(this);
    this.UpdateFabric = this.UpdateFabric.bind(this);
    this.DeleteFabric = this.DeleteFabric.bind(this);
    this.GetFabricNodes = this.GetFabricNodes.bind(this);
    this.GetNamedFabricNode = this.GetNamedFabricNode.bind(this);
    this.AddFabricNodes = this.AddFabricNodes.bind(this);
    this.UpdateFabricNode = this.UpdateFabricNode.bind(this);
    this.DeleteFabricNode = this.DeleteFabricNode.bind(this);
    this.AddNodesToFabric = this.AddNodesToFabric.bind(this);
    this.DeleteNodeFromFabric = this.DeleteNodeFromFabric.bind(this);
    this.GetFabricConnections = this.GetFabricConnections.bind(this);
    this.GetFabricConnection = this.GetFabricConnection.bind(this);
    this.DeleteFabricConnections = this.DeleteFabricConnections.bind(this);
    this.DeleteFabricConnection = this.DeleteFabricConnection.bind(this);
    this.SetFabricConnections = this.SetFabricConnections.bind(this);
    this.SetFabricConnection = this.SetFabricConnection.bind(this);
    this.AddFabricConnections = this.AddFabricConnections.bind(this);
    this.GetFabricBgpPolicies = this.GetFabricBgpPolicies.bind(this);
    this.GetPrefixLists = this.GetPrefixLists.bind(this);
    this.GetFabricBgpPolicy = this.GetFabricBgpPolicy.bind(this);
    this.AddFabricBgpPolicies = this.AddFabricBgpPolicies.bind(this);
    this.UpdateFabricBgpPolicy = this.UpdateFabricBgpPolicy.bind(this);
    this.DeleteFabricBgpPolicy = this.DeleteFabricBgpPolicy.bind(this);
    this.GetNodePortBreakouts = this.GetNodePortBreakouts.bind(this);
    this.GetNodePortBreakout = this.GetNodePortBreakout.bind(this);
    this.AddNodePortBreakouts = this.AddNodePortBreakouts.bind(this);
    this.UpdateNodePortBreakout = this.UpdateNodePortBreakout.bind(this);
    this.DeleteNodePortBreakout = this.DeleteNodePortBreakout.bind(this);
    this.GetManagementPorts = this.GetManagementPorts.bind(this);
    this.GetManagementPort = this.GetManagementPort.bind(this);
    this.AddManagementPorts = this.AddManagementPorts.bind(this);
    this.UpdateManagementPort = this.UpdateManagementPort.bind(this);
    this.ResetManagementPort = this.ResetManagementPort.bind(this);
    this.GetNodeConnections = this.GetNodeConnections.bind(this);
    this.GetPorts = this.GetPorts.bind(this);
    this.SetPorts = this.SetPorts.bind(this);
    this.GetPort = this.GetPort.bind(this);
    this.UpdatePort = this.UpdatePort.bind(this);
    this.ResetPort = this.ResetPort.bind(this);
    this.BindDevice = this.BindDevice.bind(this);
    this.UnbindDevice = this.UnbindDevice.bind(this);
    this.GetNodeSubInterfaces = this.GetNodeSubInterfaces.bind(this);
    this.GetNodeSubInterface = this.GetNodeSubInterface.bind(this);
    this.AddNodeSubInterfaces = this.AddNodeSubInterfaces.bind(this);
    this.UpdateNodeSubInterface = this.UpdateNodeSubInterface.bind(this);
    this.DeleteNodeSubInterface = this.DeleteNodeSubInterface.bind(this);
    this.GetFabricVnis = this.GetFabricVnis.bind(this);
    this.GetFabricVni = this.GetFabricVni.bind(this);
    this.AddFabricVnis = this.AddFabricVnis.bind(this);
    this.UpdateFabricVni = this.UpdateFabricVni.bind(this);
    this.DeleteFabricVni = this.DeleteFabricVni.bind(this);
    this.GetFabricVniMembers = this.GetFabricVniMembers.bind(this);
    this.GetFabricVniMember = this.GetFabricVniMember.bind(this);
    this.AddFabricVniMembers = this.AddFabricVniMembers.bind(this);
    this.DeleteFabricVniMember = this.DeleteFabricVniMember.bind(this);
    this.GetFabricVrfs = this.GetFabricVrfs.bind(this);
    this.GetFabricVrf = this.GetFabricVrf.bind(this);
    this.AddFabricVrfs = this.AddFabricVrfs.bind(this);
    this.UpdateFabricVrf = this.UpdateFabricVrf.bind(this);
    this.DeleteFabricVrf = this.DeleteFabricVrf.bind(this);
    this.GetFabricStaticRoutes = this.GetFabricStaticRoutes.bind(this);
    this.GetFabricStaticRoute = this.GetFabricStaticRoute.bind(this);
    this.AddFabricStaticRoutes = this.AddFabricStaticRoutes.bind(this);
    this.DeleteFabricStaticRoute = this.DeleteFabricStaticRoute.bind(this);
    this.UpdateFabricStaticRoute = this.UpdateFabricStaticRoute.bind(this);
    this.GetFabricDhcpRelays = this.GetFabricDhcpRelays.bind(this);
    this.GetFabricLoopbacks = this.GetFabricLoopbacks.bind(this);
    this.GetNodeLoopbacks = this.GetNodeLoopbacks.bind(this);
    this.GetNodeLoopback = this.GetNodeLoopback.bind(this);
    this.AddNodeLoopbacks = this.AddNodeLoopbacks.bind(this);
    this.UpdateNodeLoopback = this.UpdateNodeLoopback.bind(this);
    this.DeleteNodeLoopback = this.DeleteNodeLoopback.bind(this);
    this.AddFabricImageUpdate = this.AddFabricImageUpdate.bind(this);
    this.GetFabricImageUpdates = this.GetFabricImageUpdates.bind(this);
    this.UpdateNodeImageUpdates = this.UpdateNodeImageUpdates.bind(this);
    this.GetNodeImageUpdates = this.GetNodeImageUpdates.bind(this);
    this.AddNodeImagePackages = this.AddNodeImagePackages.bind(this);
    this.DeleteNodeImagePackages = this.DeleteNodeImagePackages.bind(this);
    this.GetNodeImagePackages = this.GetNodeImagePackages.bind(this);
    this.AddImagePackages = this.AddImagePackages.bind(this);
    this.GetImagePackages = this.GetImagePackages.bind(this);
    this.UpdateImagePackages = this.UpdateImagePackages.bind(this);
    this.DeleteImagePackages = this.DeleteImagePackages.bind(this);
    this.GetDevices = this.GetDevices.bind(this);
    this.AssignUnknownDevice = this.AssignUnknownDevice.bind(this);
    this.GetSingleCredentials = this.GetSingleCredentials.bind(this);
    this.LatchAssertions = this.LatchAssertions.bind(this);
    this.LatchUnlatchAssertions = this.LatchUnlatchAssertions.bind(this);
    this.GetAgentConfig = this.GetAgentConfig.bind(this);
    this.SetDeviceInventory = this.SetDeviceInventory.bind(this);
    this.SetWorkflowFeedbacks = this.SetWorkflowFeedbacks.bind(this);
    this.SetAgentPlatformInfo = this.SetAgentPlatformInfo.bind(this);
    this.GetImageManifest = this.GetImageManifest.bind(this);
    this.GetRefPoints = this.GetRefPoints.bind(this);
    this.GetRefPoint = this.GetRefPoint.bind(this);
    this.DeleteRefPoint = this.DeleteRefPoint.bind(this);
    this.CreateProvisionalRefPoint = this.CreateProvisionalRefPoint.bind(this);
    this.PromoteProvisionalRefPoint = this.PromoteProvisionalRefPoint.bind(this);
    this.CreateRefPointDiff = this.CreateRefPointDiff.bind(this);
    this.GetRefPointDiff = this.GetRefPointDiff.bind(this);
  }

  Echo(request: DeepPartial<EchoRequest>, metadata?: grpc.Metadata): Promise<EchoResponse> {
    return this.rpc.unary(ConfigDEchoDesc, EchoRequest.fromPartial(request), metadata);
  }

  StreamEcho(request: DeepPartial<EchoRequest>, metadata?: grpc.Metadata): Observable<EchoResponse> {
    return this.rpc.invoke(ConfigDStreamEchoDesc, EchoRequest.fromPartial(request), metadata);
  }

  GetLimits(request: DeepPartial<GetLimitsRequest>, metadata?: grpc.Metadata): Promise<GetLimitsResponse> {
    return this.rpc.unary(ConfigDGetLimitsDesc, GetLimitsRequest.fromPartial(request), metadata);
  }

  GetLabels(request: DeepPartial<GetLabelsRequest>, metadata?: grpc.Metadata): Promise<GetLabelsResponse> {
    return this.rpc.unary(ConfigDGetLabelsDesc, GetLabelsRequest.fromPartial(request), metadata);
  }

  GetActivityEvents(
    request: DeepPartial<GetActivityEventsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetActivityEventsResponse> {
    return this.rpc.unary(ConfigDGetActivityEventsDesc, GetActivityEventsRequest.fromPartial(request), metadata);
  }

  Provision(request: DeepPartial<ProvisionRequest>, metadata?: grpc.Metadata): Promise<ProvisionResponse> {
    return this.rpc.unary(ConfigDProvisionDesc, ProvisionRequest.fromPartial(request), metadata);
  }

  GetFabricInventories(
    request: DeepPartial<GetFabricInventoriesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetFabricInventoriesResponse> {
    return this.rpc.unary(ConfigDGetFabricInventoriesDesc, GetFabricInventoriesRequest.fromPartial(request), metadata);
  }

  GetFabricAssembly(
    request: DeepPartial<GetFabricAssemblyRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetFabricAssemblyResponse> {
    return this.rpc.unary(ConfigDGetFabricAssemblyDesc, GetFabricAssemblyRequest.fromPartial(request), metadata);
  }

  GetFabricBom(request: DeepPartial<GetFabricBomRequest>, metadata?: grpc.Metadata): Promise<GetFabricBomResponse> {
    return this.rpc.unary(ConfigDGetFabricBomDesc, GetFabricBomRequest.fromPartial(request), metadata);
  }

  GetTenantsObjects(
    request: DeepPartial<GetTenantsObjectsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<TenantsObjectsResponse> {
    return this.rpc.unary(ConfigDGetTenantsObjectsDesc, GetTenantsObjectsRequest.fromPartial(request), metadata);
  }

  GetVlans(request: DeepPartial<GetVlansRequest>, metadata?: grpc.Metadata): Promise<TenantsObjectsResponse> {
    return this.rpc.unary(ConfigDGetVlansDesc, GetVlansRequest.fromPartial(request), metadata);
  }

  GetIpmConfigs(request: DeepPartial<GetIpmConfigsRequest>, metadata?: grpc.Metadata): Promise<TenantsObjectsResponse> {
    return this.rpc.unary(ConfigDGetIpmConfigsDesc, GetIpmConfigsRequest.fromPartial(request), metadata);
  }

  GetVnis(request: DeepPartial<GetVnisRequest>, metadata?: grpc.Metadata): Promise<TenantsObjectsResponse> {
    return this.rpc.unary(ConfigDGetVnisDesc, GetVnisRequest.fromPartial(request), metadata);
  }

  GetMaterializedVni(
    request: DeepPartial<GetMaterializedVniRequest>,
    metadata?: grpc.Metadata,
  ): Promise<TenantsObjectsResponse> {
    return this.rpc.unary(ConfigDGetMaterializedVniDesc, GetMaterializedVniRequest.fromPartial(request), metadata);
  }

  GetVrfs(request: DeepPartial<GetVrfsRequest>, metadata?: grpc.Metadata): Promise<TenantsObjectsResponse> {
    return this.rpc.unary(ConfigDGetVrfsDesc, GetVrfsRequest.fromPartial(request), metadata);
  }

  GetStaticRoutes(
    request: DeepPartial<GetStaticRoutesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<TenantsObjectsResponse> {
    return this.rpc.unary(ConfigDGetStaticRoutesDesc, GetStaticRoutesRequest.fromPartial(request), metadata);
  }

  GetDhcpRelays(request: DeepPartial<GetDhcpRelaysRequest>, metadata?: grpc.Metadata): Promise<TenantsObjectsResponse> {
    return this.rpc.unary(ConfigDGetDhcpRelaysDesc, GetDhcpRelaysRequest.fromPartial(request), metadata);
  }

  GetLoopbacks(request: DeepPartial<GetLoopbacksRequest>, metadata?: grpc.Metadata): Promise<TenantsObjectsResponse> {
    return this.rpc.unary(ConfigDGetLoopbacksDesc, GetLoopbacksRequest.fromPartial(request), metadata);
  }

  GetPortChannels(
    request: DeepPartial<GetPortChannelsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<TenantsObjectsResponse> {
    return this.rpc.unary(ConfigDGetPortChannelsDesc, GetPortChannelsRequest.fromPartial(request), metadata);
  }

  GetAllPortChannels(
    request: DeepPartial<GetAllPortChannelsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<PortChannelsResponse> {
    return this.rpc.unary(ConfigDGetAllPortChannelsDesc, GetAllPortChannelsRequest.fromPartial(request), metadata);
  }

  GetPortChannel(request: DeepPartial<GetPortChannelRequest>, metadata?: grpc.Metadata): Promise<PortChannel> {
    return this.rpc.unary(ConfigDGetPortChannelDesc, GetPortChannelRequest.fromPartial(request), metadata);
  }

  AddPortChannels(
    request: DeepPartial<AddPortChannelsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<PortChannelsResponse> {
    return this.rpc.unary(ConfigDAddPortChannelsDesc, AddPortChannelsRequest.fromPartial(request), metadata);
  }

  UpdatePortChannel(request: DeepPartial<UpdatePortChannelRequest>, metadata?: grpc.Metadata): Promise<PortChannel> {
    return this.rpc.unary(ConfigDUpdatePortChannelDesc, UpdatePortChannelRequest.fromPartial(request), metadata);
  }

  DeletePortChannel(request: DeepPartial<DeletePortChannelRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(ConfigDDeletePortChannelDesc, DeletePortChannelRequest.fromPartial(request), metadata);
  }

  GetSubInterfaces(
    request: DeepPartial<GetSubInterfacesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<TenantsObjectsResponse> {
    return this.rpc.unary(ConfigDGetSubInterfacesDesc, GetSubInterfacesRequest.fromPartial(request), metadata);
  }

  GetPerVlanStp(request: DeepPartial<GetPerVlanStpRequest>, metadata?: grpc.Metadata): Promise<GetPerVlanStpResponse> {
    return this.rpc.unary(ConfigDGetPerVlanStpDesc, GetPerVlanStpRequest.fromPartial(request), metadata);
  }

  GetBgpPeers(request: DeepPartial<GetBgpPeersRequest>, metadata?: grpc.Metadata): Promise<TenantsObjectsResponse> {
    return this.rpc.unary(ConfigDGetBgpPeersDesc, GetBgpPeersRequest.fromPartial(request), metadata);
  }

  GetBgpPolicies(
    request: DeepPartial<GetBgpPoliciesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<TenantsObjectsResponse> {
    return this.rpc.unary(ConfigDGetBgpPoliciesDesc, GetBgpPoliciesRequest.fromPartial(request), metadata);
  }

  GetFabrics(request: DeepPartial<GetFabricsRequest>, metadata?: grpc.Metadata): Promise<GetFabricsResponse> {
    return this.rpc.unary(ConfigDGetFabricsDesc, GetFabricsRequest.fromPartial(request), metadata);
  }

  GetFabricCandidates(
    request: DeepPartial<GetFabricCandidatesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetFabricCandidatesResponse> {
    return this.rpc.unary(ConfigDGetFabricCandidatesDesc, GetFabricCandidatesRequest.fromPartial(request), metadata);
  }

  GetFabricCandidate(
    request: DeepPartial<GetFabricCandidateRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricCandidate> {
    return this.rpc.unary(ConfigDGetFabricCandidateDesc, GetFabricCandidateRequest.fromPartial(request), metadata);
  }

  CommitFabricCandidate(
    request: DeepPartial<CommitFabricCandidateRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CommitFabricCandidateResponse> {
    return this.rpc.unary(
      ConfigDCommitFabricCandidateDesc,
      CommitFabricCandidateRequest.fromPartial(request),
      metadata,
    );
  }

  RevertFabricCandidate(request: DeepPartial<RevertFabricCandidateRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(
      ConfigDRevertFabricCandidateDesc,
      RevertFabricCandidateRequest.fromPartial(request),
      metadata,
    );
  }

  ReviewFabricCandidate(
    request: DeepPartial<ReviewFabricCandidateRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ReviewFabricCandidateResponse> {
    return this.rpc.unary(
      ConfigDReviewFabricCandidateDesc,
      ReviewFabricCandidateRequest.fromPartial(request),
      metadata,
    );
  }

  GetNodes(request: DeepPartial<GetNodesRequest>, metadata?: grpc.Metadata): Promise<GetNodesResponse> {
    return this.rpc.unary(ConfigDGetNodesDesc, GetNodesRequest.fromPartial(request), metadata);
  }

  ClaimDevicesInternal(
    request: DeepPartial<DeviceClaimRequest>,
    metadata?: grpc.Metadata,
  ): Promise<DeviceClaimResponse> {
    return this.rpc.unary(ConfigDClaimDevicesInternalDesc, DeviceClaimRequest.fromPartial(request), metadata);
  }

  ClaimDevices(request: DeepPartial<ClaimDevicesRequest>, metadata?: grpc.Metadata): Promise<ClaimDevicesResponse> {
    return this.rpc.unary(ConfigDClaimDevicesDesc, ClaimDevicesRequest.fromPartial(request), metadata);
  }

  UnclaimDevice(request: DeepPartial<UnclaimDeviceRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(ConfigDUnclaimDeviceDesc, UnclaimDeviceRequest.fromPartial(request), metadata);
  }

  GetWorkflowFeedbacks(
    request: DeepPartial<GetWorkflowFeedbacksRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetWorkflowFeedbacksResponse> {
    return this.rpc.unary(ConfigDGetWorkflowFeedbacksDesc, GetWorkflowFeedbacksRequest.fromPartial(request), metadata);
  }

  GetNodesPorts(request: DeepPartial<GetNodesPortsRequest>, metadata?: grpc.Metadata): Promise<GetNodesPortsResponse> {
    return this.rpc.unary(ConfigDGetNodesPortsDesc, GetNodesPortsRequest.fromPartial(request), metadata);
  }

  GetPortBreakouts(
    request: DeepPartial<GetPortBreakoutsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetPortBreakoutsResponse> {
    return this.rpc.unary(ConfigDGetPortBreakoutsDesc, GetPortBreakoutsRequest.fromPartial(request), metadata);
  }

  GetOrgCertificates(
    request: DeepPartial<GetOrgCertificatesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetOrgCertificatesResponse> {
    return this.rpc.unary(ConfigDGetOrgCertificatesDesc, GetOrgCertificatesRequest.fromPartial(request), metadata);
  }

  GetDeviceModels(
    request: DeepPartial<GetDeviceModelsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetDeviceModelsResponse> {
    return this.rpc.unary(ConfigDGetDeviceModelsDesc, GetDeviceModelsRequest.fromPartial(request), metadata);
  }

  GetCableModels(
    request: DeepPartial<GetCableModelsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetCableModelsResponse> {
    return this.rpc.unary(ConfigDGetCableModelsDesc, GetCableModelsRequest.fromPartial(request), metadata);
  }

  GetDevicePsuModels(
    request: DeepPartial<GetDevicePsuModelsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetDevicePsuModelsResponse> {
    return this.rpc.unary(ConfigDGetDevicePsuModelsDesc, GetDevicePsuModelsRequest.fromPartial(request), metadata);
  }

  SyncPortState(request: DeepPartial<SyncPortStateRequest>, metadata?: grpc.Metadata): Promise<SyncPortStateResponse> {
    return this.rpc.unary(ConfigDSyncPortStateDesc, SyncPortStateRequest.fromPartial(request), metadata);
  }

  GetEndpoints(request: DeepPartial<GetEndpointsRequest>, metadata?: grpc.Metadata): Promise<EndpointsResponse> {
    return this.rpc.unary(ConfigDGetEndpointsDesc, GetEndpointsRequest.fromPartial(request), metadata);
  }

  GetEndpoint(request: DeepPartial<GetEndpointRequest>, metadata?: grpc.Metadata): Promise<Endpoint> {
    return this.rpc.unary(ConfigDGetEndpointDesc, GetEndpointRequest.fromPartial(request), metadata);
  }

  AddEndpoints(request: DeepPartial<AddEndpointsRequest>, metadata?: grpc.Metadata): Promise<EndpointsResponse> {
    return this.rpc.unary(ConfigDAddEndpointsDesc, AddEndpointsRequest.fromPartial(request), metadata);
  }

  UpdateEndpoint(request: DeepPartial<UpdateEndpointRequest>, metadata?: grpc.Metadata): Promise<Endpoint> {
    return this.rpc.unary(ConfigDUpdateEndpointDesc, UpdateEndpointRequest.fromPartial(request), metadata);
  }

  VerifyEndpoint(request: DeepPartial<VerifyEndpointRequest>, metadata?: grpc.Metadata): Promise<Endpoint> {
    return this.rpc.unary(ConfigDVerifyEndpointDesc, VerifyEndpointRequest.fromPartial(request), metadata);
  }

  ResendCodeForEndpoint(request: DeepPartial<ResendCodeForEndpointRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(
      ConfigDResendCodeForEndpointDesc,
      ResendCodeForEndpointRequest.fromPartial(request),
      metadata,
    );
  }

  DeleteEndpoint(request: DeepPartial<DeleteEndpointRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(ConfigDDeleteEndpointDesc, DeleteEndpointRequest.fromPartial(request), metadata);
  }

  GetFabricRevisionId(
    request: DeepPartial<GetFabricRevisionIdRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricRevisionIdResponse> {
    return this.rpc.unary(ConfigDGetFabricRevisionIdDesc, GetFabricRevisionIdRequest.fromPartial(request), metadata);
  }

  GetAllFabrics(request: DeepPartial<GetAllFabricsRequest>, metadata?: grpc.Metadata): Promise<GetAllFabricsResponse> {
    return this.rpc.unary(ConfigDGetAllFabricsDesc, GetAllFabricsRequest.fromPartial(request), metadata);
  }

  GetFabric(request: DeepPartial<GetFabricRequest>, metadata?: grpc.Metadata): Promise<Fabric> {
    return this.rpc.unary(ConfigDGetFabricDesc, GetFabricRequest.fromPartial(request), metadata);
  }

  GetFabricConfig(
    request: DeepPartial<GetFabricConfigRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetFabricConfigResponse> {
    return this.rpc.unary(ConfigDGetFabricConfigDesc, GetFabricConfigRequest.fromPartial(request), metadata);
  }

  AddFabrics(request: DeepPartial<AddFabricsRequest>, metadata?: grpc.Metadata): Promise<AddFabricsResponse> {
    return this.rpc.unary(ConfigDAddFabricsDesc, AddFabricsRequest.fromPartial(request), metadata);
  }

  UpdateFabric(request: DeepPartial<UpdateFabricRequest>, metadata?: grpc.Metadata): Promise<Fabric> {
    return this.rpc.unary(ConfigDUpdateFabricDesc, UpdateFabricRequest.fromPartial(request), metadata);
  }

  DeleteFabric(request: DeepPartial<DeleteFabricRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(ConfigDDeleteFabricDesc, DeleteFabricRequest.fromPartial(request), metadata);
  }

  GetFabricNodes(
    request: DeepPartial<GetFabricNodesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetFabricNodesResponse> {
    return this.rpc.unary(ConfigDGetFabricNodesDesc, GetFabricNodesRequest.fromPartial(request), metadata);
  }

  GetNamedFabricNode(request: DeepPartial<GetNamedFabricNodeRequest>, metadata?: grpc.Metadata): Promise<Node> {
    return this.rpc.unary(ConfigDGetNamedFabricNodeDesc, GetNamedFabricNodeRequest.fromPartial(request), metadata);
  }

  AddFabricNodes(
    request: DeepPartial<AddFabricNodesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AddFabricNodesResponse> {
    return this.rpc.unary(ConfigDAddFabricNodesDesc, AddFabricNodesRequest.fromPartial(request), metadata);
  }

  UpdateFabricNode(request: DeepPartial<UpdateFabricNodeRequest>, metadata?: grpc.Metadata): Promise<Node> {
    return this.rpc.unary(ConfigDUpdateFabricNodeDesc, UpdateFabricNodeRequest.fromPartial(request), metadata);
  }

  DeleteFabricNode(request: DeepPartial<DeleteFabricNodeRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(ConfigDDeleteFabricNodeDesc, DeleteFabricNodeRequest.fromPartial(request), metadata);
  }

  AddNodesToFabric(
    request: DeepPartial<AddNodesToFabricRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AddNodesToFabricResponse> {
    return this.rpc.unary(ConfigDAddNodesToFabricDesc, AddNodesToFabricRequest.fromPartial(request), metadata);
  }

  DeleteNodeFromFabric(request: DeepPartial<DeleteNodeFromFabricRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(ConfigDDeleteNodeFromFabricDesc, DeleteNodeFromFabricRequest.fromPartial(request), metadata);
  }

  GetFabricConnections(
    request: DeepPartial<GetFabricConnectionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricConnectionsResponse> {
    return this.rpc.unary(ConfigDGetFabricConnectionsDesc, GetFabricConnectionsRequest.fromPartial(request), metadata);
  }

  GetFabricConnection(
    request: DeepPartial<GetFabricConnectionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<PortConnection> {
    return this.rpc.unary(ConfigDGetFabricConnectionDesc, GetFabricConnectionRequest.fromPartial(request), metadata);
  }

  DeleteFabricConnections(
    request: DeepPartial<DeleteFabricConnectionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<Empty> {
    return this.rpc.unary(
      ConfigDDeleteFabricConnectionsDesc,
      DeleteFabricConnectionsRequest.fromPartial(request),
      metadata,
    );
  }

  DeleteFabricConnection(
    request: DeepPartial<DeleteFabricConnectionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<Empty> {
    return this.rpc.unary(
      ConfigDDeleteFabricConnectionDesc,
      DeleteFabricConnectionRequest.fromPartial(request),
      metadata,
    );
  }

  SetFabricConnections(
    request: DeepPartial<SetFabricConnectionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricConnectionsResponse> {
    return this.rpc.unary(ConfigDSetFabricConnectionsDesc, SetFabricConnectionsRequest.fromPartial(request), metadata);
  }

  SetFabricConnection(
    request: DeepPartial<SetFabricConnectionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<PortConnection> {
    return this.rpc.unary(ConfigDSetFabricConnectionDesc, SetFabricConnectionRequest.fromPartial(request), metadata);
  }

  AddFabricConnections(
    request: DeepPartial<AddFabricConnectionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricConnectionsResponse> {
    return this.rpc.unary(ConfigDAddFabricConnectionsDesc, AddFabricConnectionsRequest.fromPartial(request), metadata);
  }

  GetFabricBgpPolicies(
    request: DeepPartial<GetFabricBgpPoliciesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricBgpPoliciesResponse> {
    return this.rpc.unary(ConfigDGetFabricBgpPoliciesDesc, GetFabricBgpPoliciesRequest.fromPartial(request), metadata);
  }

  GetPrefixLists(
    request: DeepPartial<GetPrefixListsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetPrefixListsResponse> {
    return this.rpc.unary(ConfigDGetPrefixListsDesc, GetPrefixListsRequest.fromPartial(request), metadata);
  }

  GetFabricBgpPolicy(request: DeepPartial<GetFabricBgpPolicyRequest>, metadata?: grpc.Metadata): Promise<BgpPolicy> {
    return this.rpc.unary(ConfigDGetFabricBgpPolicyDesc, GetFabricBgpPolicyRequest.fromPartial(request), metadata);
  }

  AddFabricBgpPolicies(
    request: DeepPartial<AddFabricBgpPoliciesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricBgpPoliciesResponse> {
    return this.rpc.unary(ConfigDAddFabricBgpPoliciesDesc, AddFabricBgpPoliciesRequest.fromPartial(request), metadata);
  }

  UpdateFabricBgpPolicy(
    request: DeepPartial<UpdateFabricBgpPolicyRequest>,
    metadata?: grpc.Metadata,
  ): Promise<BgpPolicy> {
    return this.rpc.unary(
      ConfigDUpdateFabricBgpPolicyDesc,
      UpdateFabricBgpPolicyRequest.fromPartial(request),
      metadata,
    );
  }

  DeleteFabricBgpPolicy(request: DeepPartial<DeleteFabricBgpPolicyRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(
      ConfigDDeleteFabricBgpPolicyDesc,
      DeleteFabricBgpPolicyRequest.fromPartial(request),
      metadata,
    );
  }

  GetNodePortBreakouts(
    request: DeepPartial<GetNodePortBreakoutsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<NodePortBreakoutsResponse> {
    return this.rpc.unary(ConfigDGetNodePortBreakoutsDesc, GetNodePortBreakoutsRequest.fromPartial(request), metadata);
  }

  GetNodePortBreakout(
    request: DeepPartial<GetNodePortBreakoutRequest>,
    metadata?: grpc.Metadata,
  ): Promise<PortBreakout> {
    return this.rpc.unary(ConfigDGetNodePortBreakoutDesc, GetNodePortBreakoutRequest.fromPartial(request), metadata);
  }

  AddNodePortBreakouts(
    request: DeepPartial<AddNodePortBreakoutsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<NodePortBreakoutsResponse> {
    return this.rpc.unary(ConfigDAddNodePortBreakoutsDesc, AddNodePortBreakoutsRequest.fromPartial(request), metadata);
  }

  UpdateNodePortBreakout(
    request: DeepPartial<UpdateNodePortBreakoutRequest>,
    metadata?: grpc.Metadata,
  ): Promise<PortBreakout> {
    return this.rpc.unary(
      ConfigDUpdateNodePortBreakoutDesc,
      UpdateNodePortBreakoutRequest.fromPartial(request),
      metadata,
    );
  }

  DeleteNodePortBreakout(
    request: DeepPartial<DeleteNodePortBreakoutRequest>,
    metadata?: grpc.Metadata,
  ): Promise<Empty> {
    return this.rpc.unary(
      ConfigDDeleteNodePortBreakoutDesc,
      DeleteNodePortBreakoutRequest.fromPartial(request),
      metadata,
    );
  }

  GetManagementPorts(
    request: DeepPartial<GetManagementPortsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ManagementPortsResponse> {
    return this.rpc.unary(ConfigDGetManagementPortsDesc, GetManagementPortsRequest.fromPartial(request), metadata);
  }

  GetManagementPort(request: DeepPartial<GetManagementPortRequest>, metadata?: grpc.Metadata): Promise<ManagementPort> {
    return this.rpc.unary(ConfigDGetManagementPortDesc, GetManagementPortRequest.fromPartial(request), metadata);
  }

  AddManagementPorts(
    request: DeepPartial<AddManagementPortsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ManagementPortsResponse> {
    return this.rpc.unary(ConfigDAddManagementPortsDesc, AddManagementPortsRequest.fromPartial(request), metadata);
  }

  UpdateManagementPort(
    request: DeepPartial<UpdateManagementPortRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ManagementPort> {
    return this.rpc.unary(ConfigDUpdateManagementPortDesc, UpdateManagementPortRequest.fromPartial(request), metadata);
  }

  ResetManagementPort(request: DeepPartial<ResetManagementPortRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(ConfigDResetManagementPortDesc, ResetManagementPortRequest.fromPartial(request), metadata);
  }

  GetNodeConnections(
    request: DeepPartial<GetNodeConnectionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetNodeConnectionsResponse> {
    return this.rpc.unary(ConfigDGetNodeConnectionsDesc, GetNodeConnectionsRequest.fromPartial(request), metadata);
  }

  GetPorts(request: DeepPartial<GetPortsRequest>, metadata?: grpc.Metadata): Promise<PortsResponse> {
    return this.rpc.unary(ConfigDGetPortsDesc, GetPortsRequest.fromPartial(request), metadata);
  }

  SetPorts(request: DeepPartial<SetPortsRequest>, metadata?: grpc.Metadata): Promise<PortsResponse> {
    return this.rpc.unary(ConfigDSetPortsDesc, SetPortsRequest.fromPartial(request), metadata);
  }

  GetPort(request: DeepPartial<GetPortRequest>, metadata?: grpc.Metadata): Promise<NetworkPort> {
    return this.rpc.unary(ConfigDGetPortDesc, GetPortRequest.fromPartial(request), metadata);
  }

  UpdatePort(request: DeepPartial<UpdatePortRequest>, metadata?: grpc.Metadata): Promise<NetworkPort> {
    return this.rpc.unary(ConfigDUpdatePortDesc, UpdatePortRequest.fromPartial(request), metadata);
  }

  ResetPort(request: DeepPartial<ResetPortRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(ConfigDResetPortDesc, ResetPortRequest.fromPartial(request), metadata);
  }

  BindDevice(request: DeepPartial<BindDeviceRequest>, metadata?: grpc.Metadata): Promise<BindDeviceResponse> {
    return this.rpc.unary(ConfigDBindDeviceDesc, BindDeviceRequest.fromPartial(request), metadata);
  }

  UnbindDevice(request: DeepPartial<UnbindDeviceRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(ConfigDUnbindDeviceDesc, UnbindDeviceRequest.fromPartial(request), metadata);
  }

  GetNodeSubInterfaces(
    request: DeepPartial<GetNodeSubInterfacesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<NodeSubInterfacesResponse> {
    return this.rpc.unary(ConfigDGetNodeSubInterfacesDesc, GetNodeSubInterfacesRequest.fromPartial(request), metadata);
  }

  GetNodeSubInterface(
    request: DeepPartial<GetNodeSubInterfaceRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SubInterface> {
    return this.rpc.unary(ConfigDGetNodeSubInterfaceDesc, GetNodeSubInterfaceRequest.fromPartial(request), metadata);
  }

  AddNodeSubInterfaces(
    request: DeepPartial<AddNodeSubInterfacesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<NodeSubInterfacesResponse> {
    return this.rpc.unary(ConfigDAddNodeSubInterfacesDesc, AddNodeSubInterfacesRequest.fromPartial(request), metadata);
  }

  UpdateNodeSubInterface(
    request: DeepPartial<UpdateNodeSubInterfaceRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SubInterface> {
    return this.rpc.unary(
      ConfigDUpdateNodeSubInterfaceDesc,
      UpdateNodeSubInterfaceRequest.fromPartial(request),
      metadata,
    );
  }

  DeleteNodeSubInterface(
    request: DeepPartial<DeleteNodeSubInterfaceRequest>,
    metadata?: grpc.Metadata,
  ): Promise<Empty> {
    return this.rpc.unary(
      ConfigDDeleteNodeSubInterfaceDesc,
      DeleteNodeSubInterfaceRequest.fromPartial(request),
      metadata,
    );
  }

  GetFabricVnis(request: DeepPartial<GetFabricVnisRequest>, metadata?: grpc.Metadata): Promise<FabricVnisResponse> {
    return this.rpc.unary(ConfigDGetFabricVnisDesc, GetFabricVnisRequest.fromPartial(request), metadata);
  }

  GetFabricVni(request: DeepPartial<GetFabricVniRequest>, metadata?: grpc.Metadata): Promise<Vni> {
    return this.rpc.unary(ConfigDGetFabricVniDesc, GetFabricVniRequest.fromPartial(request), metadata);
  }

  AddFabricVnis(request: DeepPartial<AddFabricVnisRequest>, metadata?: grpc.Metadata): Promise<FabricVnisResponse> {
    return this.rpc.unary(ConfigDAddFabricVnisDesc, AddFabricVnisRequest.fromPartial(request), metadata);
  }

  UpdateFabricVni(request: DeepPartial<UpdateFabricVniRequest>, metadata?: grpc.Metadata): Promise<Vni> {
    return this.rpc.unary(ConfigDUpdateFabricVniDesc, UpdateFabricVniRequest.fromPartial(request), metadata);
  }

  DeleteFabricVni(request: DeepPartial<DeleteFabricVniRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(ConfigDDeleteFabricVniDesc, DeleteFabricVniRequest.fromPartial(request), metadata);
  }

  GetFabricVniMembers(
    request: DeepPartial<GetFabricVniMembersRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricVniMembersResponse> {
    return this.rpc.unary(ConfigDGetFabricVniMembersDesc, GetFabricVniMembersRequest.fromPartial(request), metadata);
  }

  GetFabricVniMember(request: DeepPartial<GetFabricVniMemberRequest>, metadata?: grpc.Metadata): Promise<VlanMember> {
    return this.rpc.unary(ConfigDGetFabricVniMemberDesc, GetFabricVniMemberRequest.fromPartial(request), metadata);
  }

  AddFabricVniMembers(
    request: DeepPartial<AddFabricVniMembersRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricVniMembersResponse> {
    return this.rpc.unary(ConfigDAddFabricVniMembersDesc, AddFabricVniMembersRequest.fromPartial(request), metadata);
  }

  DeleteFabricVniMember(request: DeepPartial<DeleteFabricVniMemberRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(
      ConfigDDeleteFabricVniMemberDesc,
      DeleteFabricVniMemberRequest.fromPartial(request),
      metadata,
    );
  }

  GetFabricVrfs(request: DeepPartial<GetFabricVrfsRequest>, metadata?: grpc.Metadata): Promise<FabricVrfsResponse> {
    return this.rpc.unary(ConfigDGetFabricVrfsDesc, GetFabricVrfsRequest.fromPartial(request), metadata);
  }

  GetFabricVrf(request: DeepPartial<GetFabricVrfRequest>, metadata?: grpc.Metadata): Promise<Vrf> {
    return this.rpc.unary(ConfigDGetFabricVrfDesc, GetFabricVrfRequest.fromPartial(request), metadata);
  }

  AddFabricVrfs(request: DeepPartial<AddFabricVrfsRequest>, metadata?: grpc.Metadata): Promise<FabricVrfsResponse> {
    return this.rpc.unary(ConfigDAddFabricVrfsDesc, AddFabricVrfsRequest.fromPartial(request), metadata);
  }

  UpdateFabricVrf(request: DeepPartial<UpdateFabricVrfRequest>, metadata?: grpc.Metadata): Promise<Vrf> {
    return this.rpc.unary(ConfigDUpdateFabricVrfDesc, UpdateFabricVrfRequest.fromPartial(request), metadata);
  }

  DeleteFabricVrf(request: DeepPartial<DeleteFabricVrfRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(ConfigDDeleteFabricVrfDesc, DeleteFabricVrfRequest.fromPartial(request), metadata);
  }

  GetFabricStaticRoutes(
    request: DeepPartial<GetFabricStaticRoutesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricStaticRoutesResponse> {
    return this.rpc.unary(
      ConfigDGetFabricStaticRoutesDesc,
      GetFabricStaticRoutesRequest.fromPartial(request),
      metadata,
    );
  }

  GetFabricStaticRoute(
    request: DeepPartial<GetFabricStaticRouteRequest>,
    metadata?: grpc.Metadata,
  ): Promise<StaticRoutes> {
    return this.rpc.unary(ConfigDGetFabricStaticRouteDesc, GetFabricStaticRouteRequest.fromPartial(request), metadata);
  }

  AddFabricStaticRoutes(
    request: DeepPartial<AddFabricStaticRoutesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricStaticRoutesResponse> {
    return this.rpc.unary(
      ConfigDAddFabricStaticRoutesDesc,
      AddFabricStaticRoutesRequest.fromPartial(request),
      metadata,
    );
  }

  DeleteFabricStaticRoute(
    request: DeepPartial<DeleteFabricStaticRouteRequest>,
    metadata?: grpc.Metadata,
  ): Promise<Empty> {
    return this.rpc.unary(
      ConfigDDeleteFabricStaticRouteDesc,
      DeleteFabricStaticRouteRequest.fromPartial(request),
      metadata,
    );
  }

  UpdateFabricStaticRoute(
    request: DeepPartial<UpdateFabricStaticRouteRequest>,
    metadata?: grpc.Metadata,
  ): Promise<StaticRoutes> {
    return this.rpc.unary(
      ConfigDUpdateFabricStaticRouteDesc,
      UpdateFabricStaticRouteRequest.fromPartial(request),
      metadata,
    );
  }

  GetFabricDhcpRelays(
    request: DeepPartial<GetFabricDhcpRelaysRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricDhcpRelaysResponse> {
    return this.rpc.unary(ConfigDGetFabricDhcpRelaysDesc, GetFabricDhcpRelaysRequest.fromPartial(request), metadata);
  }

  GetFabricLoopbacks(
    request: DeepPartial<GetFabricLoopbacksRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricLoopbacksResponse> {
    return this.rpc.unary(ConfigDGetFabricLoopbacksDesc, GetFabricLoopbacksRequest.fromPartial(request), metadata);
  }

  GetNodeLoopbacks(
    request: DeepPartial<GetNodeLoopbacksRequest>,
    metadata?: grpc.Metadata,
  ): Promise<NodeLoopbacksResponse> {
    return this.rpc.unary(ConfigDGetNodeLoopbacksDesc, GetNodeLoopbacksRequest.fromPartial(request), metadata);
  }

  GetNodeLoopback(request: DeepPartial<GetNodeLoopbackRequest>, metadata?: grpc.Metadata): Promise<Loopback> {
    return this.rpc.unary(ConfigDGetNodeLoopbackDesc, GetNodeLoopbackRequest.fromPartial(request), metadata);
  }

  AddNodeLoopbacks(
    request: DeepPartial<AddNodeLoopbacksRequest>,
    metadata?: grpc.Metadata,
  ): Promise<NodeLoopbacksResponse> {
    return this.rpc.unary(ConfigDAddNodeLoopbacksDesc, AddNodeLoopbacksRequest.fromPartial(request), metadata);
  }

  UpdateNodeLoopback(request: DeepPartial<UpdateNodeLoopbackRequest>, metadata?: grpc.Metadata): Promise<Loopback> {
    return this.rpc.unary(ConfigDUpdateNodeLoopbackDesc, UpdateNodeLoopbackRequest.fromPartial(request), metadata);
  }

  DeleteNodeLoopback(request: DeepPartial<DeleteNodeLoopbackRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(ConfigDDeleteNodeLoopbackDesc, DeleteNodeLoopbackRequest.fromPartial(request), metadata);
  }

  AddFabricImageUpdate(
    request: DeepPartial<AddFabricImageUpdateRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FabricImageUpdate> {
    return this.rpc.unary(ConfigDAddFabricImageUpdateDesc, AddFabricImageUpdateRequest.fromPartial(request), metadata);
  }

  GetFabricImageUpdates(
    request: DeepPartial<GetFabricImageUpdatesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetFabricImageUpdatesResponse> {
    return this.rpc.unary(
      ConfigDGetFabricImageUpdatesDesc,
      GetFabricImageUpdatesRequest.fromPartial(request),
      metadata,
    );
  }

  UpdateNodeImageUpdates(
    request: DeepPartial<UpdateNodeImageUpdatesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateNodeImageUpdatesResponse> {
    return this.rpc.unary(
      ConfigDUpdateNodeImageUpdatesDesc,
      UpdateNodeImageUpdatesRequest.fromPartial(request),
      metadata,
    );
  }

  GetNodeImageUpdates(
    request: DeepPartial<GetNodeImageUpdatesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetNodeImageUpdatesResponse> {
    return this.rpc.unary(ConfigDGetNodeImageUpdatesDesc, GetNodeImageUpdatesRequest.fromPartial(request), metadata);
  }

  AddNodeImagePackages(
    request: DeepPartial<AddNodeImagePackagesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AddNodeImagePackagesResponse> {
    return this.rpc.unary(ConfigDAddNodeImagePackagesDesc, AddNodeImagePackagesRequest.fromPartial(request), metadata);
  }

  DeleteNodeImagePackages(
    request: DeepPartial<DeleteNodeImagePackagesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<Empty> {
    return this.rpc.unary(
      ConfigDDeleteNodeImagePackagesDesc,
      DeleteNodeImagePackagesRequest.fromPartial(request),
      metadata,
    );
  }

  GetNodeImagePackages(
    request: DeepPartial<GetNodeImagePackagesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetNodeImagePackagesResponse> {
    return this.rpc.unary(ConfigDGetNodeImagePackagesDesc, GetNodeImagePackagesRequest.fromPartial(request), metadata);
  }

  AddImagePackages(
    request: DeepPartial<AddImagePackagesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ImagePackagesResponse> {
    return this.rpc.unary(ConfigDAddImagePackagesDesc, AddImagePackagesRequest.fromPartial(request), metadata);
  }

  GetImagePackages(
    request: DeepPartial<GetImagePackagesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetImagePackagesResponse> {
    return this.rpc.unary(ConfigDGetImagePackagesDesc, GetImagePackagesRequest.fromPartial(request), metadata);
  }

  UpdateImagePackages(
    request: DeepPartial<UpdateImagePackagesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ImagePackagesResponse> {
    return this.rpc.unary(ConfigDUpdateImagePackagesDesc, UpdateImagePackagesRequest.fromPartial(request), metadata);
  }

  DeleteImagePackages(request: DeepPartial<DeleteImagePackagesRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(ConfigDDeleteImagePackagesDesc, DeleteImagePackagesRequest.fromPartial(request), metadata);
  }

  GetDevices(request: DeepPartial<GetDevicesRequest>, metadata?: grpc.Metadata): Promise<GetDevicesResponse> {
    return this.rpc.unary(ConfigDGetDevicesDesc, GetDevicesRequest.fromPartial(request), metadata);
  }

  AssignUnknownDevice(
    request: DeepPartial<AssignUnknownDeviceRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AssignUnknownDeviceResponse> {
    return this.rpc.unary(ConfigDAssignUnknownDeviceDesc, AssignUnknownDeviceRequest.fromPartial(request), metadata);
  }

  GetSingleCredentials(
    request: DeepPartial<GetSingleCredentialsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<Credentials> {
    return this.rpc.unary(ConfigDGetSingleCredentialsDesc, GetSingleCredentialsRequest.fromPartial(request), metadata);
  }

  LatchAssertions(request: DeepPartial<LatchAssertionsRequest>, metadata?: grpc.Metadata): Promise<AssertionsResponse> {
    return this.rpc.unary(ConfigDLatchAssertionsDesc, LatchAssertionsRequest.fromPartial(request), metadata);
  }

  LatchUnlatchAssertions(
    request: DeepPartial<LatchUnlatchAssertionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<LatchUnlatchAssertionsResponse> {
    return this.rpc.unary(
      ConfigDLatchUnlatchAssertionsDesc,
      LatchUnlatchAssertionsRequest.fromPartial(request),
      metadata,
    );
  }

  GetAgentConfig(request: DeepPartial<AgentConfigRequest>, metadata?: grpc.Metadata): Promise<AgentConfigResponse> {
    return this.rpc.unary(ConfigDGetAgentConfigDesc, AgentConfigRequest.fromPartial(request), metadata);
  }

  SetDeviceInventory(
    request: DeepPartial<SetDeviceInventoryRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SetDeviceInventoryResponse> {
    return this.rpc.unary(ConfigDSetDeviceInventoryDesc, SetDeviceInventoryRequest.fromPartial(request), metadata);
  }

  SetWorkflowFeedbacks(
    request: DeepPartial<SetWorkflowFeedbacksRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SetWorkflowFeedbacksResponse> {
    return this.rpc.unary(ConfigDSetWorkflowFeedbacksDesc, SetWorkflowFeedbacksRequest.fromPartial(request), metadata);
  }

  SetAgentPlatformInfo(
    request: DeepPartial<SetAgentPlatformInfoRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SetAgentPlatformInfoResponse> {
    return this.rpc.unary(ConfigDSetAgentPlatformInfoDesc, SetAgentPlatformInfoRequest.fromPartial(request), metadata);
  }

  GetImageManifest(
    request: DeepPartial<GetImageManifestRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetImageManifestResponse> {
    return this.rpc.unary(ConfigDGetImageManifestDesc, GetImageManifestRequest.fromPartial(request), metadata);
  }

  GetRefPoints(request: DeepPartial<RefPointByFabricRequest>, metadata?: grpc.Metadata): Promise<RefPointList> {
    return this.rpc.unary(ConfigDGetRefPointsDesc, RefPointByFabricRequest.fromPartial(request), metadata);
  }

  GetRefPoint(request: DeepPartial<RefPointByNameRequest>, metadata?: grpc.Metadata): Promise<RefPoint> {
    return this.rpc.unary(ConfigDGetRefPointDesc, RefPointByNameRequest.fromPartial(request), metadata);
  }

  DeleteRefPoint(request: DeepPartial<RefPointByNameRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(ConfigDDeleteRefPointDesc, RefPointByNameRequest.fromPartial(request), metadata);
  }

  CreateProvisionalRefPoint(request: DeepPartial<CreateRefPointRequest>, metadata?: grpc.Metadata): Promise<RefPoint> {
    return this.rpc.unary(ConfigDCreateProvisionalRefPointDesc, CreateRefPointRequest.fromPartial(request), metadata);
  }

  PromoteProvisionalRefPoint(
    request: DeepPartial<RefPointByFabricRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RefPoint> {
    return this.rpc.unary(
      ConfigDPromoteProvisionalRefPointDesc,
      RefPointByFabricRequest.fromPartial(request),
      metadata,
    );
  }

  CreateRefPointDiff(request: DeepPartial<RefPointByFabricRequest>, metadata?: grpc.Metadata): Promise<RefPoint> {
    return this.rpc.unary(ConfigDCreateRefPointDiffDesc, RefPointByFabricRequest.fromPartial(request), metadata);
  }

  GetRefPointDiff(request: DeepPartial<RefPointByNameRequest>, metadata?: grpc.Metadata): Promise<DiffPart> {
    return this.rpc.unary(ConfigDGetRefPointDiffDesc, RefPointByNameRequest.fromPartial(request), metadata);
  }
}

export const ConfigDDesc = { serviceName: "configd.ConfigD" };

export const ConfigDEchoDesc: UnaryMethodDefinitionish = {
  methodName: "Echo",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return EchoRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = EchoResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDStreamEchoDesc: UnaryMethodDefinitionish = {
  methodName: "StreamEcho",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return EchoRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = EchoResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetLimitsDesc: UnaryMethodDefinitionish = {
  methodName: "GetLimits",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetLimitsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetLimitsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetLabelsDesc: UnaryMethodDefinitionish = {
  methodName: "GetLabels",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetLabelsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetLabelsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetActivityEventsDesc: UnaryMethodDefinitionish = {
  methodName: "GetActivityEvents",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetActivityEventsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetActivityEventsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDProvisionDesc: UnaryMethodDefinitionish = {
  methodName: "Provision",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ProvisionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ProvisionResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetFabricInventoriesDesc: UnaryMethodDefinitionish = {
  methodName: "GetFabricInventories",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFabricInventoriesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetFabricInventoriesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetFabricAssemblyDesc: UnaryMethodDefinitionish = {
  methodName: "GetFabricAssembly",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFabricAssemblyRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetFabricAssemblyResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetFabricBomDesc: UnaryMethodDefinitionish = {
  methodName: "GetFabricBom",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFabricBomRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetFabricBomResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetTenantsObjectsDesc: UnaryMethodDefinitionish = {
  methodName: "GetTenantsObjects",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetTenantsObjectsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TenantsObjectsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetVlansDesc: UnaryMethodDefinitionish = {
  methodName: "GetVlans",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetVlansRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TenantsObjectsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetIpmConfigsDesc: UnaryMethodDefinitionish = {
  methodName: "GetIpmConfigs",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetIpmConfigsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TenantsObjectsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetVnisDesc: UnaryMethodDefinitionish = {
  methodName: "GetVnis",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetVnisRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TenantsObjectsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetMaterializedVniDesc: UnaryMethodDefinitionish = {
  methodName: "GetMaterializedVni",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetMaterializedVniRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TenantsObjectsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetVrfsDesc: UnaryMethodDefinitionish = {
  methodName: "GetVrfs",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetVrfsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TenantsObjectsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetStaticRoutesDesc: UnaryMethodDefinitionish = {
  methodName: "GetStaticRoutes",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetStaticRoutesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TenantsObjectsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetDhcpRelaysDesc: UnaryMethodDefinitionish = {
  methodName: "GetDhcpRelays",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetDhcpRelaysRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TenantsObjectsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetLoopbacksDesc: UnaryMethodDefinitionish = {
  methodName: "GetLoopbacks",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetLoopbacksRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TenantsObjectsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetPortChannelsDesc: UnaryMethodDefinitionish = {
  methodName: "GetPortChannels",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetPortChannelsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TenantsObjectsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetAllPortChannelsDesc: UnaryMethodDefinitionish = {
  methodName: "GetAllPortChannels",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetAllPortChannelsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = PortChannelsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetPortChannelDesc: UnaryMethodDefinitionish = {
  methodName: "GetPortChannel",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetPortChannelRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = PortChannel.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDAddPortChannelsDesc: UnaryMethodDefinitionish = {
  methodName: "AddPortChannels",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddPortChannelsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = PortChannelsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDUpdatePortChannelDesc: UnaryMethodDefinitionish = {
  methodName: "UpdatePortChannel",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdatePortChannelRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = PortChannel.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDDeletePortChannelDesc: UnaryMethodDefinitionish = {
  methodName: "DeletePortChannel",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeletePortChannelRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetSubInterfacesDesc: UnaryMethodDefinitionish = {
  methodName: "GetSubInterfaces",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetSubInterfacesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TenantsObjectsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetPerVlanStpDesc: UnaryMethodDefinitionish = {
  methodName: "GetPerVlanStp",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetPerVlanStpRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetPerVlanStpResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetBgpPeersDesc: UnaryMethodDefinitionish = {
  methodName: "GetBgpPeers",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetBgpPeersRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TenantsObjectsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetBgpPoliciesDesc: UnaryMethodDefinitionish = {
  methodName: "GetBgpPolicies",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetBgpPoliciesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TenantsObjectsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetFabricsDesc: UnaryMethodDefinitionish = {
  methodName: "GetFabrics",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFabricsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetFabricsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetFabricCandidatesDesc: UnaryMethodDefinitionish = {
  methodName: "GetFabricCandidates",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFabricCandidatesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetFabricCandidatesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetFabricCandidateDesc: UnaryMethodDefinitionish = {
  methodName: "GetFabricCandidate",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFabricCandidateRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FabricCandidate.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDCommitFabricCandidateDesc: UnaryMethodDefinitionish = {
  methodName: "CommitFabricCandidate",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CommitFabricCandidateRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CommitFabricCandidateResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDRevertFabricCandidateDesc: UnaryMethodDefinitionish = {
  methodName: "RevertFabricCandidate",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RevertFabricCandidateRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDReviewFabricCandidateDesc: UnaryMethodDefinitionish = {
  methodName: "ReviewFabricCandidate",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ReviewFabricCandidateRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ReviewFabricCandidateResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetNodesDesc: UnaryMethodDefinitionish = {
  methodName: "GetNodes",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetNodesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetNodesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDClaimDevicesInternalDesc: UnaryMethodDefinitionish = {
  methodName: "ClaimDevicesInternal",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeviceClaimRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = DeviceClaimResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDClaimDevicesDesc: UnaryMethodDefinitionish = {
  methodName: "ClaimDevices",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ClaimDevicesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ClaimDevicesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDUnclaimDeviceDesc: UnaryMethodDefinitionish = {
  methodName: "UnclaimDevice",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UnclaimDeviceRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetWorkflowFeedbacksDesc: UnaryMethodDefinitionish = {
  methodName: "GetWorkflowFeedbacks",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetWorkflowFeedbacksRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetWorkflowFeedbacksResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetNodesPortsDesc: UnaryMethodDefinitionish = {
  methodName: "GetNodesPorts",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetNodesPortsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetNodesPortsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetPortBreakoutsDesc: UnaryMethodDefinitionish = {
  methodName: "GetPortBreakouts",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetPortBreakoutsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetPortBreakoutsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetOrgCertificatesDesc: UnaryMethodDefinitionish = {
  methodName: "GetOrgCertificates",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetOrgCertificatesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetOrgCertificatesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetDeviceModelsDesc: UnaryMethodDefinitionish = {
  methodName: "GetDeviceModels",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetDeviceModelsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetDeviceModelsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetCableModelsDesc: UnaryMethodDefinitionish = {
  methodName: "GetCableModels",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCableModelsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetCableModelsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetDevicePsuModelsDesc: UnaryMethodDefinitionish = {
  methodName: "GetDevicePsuModels",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetDevicePsuModelsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetDevicePsuModelsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDSyncPortStateDesc: UnaryMethodDefinitionish = {
  methodName: "SyncPortState",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SyncPortStateRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SyncPortStateResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetEndpointsDesc: UnaryMethodDefinitionish = {
  methodName: "GetEndpoints",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetEndpointsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = EndpointsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetEndpointDesc: UnaryMethodDefinitionish = {
  methodName: "GetEndpoint",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetEndpointRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Endpoint.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDAddEndpointsDesc: UnaryMethodDefinitionish = {
  methodName: "AddEndpoints",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddEndpointsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = EndpointsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDUpdateEndpointDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateEndpoint",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateEndpointRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Endpoint.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDVerifyEndpointDesc: UnaryMethodDefinitionish = {
  methodName: "VerifyEndpoint",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return VerifyEndpointRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Endpoint.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDResendCodeForEndpointDesc: UnaryMethodDefinitionish = {
  methodName: "ResendCodeForEndpoint",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ResendCodeForEndpointRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDDeleteEndpointDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteEndpoint",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteEndpointRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetFabricRevisionIdDesc: UnaryMethodDefinitionish = {
  methodName: "GetFabricRevisionId",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFabricRevisionIdRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FabricRevisionIdResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetAllFabricsDesc: UnaryMethodDefinitionish = {
  methodName: "GetAllFabrics",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetAllFabricsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetAllFabricsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetFabricDesc: UnaryMethodDefinitionish = {
  methodName: "GetFabric",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFabricRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Fabric.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetFabricConfigDesc: UnaryMethodDefinitionish = {
  methodName: "GetFabricConfig",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFabricConfigRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetFabricConfigResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDAddFabricsDesc: UnaryMethodDefinitionish = {
  methodName: "AddFabrics",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddFabricsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AddFabricsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDUpdateFabricDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateFabric",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateFabricRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Fabric.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDDeleteFabricDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteFabric",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteFabricRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetFabricNodesDesc: UnaryMethodDefinitionish = {
  methodName: "GetFabricNodes",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFabricNodesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetFabricNodesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetNamedFabricNodeDesc: UnaryMethodDefinitionish = {
  methodName: "GetNamedFabricNode",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetNamedFabricNodeRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Node.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDAddFabricNodesDesc: UnaryMethodDefinitionish = {
  methodName: "AddFabricNodes",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddFabricNodesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AddFabricNodesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDUpdateFabricNodeDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateFabricNode",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateFabricNodeRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Node.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDDeleteFabricNodeDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteFabricNode",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteFabricNodeRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDAddNodesToFabricDesc: UnaryMethodDefinitionish = {
  methodName: "AddNodesToFabric",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddNodesToFabricRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AddNodesToFabricResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDDeleteNodeFromFabricDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteNodeFromFabric",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteNodeFromFabricRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetFabricConnectionsDesc: UnaryMethodDefinitionish = {
  methodName: "GetFabricConnections",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFabricConnectionsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FabricConnectionsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetFabricConnectionDesc: UnaryMethodDefinitionish = {
  methodName: "GetFabricConnection",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFabricConnectionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = PortConnection.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDDeleteFabricConnectionsDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteFabricConnections",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteFabricConnectionsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDDeleteFabricConnectionDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteFabricConnection",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteFabricConnectionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDSetFabricConnectionsDesc: UnaryMethodDefinitionish = {
  methodName: "SetFabricConnections",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetFabricConnectionsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FabricConnectionsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDSetFabricConnectionDesc: UnaryMethodDefinitionish = {
  methodName: "SetFabricConnection",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetFabricConnectionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = PortConnection.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDAddFabricConnectionsDesc: UnaryMethodDefinitionish = {
  methodName: "AddFabricConnections",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddFabricConnectionsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FabricConnectionsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetFabricBgpPoliciesDesc: UnaryMethodDefinitionish = {
  methodName: "GetFabricBgpPolicies",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFabricBgpPoliciesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FabricBgpPoliciesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetPrefixListsDesc: UnaryMethodDefinitionish = {
  methodName: "GetPrefixLists",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetPrefixListsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetPrefixListsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetFabricBgpPolicyDesc: UnaryMethodDefinitionish = {
  methodName: "GetFabricBgpPolicy",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFabricBgpPolicyRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BgpPolicy.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDAddFabricBgpPoliciesDesc: UnaryMethodDefinitionish = {
  methodName: "AddFabricBgpPolicies",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddFabricBgpPoliciesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FabricBgpPoliciesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDUpdateFabricBgpPolicyDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateFabricBgpPolicy",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateFabricBgpPolicyRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BgpPolicy.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDDeleteFabricBgpPolicyDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteFabricBgpPolicy",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteFabricBgpPolicyRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetNodePortBreakoutsDesc: UnaryMethodDefinitionish = {
  methodName: "GetNodePortBreakouts",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetNodePortBreakoutsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = NodePortBreakoutsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetNodePortBreakoutDesc: UnaryMethodDefinitionish = {
  methodName: "GetNodePortBreakout",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetNodePortBreakoutRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = PortBreakout.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDAddNodePortBreakoutsDesc: UnaryMethodDefinitionish = {
  methodName: "AddNodePortBreakouts",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddNodePortBreakoutsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = NodePortBreakoutsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDUpdateNodePortBreakoutDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateNodePortBreakout",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateNodePortBreakoutRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = PortBreakout.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDDeleteNodePortBreakoutDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteNodePortBreakout",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteNodePortBreakoutRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetManagementPortsDesc: UnaryMethodDefinitionish = {
  methodName: "GetManagementPorts",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetManagementPortsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ManagementPortsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetManagementPortDesc: UnaryMethodDefinitionish = {
  methodName: "GetManagementPort",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetManagementPortRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ManagementPort.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDAddManagementPortsDesc: UnaryMethodDefinitionish = {
  methodName: "AddManagementPorts",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddManagementPortsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ManagementPortsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDUpdateManagementPortDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateManagementPort",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateManagementPortRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ManagementPort.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDResetManagementPortDesc: UnaryMethodDefinitionish = {
  methodName: "ResetManagementPort",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ResetManagementPortRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetNodeConnectionsDesc: UnaryMethodDefinitionish = {
  methodName: "GetNodeConnections",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetNodeConnectionsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetNodeConnectionsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetPortsDesc: UnaryMethodDefinitionish = {
  methodName: "GetPorts",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetPortsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = PortsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDSetPortsDesc: UnaryMethodDefinitionish = {
  methodName: "SetPorts",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetPortsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = PortsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetPortDesc: UnaryMethodDefinitionish = {
  methodName: "GetPort",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetPortRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = NetworkPort.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDUpdatePortDesc: UnaryMethodDefinitionish = {
  methodName: "UpdatePort",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdatePortRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = NetworkPort.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDResetPortDesc: UnaryMethodDefinitionish = {
  methodName: "ResetPort",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ResetPortRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDBindDeviceDesc: UnaryMethodDefinitionish = {
  methodName: "BindDevice",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return BindDeviceRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BindDeviceResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDUnbindDeviceDesc: UnaryMethodDefinitionish = {
  methodName: "UnbindDevice",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UnbindDeviceRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetNodeSubInterfacesDesc: UnaryMethodDefinitionish = {
  methodName: "GetNodeSubInterfaces",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetNodeSubInterfacesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = NodeSubInterfacesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetNodeSubInterfaceDesc: UnaryMethodDefinitionish = {
  methodName: "GetNodeSubInterface",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetNodeSubInterfaceRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SubInterface.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDAddNodeSubInterfacesDesc: UnaryMethodDefinitionish = {
  methodName: "AddNodeSubInterfaces",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddNodeSubInterfacesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = NodeSubInterfacesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDUpdateNodeSubInterfaceDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateNodeSubInterface",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateNodeSubInterfaceRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SubInterface.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDDeleteNodeSubInterfaceDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteNodeSubInterface",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteNodeSubInterfaceRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetFabricVnisDesc: UnaryMethodDefinitionish = {
  methodName: "GetFabricVnis",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFabricVnisRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FabricVnisResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetFabricVniDesc: UnaryMethodDefinitionish = {
  methodName: "GetFabricVni",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFabricVniRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Vni.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDAddFabricVnisDesc: UnaryMethodDefinitionish = {
  methodName: "AddFabricVnis",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddFabricVnisRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FabricVnisResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDUpdateFabricVniDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateFabricVni",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateFabricVniRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Vni.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDDeleteFabricVniDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteFabricVni",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteFabricVniRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetFabricVniMembersDesc: UnaryMethodDefinitionish = {
  methodName: "GetFabricVniMembers",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFabricVniMembersRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FabricVniMembersResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetFabricVniMemberDesc: UnaryMethodDefinitionish = {
  methodName: "GetFabricVniMember",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFabricVniMemberRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = VlanMember.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDAddFabricVniMembersDesc: UnaryMethodDefinitionish = {
  methodName: "AddFabricVniMembers",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddFabricVniMembersRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FabricVniMembersResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDDeleteFabricVniMemberDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteFabricVniMember",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteFabricVniMemberRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetFabricVrfsDesc: UnaryMethodDefinitionish = {
  methodName: "GetFabricVrfs",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFabricVrfsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FabricVrfsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetFabricVrfDesc: UnaryMethodDefinitionish = {
  methodName: "GetFabricVrf",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFabricVrfRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Vrf.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDAddFabricVrfsDesc: UnaryMethodDefinitionish = {
  methodName: "AddFabricVrfs",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddFabricVrfsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FabricVrfsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDUpdateFabricVrfDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateFabricVrf",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateFabricVrfRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Vrf.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDDeleteFabricVrfDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteFabricVrf",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteFabricVrfRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetFabricStaticRoutesDesc: UnaryMethodDefinitionish = {
  methodName: "GetFabricStaticRoutes",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFabricStaticRoutesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FabricStaticRoutesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetFabricStaticRouteDesc: UnaryMethodDefinitionish = {
  methodName: "GetFabricStaticRoute",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFabricStaticRouteRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = StaticRoutes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDAddFabricStaticRoutesDesc: UnaryMethodDefinitionish = {
  methodName: "AddFabricStaticRoutes",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddFabricStaticRoutesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FabricStaticRoutesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDDeleteFabricStaticRouteDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteFabricStaticRoute",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteFabricStaticRouteRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDUpdateFabricStaticRouteDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateFabricStaticRoute",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateFabricStaticRouteRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = StaticRoutes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetFabricDhcpRelaysDesc: UnaryMethodDefinitionish = {
  methodName: "GetFabricDhcpRelays",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFabricDhcpRelaysRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FabricDhcpRelaysResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetFabricLoopbacksDesc: UnaryMethodDefinitionish = {
  methodName: "GetFabricLoopbacks",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFabricLoopbacksRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FabricLoopbacksResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetNodeLoopbacksDesc: UnaryMethodDefinitionish = {
  methodName: "GetNodeLoopbacks",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetNodeLoopbacksRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = NodeLoopbacksResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetNodeLoopbackDesc: UnaryMethodDefinitionish = {
  methodName: "GetNodeLoopback",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetNodeLoopbackRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Loopback.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDAddNodeLoopbacksDesc: UnaryMethodDefinitionish = {
  methodName: "AddNodeLoopbacks",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddNodeLoopbacksRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = NodeLoopbacksResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDUpdateNodeLoopbackDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateNodeLoopback",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateNodeLoopbackRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Loopback.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDDeleteNodeLoopbackDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteNodeLoopback",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteNodeLoopbackRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDAddFabricImageUpdateDesc: UnaryMethodDefinitionish = {
  methodName: "AddFabricImageUpdate",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddFabricImageUpdateRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FabricImageUpdate.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetFabricImageUpdatesDesc: UnaryMethodDefinitionish = {
  methodName: "GetFabricImageUpdates",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFabricImageUpdatesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetFabricImageUpdatesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDUpdateNodeImageUpdatesDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateNodeImageUpdates",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateNodeImageUpdatesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateNodeImageUpdatesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetNodeImageUpdatesDesc: UnaryMethodDefinitionish = {
  methodName: "GetNodeImageUpdates",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetNodeImageUpdatesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetNodeImageUpdatesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDAddNodeImagePackagesDesc: UnaryMethodDefinitionish = {
  methodName: "AddNodeImagePackages",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddNodeImagePackagesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AddNodeImagePackagesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDDeleteNodeImagePackagesDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteNodeImagePackages",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteNodeImagePackagesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetNodeImagePackagesDesc: UnaryMethodDefinitionish = {
  methodName: "GetNodeImagePackages",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetNodeImagePackagesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetNodeImagePackagesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDAddImagePackagesDesc: UnaryMethodDefinitionish = {
  methodName: "AddImagePackages",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddImagePackagesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ImagePackagesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetImagePackagesDesc: UnaryMethodDefinitionish = {
  methodName: "GetImagePackages",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetImagePackagesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetImagePackagesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDUpdateImagePackagesDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateImagePackages",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateImagePackagesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ImagePackagesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDDeleteImagePackagesDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteImagePackages",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteImagePackagesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetDevicesDesc: UnaryMethodDefinitionish = {
  methodName: "GetDevices",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetDevicesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetDevicesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDAssignUnknownDeviceDesc: UnaryMethodDefinitionish = {
  methodName: "AssignUnknownDevice",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AssignUnknownDeviceRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AssignUnknownDeviceResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetSingleCredentialsDesc: UnaryMethodDefinitionish = {
  methodName: "GetSingleCredentials",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetSingleCredentialsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Credentials.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDLatchAssertionsDesc: UnaryMethodDefinitionish = {
  methodName: "LatchAssertions",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return LatchAssertionsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AssertionsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDLatchUnlatchAssertionsDesc: UnaryMethodDefinitionish = {
  methodName: "LatchUnlatchAssertions",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return LatchUnlatchAssertionsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = LatchUnlatchAssertionsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetAgentConfigDesc: UnaryMethodDefinitionish = {
  methodName: "GetAgentConfig",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AgentConfigRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AgentConfigResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDSetDeviceInventoryDesc: UnaryMethodDefinitionish = {
  methodName: "SetDeviceInventory",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetDeviceInventoryRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SetDeviceInventoryResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDSetWorkflowFeedbacksDesc: UnaryMethodDefinitionish = {
  methodName: "SetWorkflowFeedbacks",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetWorkflowFeedbacksRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SetWorkflowFeedbacksResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDSetAgentPlatformInfoDesc: UnaryMethodDefinitionish = {
  methodName: "SetAgentPlatformInfo",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetAgentPlatformInfoRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SetAgentPlatformInfoResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetImageManifestDesc: UnaryMethodDefinitionish = {
  methodName: "GetImageManifest",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetImageManifestRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetImageManifestResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetRefPointsDesc: UnaryMethodDefinitionish = {
  methodName: "GetRefPoints",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RefPointByFabricRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RefPointList.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetRefPointDesc: UnaryMethodDefinitionish = {
  methodName: "GetRefPoint",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RefPointByNameRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RefPoint.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDDeleteRefPointDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteRefPoint",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RefPointByNameRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDCreateProvisionalRefPointDesc: UnaryMethodDefinitionish = {
  methodName: "CreateProvisionalRefPoint",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateRefPointRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RefPoint.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDPromoteProvisionalRefPointDesc: UnaryMethodDefinitionish = {
  methodName: "PromoteProvisionalRefPoint",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RefPointByFabricRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RefPoint.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDCreateRefPointDiffDesc: UnaryMethodDefinitionish = {
  methodName: "CreateRefPointDiff",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RefPointByFabricRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RefPoint.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ConfigDGetRefPointDiffDesc: UnaryMethodDefinitionish = {
  methodName: "GetRefPointDiff",
  service: ConfigDDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RefPointByNameRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = DiffPart.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;
    streamingTransport?: grpc.TransportFactory;
    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;
      streamingTransport?: grpc.TransportFactory;
      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }

  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any> {
    const upStreamCodes = this.options.upStreamRetryCodes ?? [];
    const DEFAULT_TIMEOUT_TIME: number = 3_000;
    const request = { ..._request, ...methodDesc.requestType };
    const transport = this.options.streamingTransport ?? this.options.transport;
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Observable((observer) => {
      const upStream = () => {
        const client = grpc.invoke(methodDesc, {
          host: this.host,
          request,
          ...(transport !== undefined ? { transport } : {}),
          metadata: maybeCombinedMetadata ?? {},
          debug: this.options.debug ?? false,
          onMessage: (next) => observer.next(next),
          onEnd: (code: grpc.Code, message: string, trailers: grpc.Metadata) => {
            if (code === 0) {
              observer.complete();
            } else if (upStreamCodes.includes(code)) {
              setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
            } else {
              const err = new Error(message) as any;
              err.code = code;
              err.metadata = trailers;
              observer.error(err);
            }
          },
        });
        observer.add(() => client.close());
      };
      upStream();
    }).pipe(share());
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
