// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: brig/bservice.proto

/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";
import { EchoRequest, EchoResponse } from "../common/echo";
import { Empty } from "../common/empty";
import { User, UserPreferences } from "../models/user";
import {
  AgentAttestCompleteRequest,
  AgentAttestCompleteResponse,
  AgentAttestStartRequest,
  AgentAttestStartResponse,
} from "./bagent";
import {
  AddTenantRequest,
  AddTenantResponse,
  DeleteAgentSessionRequest,
  DeleteTenantRequest,
  DeleteTenantsRequest,
  DeleteUserRequest,
  DeleteUserSessionRequest,
  DeleteUsersRequest,
  GetAgentSessionRequest,
  GetAgentSessionResponse,
  GetTenantsRequest,
  GetUserRequest,
  GetUserSessionRequest,
  GetUserSessionResponse,
  GetUsersRequest,
  NewOrgRequest,
  NewOrgResponse,
  RefreshAgentSessionRequest,
  RefreshUserSessionRequest,
  SetUsersRequest,
  StartAgentSessionRequest,
  StartAgentSessionResponse,
  StartUserSessionRequest,
  StartUserSessionResponse,
  SwitchTenantRequest,
  TenantsResponse,
  UpdateTenantRequest,
  UpdateTenantsRequest,
  UpdateUserRequest,
  UsersResponse,
} from "./bapi";
import { ApiKey, ApiKeysResponse, DeleteApiKeyRequest, GetApiKeysRequest, UploadApiKeyRequest } from "./bapikeys";
import { SignManagedEndpointClientCertRequest, SignManagedEndpointClientCertResponse } from "./bmanaged_endpoint";
import { HttpRequest, HttpResponse, MarshalerRequest, MarshalerResponse, ParamsRequest, ParamsResponse } from "./btest";
import {
  BearerToken,
  BearerTokensResponse,
  CreateBearerTokenRequest,
  CreateBearerTokensRequest,
  CreateBearerTokensResponse,
  DeleteBearerTokenRequest,
  GetBearerTokenRequest,
  GetBearerTokensRequest,
} from "./btokens";

export const protobufPackage = "brig";

export interface Brig {
  Echo(request: DeepPartial<EchoRequest>, metadata?: grpc.Metadata): Promise<EchoResponse>;
  StreamEcho(request: DeepPartial<EchoRequest>, metadata?: grpc.Metadata): Observable<EchoResponse>;
  /** Start a new user session. */
  StartUserSession(
    request: DeepPartial<StartUserSessionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<StartUserSessionResponse>;
  /** Refresh current user session. */
  RefreshUserSession(
    request: DeepPartial<RefreshUserSessionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<StartUserSessionResponse>;
  /** Replace current user session with one in a different tenant. */
  SwitchTenant(request: DeepPartial<SwitchTenantRequest>, metadata?: grpc.Metadata): Promise<StartUserSessionResponse>;
  /**
   * Introspect current user session.
   *
   * The user session is the one the user is currently logged into.
   */
  GetUserSession(
    request: DeepPartial<GetUserSessionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetUserSessionResponse>;
  /** End user session. */
  DeleteUserSession(request: DeepPartial<DeleteUserSessionRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /** Start a new agent session. */
  StartAgentSession(
    request: DeepPartial<StartAgentSessionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<StartAgentSessionResponse>;
  /** Refresh current agent session. */
  RefreshAgentSession(
    request: DeepPartial<RefreshAgentSessionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<StartAgentSessionResponse>;
  /** Introspect current agent session. */
  GetAgentSession(
    request: DeepPartial<GetAgentSessionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetAgentSessionResponse>;
  /** End agent session. */
  DeleteAgentSession(request: DeepPartial<DeleteAgentSessionRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /** AgentAttestStart */
  AgentAttestStart(
    request: DeepPartial<AgentAttestStartRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AgentAttestStartResponse>;
  /** AgentAttestComplete */
  AgentAttestComplete(
    request: DeepPartial<AgentAttestCompleteRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AgentAttestCompleteResponse>;
  /** Signup.  Adds an organization, a default-tenant for that organization and an admin user to the default-tenant. */
  NewOrg(request: DeepPartial<NewOrgRequest>, metadata?: grpc.Metadata): Promise<NewOrgResponse>;
  /**
   * Add one or more tenants.
   *
   * Add one or more tenants to the organization.
   */
  AddTenant(request: DeepPartial<AddTenantRequest>, metadata?: grpc.Metadata): Promise<AddTenantResponse>;
  /**
   * Update a specific tenant.
   *
   * Update a specific tenant.
   */
  UpdateTenant(request: DeepPartial<UpdateTenantRequest>, metadata?: grpc.Metadata): Promise<TenantsResponse>;
  /**
   * Update one or more tenants.
   *
   * Update one or more tenants from the context of default-tenant.
   */
  UpdateTenants(request: DeepPartial<UpdateTenantsRequest>, metadata?: grpc.Metadata): Promise<TenantsResponse>;
  /**
   * Get a specific tenant.
   *
   * Get a specific tenant.
   */
  GetTenants(request: DeepPartial<GetTenantsRequest>, metadata?: grpc.Metadata): Promise<TenantsResponse>;
  /**
   * Delete a specific non-default tenant.
   *
   * Delete a specific non-default tenant.
   */
  DeleteTenant(request: DeepPartial<DeleteTenantRequest>, metadata?: grpc.Metadata): Promise<TenantsResponse>;
  /**
   * Delete one or more non-default tenants.
   *
   * Delete one or more non-default tenants.
   */
  DeleteTenants(request: DeepPartial<DeleteTenantsRequest>, metadata?: grpc.Metadata): Promise<TenantsResponse>;
  /**
   * Add one or more users.
   *
   * Add one or more users to the organization.
   */
  SetUsers(request: DeepPartial<SetUsersRequest>, metadata?: grpc.Metadata): Promise<UsersResponse>;
  /**
   * Get a list of users.
   *
   * Get the list of users associated with the organization.
   */
  GetUsers(request: DeepPartial<GetUsersRequest>, metadata?: grpc.Metadata): Promise<UsersResponse>;
  /**
   * Get a specific user.
   *
   * Get a specific user associated with the organization.
   */
  GetUser(request: DeepPartial<GetUserRequest>, metadata?: grpc.Metadata): Promise<User>;
  /**
   * Update a specific user.
   *
   * Update a specific user associated with the organization.
   */
  UpdateUser(request: DeepPartial<UpdateUserRequest>, metadata?: grpc.Metadata): Promise<User>;
  /**
   * Delete a specific user.
   *
   * Delete a specific user associated with the organization.
   * This will remove all access of the user to the organization.
   */
  DeleteUser(request: DeepPartial<DeleteUserRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * Delete one or more users.
   *
   * Delete one or more users associated with the organization.
   * This will remove all access of the provided users to the organization.
   */
  DeleteUsers(request: DeepPartial<DeleteUsersRequest>, metadata?: grpc.Metadata): Promise<UsersResponse>;
  /** Test API for various aspects of the infrastructure. */
  TestParams(request: DeepPartial<ParamsRequest>, metadata?: grpc.Metadata): Promise<ParamsResponse>;
  /**
   * Test API for a basic http handler.
   * Note that the signature of the handler to be implemented is different.
   */
  TestHttp(request: DeepPartial<HttpRequest>, metadata?: grpc.Metadata): Promise<HttpResponse>;
  /** Test API for custom PUT marshaler. */
  TestUpdateMarshalers(request: DeepPartial<MarshalerRequest>, metadata?: grpc.Metadata): Promise<MarshalerResponse>;
  /** Test API for custom POST marshaler. */
  TestAddMarshalers(request: DeepPartial<MarshalerRequest>, metadata?: grpc.Metadata): Promise<MarshalerResponse>;
  /**
   * Add one or more API keys.
   *
   * Add one or more API keys by uploading the PEM encoded public keys, either ed25519 or RSA.
   *
   * Asymmetric keys can be used to sign REST requests as an alternative to using
   * bearer tokens. The caller must sign the request correctly using their private key.
   * The response will include a generated fingerprint of the key which should be
   * included in the Authorization header along with the signature.
   */
  UploadApiKey(request: DeepPartial<UploadApiKeyRequest>, metadata?: grpc.Metadata): Promise<ApiKey>;
  /**
   * Delete a specific API key.
   *
   * Delete a specific API key associated with the user.
   */
  DeleteApiKey(request: DeepPartial<DeleteApiKeyRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * Get the list of API keys.
   *
   * Get the list of API keys associated with the user.
   */
  GetApiKeys(request: DeepPartial<GetApiKeysRequest>, metadata?: grpc.Metadata): Promise<ApiKeysResponse>;
  /** Add a bearer token for a specific user. */
  CreateBearerToken(request: DeepPartial<CreateBearerTokenRequest>, metadata?: grpc.Metadata): Promise<BearerToken>;
  /**
   * Add one or more bearer tokens.
   *
   * Add one or more bearer tokens for the user.
   */
  CreateBearerTokens(
    request: DeepPartial<CreateBearerTokensRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateBearerTokensResponse>;
  /**
   * Delete a specific bearer token.
   *
   * Delete a specific bearer token associated with the user.
   */
  DeleteBearerToken(request: DeepPartial<DeleteBearerTokenRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * Get the list of bearer tokens.
   *
   * Get the list of bearer tokens associated with the user.
   */
  GetBearerTokens(
    request: DeepPartial<GetBearerTokensRequest>,
    metadata?: grpc.Metadata,
  ): Promise<BearerTokensResponse>;
  /**
   * Get a specific bearer token.
   *
   * Get a specific bearer token associated with the user.
   */
  GetBearerToken(request: DeepPartial<GetBearerTokenRequest>, metadata?: grpc.Metadata): Promise<BearerToken>;
  SignManagedEndpointClientCert(
    request: DeepPartial<SignManagedEndpointClientCertRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SignManagedEndpointClientCertResponse>;
  /**
   * Update the user preferences.
   *
   * Update the user preferences and return the new preferences.
   */
  UpdateUserPreferences(request: DeepPartial<UserPreferences>, metadata?: grpc.Metadata): Promise<UserPreferences>;
}

export class BrigClientImpl implements Brig {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.Echo = this.Echo.bind(this);
    this.StreamEcho = this.StreamEcho.bind(this);
    this.StartUserSession = this.StartUserSession.bind(this);
    this.RefreshUserSession = this.RefreshUserSession.bind(this);
    this.SwitchTenant = this.SwitchTenant.bind(this);
    this.GetUserSession = this.GetUserSession.bind(this);
    this.DeleteUserSession = this.DeleteUserSession.bind(this);
    this.StartAgentSession = this.StartAgentSession.bind(this);
    this.RefreshAgentSession = this.RefreshAgentSession.bind(this);
    this.GetAgentSession = this.GetAgentSession.bind(this);
    this.DeleteAgentSession = this.DeleteAgentSession.bind(this);
    this.AgentAttestStart = this.AgentAttestStart.bind(this);
    this.AgentAttestComplete = this.AgentAttestComplete.bind(this);
    this.NewOrg = this.NewOrg.bind(this);
    this.AddTenant = this.AddTenant.bind(this);
    this.UpdateTenant = this.UpdateTenant.bind(this);
    this.UpdateTenants = this.UpdateTenants.bind(this);
    this.GetTenants = this.GetTenants.bind(this);
    this.DeleteTenant = this.DeleteTenant.bind(this);
    this.DeleteTenants = this.DeleteTenants.bind(this);
    this.SetUsers = this.SetUsers.bind(this);
    this.GetUsers = this.GetUsers.bind(this);
    this.GetUser = this.GetUser.bind(this);
    this.UpdateUser = this.UpdateUser.bind(this);
    this.DeleteUser = this.DeleteUser.bind(this);
    this.DeleteUsers = this.DeleteUsers.bind(this);
    this.TestParams = this.TestParams.bind(this);
    this.TestHttp = this.TestHttp.bind(this);
    this.TestUpdateMarshalers = this.TestUpdateMarshalers.bind(this);
    this.TestAddMarshalers = this.TestAddMarshalers.bind(this);
    this.UploadApiKey = this.UploadApiKey.bind(this);
    this.DeleteApiKey = this.DeleteApiKey.bind(this);
    this.GetApiKeys = this.GetApiKeys.bind(this);
    this.CreateBearerToken = this.CreateBearerToken.bind(this);
    this.CreateBearerTokens = this.CreateBearerTokens.bind(this);
    this.DeleteBearerToken = this.DeleteBearerToken.bind(this);
    this.GetBearerTokens = this.GetBearerTokens.bind(this);
    this.GetBearerToken = this.GetBearerToken.bind(this);
    this.SignManagedEndpointClientCert = this.SignManagedEndpointClientCert.bind(this);
    this.UpdateUserPreferences = this.UpdateUserPreferences.bind(this);
  }

  Echo(request: DeepPartial<EchoRequest>, metadata?: grpc.Metadata): Promise<EchoResponse> {
    return this.rpc.unary(BrigEchoDesc, EchoRequest.fromPartial(request), metadata);
  }

  StreamEcho(request: DeepPartial<EchoRequest>, metadata?: grpc.Metadata): Observable<EchoResponse> {
    return this.rpc.invoke(BrigStreamEchoDesc, EchoRequest.fromPartial(request), metadata);
  }

  StartUserSession(
    request: DeepPartial<StartUserSessionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<StartUserSessionResponse> {
    return this.rpc.unary(BrigStartUserSessionDesc, StartUserSessionRequest.fromPartial(request), metadata);
  }

  RefreshUserSession(
    request: DeepPartial<RefreshUserSessionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<StartUserSessionResponse> {
    return this.rpc.unary(BrigRefreshUserSessionDesc, RefreshUserSessionRequest.fromPartial(request), metadata);
  }

  SwitchTenant(request: DeepPartial<SwitchTenantRequest>, metadata?: grpc.Metadata): Promise<StartUserSessionResponse> {
    return this.rpc.unary(BrigSwitchTenantDesc, SwitchTenantRequest.fromPartial(request), metadata);
  }

  GetUserSession(
    request: DeepPartial<GetUserSessionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetUserSessionResponse> {
    return this.rpc.unary(BrigGetUserSessionDesc, GetUserSessionRequest.fromPartial(request), metadata);
  }

  DeleteUserSession(request: DeepPartial<DeleteUserSessionRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(BrigDeleteUserSessionDesc, DeleteUserSessionRequest.fromPartial(request), metadata);
  }

  StartAgentSession(
    request: DeepPartial<StartAgentSessionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<StartAgentSessionResponse> {
    return this.rpc.unary(BrigStartAgentSessionDesc, StartAgentSessionRequest.fromPartial(request), metadata);
  }

  RefreshAgentSession(
    request: DeepPartial<RefreshAgentSessionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<StartAgentSessionResponse> {
    return this.rpc.unary(BrigRefreshAgentSessionDesc, RefreshAgentSessionRequest.fromPartial(request), metadata);
  }

  GetAgentSession(
    request: DeepPartial<GetAgentSessionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetAgentSessionResponse> {
    return this.rpc.unary(BrigGetAgentSessionDesc, GetAgentSessionRequest.fromPartial(request), metadata);
  }

  DeleteAgentSession(request: DeepPartial<DeleteAgentSessionRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(BrigDeleteAgentSessionDesc, DeleteAgentSessionRequest.fromPartial(request), metadata);
  }

  AgentAttestStart(
    request: DeepPartial<AgentAttestStartRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AgentAttestStartResponse> {
    return this.rpc.unary(BrigAgentAttestStartDesc, AgentAttestStartRequest.fromPartial(request), metadata);
  }

  AgentAttestComplete(
    request: DeepPartial<AgentAttestCompleteRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AgentAttestCompleteResponse> {
    return this.rpc.unary(BrigAgentAttestCompleteDesc, AgentAttestCompleteRequest.fromPartial(request), metadata);
  }

  NewOrg(request: DeepPartial<NewOrgRequest>, metadata?: grpc.Metadata): Promise<NewOrgResponse> {
    return this.rpc.unary(BrigNewOrgDesc, NewOrgRequest.fromPartial(request), metadata);
  }

  AddTenant(request: DeepPartial<AddTenantRequest>, metadata?: grpc.Metadata): Promise<AddTenantResponse> {
    return this.rpc.unary(BrigAddTenantDesc, AddTenantRequest.fromPartial(request), metadata);
  }

  UpdateTenant(request: DeepPartial<UpdateTenantRequest>, metadata?: grpc.Metadata): Promise<TenantsResponse> {
    return this.rpc.unary(BrigUpdateTenantDesc, UpdateTenantRequest.fromPartial(request), metadata);
  }

  UpdateTenants(request: DeepPartial<UpdateTenantsRequest>, metadata?: grpc.Metadata): Promise<TenantsResponse> {
    return this.rpc.unary(BrigUpdateTenantsDesc, UpdateTenantsRequest.fromPartial(request), metadata);
  }

  GetTenants(request: DeepPartial<GetTenantsRequest>, metadata?: grpc.Metadata): Promise<TenantsResponse> {
    return this.rpc.unary(BrigGetTenantsDesc, GetTenantsRequest.fromPartial(request), metadata);
  }

  DeleteTenant(request: DeepPartial<DeleteTenantRequest>, metadata?: grpc.Metadata): Promise<TenantsResponse> {
    return this.rpc.unary(BrigDeleteTenantDesc, DeleteTenantRequest.fromPartial(request), metadata);
  }

  DeleteTenants(request: DeepPartial<DeleteTenantsRequest>, metadata?: grpc.Metadata): Promise<TenantsResponse> {
    return this.rpc.unary(BrigDeleteTenantsDesc, DeleteTenantsRequest.fromPartial(request), metadata);
  }

  SetUsers(request: DeepPartial<SetUsersRequest>, metadata?: grpc.Metadata): Promise<UsersResponse> {
    return this.rpc.unary(BrigSetUsersDesc, SetUsersRequest.fromPartial(request), metadata);
  }

  GetUsers(request: DeepPartial<GetUsersRequest>, metadata?: grpc.Metadata): Promise<UsersResponse> {
    return this.rpc.unary(BrigGetUsersDesc, GetUsersRequest.fromPartial(request), metadata);
  }

  GetUser(request: DeepPartial<GetUserRequest>, metadata?: grpc.Metadata): Promise<User> {
    return this.rpc.unary(BrigGetUserDesc, GetUserRequest.fromPartial(request), metadata);
  }

  UpdateUser(request: DeepPartial<UpdateUserRequest>, metadata?: grpc.Metadata): Promise<User> {
    return this.rpc.unary(BrigUpdateUserDesc, UpdateUserRequest.fromPartial(request), metadata);
  }

  DeleteUser(request: DeepPartial<DeleteUserRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(BrigDeleteUserDesc, DeleteUserRequest.fromPartial(request), metadata);
  }

  DeleteUsers(request: DeepPartial<DeleteUsersRequest>, metadata?: grpc.Metadata): Promise<UsersResponse> {
    return this.rpc.unary(BrigDeleteUsersDesc, DeleteUsersRequest.fromPartial(request), metadata);
  }

  TestParams(request: DeepPartial<ParamsRequest>, metadata?: grpc.Metadata): Promise<ParamsResponse> {
    return this.rpc.unary(BrigTestParamsDesc, ParamsRequest.fromPartial(request), metadata);
  }

  TestHttp(request: DeepPartial<HttpRequest>, metadata?: grpc.Metadata): Promise<HttpResponse> {
    return this.rpc.unary(BrigTestHttpDesc, HttpRequest.fromPartial(request), metadata);
  }

  TestUpdateMarshalers(request: DeepPartial<MarshalerRequest>, metadata?: grpc.Metadata): Promise<MarshalerResponse> {
    return this.rpc.unary(BrigTestUpdateMarshalersDesc, MarshalerRequest.fromPartial(request), metadata);
  }

  TestAddMarshalers(request: DeepPartial<MarshalerRequest>, metadata?: grpc.Metadata): Promise<MarshalerResponse> {
    return this.rpc.unary(BrigTestAddMarshalersDesc, MarshalerRequest.fromPartial(request), metadata);
  }

  UploadApiKey(request: DeepPartial<UploadApiKeyRequest>, metadata?: grpc.Metadata): Promise<ApiKey> {
    return this.rpc.unary(BrigUploadApiKeyDesc, UploadApiKeyRequest.fromPartial(request), metadata);
  }

  DeleteApiKey(request: DeepPartial<DeleteApiKeyRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(BrigDeleteApiKeyDesc, DeleteApiKeyRequest.fromPartial(request), metadata);
  }

  GetApiKeys(request: DeepPartial<GetApiKeysRequest>, metadata?: grpc.Metadata): Promise<ApiKeysResponse> {
    return this.rpc.unary(BrigGetApiKeysDesc, GetApiKeysRequest.fromPartial(request), metadata);
  }

  CreateBearerToken(request: DeepPartial<CreateBearerTokenRequest>, metadata?: grpc.Metadata): Promise<BearerToken> {
    return this.rpc.unary(BrigCreateBearerTokenDesc, CreateBearerTokenRequest.fromPartial(request), metadata);
  }

  CreateBearerTokens(
    request: DeepPartial<CreateBearerTokensRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateBearerTokensResponse> {
    return this.rpc.unary(BrigCreateBearerTokensDesc, CreateBearerTokensRequest.fromPartial(request), metadata);
  }

  DeleteBearerToken(request: DeepPartial<DeleteBearerTokenRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(BrigDeleteBearerTokenDesc, DeleteBearerTokenRequest.fromPartial(request), metadata);
  }

  GetBearerTokens(
    request: DeepPartial<GetBearerTokensRequest>,
    metadata?: grpc.Metadata,
  ): Promise<BearerTokensResponse> {
    return this.rpc.unary(BrigGetBearerTokensDesc, GetBearerTokensRequest.fromPartial(request), metadata);
  }

  GetBearerToken(request: DeepPartial<GetBearerTokenRequest>, metadata?: grpc.Metadata): Promise<BearerToken> {
    return this.rpc.unary(BrigGetBearerTokenDesc, GetBearerTokenRequest.fromPartial(request), metadata);
  }

  SignManagedEndpointClientCert(
    request: DeepPartial<SignManagedEndpointClientCertRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SignManagedEndpointClientCertResponse> {
    return this.rpc.unary(
      BrigSignManagedEndpointClientCertDesc,
      SignManagedEndpointClientCertRequest.fromPartial(request),
      metadata,
    );
  }

  UpdateUserPreferences(request: DeepPartial<UserPreferences>, metadata?: grpc.Metadata): Promise<UserPreferences> {
    return this.rpc.unary(BrigUpdateUserPreferencesDesc, UserPreferences.fromPartial(request), metadata);
  }
}

export const BrigDesc = { serviceName: "brig.Brig" };

export const BrigEchoDesc: UnaryMethodDefinitionish = {
  methodName: "Echo",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return EchoRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = EchoResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigStreamEchoDesc: UnaryMethodDefinitionish = {
  methodName: "StreamEcho",
  service: BrigDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return EchoRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = EchoResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigStartUserSessionDesc: UnaryMethodDefinitionish = {
  methodName: "StartUserSession",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return StartUserSessionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = StartUserSessionResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigRefreshUserSessionDesc: UnaryMethodDefinitionish = {
  methodName: "RefreshUserSession",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RefreshUserSessionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = StartUserSessionResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigSwitchTenantDesc: UnaryMethodDefinitionish = {
  methodName: "SwitchTenant",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SwitchTenantRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = StartUserSessionResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigGetUserSessionDesc: UnaryMethodDefinitionish = {
  methodName: "GetUserSession",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetUserSessionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetUserSessionResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigDeleteUserSessionDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteUserSession",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteUserSessionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigStartAgentSessionDesc: UnaryMethodDefinitionish = {
  methodName: "StartAgentSession",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return StartAgentSessionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = StartAgentSessionResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigRefreshAgentSessionDesc: UnaryMethodDefinitionish = {
  methodName: "RefreshAgentSession",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RefreshAgentSessionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = StartAgentSessionResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigGetAgentSessionDesc: UnaryMethodDefinitionish = {
  methodName: "GetAgentSession",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetAgentSessionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetAgentSessionResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigDeleteAgentSessionDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteAgentSession",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteAgentSessionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigAgentAttestStartDesc: UnaryMethodDefinitionish = {
  methodName: "AgentAttestStart",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AgentAttestStartRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AgentAttestStartResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigAgentAttestCompleteDesc: UnaryMethodDefinitionish = {
  methodName: "AgentAttestComplete",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AgentAttestCompleteRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AgentAttestCompleteResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigNewOrgDesc: UnaryMethodDefinitionish = {
  methodName: "NewOrg",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return NewOrgRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = NewOrgResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigAddTenantDesc: UnaryMethodDefinitionish = {
  methodName: "AddTenant",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddTenantRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AddTenantResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigUpdateTenantDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateTenant",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateTenantRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TenantsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigUpdateTenantsDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateTenants",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateTenantsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TenantsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigGetTenantsDesc: UnaryMethodDefinitionish = {
  methodName: "GetTenants",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetTenantsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TenantsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigDeleteTenantDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteTenant",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteTenantRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TenantsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigDeleteTenantsDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteTenants",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteTenantsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TenantsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigSetUsersDesc: UnaryMethodDefinitionish = {
  methodName: "SetUsers",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetUsersRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UsersResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigGetUsersDesc: UnaryMethodDefinitionish = {
  methodName: "GetUsers",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetUsersRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UsersResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigGetUserDesc: UnaryMethodDefinitionish = {
  methodName: "GetUser",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetUserRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = User.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigUpdateUserDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateUser",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateUserRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = User.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigDeleteUserDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteUser",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteUserRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigDeleteUsersDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteUsers",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteUsersRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UsersResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigTestParamsDesc: UnaryMethodDefinitionish = {
  methodName: "TestParams",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ParamsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ParamsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigTestHttpDesc: UnaryMethodDefinitionish = {
  methodName: "TestHttp",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return HttpRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = HttpResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigTestUpdateMarshalersDesc: UnaryMethodDefinitionish = {
  methodName: "TestUpdateMarshalers",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return MarshalerRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = MarshalerResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigTestAddMarshalersDesc: UnaryMethodDefinitionish = {
  methodName: "TestAddMarshalers",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return MarshalerRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = MarshalerResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigUploadApiKeyDesc: UnaryMethodDefinitionish = {
  methodName: "UploadApiKey",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UploadApiKeyRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ApiKey.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigDeleteApiKeyDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteApiKey",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteApiKeyRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigGetApiKeysDesc: UnaryMethodDefinitionish = {
  methodName: "GetApiKeys",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetApiKeysRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ApiKeysResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigCreateBearerTokenDesc: UnaryMethodDefinitionish = {
  methodName: "CreateBearerToken",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateBearerTokenRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BearerToken.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigCreateBearerTokensDesc: UnaryMethodDefinitionish = {
  methodName: "CreateBearerTokens",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateBearerTokensRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateBearerTokensResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigDeleteBearerTokenDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteBearerToken",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteBearerTokenRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigGetBearerTokensDesc: UnaryMethodDefinitionish = {
  methodName: "GetBearerTokens",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetBearerTokensRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BearerTokensResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigGetBearerTokenDesc: UnaryMethodDefinitionish = {
  methodName: "GetBearerToken",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetBearerTokenRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BearerToken.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigSignManagedEndpointClientCertDesc: UnaryMethodDefinitionish = {
  methodName: "SignManagedEndpointClientCert",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SignManagedEndpointClientCertRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SignManagedEndpointClientCertResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BrigUpdateUserPreferencesDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateUserPreferences",
  service: BrigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UserPreferences.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UserPreferences.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;
    streamingTransport?: grpc.TransportFactory;
    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;
      streamingTransport?: grpc.TransportFactory;
      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }

  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any> {
    const upStreamCodes = this.options.upStreamRetryCodes ?? [];
    const DEFAULT_TIMEOUT_TIME: number = 3_000;
    const request = { ..._request, ...methodDesc.requestType };
    const transport = this.options.streamingTransport ?? this.options.transport;
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Observable((observer) => {
      const upStream = () => {
        const client = grpc.invoke(methodDesc, {
          host: this.host,
          request,
          ...(transport !== undefined ? { transport } : {}),
          metadata: maybeCombinedMetadata ?? {},
          debug: this.options.debug ?? false,
          onMessage: (next) => observer.next(next),
          onEnd: (code: grpc.Code, message: string, trailers: grpc.Metadata) => {
            if (code === 0) {
              observer.complete();
            } else if (upStreamCodes.includes(code)) {
              setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
            } else {
              const err = new Error(message) as any;
              err.code = code;
              err.metadata = trailers;
              observer.error(err);
            }
          },
        });
        observer.add(() => client.close());
      };
      upStream();
    }).pipe(share());
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
