// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: configd/rest_node.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { NodeRole, nodeRoleFromJSON, nodeRoleToJSON } from "../models/fabric";
import { Annotation } from "../models/global";
import { Metadata, PsuAirflow } from "../models/types";

export const protobufPackage = "configd";

/**
 * A Node is a logical representation of a device in a fabric that allows the separation to
 * the logical configuration from the actual physical device simplifying RMA and hardware
 * replacements. When associated or bound to a node, a device assumes the node identity and
 * all its associated configuration. A node can be pre-configured and referenced in other
 * fabric level constructs such as VRFs, VNIs and Link Aggregation Groups (LAGs) before a
 * device is bound to it allowing for pre-configuration of a complete fabric.
 */
export interface Node {
  /** The unique identifier of the node. */
  nodeId: string;
  /**
   * The user-defined name of the node. The name is used as hostname for the node and need to
   * comply with DNS restrictions, is case-insensitive and must be unique in the organization.
   */
  name: string;
  /** The unique identifier of the device associated with the node. */
  deviceId: string;
  /** The description is a user-defined field to store notes about the node. */
  description: string;
  /** The enabled state of the node which indicates if the node is enabled or disabled. */
  enabled: boolean;
  /** The serial number of the device associated with the node. */
  serialNumber: string;
  /** The name of the hardware model of the node. */
  modelName: string;
  /** The location is a user-defined field to store information about the location of the node (E.g. SJC01). */
  location: string;
  /** A list of roles for the node. */
  roles: NodeRole[];
  /** A list of user-defined labels that can be used for grouping and filtering nodes. */
  labels: string[];
  /**
   * A list of name-value annotations to store user-defined data including complex data such as JSON
   * associated with the node.
   */
  annotations: Annotation[];
  /** A map of attributes related to the lifecycle of the node. */
  metadata:
    | Metadata
    | undefined;
  /** Airflows and corresponding psu models of the node. */
  psuAirflows: PsuAirflow[];
}

/** The request for the list of nodes in a specific fabric. */
export interface GetFabricNodesRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The candidate configuration name. If not set the default candidate configuration values are returned. */
  candidate: string;
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

/** The response returned to a request for the list of nodes in a specific fabric. */
export interface GetFabricNodesResponse {
  /** The list of nodes in the fabric */
  nodes: Node[];
}

/** The request for a specific node in a fabric. */
export interface GetNamedFabricNodeRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The node id or name. */
  nodeId: string;
  /** The candidate configuration name. If not set the default candidate configuration values are returned. */
  candidate: string;
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

/** The request to add one or more nodes to a specific fabric. */
export interface AddFabricNodesRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** A list of nodes to be added to the fabric. */
  nodes: Node[];
}

/** The response returned to a request to add a list of nodes to a specific fabric. */
export interface AddFabricNodesResponse {
  /** The list of newly added nodes to the fabric. */
  nodes: Node[];
}

/** The request to update a specific node in a fabric. */
export interface UpdateFabricNodeRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The node id or name. */
  nodeId: string;
  /** The updated node definition. */
  node: Node | undefined;
}

/** The request to delete a specific node in a fabric. */
export interface DeleteFabricNodeRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** A node id or name. */
  nodeId: string;
}

function createBaseNode(): Node {
  return {
    nodeId: "",
    name: "",
    deviceId: "",
    description: "",
    enabled: false,
    serialNumber: "",
    modelName: "",
    location: "",
    roles: [],
    labels: [],
    annotations: [],
    metadata: undefined,
    psuAirflows: [],
  };
}

export const Node = {
  encode(message: Node, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nodeId !== "") {
      writer.uint32(10).string(message.nodeId);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.deviceId !== "") {
      writer.uint32(26).string(message.deviceId);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.enabled !== false) {
      writer.uint32(40).bool(message.enabled);
    }
    if (message.serialNumber !== "") {
      writer.uint32(50).string(message.serialNumber);
    }
    if (message.modelName !== "") {
      writer.uint32(58).string(message.modelName);
    }
    if (message.location !== "") {
      writer.uint32(66).string(message.location);
    }
    writer.uint32(74).fork();
    for (const v of message.roles) {
      writer.int32(v);
    }
    writer.ldelim();
    for (const v of message.labels) {
      writer.uint32(82).string(v!);
    }
    for (const v of message.annotations) {
      Annotation.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(98).fork()).ldelim();
    }
    for (const v of message.psuAirflows) {
      PsuAirflow.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Node {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNode();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.modelName = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.location = reader.string();
          continue;
        case 9:
          if (tag === 72) {
            message.roles.push(reader.int32() as any);

            continue;
          }

          if (tag === 74) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.roles.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.annotations.push(Annotation.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.psuAirflows.push(PsuAirflow.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Node {
    return {
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
      modelName: isSet(object.modelName) ? globalThis.String(object.modelName) : "",
      location: isSet(object.location) ? globalThis.String(object.location) : "",
      roles: globalThis.Array.isArray(object?.roles) ? object.roles.map((e: any) => nodeRoleFromJSON(e)) : [],
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      annotations: globalThis.Array.isArray(object?.annotations)
        ? object.annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      psuAirflows: globalThis.Array.isArray(object?.psuAirflows)
        ? object.psuAirflows.map((e: any) => PsuAirflow.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Node): unknown {
    const obj: any = {};
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    if (message.modelName !== "") {
      obj.modelName = message.modelName;
    }
    if (message.location !== "") {
      obj.location = message.location;
    }
    if (message.roles?.length) {
      obj.roles = message.roles.map((e) => nodeRoleToJSON(e));
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.annotations?.length) {
      obj.annotations = message.annotations.map((e) => Annotation.toJSON(e));
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.psuAirflows?.length) {
      obj.psuAirflows = message.psuAirflows.map((e) => PsuAirflow.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Node>, I>>(base?: I): Node {
    return Node.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Node>, I>>(object: I): Node {
    const message = createBaseNode();
    message.nodeId = object.nodeId ?? "";
    message.name = object.name ?? "";
    message.deviceId = object.deviceId ?? "";
    message.description = object.description ?? "";
    message.enabled = object.enabled ?? false;
    message.serialNumber = object.serialNumber ?? "";
    message.modelName = object.modelName ?? "";
    message.location = object.location ?? "";
    message.roles = object.roles?.map((e) => e) || [];
    message.labels = object.labels?.map((e) => e) || [];
    message.annotations = object.annotations?.map((e) => Annotation.fromPartial(e)) || [];
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.psuAirflows = object.psuAirflows?.map((e) => PsuAirflow.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetFabricNodesRequest(): GetFabricNodesRequest {
  return { fabricId: "", candidate: "", includeMetadata: false };
}

export const GetFabricNodesRequest = {
  encode(message: GetFabricNodesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.candidate !== "") {
      writer.uint32(18).string(message.candidate);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(24).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricNodesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricNodesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricNodesRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetFabricNodesRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricNodesRequest>, I>>(base?: I): GetFabricNodesRequest {
    return GetFabricNodesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricNodesRequest>, I>>(object: I): GetFabricNodesRequest {
    const message = createBaseGetFabricNodesRequest();
    message.fabricId = object.fabricId ?? "";
    message.candidate = object.candidate ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseGetFabricNodesResponse(): GetFabricNodesResponse {
  return { nodes: [] };
}

export const GetFabricNodesResponse = {
  encode(message: GetFabricNodesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.nodes) {
      Node.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricNodesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricNodesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nodes.push(Node.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricNodesResponse {
    return { nodes: globalThis.Array.isArray(object?.nodes) ? object.nodes.map((e: any) => Node.fromJSON(e)) : [] };
  },

  toJSON(message: GetFabricNodesResponse): unknown {
    const obj: any = {};
    if (message.nodes?.length) {
      obj.nodes = message.nodes.map((e) => Node.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricNodesResponse>, I>>(base?: I): GetFabricNodesResponse {
    return GetFabricNodesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricNodesResponse>, I>>(object: I): GetFabricNodesResponse {
    const message = createBaseGetFabricNodesResponse();
    message.nodes = object.nodes?.map((e) => Node.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetNamedFabricNodeRequest(): GetNamedFabricNodeRequest {
  return { fabricId: "", nodeId: "", candidate: "", includeMetadata: false };
}

export const GetNamedFabricNodeRequest = {
  encode(message: GetNamedFabricNodeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.candidate !== "") {
      writer.uint32(26).string(message.candidate);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(32).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNamedFabricNodeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNamedFabricNodeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNamedFabricNodeRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetNamedFabricNodeRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNamedFabricNodeRequest>, I>>(base?: I): GetNamedFabricNodeRequest {
    return GetNamedFabricNodeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNamedFabricNodeRequest>, I>>(object: I): GetNamedFabricNodeRequest {
    const message = createBaseGetNamedFabricNodeRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.candidate = object.candidate ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseAddFabricNodesRequest(): AddFabricNodesRequest {
  return { fabricId: "", nodes: [] };
}

export const AddFabricNodesRequest = {
  encode(message: AddFabricNodesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    for (const v of message.nodes) {
      Node.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddFabricNodesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddFabricNodesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodes.push(Node.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddFabricNodesRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodes: globalThis.Array.isArray(object?.nodes) ? object.nodes.map((e: any) => Node.fromJSON(e)) : [],
    };
  },

  toJSON(message: AddFabricNodesRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodes?.length) {
      obj.nodes = message.nodes.map((e) => Node.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddFabricNodesRequest>, I>>(base?: I): AddFabricNodesRequest {
    return AddFabricNodesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddFabricNodesRequest>, I>>(object: I): AddFabricNodesRequest {
    const message = createBaseAddFabricNodesRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodes = object.nodes?.map((e) => Node.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAddFabricNodesResponse(): AddFabricNodesResponse {
  return { nodes: [] };
}

export const AddFabricNodesResponse = {
  encode(message: AddFabricNodesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.nodes) {
      Node.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddFabricNodesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddFabricNodesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nodes.push(Node.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddFabricNodesResponse {
    return { nodes: globalThis.Array.isArray(object?.nodes) ? object.nodes.map((e: any) => Node.fromJSON(e)) : [] };
  },

  toJSON(message: AddFabricNodesResponse): unknown {
    const obj: any = {};
    if (message.nodes?.length) {
      obj.nodes = message.nodes.map((e) => Node.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddFabricNodesResponse>, I>>(base?: I): AddFabricNodesResponse {
    return AddFabricNodesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddFabricNodesResponse>, I>>(object: I): AddFabricNodesResponse {
    const message = createBaseAddFabricNodesResponse();
    message.nodes = object.nodes?.map((e) => Node.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateFabricNodeRequest(): UpdateFabricNodeRequest {
  return { fabricId: "", nodeId: "", node: undefined };
}

export const UpdateFabricNodeRequest = {
  encode(message: UpdateFabricNodeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.node !== undefined) {
      Node.encode(message.node, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateFabricNodeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateFabricNodeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.node = Node.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateFabricNodeRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      node: isSet(object.node) ? Node.fromJSON(object.node) : undefined,
    };
  },

  toJSON(message: UpdateFabricNodeRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.node !== undefined) {
      obj.node = Node.toJSON(message.node);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateFabricNodeRequest>, I>>(base?: I): UpdateFabricNodeRequest {
    return UpdateFabricNodeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateFabricNodeRequest>, I>>(object: I): UpdateFabricNodeRequest {
    const message = createBaseUpdateFabricNodeRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.node = (object.node !== undefined && object.node !== null) ? Node.fromPartial(object.node) : undefined;
    return message;
  },
};

function createBaseDeleteFabricNodeRequest(): DeleteFabricNodeRequest {
  return { fabricId: "", nodeId: "" };
}

export const DeleteFabricNodeRequest = {
  encode(message: DeleteFabricNodeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteFabricNodeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteFabricNodeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteFabricNodeRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
    };
  },

  toJSON(message: DeleteFabricNodeRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteFabricNodeRequest>, I>>(base?: I): DeleteFabricNodeRequest {
    return DeleteFabricNodeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteFabricNodeRequest>, I>>(object: I): DeleteFabricNodeRequest {
    const message = createBaseDeleteFabricNodeRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
