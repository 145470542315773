// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: onprem/workflow.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { NodeRole, nodeRoleFromJSON, nodeRoleToJSON } from "../models/fabric";
import { TaskType, taskTypeFromJSON, taskTypeToJSON } from "../models/global";

export const protobufPackage = "onprem";

/** WorkflowStatus enumerates various workflow status  values. */
export enum WorkflowStatus {
  /** WORKFLOW_UNSPECIFIED - Unspecified workflow status. */
  WORKFLOW_UNSPECIFIED = 0,
  /** WORKFLOW_OK - Execution succeeded. */
  WORKFLOW_OK = 1,
  /** WORKFLOW_NOOP - Noop, and do not record. */
  WORKFLOW_NOOP = 2,
  /** WORKFLOW_RETRY - Retry last action. */
  WORKFLOW_RETRY = 3,
  /** WORKFLOW_ABORT - Abort workflow execution. */
  WORKFLOW_ABORT = 4,
  /** WORKFLOW_CANNOT_CONNECT - Remote connection failed. */
  WORKFLOW_CANNOT_CONNECT = 5,
  /** WORKFLOW_CANNOT_EXECUTE - Cannot execute workflow. */
  WORKFLOW_CANNOT_EXECUTE = 6,
  /** WORKFLOW_ACCESS_DENIED - Authentication or authorization failed. */
  WORKFLOW_ACCESS_DENIED = 7,
  /** WORKFLOW_TIMED_OUT - Execution timed out. */
  WORKFLOW_TIMED_OUT = 8,
  /** WORKFLOW_NOT_FOUND - Workflow received not found response. */
  WORKFLOW_NOT_FOUND = 9,
  UNRECOGNIZED = -1,
}

export function workflowStatusFromJSON(object: any): WorkflowStatus {
  switch (object) {
    case 0:
    case "WORKFLOW_UNSPECIFIED":
      return WorkflowStatus.WORKFLOW_UNSPECIFIED;
    case 1:
    case "WORKFLOW_OK":
      return WorkflowStatus.WORKFLOW_OK;
    case 2:
    case "WORKFLOW_NOOP":
      return WorkflowStatus.WORKFLOW_NOOP;
    case 3:
    case "WORKFLOW_RETRY":
      return WorkflowStatus.WORKFLOW_RETRY;
    case 4:
    case "WORKFLOW_ABORT":
      return WorkflowStatus.WORKFLOW_ABORT;
    case 5:
    case "WORKFLOW_CANNOT_CONNECT":
      return WorkflowStatus.WORKFLOW_CANNOT_CONNECT;
    case 6:
    case "WORKFLOW_CANNOT_EXECUTE":
      return WorkflowStatus.WORKFLOW_CANNOT_EXECUTE;
    case 7:
    case "WORKFLOW_ACCESS_DENIED":
      return WorkflowStatus.WORKFLOW_ACCESS_DENIED;
    case 8:
    case "WORKFLOW_TIMED_OUT":
      return WorkflowStatus.WORKFLOW_TIMED_OUT;
    case 9:
    case "WORKFLOW_NOT_FOUND":
      return WorkflowStatus.WORKFLOW_NOT_FOUND;
    case -1:
    case "UNRECOGNIZED":
    default:
      return WorkflowStatus.UNRECOGNIZED;
  }
}

export function workflowStatusToJSON(object: WorkflowStatus): string {
  switch (object) {
    case WorkflowStatus.WORKFLOW_UNSPECIFIED:
      return "WORKFLOW_UNSPECIFIED";
    case WorkflowStatus.WORKFLOW_OK:
      return "WORKFLOW_OK";
    case WorkflowStatus.WORKFLOW_NOOP:
      return "WORKFLOW_NOOP";
    case WorkflowStatus.WORKFLOW_RETRY:
      return "WORKFLOW_RETRY";
    case WorkflowStatus.WORKFLOW_ABORT:
      return "WORKFLOW_ABORT";
    case WorkflowStatus.WORKFLOW_CANNOT_CONNECT:
      return "WORKFLOW_CANNOT_CONNECT";
    case WorkflowStatus.WORKFLOW_CANNOT_EXECUTE:
      return "WORKFLOW_CANNOT_EXECUTE";
    case WorkflowStatus.WORKFLOW_ACCESS_DENIED:
      return "WORKFLOW_ACCESS_DENIED";
    case WorkflowStatus.WORKFLOW_TIMED_OUT:
      return "WORKFLOW_TIMED_OUT";
    case WorkflowStatus.WORKFLOW_NOT_FOUND:
      return "WORKFLOW_NOT_FOUND";
    case WorkflowStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * WorkflowType enumerates various well-known workflow types. WorkflowType
 * enumeration is not set-in-stone. It is going to change considerably as
 * Tortuga workflows evolve.
 */
export enum WorkflowType {
  /** WORKFLOW_TYPE_UNSPECIFIED - Unspecified workflow set. */
  WORKFLOW_TYPE_UNSPECIFIED = 0,
  /** WORKFLOW_TYPE_FINAL - Final status report. */
  WORKFLOW_TYPE_FINAL = 1,
  /** WORKFLOW_TYPE_FABRIC_PORT - Fabric ports workflow. */
  WORKFLOW_TYPE_FABRIC_PORT = 2,
  /** WORKFLOW_TYPE_ROUTED_PORT - Configure IP addresses of routed ports. */
  WORKFLOW_TYPE_ROUTED_PORT = 3,
  /** WORKFLOW_TYPE_PORT_ACL - Apply port ACLs. */
  WORKFLOW_TYPE_PORT_ACL = 4,
  /** WORKFLOW_TYPE_VLAN - Add/update/delete Vlans. */
  WORKFLOW_TYPE_VLAN = 5,
  /** WORKFLOW_TYPE_BGP_VRF_CFG - Apply VRF BGP configs. */
  WORKFLOW_TYPE_BGP_VRF_CFG = 6,
  /** WORKFLOW_TYPE_STATIC_ROUTE - Configure VRF static routes. */
  WORKFLOW_TYPE_STATIC_ROUTE = 7,
  /** WORKFLOW_TYPE_COUNTER - Enable stats counters. */
  WORKFLOW_TYPE_COUNTER = 8,
  /** WORKFLOW_TYPE_FEATURE - Enables or disables features. */
  WORKFLOW_TYPE_FEATURE = 9,
  /** WORKFLOW_TYPE_HOST_PORT - Enable/disable host ports. */
  WORKFLOW_TYPE_HOST_PORT = 10,
  /** WORKFLOW_TYPE_BGP_CHECK - Check BGP health. */
  WORKFLOW_TYPE_BGP_CHECK = 11,
  /** WORKFLOW_TYPE_LOOPBACK - Add/update/delete loopback devices. */
  WORKFLOW_TYPE_LOOPBACK = 12,
  /** WORKFLOW_TYPE_VRF - Add/update/delete VRF. */
  WORKFLOW_TYPE_VRF = 13,
  /** WORKFLOW_TYPE_SUB_INTERFACE - Configure sub-interfaces. */
  WORKFLOW_TYPE_SUB_INTERFACE = 14,
  /** WORKFLOW_TYPE_VNI - Add/update/delete VNI. */
  WORKFLOW_TYPE_VNI = 15,
  /** WORKFLOW_TYPE_PURGE - Overlay config purge. */
  WORKFLOW_TYPE_PURGE = 16,
  /** WORKFLOW_TYPE_SVI - Apply Vlan SVIs. */
  WORKFLOW_TYPE_SVI = 17,
  /** WORKFLOW_TYPE_BGP_VRF_VNI - Add BGP VRF/VNI mappings. */
  WORKFLOW_TYPE_BGP_VRF_VNI = 18,
  /** WORKFLOW_TYPE_DHCP_RELAY - Adds DHCP relay properties. */
  WORKFLOW_TYPE_DHCP_RELAY = 19,
  /** WORKFLOW_TYPE_UNUSED_PORT - Make config for unused ports. */
  WORKFLOW_TYPE_UNUSED_PORT = 20,
  /** WORKFLOW_TYPE_PORT_CHANNEL - Make config for port channels. */
  WORKFLOW_TYPE_PORT_CHANNEL = 21,
  /** WORKFLOW_TYPE_UNBIND_VRF_INF - Unbind interfaces from VRFs. */
  WORKFLOW_TYPE_UNBIND_VRF_INF = 22,
  /** WORKFLOW_TYPE_BGP_ROUTE_MAP - BGP route map config. */
  WORKFLOW_TYPE_BGP_ROUTE_MAP = 23,
  /** WORKFLOW_TYPE_INTERFACE_IP - Configures interface IP addresses. */
  WORKFLOW_TYPE_INTERFACE_IP = 24,
  /** WORKFLOW_TYPE_PORT_BREAKOUT - Configure port breakouts. */
  WORKFLOW_TYPE_PORT_BREAKOUT = 25,
  /** WORKFLOW_TYPE_VLAN_STP - Configure per-vlan STP. */
  WORKFLOW_TYPE_VLAN_STP = 26,
  /** WORKFLOW_TYPE_LAG_PORT - Configure LAG/MLAG ports. */
  WORKFLOW_TYPE_LAG_PORT = 27,
  /** WORKFLOW_TYPE_BGP_PREFIX_LIST - Configure BGP prefix lists. */
  WORKFLOW_TYPE_BGP_PREFIX_LIST = 28,
  /** WORKFLOW_TYPE_BGP_COMMUNITY_LIST - Configure BGP community lists. */
  WORKFLOW_TYPE_BGP_COMMUNITY_LIST = 29,
  /** WORKFLOW_TYPE_GLOBAL_STP - Enable or disable global STP. */
  WORKFLOW_TYPE_GLOBAL_STP = 30,
  /** WORKFLOW_TYPE_DELETE_VLAN - Delete obsolete Vlans. */
  WORKFLOW_TYPE_DELETE_VLAN = 31,
  /** WORKFLOW_TYPE_BGP_GLOBAL - Set up global BGP route-maps. */
  WORKFLOW_TYPE_BGP_GLOBAL = 32,
  WORKFLOW_TYPE_RESERVED33 = 33,
  WORKFLOW_TYPE_RESERVED34 = 34,
  WORKFLOW_TYPE_RESERVED35 = 35,
  WORKFLOW_TYPE_RESERVED36 = 36,
  WORKFLOW_TYPE_RESERVED37 = 37,
  WORKFLOW_TYPE_RESERVED38 = 38,
  WORKFLOW_TYPE_RESERVED39 = 39,
  WORKFLOW_TYPE_RESERVED40 = 40,
  UNRECOGNIZED = -1,
}

export function workflowTypeFromJSON(object: any): WorkflowType {
  switch (object) {
    case 0:
    case "WORKFLOW_TYPE_UNSPECIFIED":
      return WorkflowType.WORKFLOW_TYPE_UNSPECIFIED;
    case 1:
    case "WORKFLOW_TYPE_FINAL":
      return WorkflowType.WORKFLOW_TYPE_FINAL;
    case 2:
    case "WORKFLOW_TYPE_FABRIC_PORT":
      return WorkflowType.WORKFLOW_TYPE_FABRIC_PORT;
    case 3:
    case "WORKFLOW_TYPE_ROUTED_PORT":
      return WorkflowType.WORKFLOW_TYPE_ROUTED_PORT;
    case 4:
    case "WORKFLOW_TYPE_PORT_ACL":
      return WorkflowType.WORKFLOW_TYPE_PORT_ACL;
    case 5:
    case "WORKFLOW_TYPE_VLAN":
      return WorkflowType.WORKFLOW_TYPE_VLAN;
    case 6:
    case "WORKFLOW_TYPE_BGP_VRF_CFG":
      return WorkflowType.WORKFLOW_TYPE_BGP_VRF_CFG;
    case 7:
    case "WORKFLOW_TYPE_STATIC_ROUTE":
      return WorkflowType.WORKFLOW_TYPE_STATIC_ROUTE;
    case 8:
    case "WORKFLOW_TYPE_COUNTER":
      return WorkflowType.WORKFLOW_TYPE_COUNTER;
    case 9:
    case "WORKFLOW_TYPE_FEATURE":
      return WorkflowType.WORKFLOW_TYPE_FEATURE;
    case 10:
    case "WORKFLOW_TYPE_HOST_PORT":
      return WorkflowType.WORKFLOW_TYPE_HOST_PORT;
    case 11:
    case "WORKFLOW_TYPE_BGP_CHECK":
      return WorkflowType.WORKFLOW_TYPE_BGP_CHECK;
    case 12:
    case "WORKFLOW_TYPE_LOOPBACK":
      return WorkflowType.WORKFLOW_TYPE_LOOPBACK;
    case 13:
    case "WORKFLOW_TYPE_VRF":
      return WorkflowType.WORKFLOW_TYPE_VRF;
    case 14:
    case "WORKFLOW_TYPE_SUB_INTERFACE":
      return WorkflowType.WORKFLOW_TYPE_SUB_INTERFACE;
    case 15:
    case "WORKFLOW_TYPE_VNI":
      return WorkflowType.WORKFLOW_TYPE_VNI;
    case 16:
    case "WORKFLOW_TYPE_PURGE":
      return WorkflowType.WORKFLOW_TYPE_PURGE;
    case 17:
    case "WORKFLOW_TYPE_SVI":
      return WorkflowType.WORKFLOW_TYPE_SVI;
    case 18:
    case "WORKFLOW_TYPE_BGP_VRF_VNI":
      return WorkflowType.WORKFLOW_TYPE_BGP_VRF_VNI;
    case 19:
    case "WORKFLOW_TYPE_DHCP_RELAY":
      return WorkflowType.WORKFLOW_TYPE_DHCP_RELAY;
    case 20:
    case "WORKFLOW_TYPE_UNUSED_PORT":
      return WorkflowType.WORKFLOW_TYPE_UNUSED_PORT;
    case 21:
    case "WORKFLOW_TYPE_PORT_CHANNEL":
      return WorkflowType.WORKFLOW_TYPE_PORT_CHANNEL;
    case 22:
    case "WORKFLOW_TYPE_UNBIND_VRF_INF":
      return WorkflowType.WORKFLOW_TYPE_UNBIND_VRF_INF;
    case 23:
    case "WORKFLOW_TYPE_BGP_ROUTE_MAP":
      return WorkflowType.WORKFLOW_TYPE_BGP_ROUTE_MAP;
    case 24:
    case "WORKFLOW_TYPE_INTERFACE_IP":
      return WorkflowType.WORKFLOW_TYPE_INTERFACE_IP;
    case 25:
    case "WORKFLOW_TYPE_PORT_BREAKOUT":
      return WorkflowType.WORKFLOW_TYPE_PORT_BREAKOUT;
    case 26:
    case "WORKFLOW_TYPE_VLAN_STP":
      return WorkflowType.WORKFLOW_TYPE_VLAN_STP;
    case 27:
    case "WORKFLOW_TYPE_LAG_PORT":
      return WorkflowType.WORKFLOW_TYPE_LAG_PORT;
    case 28:
    case "WORKFLOW_TYPE_BGP_PREFIX_LIST":
      return WorkflowType.WORKFLOW_TYPE_BGP_PREFIX_LIST;
    case 29:
    case "WORKFLOW_TYPE_BGP_COMMUNITY_LIST":
      return WorkflowType.WORKFLOW_TYPE_BGP_COMMUNITY_LIST;
    case 30:
    case "WORKFLOW_TYPE_GLOBAL_STP":
      return WorkflowType.WORKFLOW_TYPE_GLOBAL_STP;
    case 31:
    case "WORKFLOW_TYPE_DELETE_VLAN":
      return WorkflowType.WORKFLOW_TYPE_DELETE_VLAN;
    case 32:
    case "WORKFLOW_TYPE_BGP_GLOBAL":
      return WorkflowType.WORKFLOW_TYPE_BGP_GLOBAL;
    case 33:
    case "WORKFLOW_TYPE_RESERVED33":
      return WorkflowType.WORKFLOW_TYPE_RESERVED33;
    case 34:
    case "WORKFLOW_TYPE_RESERVED34":
      return WorkflowType.WORKFLOW_TYPE_RESERVED34;
    case 35:
    case "WORKFLOW_TYPE_RESERVED35":
      return WorkflowType.WORKFLOW_TYPE_RESERVED35;
    case 36:
    case "WORKFLOW_TYPE_RESERVED36":
      return WorkflowType.WORKFLOW_TYPE_RESERVED36;
    case 37:
    case "WORKFLOW_TYPE_RESERVED37":
      return WorkflowType.WORKFLOW_TYPE_RESERVED37;
    case 38:
    case "WORKFLOW_TYPE_RESERVED38":
      return WorkflowType.WORKFLOW_TYPE_RESERVED38;
    case 39:
    case "WORKFLOW_TYPE_RESERVED39":
      return WorkflowType.WORKFLOW_TYPE_RESERVED39;
    case 40:
    case "WORKFLOW_TYPE_RESERVED40":
      return WorkflowType.WORKFLOW_TYPE_RESERVED40;
    case -1:
    case "UNRECOGNIZED":
    default:
      return WorkflowType.UNRECOGNIZED;
  }
}

export function workflowTypeToJSON(object: WorkflowType): string {
  switch (object) {
    case WorkflowType.WORKFLOW_TYPE_UNSPECIFIED:
      return "WORKFLOW_TYPE_UNSPECIFIED";
    case WorkflowType.WORKFLOW_TYPE_FINAL:
      return "WORKFLOW_TYPE_FINAL";
    case WorkflowType.WORKFLOW_TYPE_FABRIC_PORT:
      return "WORKFLOW_TYPE_FABRIC_PORT";
    case WorkflowType.WORKFLOW_TYPE_ROUTED_PORT:
      return "WORKFLOW_TYPE_ROUTED_PORT";
    case WorkflowType.WORKFLOW_TYPE_PORT_ACL:
      return "WORKFLOW_TYPE_PORT_ACL";
    case WorkflowType.WORKFLOW_TYPE_VLAN:
      return "WORKFLOW_TYPE_VLAN";
    case WorkflowType.WORKFLOW_TYPE_BGP_VRF_CFG:
      return "WORKFLOW_TYPE_BGP_VRF_CFG";
    case WorkflowType.WORKFLOW_TYPE_STATIC_ROUTE:
      return "WORKFLOW_TYPE_STATIC_ROUTE";
    case WorkflowType.WORKFLOW_TYPE_COUNTER:
      return "WORKFLOW_TYPE_COUNTER";
    case WorkflowType.WORKFLOW_TYPE_FEATURE:
      return "WORKFLOW_TYPE_FEATURE";
    case WorkflowType.WORKFLOW_TYPE_HOST_PORT:
      return "WORKFLOW_TYPE_HOST_PORT";
    case WorkflowType.WORKFLOW_TYPE_BGP_CHECK:
      return "WORKFLOW_TYPE_BGP_CHECK";
    case WorkflowType.WORKFLOW_TYPE_LOOPBACK:
      return "WORKFLOW_TYPE_LOOPBACK";
    case WorkflowType.WORKFLOW_TYPE_VRF:
      return "WORKFLOW_TYPE_VRF";
    case WorkflowType.WORKFLOW_TYPE_SUB_INTERFACE:
      return "WORKFLOW_TYPE_SUB_INTERFACE";
    case WorkflowType.WORKFLOW_TYPE_VNI:
      return "WORKFLOW_TYPE_VNI";
    case WorkflowType.WORKFLOW_TYPE_PURGE:
      return "WORKFLOW_TYPE_PURGE";
    case WorkflowType.WORKFLOW_TYPE_SVI:
      return "WORKFLOW_TYPE_SVI";
    case WorkflowType.WORKFLOW_TYPE_BGP_VRF_VNI:
      return "WORKFLOW_TYPE_BGP_VRF_VNI";
    case WorkflowType.WORKFLOW_TYPE_DHCP_RELAY:
      return "WORKFLOW_TYPE_DHCP_RELAY";
    case WorkflowType.WORKFLOW_TYPE_UNUSED_PORT:
      return "WORKFLOW_TYPE_UNUSED_PORT";
    case WorkflowType.WORKFLOW_TYPE_PORT_CHANNEL:
      return "WORKFLOW_TYPE_PORT_CHANNEL";
    case WorkflowType.WORKFLOW_TYPE_UNBIND_VRF_INF:
      return "WORKFLOW_TYPE_UNBIND_VRF_INF";
    case WorkflowType.WORKFLOW_TYPE_BGP_ROUTE_MAP:
      return "WORKFLOW_TYPE_BGP_ROUTE_MAP";
    case WorkflowType.WORKFLOW_TYPE_INTERFACE_IP:
      return "WORKFLOW_TYPE_INTERFACE_IP";
    case WorkflowType.WORKFLOW_TYPE_PORT_BREAKOUT:
      return "WORKFLOW_TYPE_PORT_BREAKOUT";
    case WorkflowType.WORKFLOW_TYPE_VLAN_STP:
      return "WORKFLOW_TYPE_VLAN_STP";
    case WorkflowType.WORKFLOW_TYPE_LAG_PORT:
      return "WORKFLOW_TYPE_LAG_PORT";
    case WorkflowType.WORKFLOW_TYPE_BGP_PREFIX_LIST:
      return "WORKFLOW_TYPE_BGP_PREFIX_LIST";
    case WorkflowType.WORKFLOW_TYPE_BGP_COMMUNITY_LIST:
      return "WORKFLOW_TYPE_BGP_COMMUNITY_LIST";
    case WorkflowType.WORKFLOW_TYPE_GLOBAL_STP:
      return "WORKFLOW_TYPE_GLOBAL_STP";
    case WorkflowType.WORKFLOW_TYPE_DELETE_VLAN:
      return "WORKFLOW_TYPE_DELETE_VLAN";
    case WorkflowType.WORKFLOW_TYPE_BGP_GLOBAL:
      return "WORKFLOW_TYPE_BGP_GLOBAL";
    case WorkflowType.WORKFLOW_TYPE_RESERVED33:
      return "WORKFLOW_TYPE_RESERVED33";
    case WorkflowType.WORKFLOW_TYPE_RESERVED34:
      return "WORKFLOW_TYPE_RESERVED34";
    case WorkflowType.WORKFLOW_TYPE_RESERVED35:
      return "WORKFLOW_TYPE_RESERVED35";
    case WorkflowType.WORKFLOW_TYPE_RESERVED36:
      return "WORKFLOW_TYPE_RESERVED36";
    case WorkflowType.WORKFLOW_TYPE_RESERVED37:
      return "WORKFLOW_TYPE_RESERVED37";
    case WorkflowType.WORKFLOW_TYPE_RESERVED38:
      return "WORKFLOW_TYPE_RESERVED38";
    case WorkflowType.WORKFLOW_TYPE_RESERVED39:
      return "WORKFLOW_TYPE_RESERVED39";
    case WorkflowType.WORKFLOW_TYPE_RESERVED40:
      return "WORKFLOW_TYPE_RESERVED40";
    case WorkflowType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Value encapsulates a task var value. Only one of Scalar, Object or
 * Values field maybe set. Precedence is in the same order as fields
 * are declared in the message.
 */
export interface Value {
  boolean?:
    | boolean
    | undefined;
  /** Cannot use "string" as protobuf compiler appends "_". */
  strVal?: string | undefined;
  int32?: number | undefined;
  uint32?: number | undefined;
  int64?: number | undefined;
  uint64?:
    | number
    | undefined;
  /** Object fields and values. */
  object: { [key: string]: Value };
  /** Multiple values (lowest precedence). */
  values: Value[];
}

export interface Value_ObjectEntry {
  key: string;
  value: Value | undefined;
}

/** VarConfig encapsulates a variable's config. */
export interface VarConfig {
  /** The name of the variable */
  name: string;
  /** A set of values. */
  values: Value[];
  /** A set of strings. This is to simplify YAML parsing. */
  strings: string[];
  /** If true, then object must have a value for values or strings. */
  assert: boolean;
}

/** CommandConfig encapsulates properties of a single command. */
export interface CommandConfig {
  /** Command scripts. */
  scripts: string;
  /** Indicates that scripts will not change data. */
  query: boolean;
  /** Required workflow labels. */
  labels: string[];
  /** Number of prefix indentations (1 = 2 spaces). */
  indent: number;
  /** Number of retries. */
  retries: number;
  /** Sleep for a given duration. */
  sleep: string;
}

/**
 * TaskConfig encapsulates properties of a workflow task.
 * Rules:
 * 1) If When is set, then the task is a decision task.
 *    Decision task must have a Then task.
 * 2) If Foreach is set, then the task is a Foreach (loop) task.
 *    Foreach requires either Tasks or Commands.
 * 3) A task cannot have both sub-tasks and commands.
 */
export interface TaskConfig {
  /** The name of the task. */
  name: string;
  /** Detailed description of the task. */
  description: string;
  /** Required workflow labels. */
  labels: string[];
  /** Task version. */
  version: string;
  /** Task type (E.g. SONiC). */
  type: string;
  /** Admin state of task (ENABLED/DISABLED). */
  adminState: string;
  /** Task should be run exactly once in a loop. */
  runOnce: boolean;
  /** Execute this command instead of task commands. */
  executable: string;
  /** Write merged commands to this file. */
  filename: string;
  /** Retry count for this task (valid only for commands). */
  retries: number;
  vars: VarConfig[];
  tasks: TaskConfig[];
  commands: CommandConfig[];
  /** Decision task configuration. */
  when: string;
  then: TaskConfig | undefined;
  else:
    | TaskConfig
    | undefined;
  /** Foreach task configuration. */
  foreach: string[];
  /** Final task. */
  finally: TaskConfig | undefined;
}

/** WorkflowTask encapsulates properties of workflow task. */
export interface WorkflowTask {
  task: string;
  vars: VarConfig[];
}

/** WorkflowConfig encapsulates properties of workflow. */
export interface WorkflowConfig {
  /** The workflow name. */
  name: string;
  /** Workflow description. */
  description: string;
  /** Workflow labels. */
  labels: string[];
  /** Operating system version. */
  version: string;
  /** Workflow task/OS type. */
  type: TaskType;
  /** A set of tasks (required). */
  tasks: WorkflowTask[];
  /** A set of workflow variables. */
  vars: VarConfig[];
}

/**
 * WorkflowSet encapsulates a set of workflows that can be executed
 * sequentially.
 */
export interface WorkflowSet {
  /** The name of workflow set. */
  name: string;
  /** A set of device roles. */
  roles: NodeRole[];
  /** A set of workflows. */
  workflows: WorkflowConfig[];
  /** Wait for this many seconds to get feedbacks. */
  waitSecs: number;
  /** Workflow type name. */
  type: WorkflowType;
  /** The fingerprint of workflow set. */
  configFp: string;
  /** Must run workflow set even when configFp does not change. */
  mustRun: boolean;
  /** Disables commit operation. */
  disableCommit: boolean;
  /** Grind identifier specific to this node. */
  grindId: number;
}

function createBaseValue(): Value {
  return {
    boolean: undefined,
    strVal: undefined,
    int32: undefined,
    uint32: undefined,
    int64: undefined,
    uint64: undefined,
    object: {},
    values: [],
  };
}

export const Value = {
  encode(message: Value, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.boolean !== undefined) {
      writer.uint32(8).bool(message.boolean);
    }
    if (message.strVal !== undefined) {
      writer.uint32(18).string(message.strVal);
    }
    if (message.int32 !== undefined) {
      writer.uint32(24).int32(message.int32);
    }
    if (message.uint32 !== undefined) {
      writer.uint32(32).uint32(message.uint32);
    }
    if (message.int64 !== undefined) {
      writer.uint32(40).int64(message.int64);
    }
    if (message.uint64 !== undefined) {
      writer.uint32(48).uint64(message.uint64);
    }
    Object.entries(message.object).forEach(([key, value]) => {
      Value_ObjectEntry.encode({ key: key as any, value }, writer.uint32(162).fork()).ldelim();
    });
    for (const v of message.values) {
      Value.encode(v!, writer.uint32(170).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Value {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.boolean = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.strVal = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.int32 = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.uint32 = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.int64 = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.uint64 = longToNumber(reader.uint64() as Long);
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          const entry20 = Value_ObjectEntry.decode(reader, reader.uint32());
          if (entry20.value !== undefined) {
            message.object[entry20.key] = entry20.value;
          }
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.values.push(Value.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Value {
    return {
      boolean: isSet(object.boolean) ? globalThis.Boolean(object.boolean) : undefined,
      strVal: isSet(object.strVal) ? globalThis.String(object.strVal) : undefined,
      int32: isSet(object.int32) ? globalThis.Number(object.int32) : undefined,
      uint32: isSet(object.uint32) ? globalThis.Number(object.uint32) : undefined,
      int64: isSet(object.int64) ? globalThis.Number(object.int64) : undefined,
      uint64: isSet(object.uint64) ? globalThis.Number(object.uint64) : undefined,
      object: isObject(object.object)
        ? Object.entries(object.object).reduce<{ [key: string]: Value }>((acc, [key, value]) => {
          acc[key] = Value.fromJSON(value);
          return acc;
        }, {})
        : {},
      values: globalThis.Array.isArray(object?.values) ? object.values.map((e: any) => Value.fromJSON(e)) : [],
    };
  },

  toJSON(message: Value): unknown {
    const obj: any = {};
    if (message.boolean !== undefined) {
      obj.boolean = message.boolean;
    }
    if (message.strVal !== undefined) {
      obj.strVal = message.strVal;
    }
    if (message.int32 !== undefined) {
      obj.int32 = Math.round(message.int32);
    }
    if (message.uint32 !== undefined) {
      obj.uint32 = Math.round(message.uint32);
    }
    if (message.int64 !== undefined) {
      obj.int64 = Math.round(message.int64);
    }
    if (message.uint64 !== undefined) {
      obj.uint64 = Math.round(message.uint64);
    }
    if (message.object) {
      const entries = Object.entries(message.object);
      if (entries.length > 0) {
        obj.object = {};
        entries.forEach(([k, v]) => {
          obj.object[k] = Value.toJSON(v);
        });
      }
    }
    if (message.values?.length) {
      obj.values = message.values.map((e) => Value.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Value>, I>>(base?: I): Value {
    return Value.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Value>, I>>(object: I): Value {
    const message = createBaseValue();
    message.boolean = object.boolean ?? undefined;
    message.strVal = object.strVal ?? undefined;
    message.int32 = object.int32 ?? undefined;
    message.uint32 = object.uint32 ?? undefined;
    message.int64 = object.int64 ?? undefined;
    message.uint64 = object.uint64 ?? undefined;
    message.object = Object.entries(object.object ?? {}).reduce<{ [key: string]: Value }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Value.fromPartial(value);
      }
      return acc;
    }, {});
    message.values = object.values?.map((e) => Value.fromPartial(e)) || [];
    return message;
  },
};

function createBaseValue_ObjectEntry(): Value_ObjectEntry {
  return { key: "", value: undefined };
}

export const Value_ObjectEntry = {
  encode(message: Value_ObjectEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Value.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Value_ObjectEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValue_ObjectEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Value.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Value_ObjectEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? Value.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: Value_ObjectEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = Value.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Value_ObjectEntry>, I>>(base?: I): Value_ObjectEntry {
    return Value_ObjectEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Value_ObjectEntry>, I>>(object: I): Value_ObjectEntry {
    const message = createBaseValue_ObjectEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null) ? Value.fromPartial(object.value) : undefined;
    return message;
  },
};

function createBaseVarConfig(): VarConfig {
  return { name: "", values: [], strings: [], assert: false };
}

export const VarConfig = {
  encode(message: VarConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    for (const v of message.values) {
      Value.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.strings) {
      writer.uint32(26).string(v!);
    }
    if (message.assert !== false) {
      writer.uint32(32).bool(message.assert);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VarConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVarConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.values.push(Value.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.strings.push(reader.string());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.assert = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VarConfig {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      values: globalThis.Array.isArray(object?.values) ? object.values.map((e: any) => Value.fromJSON(e)) : [],
      strings: globalThis.Array.isArray(object?.strings) ? object.strings.map((e: any) => globalThis.String(e)) : [],
      assert: isSet(object.assert) ? globalThis.Boolean(object.assert) : false,
    };
  },

  toJSON(message: VarConfig): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.values?.length) {
      obj.values = message.values.map((e) => Value.toJSON(e));
    }
    if (message.strings?.length) {
      obj.strings = message.strings;
    }
    if (message.assert !== false) {
      obj.assert = message.assert;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VarConfig>, I>>(base?: I): VarConfig {
    return VarConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VarConfig>, I>>(object: I): VarConfig {
    const message = createBaseVarConfig();
    message.name = object.name ?? "";
    message.values = object.values?.map((e) => Value.fromPartial(e)) || [];
    message.strings = object.strings?.map((e) => e) || [];
    message.assert = object.assert ?? false;
    return message;
  },
};

function createBaseCommandConfig(): CommandConfig {
  return { scripts: "", query: false, labels: [], indent: 0, retries: 0, sleep: "" };
}

export const CommandConfig = {
  encode(message: CommandConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scripts !== "") {
      writer.uint32(10).string(message.scripts);
    }
    if (message.query !== false) {
      writer.uint32(16).bool(message.query);
    }
    for (const v of message.labels) {
      writer.uint32(26).string(v!);
    }
    if (message.indent !== 0) {
      writer.uint32(32).uint32(message.indent);
    }
    if (message.retries !== 0) {
      writer.uint32(40).uint32(message.retries);
    }
    if (message.sleep !== "") {
      writer.uint32(50).string(message.sleep);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CommandConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommandConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scripts = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.query = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.indent = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.retries = reader.uint32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.sleep = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CommandConfig {
    return {
      scripts: isSet(object.scripts) ? globalThis.String(object.scripts) : "",
      query: isSet(object.query) ? globalThis.Boolean(object.query) : false,
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      indent: isSet(object.indent) ? globalThis.Number(object.indent) : 0,
      retries: isSet(object.retries) ? globalThis.Number(object.retries) : 0,
      sleep: isSet(object.sleep) ? globalThis.String(object.sleep) : "",
    };
  },

  toJSON(message: CommandConfig): unknown {
    const obj: any = {};
    if (message.scripts !== "") {
      obj.scripts = message.scripts;
    }
    if (message.query !== false) {
      obj.query = message.query;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.indent !== 0) {
      obj.indent = Math.round(message.indent);
    }
    if (message.retries !== 0) {
      obj.retries = Math.round(message.retries);
    }
    if (message.sleep !== "") {
      obj.sleep = message.sleep;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CommandConfig>, I>>(base?: I): CommandConfig {
    return CommandConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CommandConfig>, I>>(object: I): CommandConfig {
    const message = createBaseCommandConfig();
    message.scripts = object.scripts ?? "";
    message.query = object.query ?? false;
    message.labels = object.labels?.map((e) => e) || [];
    message.indent = object.indent ?? 0;
    message.retries = object.retries ?? 0;
    message.sleep = object.sleep ?? "";
    return message;
  },
};

function createBaseTaskConfig(): TaskConfig {
  return {
    name: "",
    description: "",
    labels: [],
    version: "",
    type: "",
    adminState: "",
    runOnce: false,
    executable: "",
    filename: "",
    retries: 0,
    vars: [],
    tasks: [],
    commands: [],
    when: "",
    then: undefined,
    else: undefined,
    foreach: [],
    finally: undefined,
  };
}

export const TaskConfig = {
  encode(message: TaskConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    for (const v of message.labels) {
      writer.uint32(26).string(v!);
    }
    if (message.version !== "") {
      writer.uint32(34).string(message.version);
    }
    if (message.type !== "") {
      writer.uint32(42).string(message.type);
    }
    if (message.adminState !== "") {
      writer.uint32(50).string(message.adminState);
    }
    if (message.runOnce !== false) {
      writer.uint32(56).bool(message.runOnce);
    }
    if (message.executable !== "") {
      writer.uint32(66).string(message.executable);
    }
    if (message.filename !== "") {
      writer.uint32(74).string(message.filename);
    }
    if (message.retries !== 0) {
      writer.uint32(80).uint32(message.retries);
    }
    for (const v of message.vars) {
      VarConfig.encode(v!, writer.uint32(162).fork()).ldelim();
    }
    for (const v of message.tasks) {
      TaskConfig.encode(v!, writer.uint32(170).fork()).ldelim();
    }
    for (const v of message.commands) {
      CommandConfig.encode(v!, writer.uint32(178).fork()).ldelim();
    }
    if (message.when !== "") {
      writer.uint32(186).string(message.when);
    }
    if (message.then !== undefined) {
      TaskConfig.encode(message.then, writer.uint32(194).fork()).ldelim();
    }
    if (message.else !== undefined) {
      TaskConfig.encode(message.else, writer.uint32(202).fork()).ldelim();
    }
    for (const v of message.foreach) {
      writer.uint32(210).string(v!);
    }
    if (message.finally !== undefined) {
      TaskConfig.encode(message.finally, writer.uint32(242).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TaskConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTaskConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.version = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.type = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.adminState = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.runOnce = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.executable = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.filename = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.retries = reader.uint32();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.vars.push(VarConfig.decode(reader, reader.uint32()));
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.tasks.push(TaskConfig.decode(reader, reader.uint32()));
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.commands.push(CommandConfig.decode(reader, reader.uint32()));
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.when = reader.string();
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.then = TaskConfig.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.else = TaskConfig.decode(reader, reader.uint32());
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.foreach.push(reader.string());
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.finally = TaskConfig.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TaskConfig {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      version: isSet(object.version) ? globalThis.String(object.version) : "",
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      adminState: isSet(object.adminState) ? globalThis.String(object.adminState) : "",
      runOnce: isSet(object.runOnce) ? globalThis.Boolean(object.runOnce) : false,
      executable: isSet(object.executable) ? globalThis.String(object.executable) : "",
      filename: isSet(object.filename) ? globalThis.String(object.filename) : "",
      retries: isSet(object.retries) ? globalThis.Number(object.retries) : 0,
      vars: globalThis.Array.isArray(object?.vars) ? object.vars.map((e: any) => VarConfig.fromJSON(e)) : [],
      tasks: globalThis.Array.isArray(object?.tasks) ? object.tasks.map((e: any) => TaskConfig.fromJSON(e)) : [],
      commands: globalThis.Array.isArray(object?.commands)
        ? object.commands.map((e: any) => CommandConfig.fromJSON(e))
        : [],
      when: isSet(object.when) ? globalThis.String(object.when) : "",
      then: isSet(object.then) ? TaskConfig.fromJSON(object.then) : undefined,
      else: isSet(object.else) ? TaskConfig.fromJSON(object.else) : undefined,
      foreach: globalThis.Array.isArray(object?.foreach) ? object.foreach.map((e: any) => globalThis.String(e)) : [],
      finally: isSet(object.finally) ? TaskConfig.fromJSON(object.finally) : undefined,
    };
  },

  toJSON(message: TaskConfig): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.version !== "") {
      obj.version = message.version;
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.adminState !== "") {
      obj.adminState = message.adminState;
    }
    if (message.runOnce !== false) {
      obj.runOnce = message.runOnce;
    }
    if (message.executable !== "") {
      obj.executable = message.executable;
    }
    if (message.filename !== "") {
      obj.filename = message.filename;
    }
    if (message.retries !== 0) {
      obj.retries = Math.round(message.retries);
    }
    if (message.vars?.length) {
      obj.vars = message.vars.map((e) => VarConfig.toJSON(e));
    }
    if (message.tasks?.length) {
      obj.tasks = message.tasks.map((e) => TaskConfig.toJSON(e));
    }
    if (message.commands?.length) {
      obj.commands = message.commands.map((e) => CommandConfig.toJSON(e));
    }
    if (message.when !== "") {
      obj.when = message.when;
    }
    if (message.then !== undefined) {
      obj.then = TaskConfig.toJSON(message.then);
    }
    if (message.else !== undefined) {
      obj.else = TaskConfig.toJSON(message.else);
    }
    if (message.foreach?.length) {
      obj.foreach = message.foreach;
    }
    if (message.finally !== undefined) {
      obj.finally = TaskConfig.toJSON(message.finally);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TaskConfig>, I>>(base?: I): TaskConfig {
    return TaskConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TaskConfig>, I>>(object: I): TaskConfig {
    const message = createBaseTaskConfig();
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.labels = object.labels?.map((e) => e) || [];
    message.version = object.version ?? "";
    message.type = object.type ?? "";
    message.adminState = object.adminState ?? "";
    message.runOnce = object.runOnce ?? false;
    message.executable = object.executable ?? "";
    message.filename = object.filename ?? "";
    message.retries = object.retries ?? 0;
    message.vars = object.vars?.map((e) => VarConfig.fromPartial(e)) || [];
    message.tasks = object.tasks?.map((e) => TaskConfig.fromPartial(e)) || [];
    message.commands = object.commands?.map((e) => CommandConfig.fromPartial(e)) || [];
    message.when = object.when ?? "";
    message.then = (object.then !== undefined && object.then !== null)
      ? TaskConfig.fromPartial(object.then)
      : undefined;
    message.else = (object.else !== undefined && object.else !== null)
      ? TaskConfig.fromPartial(object.else)
      : undefined;
    message.foreach = object.foreach?.map((e) => e) || [];
    message.finally = (object.finally !== undefined && object.finally !== null)
      ? TaskConfig.fromPartial(object.finally)
      : undefined;
    return message;
  },
};

function createBaseWorkflowTask(): WorkflowTask {
  return { task: "", vars: [] };
}

export const WorkflowTask = {
  encode(message: WorkflowTask, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.task !== "") {
      writer.uint32(10).string(message.task);
    }
    for (const v of message.vars) {
      VarConfig.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WorkflowTask {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWorkflowTask();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.task = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vars.push(VarConfig.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WorkflowTask {
    return {
      task: isSet(object.task) ? globalThis.String(object.task) : "",
      vars: globalThis.Array.isArray(object?.vars) ? object.vars.map((e: any) => VarConfig.fromJSON(e)) : [],
    };
  },

  toJSON(message: WorkflowTask): unknown {
    const obj: any = {};
    if (message.task !== "") {
      obj.task = message.task;
    }
    if (message.vars?.length) {
      obj.vars = message.vars.map((e) => VarConfig.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WorkflowTask>, I>>(base?: I): WorkflowTask {
    return WorkflowTask.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WorkflowTask>, I>>(object: I): WorkflowTask {
    const message = createBaseWorkflowTask();
    message.task = object.task ?? "";
    message.vars = object.vars?.map((e) => VarConfig.fromPartial(e)) || [];
    return message;
  },
};

function createBaseWorkflowConfig(): WorkflowConfig {
  return { name: "", description: "", labels: [], version: "", type: 0, tasks: [], vars: [] };
}

export const WorkflowConfig = {
  encode(message: WorkflowConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    for (const v of message.labels) {
      writer.uint32(26).string(v!);
    }
    if (message.version !== "") {
      writer.uint32(34).string(message.version);
    }
    if (message.type !== 0) {
      writer.uint32(48).int32(message.type);
    }
    for (const v of message.tasks) {
      WorkflowTask.encode(v!, writer.uint32(162).fork()).ldelim();
    }
    for (const v of message.vars) {
      VarConfig.encode(v!, writer.uint32(170).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WorkflowConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWorkflowConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.version = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.tasks.push(WorkflowTask.decode(reader, reader.uint32()));
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.vars.push(VarConfig.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WorkflowConfig {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      version: isSet(object.version) ? globalThis.String(object.version) : "",
      type: isSet(object.type) ? taskTypeFromJSON(object.type) : 0,
      tasks: globalThis.Array.isArray(object?.tasks) ? object.tasks.map((e: any) => WorkflowTask.fromJSON(e)) : [],
      vars: globalThis.Array.isArray(object?.vars) ? object.vars.map((e: any) => VarConfig.fromJSON(e)) : [],
    };
  },

  toJSON(message: WorkflowConfig): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.version !== "") {
      obj.version = message.version;
    }
    if (message.type !== 0) {
      obj.type = taskTypeToJSON(message.type);
    }
    if (message.tasks?.length) {
      obj.tasks = message.tasks.map((e) => WorkflowTask.toJSON(e));
    }
    if (message.vars?.length) {
      obj.vars = message.vars.map((e) => VarConfig.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WorkflowConfig>, I>>(base?: I): WorkflowConfig {
    return WorkflowConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WorkflowConfig>, I>>(object: I): WorkflowConfig {
    const message = createBaseWorkflowConfig();
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.labels = object.labels?.map((e) => e) || [];
    message.version = object.version ?? "";
    message.type = object.type ?? 0;
    message.tasks = object.tasks?.map((e) => WorkflowTask.fromPartial(e)) || [];
    message.vars = object.vars?.map((e) => VarConfig.fromPartial(e)) || [];
    return message;
  },
};

function createBaseWorkflowSet(): WorkflowSet {
  return {
    name: "",
    roles: [],
    workflows: [],
    waitSecs: 0,
    type: 0,
    configFp: "",
    mustRun: false,
    disableCommit: false,
    grindId: 0,
  };
}

export const WorkflowSet = {
  encode(message: WorkflowSet, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    writer.uint32(18).fork();
    for (const v of message.roles) {
      writer.int32(v);
    }
    writer.ldelim();
    for (const v of message.workflows) {
      WorkflowConfig.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.waitSecs !== 0) {
      writer.uint32(32).uint32(message.waitSecs);
    }
    if (message.type !== 0) {
      writer.uint32(40).int32(message.type);
    }
    if (message.configFp !== "") {
      writer.uint32(50).string(message.configFp);
    }
    if (message.mustRun !== false) {
      writer.uint32(56).bool(message.mustRun);
    }
    if (message.disableCommit !== false) {
      writer.uint32(64).bool(message.disableCommit);
    }
    if (message.grindId !== 0) {
      writer.uint32(72).int32(message.grindId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WorkflowSet {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWorkflowSet();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag === 16) {
            message.roles.push(reader.int32() as any);

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.roles.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.workflows.push(WorkflowConfig.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.waitSecs = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.configFp = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.mustRun = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.disableCommit = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.grindId = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WorkflowSet {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      roles: globalThis.Array.isArray(object?.roles) ? object.roles.map((e: any) => nodeRoleFromJSON(e)) : [],
      workflows: globalThis.Array.isArray(object?.workflows)
        ? object.workflows.map((e: any) => WorkflowConfig.fromJSON(e))
        : [],
      waitSecs: isSet(object.waitSecs) ? globalThis.Number(object.waitSecs) : 0,
      type: isSet(object.type) ? workflowTypeFromJSON(object.type) : 0,
      configFp: isSet(object.configFp) ? globalThis.String(object.configFp) : "",
      mustRun: isSet(object.mustRun) ? globalThis.Boolean(object.mustRun) : false,
      disableCommit: isSet(object.disableCommit) ? globalThis.Boolean(object.disableCommit) : false,
      grindId: isSet(object.grindId) ? globalThis.Number(object.grindId) : 0,
    };
  },

  toJSON(message: WorkflowSet): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.roles?.length) {
      obj.roles = message.roles.map((e) => nodeRoleToJSON(e));
    }
    if (message.workflows?.length) {
      obj.workflows = message.workflows.map((e) => WorkflowConfig.toJSON(e));
    }
    if (message.waitSecs !== 0) {
      obj.waitSecs = Math.round(message.waitSecs);
    }
    if (message.type !== 0) {
      obj.type = workflowTypeToJSON(message.type);
    }
    if (message.configFp !== "") {
      obj.configFp = message.configFp;
    }
    if (message.mustRun !== false) {
      obj.mustRun = message.mustRun;
    }
    if (message.disableCommit !== false) {
      obj.disableCommit = message.disableCommit;
    }
    if (message.grindId !== 0) {
      obj.grindId = Math.round(message.grindId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WorkflowSet>, I>>(base?: I): WorkflowSet {
    return WorkflowSet.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WorkflowSet>, I>>(object: I): WorkflowSet {
    const message = createBaseWorkflowSet();
    message.name = object.name ?? "";
    message.roles = object.roles?.map((e) => e) || [];
    message.workflows = object.workflows?.map((e) => WorkflowConfig.fromPartial(e)) || [];
    message.waitSecs = object.waitSecs ?? 0;
    message.type = object.type ?? 0;
    message.configFp = object.configFp ?? "";
    message.mustRun = object.mustRun ?? false;
    message.disableCommit = object.disableCommit ?? false;
    message.grindId = object.grindId ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
