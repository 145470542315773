// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: models/global.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "models";

/** AdminState defines an enumeration for object's administrative states. */
export enum AdminState {
  /** STATE_UNSPECIFIED - Invalid object state. */
  STATE_UNSPECIFIED = 0,
  /** ENABLED - Object is in enabled or active state. */
  ENABLED = 1,
  /** DISABLED - Object is in disabled or suspended state. */
  DISABLED = 2,
  /** DELETED - Object is in deleted state. */
  DELETED = 3,
  /** PENDING - Object is in pending state. */
  PENDING = 4,
  UNRECOGNIZED = -1,
}

export function adminStateFromJSON(object: any): AdminState {
  switch (object) {
    case 0:
    case "STATE_UNSPECIFIED":
      return AdminState.STATE_UNSPECIFIED;
    case 1:
    case "ENABLED":
      return AdminState.ENABLED;
    case 2:
    case "DISABLED":
      return AdminState.DISABLED;
    case 3:
    case "DELETED":
      return AdminState.DELETED;
    case 4:
    case "PENDING":
      return AdminState.PENDING;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AdminState.UNRECOGNIZED;
  }
}

export function adminStateToJSON(object: AdminState): string {
  switch (object) {
    case AdminState.STATE_UNSPECIFIED:
      return "STATE_UNSPECIFIED";
    case AdminState.ENABLED:
      return "ENABLED";
    case AdminState.DISABLED:
      return "DISABLED";
    case AdminState.DELETED:
      return "DELETED";
    case AdminState.PENDING:
      return "PENDING";
    case AdminState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** ApplicationType defines an enumeration for different applications. */
export enum ApplicationType {
  /** APPLICATION_UNSPECIFIED - Unspecified application. */
  APPLICATION_UNSPECIFIED = 0,
  /** SEXTANT - Sextant application. */
  SEXTANT = 1,
  /** BILGE - Bilge application. */
  BILGE = 2,
  /** CONFIGD - Configd application. */
  CONFIGD = 3,
  /** BRIG - Brig application. */
  BRIG = 4,
  /** MIZZEN - Mizzen application. */
  MIZZEN = 5,
  /** RUDDER - Rudder application. */
  RUDDER = 6,
  /** FRED - Fred application. */
  FRED = 7,
  UNRECOGNIZED = -1,
}

export function applicationTypeFromJSON(object: any): ApplicationType {
  switch (object) {
    case 0:
    case "APPLICATION_UNSPECIFIED":
      return ApplicationType.APPLICATION_UNSPECIFIED;
    case 1:
    case "SEXTANT":
      return ApplicationType.SEXTANT;
    case 2:
    case "BILGE":
      return ApplicationType.BILGE;
    case 3:
    case "CONFIGD":
      return ApplicationType.CONFIGD;
    case 4:
    case "BRIG":
      return ApplicationType.BRIG;
    case 5:
    case "MIZZEN":
      return ApplicationType.MIZZEN;
    case 6:
    case "RUDDER":
      return ApplicationType.RUDDER;
    case 7:
    case "FRED":
      return ApplicationType.FRED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ApplicationType.UNRECOGNIZED;
  }
}

export function applicationTypeToJSON(object: ApplicationType): string {
  switch (object) {
    case ApplicationType.APPLICATION_UNSPECIFIED:
      return "APPLICATION_UNSPECIFIED";
    case ApplicationType.SEXTANT:
      return "SEXTANT";
    case ApplicationType.BILGE:
      return "BILGE";
    case ApplicationType.CONFIGD:
      return "CONFIGD";
    case ApplicationType.BRIG:
      return "BRIG";
    case ApplicationType.MIZZEN:
      return "MIZZEN";
    case ApplicationType.RUDDER:
      return "RUDDER";
    case ApplicationType.FRED:
      return "FRED";
    case ApplicationType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** BoolType defines a nullable boolean type. */
export enum BoolType {
  /** BOOL_UNSPECIFIED - Unspecified or undefined boolean. */
  BOOL_UNSPECIFIED = 0,
  /** BOOL_FALSE - Boolean value of false. */
  BOOL_FALSE = 1,
  /** BOOL_TRUE - Boolean value of true. */
  BOOL_TRUE = 2,
  UNRECOGNIZED = -1,
}

export function boolTypeFromJSON(object: any): BoolType {
  switch (object) {
    case 0:
    case "BOOL_UNSPECIFIED":
      return BoolType.BOOL_UNSPECIFIED;
    case 1:
    case "BOOL_FALSE":
      return BoolType.BOOL_FALSE;
    case 2:
    case "BOOL_TRUE":
      return BoolType.BOOL_TRUE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BoolType.UNRECOGNIZED;
  }
}

export function boolTypeToJSON(object: BoolType): string {
  switch (object) {
    case BoolType.BOOL_UNSPECIFIED:
      return "BOOL_UNSPECIFIED";
    case BoolType.BOOL_FALSE:
      return "BOOL_FALSE";
    case BoolType.BOOL_TRUE:
      return "BOOL_TRUE";
    case BoolType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** CachePartition defines an enumeration of the cache partition names on a device */
export enum CachePartition {
  /** CACHE_PARTITION_UNSPECIFIED - Unspecified partition location */
  CACHE_PARTITION_UNSPECIFIED = 0,
  /** PARTITION_A - Cache partition A */
  PARTITION_A = 1,
  /** PARTITION_B - Cache partition B */
  PARTITION_B = 2,
  UNRECOGNIZED = -1,
}

export function cachePartitionFromJSON(object: any): CachePartition {
  switch (object) {
    case 0:
    case "CACHE_PARTITION_UNSPECIFIED":
      return CachePartition.CACHE_PARTITION_UNSPECIFIED;
    case 1:
    case "PARTITION_A":
      return CachePartition.PARTITION_A;
    case 2:
    case "PARTITION_B":
      return CachePartition.PARTITION_B;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CachePartition.UNRECOGNIZED;
  }
}

export function cachePartitionToJSON(object: CachePartition): string {
  switch (object) {
    case CachePartition.CACHE_PARTITION_UNSPECIFIED:
      return "CACHE_PARTITION_UNSPECIFIED";
    case CachePartition.PARTITION_A:
      return "PARTITION_A";
    case CachePartition.PARTITION_B:
      return "PARTITION_B";
    case CachePartition.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** ClaimStatus defines an enumeration of the possible outcomes when attempting to claim a device. */
export enum ClaimStatus {
  /** CLAIM_STATUS_UNSPECIFIED - Unspecified claim error (there was no error). */
  CLAIM_STATUS_UNSPECIFIED = 0,
  /** CLAIM_STATUS_SUCCESS - Successfully claimed device. */
  CLAIM_STATUS_SUCCESS = 1,
  /** CLAIM_STATUS_ALREADY_CLAIMED - Device has been claimed already. */
  CLAIM_STATUS_ALREADY_CLAIMED = 2,
  /** CLAIM_STATUS_INVALID_CODE - The claim code provided is invalid (non-existent, malformed, etc). */
  CLAIM_STATUS_INVALID_CODE = 3,
  /** CLAIM_STATUS_EXPIRED_CODE - Expired claim code. */
  CLAIM_STATUS_EXPIRED_CODE = 4,
  /** CLAIM_STATUS_REJECT_CLAIM - The claim has been rejected. */
  CLAIM_STATUS_REJECT_CLAIM = 5,
  UNRECOGNIZED = -1,
}

export function claimStatusFromJSON(object: any): ClaimStatus {
  switch (object) {
    case 0:
    case "CLAIM_STATUS_UNSPECIFIED":
      return ClaimStatus.CLAIM_STATUS_UNSPECIFIED;
    case 1:
    case "CLAIM_STATUS_SUCCESS":
      return ClaimStatus.CLAIM_STATUS_SUCCESS;
    case 2:
    case "CLAIM_STATUS_ALREADY_CLAIMED":
      return ClaimStatus.CLAIM_STATUS_ALREADY_CLAIMED;
    case 3:
    case "CLAIM_STATUS_INVALID_CODE":
      return ClaimStatus.CLAIM_STATUS_INVALID_CODE;
    case 4:
    case "CLAIM_STATUS_EXPIRED_CODE":
      return ClaimStatus.CLAIM_STATUS_EXPIRED_CODE;
    case 5:
    case "CLAIM_STATUS_REJECT_CLAIM":
      return ClaimStatus.CLAIM_STATUS_REJECT_CLAIM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ClaimStatus.UNRECOGNIZED;
  }
}

export function claimStatusToJSON(object: ClaimStatus): string {
  switch (object) {
    case ClaimStatus.CLAIM_STATUS_UNSPECIFIED:
      return "CLAIM_STATUS_UNSPECIFIED";
    case ClaimStatus.CLAIM_STATUS_SUCCESS:
      return "CLAIM_STATUS_SUCCESS";
    case ClaimStatus.CLAIM_STATUS_ALREADY_CLAIMED:
      return "CLAIM_STATUS_ALREADY_CLAIMED";
    case ClaimStatus.CLAIM_STATUS_INVALID_CODE:
      return "CLAIM_STATUS_INVALID_CODE";
    case ClaimStatus.CLAIM_STATUS_EXPIRED_CODE:
      return "CLAIM_STATUS_EXPIRED_CODE";
    case ClaimStatus.CLAIM_STATUS_REJECT_CLAIM:
      return "CLAIM_STATUS_REJECT_CLAIM";
    case ClaimStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Criticality defines an enumeration of activity event criticalities. */
export enum Criticality {
  /** CRITICALITY_UNSPECIFIED - Unspecified criticality. */
  CRITICALITY_UNSPECIFIED = 0,
  /** EMERGENCY - System is unusable. */
  EMERGENCY = 1,
  /** ALERT - Action must be taken immediately. */
  ALERT = 2,
  /** CRITICAL - Critical conditions. */
  CRITICAL = 3,
  /** ERROR - Error conditions. */
  ERROR = 4,
  /** WARNING - Warning conditions. */
  WARNING = 5,
  /** NOTICE - Normal, but significant, condition. */
  NOTICE = 6,
  /** INFO - Informational message. */
  INFO = 7,
  /** DEBUG - Debug level message. */
  DEBUG = 8,
  UNRECOGNIZED = -1,
}

export function criticalityFromJSON(object: any): Criticality {
  switch (object) {
    case 0:
    case "CRITICALITY_UNSPECIFIED":
      return Criticality.CRITICALITY_UNSPECIFIED;
    case 1:
    case "EMERGENCY":
      return Criticality.EMERGENCY;
    case 2:
    case "ALERT":
      return Criticality.ALERT;
    case 3:
    case "CRITICAL":
      return Criticality.CRITICAL;
    case 4:
    case "ERROR":
      return Criticality.ERROR;
    case 5:
    case "WARNING":
      return Criticality.WARNING;
    case 6:
    case "NOTICE":
      return Criticality.NOTICE;
    case 7:
    case "INFO":
      return Criticality.INFO;
    case 8:
    case "DEBUG":
      return Criticality.DEBUG;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Criticality.UNRECOGNIZED;
  }
}

export function criticalityToJSON(object: Criticality): string {
  switch (object) {
    case Criticality.CRITICALITY_UNSPECIFIED:
      return "CRITICALITY_UNSPECIFIED";
    case Criticality.EMERGENCY:
      return "EMERGENCY";
    case Criticality.ALERT:
      return "ALERT";
    case Criticality.CRITICAL:
      return "CRITICAL";
    case Criticality.ERROR:
      return "ERROR";
    case Criticality.WARNING:
      return "WARNING";
    case Criticality.NOTICE:
      return "NOTICE";
    case Criticality.INFO:
      return "INFO";
    case Criticality.DEBUG:
      return "DEBUG";
    case Criticality.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** DataType defines an enumeration of data types. */
export enum DataType {
  /** DATA_TYPE_UNSPECIFIED - Unspecified data type. */
  DATA_TYPE_UNSPECIFIED = 0,
  /** STRING - String type. */
  STRING = 1,
  /** INT32 - 32-bit signed integer. */
  INT32 = 2,
  /** UINT32 - 32-bit unsigned integer. */
  UINT32 = 3,
  /** INT64 - 64-bit signed integer. */
  INT64 = 4,
  /** UINT64 - 64-bit unsigned integer. */
  UINT64 = 5,
  /** BOOL - Boolean value. */
  BOOL = 6,
  /** TIME - Time value in ISO-8601 format. */
  TIME = 7,
  /** UUID - UUID value. */
  UUID = 8,
  /** DURATION - Duration value. */
  DURATION = 9,
  /** JSON - JSON data. */
  JSON = 10,
  UNRECOGNIZED = -1,
}

export function dataTypeFromJSON(object: any): DataType {
  switch (object) {
    case 0:
    case "DATA_TYPE_UNSPECIFIED":
      return DataType.DATA_TYPE_UNSPECIFIED;
    case 1:
    case "STRING":
      return DataType.STRING;
    case 2:
    case "INT32":
      return DataType.INT32;
    case 3:
    case "UINT32":
      return DataType.UINT32;
    case 4:
    case "INT64":
      return DataType.INT64;
    case 5:
    case "UINT64":
      return DataType.UINT64;
    case 6:
    case "BOOL":
      return DataType.BOOL;
    case 7:
    case "TIME":
      return DataType.TIME;
    case 8:
    case "UUID":
      return DataType.UUID;
    case 9:
    case "DURATION":
      return DataType.DURATION;
    case 10:
    case "JSON":
      return DataType.JSON;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DataType.UNRECOGNIZED;
  }
}

export function dataTypeToJSON(object: DataType): string {
  switch (object) {
    case DataType.DATA_TYPE_UNSPECIFIED:
      return "DATA_TYPE_UNSPECIFIED";
    case DataType.STRING:
      return "STRING";
    case DataType.INT32:
      return "INT32";
    case DataType.UINT32:
      return "UINT32";
    case DataType.INT64:
      return "INT64";
    case DataType.UINT64:
      return "UINT64";
    case DataType.BOOL:
      return "BOOL";
    case DataType.TIME:
      return "TIME";
    case DataType.UUID:
      return "UUID";
    case DataType.DURATION:
      return "DURATION";
    case DataType.JSON:
      return "JSON";
    case DataType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Duration defines an enumeration of time durations. */
export enum Duration {
  /** DURATION_UNSPECIFIED - Unspecified duration. */
  DURATION_UNSPECIFIED = 0,
  /** SECOND - Seconds. */
  SECOND = 1,
  /** MINUTE - Minutes. */
  MINUTE = 3,
  /** HOUR - Hours. */
  HOUR = 4,
  /** DAY - Days. */
  DAY = 5,
  /** WEEK - Weeks. */
  WEEK = 6,
  /** MONTH - Months. */
  MONTH = 7,
  /** YEAR - Years. */
  YEAR = 8,
  UNRECOGNIZED = -1,
}

export function durationFromJSON(object: any): Duration {
  switch (object) {
    case 0:
    case "DURATION_UNSPECIFIED":
      return Duration.DURATION_UNSPECIFIED;
    case 1:
    case "SECOND":
      return Duration.SECOND;
    case 3:
    case "MINUTE":
      return Duration.MINUTE;
    case 4:
    case "HOUR":
      return Duration.HOUR;
    case 5:
    case "DAY":
      return Duration.DAY;
    case 6:
    case "WEEK":
      return Duration.WEEK;
    case 7:
    case "MONTH":
      return Duration.MONTH;
    case 8:
    case "YEAR":
      return Duration.YEAR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Duration.UNRECOGNIZED;
  }
}

export function durationToJSON(object: Duration): string {
  switch (object) {
    case Duration.DURATION_UNSPECIFIED:
      return "DURATION_UNSPECIFIED";
    case Duration.SECOND:
      return "SECOND";
    case Duration.MINUTE:
      return "MINUTE";
    case Duration.HOUR:
      return "HOUR";
    case Duration.DAY:
      return "DAY";
    case Duration.WEEK:
      return "WEEK";
    case Duration.MONTH:
      return "MONTH";
    case Duration.YEAR:
      return "YEAR";
    case Duration.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** FabricType defines an enumeration of fabric types. */
export enum FabricType {
  /** FABRIC_TYPE_UNSPECIFIED - Unspecified fabric type. */
  FABRIC_TYPE_UNSPECIFIED = 0,
  /** INVENTORY - Fabric is derived from inventory switches. */
  INVENTORY = 1,
  /** BLUEPRINT - Fabric is a blueprint fabric with linked switches. */
  BLUEPRINT = 2,
  /** PARKINGLOT - Fabric is a parking lot fabric with unbound switches. */
  PARKINGLOT = 3,
  UNRECOGNIZED = -1,
}

export function fabricTypeFromJSON(object: any): FabricType {
  switch (object) {
    case 0:
    case "FABRIC_TYPE_UNSPECIFIED":
      return FabricType.FABRIC_TYPE_UNSPECIFIED;
    case 1:
    case "INVENTORY":
      return FabricType.INVENTORY;
    case 2:
    case "BLUEPRINT":
      return FabricType.BLUEPRINT;
    case 3:
    case "PARKINGLOT":
      return FabricType.PARKINGLOT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FabricType.UNRECOGNIZED;
  }
}

export function fabricTypeToJSON(object: FabricType): string {
  switch (object) {
    case FabricType.FABRIC_TYPE_UNSPECIFIED:
      return "FABRIC_TYPE_UNSPECIFIED";
    case FabricType.INVENTORY:
      return "INVENTORY";
    case FabricType.BLUEPRINT:
      return "BLUEPRINT";
    case FabricType.PARKINGLOT:
      return "PARKINGLOT";
    case FabricType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * ObjectType defines an enumeration of types of objects.
 *
 * INTERNAL
 *
 * By convention, ObjectType must have exact same string name (in uppercase)
 * as Go type name. For example, Go type Fabric must have an object type of
 * FABRIC.
 */
export enum ObjectType {
  /** OBJECT_UNSPECIFIED - Unspecified object type. */
  OBJECT_UNSPECIFIED = 0,
  /** USER - System user type. */
  USER = 1,
  /** TENANT - Tenant object type. */
  TENANT = 2,
  /** FABRIC - Fabric object type. */
  FABRIC = 3,
  /** NODE - Node object type. */
  NODE = 4,
  /** NETWORK_PORT - Network port type. */
  NETWORK_PORT = 5,
  /** PORT_CONNECTION - Network port connection. */
  PORT_CONNECTION = 6,
  /** VLAN - VLAN object type. */
  VLAN = 7,
  /** SVI - VLAN SVI object type. */
  SVI = 8,
  /** DHCP_RELAY - DHCP relay object type. */
  DHCP_RELAY = 9,
  /** VRF - Virtual routing and forwarding (VRF) type. */
  VRF = 10,
  /** STATIC_ROUTES - Static routes type. */
  STATIC_ROUTES = 11,
  /** PORT_CHANNEL - Port channel object type. */
  PORT_CHANNEL = 12,
  /** FABRIC_TRANSACTION - Fabric transaction type. */
  FABRIC_TRANSACTION = 13,
  /** ORGANIZATION - Organization type. */
  ORGANIZATION = 14,
  /** BEARER_TOKEN - Bearer token type. */
  BEARER_TOKEN = 15,
  /** API_KEY - API key type. */
  API_KEY = 16,
  /** REST_KEY - API public key type. */
  REST_KEY = 17,
  /** VNI - Logical network (VNI) type. */
  VNI = 18,
  /** MANAGEMENT_PORT - Ethernet management port type. */
  MANAGEMENT_PORT = 19,
  /** LOOPBACK - Loopback device type. */
  LOOPBACK = 20,
  /** SUB_INTERFACE - Sub-interface type. */
  SUB_INTERFACE = 21,
  /** PER_VLAN_STP - Per-VLAN spanning tree protocol type. */
  PER_VLAN_STP = 22,
  /** BGP_PEER - BGP peer config object type. */
  BGP_PEER = 23,
  /** PORT_BREAKOUT - Network port breakout config. */
  PORT_BREAKOUT = 24,
  /** BGP_POLICY - BGP peering policy config. */
  BGP_POLICY = 25,
  /** PREFIX_LIST - IP prefix list. */
  PREFIX_LIST = 26,
  /** DEVICE - Device object type. */
  DEVICE = 27,
  /** NOTIFICATION_ENDPOINT - Notification endpoint object type. */
  NOTIFICATION_ENDPOINT = 28,
  /** CERTIFICATES - Certificates object type. */
  CERTIFICATES = 29,
  /** FABRIC_IMAGE_UPDATE - FabricImageUpdate object type. */
  FABRIC_IMAGE_UPDATE = 30,
  /** NODE_IMAGE_PACKAGE - Node image package object type. */
  NODE_IMAGE_PACKAGE = 31,
  /** IMAGE_PACKAGE - Image package object type. */
  IMAGE_PACKAGE = 32,
  /** IPM_CONFIG - Configuration for user-initiated IPM collection. */
  IPM_CONFIG = 33,
  /** NODE_IMAGE_UPDATE - NodeImageUpdate object type. */
  NODE_IMAGE_UPDATE = 34,
  UNRECOGNIZED = -1,
}

export function objectTypeFromJSON(object: any): ObjectType {
  switch (object) {
    case 0:
    case "OBJECT_UNSPECIFIED":
      return ObjectType.OBJECT_UNSPECIFIED;
    case 1:
    case "USER":
      return ObjectType.USER;
    case 2:
    case "TENANT":
      return ObjectType.TENANT;
    case 3:
    case "FABRIC":
      return ObjectType.FABRIC;
    case 4:
    case "NODE":
      return ObjectType.NODE;
    case 5:
    case "NETWORK_PORT":
      return ObjectType.NETWORK_PORT;
    case 6:
    case "PORT_CONNECTION":
      return ObjectType.PORT_CONNECTION;
    case 7:
    case "VLAN":
      return ObjectType.VLAN;
    case 8:
    case "SVI":
      return ObjectType.SVI;
    case 9:
    case "DHCP_RELAY":
      return ObjectType.DHCP_RELAY;
    case 10:
    case "VRF":
      return ObjectType.VRF;
    case 11:
    case "STATIC_ROUTES":
      return ObjectType.STATIC_ROUTES;
    case 12:
    case "PORT_CHANNEL":
      return ObjectType.PORT_CHANNEL;
    case 13:
    case "FABRIC_TRANSACTION":
      return ObjectType.FABRIC_TRANSACTION;
    case 14:
    case "ORGANIZATION":
      return ObjectType.ORGANIZATION;
    case 15:
    case "BEARER_TOKEN":
      return ObjectType.BEARER_TOKEN;
    case 16:
    case "API_KEY":
      return ObjectType.API_KEY;
    case 17:
    case "REST_KEY":
      return ObjectType.REST_KEY;
    case 18:
    case "VNI":
      return ObjectType.VNI;
    case 19:
    case "MANAGEMENT_PORT":
      return ObjectType.MANAGEMENT_PORT;
    case 20:
    case "LOOPBACK":
      return ObjectType.LOOPBACK;
    case 21:
    case "SUB_INTERFACE":
      return ObjectType.SUB_INTERFACE;
    case 22:
    case "PER_VLAN_STP":
      return ObjectType.PER_VLAN_STP;
    case 23:
    case "BGP_PEER":
      return ObjectType.BGP_PEER;
    case 24:
    case "PORT_BREAKOUT":
      return ObjectType.PORT_BREAKOUT;
    case 25:
    case "BGP_POLICY":
      return ObjectType.BGP_POLICY;
    case 26:
    case "PREFIX_LIST":
      return ObjectType.PREFIX_LIST;
    case 27:
    case "DEVICE":
      return ObjectType.DEVICE;
    case 28:
    case "NOTIFICATION_ENDPOINT":
      return ObjectType.NOTIFICATION_ENDPOINT;
    case 29:
    case "CERTIFICATES":
      return ObjectType.CERTIFICATES;
    case 30:
    case "FABRIC_IMAGE_UPDATE":
      return ObjectType.FABRIC_IMAGE_UPDATE;
    case 31:
    case "NODE_IMAGE_PACKAGE":
      return ObjectType.NODE_IMAGE_PACKAGE;
    case 32:
    case "IMAGE_PACKAGE":
      return ObjectType.IMAGE_PACKAGE;
    case 33:
    case "IPM_CONFIG":
      return ObjectType.IPM_CONFIG;
    case 34:
    case "NODE_IMAGE_UPDATE":
      return ObjectType.NODE_IMAGE_UPDATE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ObjectType.UNRECOGNIZED;
  }
}

export function objectTypeToJSON(object: ObjectType): string {
  switch (object) {
    case ObjectType.OBJECT_UNSPECIFIED:
      return "OBJECT_UNSPECIFIED";
    case ObjectType.USER:
      return "USER";
    case ObjectType.TENANT:
      return "TENANT";
    case ObjectType.FABRIC:
      return "FABRIC";
    case ObjectType.NODE:
      return "NODE";
    case ObjectType.NETWORK_PORT:
      return "NETWORK_PORT";
    case ObjectType.PORT_CONNECTION:
      return "PORT_CONNECTION";
    case ObjectType.VLAN:
      return "VLAN";
    case ObjectType.SVI:
      return "SVI";
    case ObjectType.DHCP_RELAY:
      return "DHCP_RELAY";
    case ObjectType.VRF:
      return "VRF";
    case ObjectType.STATIC_ROUTES:
      return "STATIC_ROUTES";
    case ObjectType.PORT_CHANNEL:
      return "PORT_CHANNEL";
    case ObjectType.FABRIC_TRANSACTION:
      return "FABRIC_TRANSACTION";
    case ObjectType.ORGANIZATION:
      return "ORGANIZATION";
    case ObjectType.BEARER_TOKEN:
      return "BEARER_TOKEN";
    case ObjectType.API_KEY:
      return "API_KEY";
    case ObjectType.REST_KEY:
      return "REST_KEY";
    case ObjectType.VNI:
      return "VNI";
    case ObjectType.MANAGEMENT_PORT:
      return "MANAGEMENT_PORT";
    case ObjectType.LOOPBACK:
      return "LOOPBACK";
    case ObjectType.SUB_INTERFACE:
      return "SUB_INTERFACE";
    case ObjectType.PER_VLAN_STP:
      return "PER_VLAN_STP";
    case ObjectType.BGP_PEER:
      return "BGP_PEER";
    case ObjectType.PORT_BREAKOUT:
      return "PORT_BREAKOUT";
    case ObjectType.BGP_POLICY:
      return "BGP_POLICY";
    case ObjectType.PREFIX_LIST:
      return "PREFIX_LIST";
    case ObjectType.DEVICE:
      return "DEVICE";
    case ObjectType.NOTIFICATION_ENDPOINT:
      return "NOTIFICATION_ENDPOINT";
    case ObjectType.CERTIFICATES:
      return "CERTIFICATES";
    case ObjectType.FABRIC_IMAGE_UPDATE:
      return "FABRIC_IMAGE_UPDATE";
    case ObjectType.NODE_IMAGE_PACKAGE:
      return "NODE_IMAGE_PACKAGE";
    case ObjectType.IMAGE_PACKAGE:
      return "IMAGE_PACKAGE";
    case ObjectType.IPM_CONFIG:
      return "IPM_CONFIG";
    case ObjectType.NODE_IMAGE_UPDATE:
      return "NODE_IMAGE_UPDATE";
    case ObjectType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** OperState defines an enumeration for object's operational states. */
export enum OperState {
  /** OP_STATE_UNSPECIFIED - Invalid operational state. */
  OP_STATE_UNSPECIFIED = 0,
  /** UP - Object's operational state is active. */
  UP = 1,
  /** DOWN - Object's operational state is inactive. */
  DOWN = 2,
  UNRECOGNIZED = -1,
}

export function operStateFromJSON(object: any): OperState {
  switch (object) {
    case 0:
    case "OP_STATE_UNSPECIFIED":
      return OperState.OP_STATE_UNSPECIFIED;
    case 1:
    case "UP":
      return OperState.UP;
    case 2:
    case "DOWN":
      return OperState.DOWN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OperState.UNRECOGNIZED;
  }
}

export function operStateToJSON(object: OperState): string {
  switch (object) {
    case OperState.OP_STATE_UNSPECIFIED:
      return "OP_STATE_UNSPECIFIED";
    case OperState.UP:
      return "UP";
    case OperState.DOWN:
      return "DOWN";
    case OperState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Operation defines an enumeration of the types of operations or actions. */
export enum Operation {
  /** OPERATION_UNSPECIFIED - Unspecified operation type. */
  OPERATION_UNSPECIFIED = 0,
  /** CREATE - An object has been added. */
  CREATE = 1,
  /** UPDATE - An object has been updated. */
  UPDATE = 2,
  /** DELETE - An object has been deleted. */
  DELETE = 3,
  /** ENABLE - An object has been enabled. */
  ENABLE = 4,
  /** DISABLE - An object has been disabled. */
  DISABLE = 5,
  /** LOGIN - A user has logged in. */
  LOGIN = 6,
  /** LOGOUT - A user has logged out. */
  LOGOUT = 7,
  /** COMMIT - A transaction has been committed. */
  COMMIT = 8,
  /** REVERT - A transaction has been reverted. */
  REVERT = 9,
  /** BIND - A device has been bound to a blueprint node. */
  BIND = 10,
  /** UNBIND - A switch has been unbound from a blueprint node. */
  UNBIND = 11,
  /** COMMENT - User has added a comment. */
  COMMENT = 12,
  UNRECOGNIZED = -1,
}

export function operationFromJSON(object: any): Operation {
  switch (object) {
    case 0:
    case "OPERATION_UNSPECIFIED":
      return Operation.OPERATION_UNSPECIFIED;
    case 1:
    case "CREATE":
      return Operation.CREATE;
    case 2:
    case "UPDATE":
      return Operation.UPDATE;
    case 3:
    case "DELETE":
      return Operation.DELETE;
    case 4:
    case "ENABLE":
      return Operation.ENABLE;
    case 5:
    case "DISABLE":
      return Operation.DISABLE;
    case 6:
    case "LOGIN":
      return Operation.LOGIN;
    case 7:
    case "LOGOUT":
      return Operation.LOGOUT;
    case 8:
    case "COMMIT":
      return Operation.COMMIT;
    case 9:
    case "REVERT":
      return Operation.REVERT;
    case 10:
    case "BIND":
      return Operation.BIND;
    case 11:
    case "UNBIND":
      return Operation.UNBIND;
    case 12:
    case "COMMENT":
      return Operation.COMMENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Operation.UNRECOGNIZED;
  }
}

export function operationToJSON(object: Operation): string {
  switch (object) {
    case Operation.OPERATION_UNSPECIFIED:
      return "OPERATION_UNSPECIFIED";
    case Operation.CREATE:
      return "CREATE";
    case Operation.UPDATE:
      return "UPDATE";
    case Operation.DELETE:
      return "DELETE";
    case Operation.ENABLE:
      return "ENABLE";
    case Operation.DISABLE:
      return "DISABLE";
    case Operation.LOGIN:
      return "LOGIN";
    case Operation.LOGOUT:
      return "LOGOUT";
    case Operation.COMMIT:
      return "COMMIT";
    case Operation.REVERT:
      return "REVERT";
    case Operation.BIND:
      return "BIND";
    case Operation.UNBIND:
      return "UNBIND";
    case Operation.COMMENT:
      return "COMMENT";
    case Operation.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** OrgKind lists various organization types. */
export enum OrgKind {
  /** ORG_KIND_UNSPECIFIED - Unspecified organization kind. */
  ORG_KIND_UNSPECIFIED = 0,
  /** REGULAR - Regular organization. */
  REGULAR = 1,
  /** PARKING_LOT - Parking lot organization. */
  PARKING_LOT = 2,
  UNRECOGNIZED = -1,
}

export function orgKindFromJSON(object: any): OrgKind {
  switch (object) {
    case 0:
    case "ORG_KIND_UNSPECIFIED":
      return OrgKind.ORG_KIND_UNSPECIFIED;
    case 1:
    case "REGULAR":
      return OrgKind.REGULAR;
    case 2:
    case "PARKING_LOT":
      return OrgKind.PARKING_LOT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OrgKind.UNRECOGNIZED;
  }
}

export function orgKindToJSON(object: OrgKind): string {
  switch (object) {
    case OrgKind.ORG_KIND_UNSPECIFIED:
      return "ORG_KIND_UNSPECIFIED";
    case OrgKind.REGULAR:
      return "REGULAR";
    case OrgKind.PARKING_LOT:
      return "PARKING_LOT";
    case OrgKind.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** OrgFlagRelease defines the release target selection scope of an org */
export enum OrgflagRelease {
  /** ORGFLAG_RELEASE_UNSPECIFIED - OrgFlagRelease flag unspecified. */
  ORGFLAG_RELEASE_UNSPECIFIED = 0,
  /**
   * ORGFLAG_RELEASE - 1. anything the org is running
   * 2. latest prod-blessed image for the cell pair (does nto include non-blessed)
   * 3. anything the org was running in the last two weeks (make the lookback a configd config int var in days)
   */
  ORGFLAG_RELEASE = 1,
  /** ORGFLAG_ALL - Org may select anything that the cell is aware of. */
  ORGFLAG_ALL = 2,
  /** ORGFLAG_PRERELEASE - Org may select PRERELEASE images. */
  ORGFLAG_PRERELEASE = 3,
  UNRECOGNIZED = -1,
}

export function orgflagReleaseFromJSON(object: any): OrgflagRelease {
  switch (object) {
    case 0:
    case "ORGFLAG_RELEASE_UNSPECIFIED":
      return OrgflagRelease.ORGFLAG_RELEASE_UNSPECIFIED;
    case 1:
    case "ORGFLAG_RELEASE":
      return OrgflagRelease.ORGFLAG_RELEASE;
    case 2:
    case "ORGFLAG_ALL":
      return OrgflagRelease.ORGFLAG_ALL;
    case 3:
    case "ORGFLAG_PRERELEASE":
      return OrgflagRelease.ORGFLAG_PRERELEASE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OrgflagRelease.UNRECOGNIZED;
  }
}

export function orgflagReleaseToJSON(object: OrgflagRelease): string {
  switch (object) {
    case OrgflagRelease.ORGFLAG_RELEASE_UNSPECIFIED:
      return "ORGFLAG_RELEASE_UNSPECIFIED";
    case OrgflagRelease.ORGFLAG_RELEASE:
      return "ORGFLAG_RELEASE";
    case OrgflagRelease.ORGFLAG_ALL:
      return "ORGFLAG_ALL";
    case OrgflagRelease.ORGFLAG_PRERELEASE:
      return "ORGFLAG_PRERELEASE";
    case OrgflagRelease.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** PackageName defines an enumeration of the names of the image packages */
export enum PackageName {
  /** PACKAGE_NAME_UNSPECIFIED - Unspecified package name */
  PACKAGE_NAME_UNSPECIFIED = 0,
  /** AGENTS - The agent package */
  AGENTS = 1,
  /** SWSS - The sonic image name */
  SWSS = 2,
  /** BOOTLOADER - The bootloader package */
  BOOTLOADER = 3,
  /** BOOTLOADERCFG - The bootloadercfg package */
  BOOTLOADERCFG = 4,
  /** KERNEL - The kernel package */
  KERNEL = 5,
  /** MONOLITH - The monolith package */
  MONOLITH = 6,
  /** DOCKERFS - The dockerfs package */
  DOCKERFS = 7,
  /** INITRD - The initrd package */
  INITRD = 8,
  UNRECOGNIZED = -1,
}

export function packageNameFromJSON(object: any): PackageName {
  switch (object) {
    case 0:
    case "PACKAGE_NAME_UNSPECIFIED":
      return PackageName.PACKAGE_NAME_UNSPECIFIED;
    case 1:
    case "AGENTS":
      return PackageName.AGENTS;
    case 2:
    case "SWSS":
      return PackageName.SWSS;
    case 3:
    case "BOOTLOADER":
      return PackageName.BOOTLOADER;
    case 4:
    case "BOOTLOADERCFG":
      return PackageName.BOOTLOADERCFG;
    case 5:
    case "KERNEL":
      return PackageName.KERNEL;
    case 6:
    case "MONOLITH":
      return PackageName.MONOLITH;
    case 7:
    case "DOCKERFS":
      return PackageName.DOCKERFS;
    case 8:
    case "INITRD":
      return PackageName.INITRD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PackageName.UNRECOGNIZED;
  }
}

export function packageNameToJSON(object: PackageName): string {
  switch (object) {
    case PackageName.PACKAGE_NAME_UNSPECIFIED:
      return "PACKAGE_NAME_UNSPECIFIED";
    case PackageName.AGENTS:
      return "AGENTS";
    case PackageName.SWSS:
      return "SWSS";
    case PackageName.BOOTLOADER:
      return "BOOTLOADER";
    case PackageName.BOOTLOADERCFG:
      return "BOOTLOADERCFG";
    case PackageName.KERNEL:
      return "KERNEL";
    case PackageName.MONOLITH:
      return "MONOLITH";
    case PackageName.DOCKERFS:
      return "DOCKERFS";
    case PackageName.INITRD:
      return "INITRD";
    case PackageName.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** ReleaseType defines an enumeration of image release types */
export enum ReleaseType {
  /** RELEASE_TYPE_UNSPECIFIED - Unspecified image release type */
  RELEASE_TYPE_UNSPECIFIED = 0,
  /** RELEASE - A released image */
  RELEASE = 1,
  /** PRERELEASE - A pre-release image (only for _ALL or _PRERELEASE orgs) */
  PRERELEASE = 2,
  /** TEST_IMAGE - A test image (only for _ALL orgs) */
  TEST_IMAGE = 3,
  /** DEV_IMAGE - A dev image (only for _ALL orgs) */
  DEV_IMAGE = 4,
  UNRECOGNIZED = -1,
}

export function releaseTypeFromJSON(object: any): ReleaseType {
  switch (object) {
    case 0:
    case "RELEASE_TYPE_UNSPECIFIED":
      return ReleaseType.RELEASE_TYPE_UNSPECIFIED;
    case 1:
    case "RELEASE":
      return ReleaseType.RELEASE;
    case 2:
    case "PRERELEASE":
      return ReleaseType.PRERELEASE;
    case 3:
    case "TEST_IMAGE":
      return ReleaseType.TEST_IMAGE;
    case 4:
    case "DEV_IMAGE":
      return ReleaseType.DEV_IMAGE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ReleaseType.UNRECOGNIZED;
  }
}

export function releaseTypeToJSON(object: ReleaseType): string {
  switch (object) {
    case ReleaseType.RELEASE_TYPE_UNSPECIFIED:
      return "RELEASE_TYPE_UNSPECIFIED";
    case ReleaseType.RELEASE:
      return "RELEASE";
    case ReleaseType.PRERELEASE:
      return "PRERELEASE";
    case ReleaseType.TEST_IMAGE:
      return "TEST_IMAGE";
    case ReleaseType.DEV_IMAGE:
      return "DEV_IMAGE";
    case ReleaseType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** TaskType defines an enumeration of various workflow tasks. */
export enum TaskType {
  /** TASK_TYPE_UNSPECIFIED - Unspecified task type. */
  TASK_TYPE_UNSPECIFIED = 0,
  /** TASK_TESTING - Tasks used for testing and verifying workflows. */
  TASK_TESTING = 1,
  /** TASK_SONIC - Workflow tasks for SONiC. */
  TASK_SONIC = 2,
  UNRECOGNIZED = -1,
}

export function taskTypeFromJSON(object: any): TaskType {
  switch (object) {
    case 0:
    case "TASK_TYPE_UNSPECIFIED":
      return TaskType.TASK_TYPE_UNSPECIFIED;
    case 1:
    case "TASK_TESTING":
      return TaskType.TASK_TESTING;
    case 2:
    case "TASK_SONIC":
      return TaskType.TASK_SONIC;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TaskType.UNRECOGNIZED;
  }
}

export function taskTypeToJSON(object: TaskType): string {
  switch (object) {
    case TaskType.TASK_TYPE_UNSPECIFIED:
      return "TASK_TYPE_UNSPECIFIED";
    case TaskType.TASK_TESTING:
      return "TASK_TESTING";
    case TaskType.TASK_SONIC:
      return "TASK_SONIC";
    case TaskType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Annotation defines a typed name/value pair to store user-defined data
 * including complex data such as JSON.
 */
export interface Annotation {
  /** The user-defined name used to uniquely identify the annotation. */
  name: string;
  /** The value of the annotation expressed as a string. */
  value: string;
  /** The type of data stored in the value of the annotation. */
  dataType: DataType;
}

/** Claim result defines the status of claim attempt on a specific device ID using a specific claim code. */
export interface ClaimResult {
  /** DeviceId defines MAC address of the device. */
  deviceId: string;
  /** ClaimCode defines the claim code of the device. */
  claimCode: string;
  /** ClaimStatus defines the claim status of the device */
  claimStatus: ClaimStatus;
}

function createBaseAnnotation(): Annotation {
  return { name: "", value: "", dataType: 0 };
}

export const Annotation = {
  encode(message: Annotation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    if (message.dataType !== 0) {
      writer.uint32(24).int32(message.dataType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Annotation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAnnotation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.dataType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Annotation {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
      dataType: isSet(object.dataType) ? dataTypeFromJSON(object.dataType) : 0,
    };
  },

  toJSON(message: Annotation): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    if (message.dataType !== 0) {
      obj.dataType = dataTypeToJSON(message.dataType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Annotation>, I>>(base?: I): Annotation {
    return Annotation.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Annotation>, I>>(object: I): Annotation {
    const message = createBaseAnnotation();
    message.name = object.name ?? "";
    message.value = object.value ?? "";
    message.dataType = object.dataType ?? 0;
    return message;
  },
};

function createBaseClaimResult(): ClaimResult {
  return { deviceId: "", claimCode: "", claimStatus: 0 };
}

export const ClaimResult = {
  encode(message: ClaimResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deviceId !== "") {
      writer.uint32(10).string(message.deviceId);
    }
    if (message.claimCode !== "") {
      writer.uint32(18).string(message.claimCode);
    }
    if (message.claimStatus !== 0) {
      writer.uint32(24).int32(message.claimStatus);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClaimResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClaimResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.claimCode = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.claimStatus = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClaimResult {
    return {
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      claimCode: isSet(object.claimCode) ? globalThis.String(object.claimCode) : "",
      claimStatus: isSet(object.claimStatus) ? claimStatusFromJSON(object.claimStatus) : 0,
    };
  },

  toJSON(message: ClaimResult): unknown {
    const obj: any = {};
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.claimCode !== "") {
      obj.claimCode = message.claimCode;
    }
    if (message.claimStatus !== 0) {
      obj.claimStatus = claimStatusToJSON(message.claimStatus);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ClaimResult>, I>>(base?: I): ClaimResult {
    return ClaimResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ClaimResult>, I>>(object: I): ClaimResult {
    const message = createBaseClaimResult();
    message.deviceId = object.deviceId ?? "";
    message.claimCode = object.claimCode ?? "";
    message.claimStatus = object.claimStatus ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
