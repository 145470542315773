// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: configd/rest_networks.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { DhcpRelay, Loopback, StaticRoutes, SubInterface, Vni, Vrf } from "../models/models";
import { VlanMember } from "../models/types";

export const protobufPackage = "configd";

/** The request for the list of VNIs in a specific fabric. */
export interface GetFabricVnisRequest {
  /** The fabric id or name. */
  fabricId: string;
  /**
   * The candidate configuration name. If not set the default candidate configuration values
   * are returned.
   */
  candidate: string;
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

/** The request for a specific VNI in a fabric. */
export interface GetFabricVniRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The VNI id or name. */
  vniId: string;
  /**
   * The candidate configuration name. If not set the default candidate configuration values
   * are returned.
   */
  candidate: string;
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

/** The request to add one or more VNIs to a specific fabric. */
export interface AddFabricVnisRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** A list of VNIs to be added to the fabric. */
  vnis: Vni[];
}

/** The request to update a specific VNI in a fabric. */
export interface UpdateFabricVniRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The VNI id or name. */
  vniId: string;
  /** The updated VNI definition. */
  vni: Vni | undefined;
}

/** The request to delete a specific VNI in a fabric. */
export interface DeleteFabricVniRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The VNI id or name. */
  vniId: string;
}

/** The response returned to a request for a list of VNIs in a fabric. */
export interface FabricVnisResponse {
  /** A list of VNIs in a fabric. */
  vnis: Vni[];
}

/** The response returned to a request for a list of members of a VNI in a fabric. */
export interface FabricVniMembersResponse {
  /** A list of members of a VNI. */
  members: VlanMember[];
}

/** The request for the list of members of a specific VNI in a fabric. */
export interface GetFabricVniMembersRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The VNI id or name. */
  vniId: string;
  /**
   * The candidate configuration name. If not set the default candidate configuration values
   * are returned.
   */
  candidate: string;
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

/** The request for a specific member of a VNI in a fabric. */
export interface GetFabricVniMemberRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The VNI id or name. */
  vniId: string;
  /** The member id or name. */
  memberId: string;
  /**
   * The candidate configuration name. If not set the default candidate configuration values
   * are returned.
   */
  candidate: string;
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

/** The request to add one or more members to a specific VNI of a fabric. */
export interface AddFabricVniMembersRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The VNI id or name. */
  vniId: string;
  /** A list of members to be added to the VNI. */
  members: VlanMember[];
}

/** The request to delete a specific member of a VNI in a fabric. */
export interface DeleteFabricVniMemberRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The VNI id or name. */
  vniId: string;
  /** The member id or name. */
  memberId: string;
}

/** The request for the list of VRFs in a specific fabric. */
export interface GetFabricVrfsRequest {
  /** The fabric id or name. */
  fabricId: string;
  /**
   * The candidate configuration name. If not set the default candidate configuration values
   * are returned.
   */
  candidate: string;
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

/** The request for a specific VNI in a fabric. */
export interface GetFabricVrfRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The VRF id or name. */
  vrfId: string;
  /** The candidate configuration name. If not set the default candidate configuration values are returned. */
  candidate: string;
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

/** The request to add one or more VRFs to a specific fabric. */
export interface AddFabricVrfsRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** A list of VRFs to be added to the fabric. */
  vrfs: Vrf[];
}

/** The request to update a specific VRF in a fabric. */
export interface UpdateFabricVrfRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The VRF id or name. */
  vrfId: string;
  /** The updated VRF definition. */
  vrf: Vrf | undefined;
}

/** The request to delete a specific VRF in a fabric. */
export interface DeleteFabricVrfRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The VRF id or name. */
  vrfId: string;
}

/** The response returned to a request for a list of VRFs in a fabric. */
export interface FabricVrfsResponse {
  /** A list of VRFs in a fabric. */
  vrfs: Vrf[];
}

/** The request for the list of static routes in a specific VRF in a fabric. */
export interface GetFabricStaticRoutesRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The vrf id or name. */
  vrfId: string;
  /**
   * The candidate configuration name. If not set the default candidate configuration values
   * are returned.
   */
  candidate: string;
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

/** The request for a specific static route in a VRF in a fabric. */
export interface GetFabricStaticRouteRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The vrf id or name. */
  vrfId: string;
  /** The route id or name. */
  routeId: string;
  /**
   * The candidate configuration name. If not set the default candidate configuration values
   * are returned.
   */
  candidate: string;
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

/** The request to add one or more static routes to a specific VRF in a fabric. */
export interface AddFabricStaticRoutesRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The vrf id or name. */
  vrfId: string;
  /** A list of static routes to be added to the VRF. */
  staticRoutes: StaticRoutes[];
}

/** The request to delete a specific static route of a VRF in a fabric. */
export interface DeleteFabricStaticRouteRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The vrf id or name. */
  vrfId: string;
  /** The route id or name. */
  routeId: string;
}

/** The request to update a specific static route of a VRF in a fabric. */
export interface UpdateFabricStaticRouteRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The vrf id. */
  vrfId: string;
  /** The route id or name. */
  routeId: string;
  /** The updated static routes definition. */
  staticRoute: StaticRoutes | undefined;
}

/**
 * The response returned to a request for the list of static routes of a specific VRF
 * in a fabric.
 */
export interface FabricStaticRoutesResponse {
  /** A list of static routes definitions. */
  staticRoutes: StaticRoutes[];
}

export interface GetFabricDhcpRelaysRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The candidate configuration name. If not set the running configuration values are returned. */
  candidate: string;
}

export interface FabricDhcpRelaysResponse {
  relays: DhcpRelay[];
}

export interface GetFabricLoopbacksRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The candidate configuration name. If not set the running configuration values are returned. */
  candidate: string;
}

export interface FabricLoopbacksResponse {
  loopbacks: Loopback[];
}

export interface GetNodeLoopbacksRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The node id or name. */
  nodeId: string;
  /** The candidate configuration name. If not set the running configuration values are returned. */
  candidate: string;
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

export interface NodeLoopbacksResponse {
  /** A list of loopbacks */
  loopbacks: Loopback[];
}

export interface GetNodeLoopbackRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The node id or name. */
  nodeId: string;
  /** The loopback id or name. */
  loopbackId: string;
  /** The candidate configuration name. If not set the running configuration values are returned. */
  candidate: string;
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

export interface AddNodeLoopbacksRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The node id or name. */
  nodeId: string;
  /** The loopbacks for the fabric. */
  loopbacks: Loopback[];
}

export interface UpdateNodeLoopbackRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The node id or name. */
  nodeId: string;
  /** The loopback id or name. */
  loopbackId: string;
  /** The updated loopback definition */
  loopback: Loopback | undefined;
}

export interface DeleteNodeLoopbackRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The node id or name. */
  nodeId: string;
  /** The loopback id or name. */
  loopbackId: string;
}

export interface GetNodeSubInterfacesRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The node id or name. */
  nodeId: string;
  /** The candidate configuration name. If not set the running configuration values are returned. */
  candidate: string;
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

export interface NodeSubInterfacesResponse {
  /** A list of sub-interfaces */
  subInterfaces: SubInterface[];
}

export interface GetNodeSubInterfaceRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The node id or name. */
  nodeId: string;
  /** The sub-interface id or name. */
  subInterfaceId: string;
  /** The candidate configuration name. If not set the running configuration values are returned. */
  candidate: string;
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

export interface AddNodeSubInterfacesRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The node id or name. */
  nodeId: string;
  /** The sub-interfaces for the fabric. */
  subInterfaces: SubInterface[];
}

export interface UpdateNodeSubInterfaceRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The node id or name. */
  nodeId: string;
  /** The sub-interface id or name. */
  subInterfaceId: string;
  /** The updated sub-interface definition */
  subInterface: SubInterface | undefined;
}

export interface DeleteNodeSubInterfaceRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The node id or name. */
  nodeId: string;
  /** The sub-interface id or name. */
  subInterfaceId: string;
}

function createBaseGetFabricVnisRequest(): GetFabricVnisRequest {
  return { fabricId: "", candidate: "", includeMetadata: false };
}

export const GetFabricVnisRequest = {
  encode(message: GetFabricVnisRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.candidate !== "") {
      writer.uint32(18).string(message.candidate);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(32).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricVnisRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricVnisRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricVnisRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetFabricVnisRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricVnisRequest>, I>>(base?: I): GetFabricVnisRequest {
    return GetFabricVnisRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricVnisRequest>, I>>(object: I): GetFabricVnisRequest {
    const message = createBaseGetFabricVnisRequest();
    message.fabricId = object.fabricId ?? "";
    message.candidate = object.candidate ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseGetFabricVniRequest(): GetFabricVniRequest {
  return { fabricId: "", vniId: "", candidate: "", includeMetadata: false };
}

export const GetFabricVniRequest = {
  encode(message: GetFabricVniRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.vniId !== "") {
      writer.uint32(18).string(message.vniId);
    }
    if (message.candidate !== "") {
      writer.uint32(26).string(message.candidate);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(32).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricVniRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricVniRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vniId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricVniRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      vniId: isSet(object.vniId) ? globalThis.String(object.vniId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetFabricVniRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.vniId !== "") {
      obj.vniId = message.vniId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricVniRequest>, I>>(base?: I): GetFabricVniRequest {
    return GetFabricVniRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricVniRequest>, I>>(object: I): GetFabricVniRequest {
    const message = createBaseGetFabricVniRequest();
    message.fabricId = object.fabricId ?? "";
    message.vniId = object.vniId ?? "";
    message.candidate = object.candidate ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseAddFabricVnisRequest(): AddFabricVnisRequest {
  return { fabricId: "", vnis: [] };
}

export const AddFabricVnisRequest = {
  encode(message: AddFabricVnisRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    for (const v of message.vnis) {
      Vni.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddFabricVnisRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddFabricVnisRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vnis.push(Vni.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddFabricVnisRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      vnis: globalThis.Array.isArray(object?.vnis) ? object.vnis.map((e: any) => Vni.fromJSON(e)) : [],
    };
  },

  toJSON(message: AddFabricVnisRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.vnis?.length) {
      obj.vnis = message.vnis.map((e) => Vni.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddFabricVnisRequest>, I>>(base?: I): AddFabricVnisRequest {
    return AddFabricVnisRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddFabricVnisRequest>, I>>(object: I): AddFabricVnisRequest {
    const message = createBaseAddFabricVnisRequest();
    message.fabricId = object.fabricId ?? "";
    message.vnis = object.vnis?.map((e) => Vni.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateFabricVniRequest(): UpdateFabricVniRequest {
  return { fabricId: "", vniId: "", vni: undefined };
}

export const UpdateFabricVniRequest = {
  encode(message: UpdateFabricVniRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.vniId !== "") {
      writer.uint32(18).string(message.vniId);
    }
    if (message.vni !== undefined) {
      Vni.encode(message.vni, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateFabricVniRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateFabricVniRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vniId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.vni = Vni.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateFabricVniRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      vniId: isSet(object.vniId) ? globalThis.String(object.vniId) : "",
      vni: isSet(object.vni) ? Vni.fromJSON(object.vni) : undefined,
    };
  },

  toJSON(message: UpdateFabricVniRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.vniId !== "") {
      obj.vniId = message.vniId;
    }
    if (message.vni !== undefined) {
      obj.vni = Vni.toJSON(message.vni);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateFabricVniRequest>, I>>(base?: I): UpdateFabricVniRequest {
    return UpdateFabricVniRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateFabricVniRequest>, I>>(object: I): UpdateFabricVniRequest {
    const message = createBaseUpdateFabricVniRequest();
    message.fabricId = object.fabricId ?? "";
    message.vniId = object.vniId ?? "";
    message.vni = (object.vni !== undefined && object.vni !== null) ? Vni.fromPartial(object.vni) : undefined;
    return message;
  },
};

function createBaseDeleteFabricVniRequest(): DeleteFabricVniRequest {
  return { fabricId: "", vniId: "" };
}

export const DeleteFabricVniRequest = {
  encode(message: DeleteFabricVniRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.vniId !== "") {
      writer.uint32(18).string(message.vniId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteFabricVniRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteFabricVniRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vniId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteFabricVniRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      vniId: isSet(object.vniId) ? globalThis.String(object.vniId) : "",
    };
  },

  toJSON(message: DeleteFabricVniRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.vniId !== "") {
      obj.vniId = message.vniId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteFabricVniRequest>, I>>(base?: I): DeleteFabricVniRequest {
    return DeleteFabricVniRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteFabricVniRequest>, I>>(object: I): DeleteFabricVniRequest {
    const message = createBaseDeleteFabricVniRequest();
    message.fabricId = object.fabricId ?? "";
    message.vniId = object.vniId ?? "";
    return message;
  },
};

function createBaseFabricVnisResponse(): FabricVnisResponse {
  return { vnis: [] };
}

export const FabricVnisResponse = {
  encode(message: FabricVnisResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.vnis) {
      Vni.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FabricVnisResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFabricVnisResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.vnis.push(Vni.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FabricVnisResponse {
    return { vnis: globalThis.Array.isArray(object?.vnis) ? object.vnis.map((e: any) => Vni.fromJSON(e)) : [] };
  },

  toJSON(message: FabricVnisResponse): unknown {
    const obj: any = {};
    if (message.vnis?.length) {
      obj.vnis = message.vnis.map((e) => Vni.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FabricVnisResponse>, I>>(base?: I): FabricVnisResponse {
    return FabricVnisResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FabricVnisResponse>, I>>(object: I): FabricVnisResponse {
    const message = createBaseFabricVnisResponse();
    message.vnis = object.vnis?.map((e) => Vni.fromPartial(e)) || [];
    return message;
  },
};

function createBaseFabricVniMembersResponse(): FabricVniMembersResponse {
  return { members: [] };
}

export const FabricVniMembersResponse = {
  encode(message: FabricVniMembersResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.members) {
      VlanMember.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FabricVniMembersResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFabricVniMembersResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.members.push(VlanMember.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FabricVniMembersResponse {
    return {
      members: globalThis.Array.isArray(object?.members) ? object.members.map((e: any) => VlanMember.fromJSON(e)) : [],
    };
  },

  toJSON(message: FabricVniMembersResponse): unknown {
    const obj: any = {};
    if (message.members?.length) {
      obj.members = message.members.map((e) => VlanMember.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FabricVniMembersResponse>, I>>(base?: I): FabricVniMembersResponse {
    return FabricVniMembersResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FabricVniMembersResponse>, I>>(object: I): FabricVniMembersResponse {
    const message = createBaseFabricVniMembersResponse();
    message.members = object.members?.map((e) => VlanMember.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetFabricVniMembersRequest(): GetFabricVniMembersRequest {
  return { fabricId: "", vniId: "", candidate: "", includeMetadata: false };
}

export const GetFabricVniMembersRequest = {
  encode(message: GetFabricVniMembersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.vniId !== "") {
      writer.uint32(18).string(message.vniId);
    }
    if (message.candidate !== "") {
      writer.uint32(26).string(message.candidate);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(32).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricVniMembersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricVniMembersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vniId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricVniMembersRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      vniId: isSet(object.vniId) ? globalThis.String(object.vniId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetFabricVniMembersRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.vniId !== "") {
      obj.vniId = message.vniId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricVniMembersRequest>, I>>(base?: I): GetFabricVniMembersRequest {
    return GetFabricVniMembersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricVniMembersRequest>, I>>(object: I): GetFabricVniMembersRequest {
    const message = createBaseGetFabricVniMembersRequest();
    message.fabricId = object.fabricId ?? "";
    message.vniId = object.vniId ?? "";
    message.candidate = object.candidate ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseGetFabricVniMemberRequest(): GetFabricVniMemberRequest {
  return { fabricId: "", vniId: "", memberId: "", candidate: "", includeMetadata: false };
}

export const GetFabricVniMemberRequest = {
  encode(message: GetFabricVniMemberRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.vniId !== "") {
      writer.uint32(18).string(message.vniId);
    }
    if (message.memberId !== "") {
      writer.uint32(26).string(message.memberId);
    }
    if (message.candidate !== "") {
      writer.uint32(34).string(message.candidate);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(40).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricVniMemberRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricVniMemberRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vniId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.memberId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricVniMemberRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      vniId: isSet(object.vniId) ? globalThis.String(object.vniId) : "",
      memberId: isSet(object.memberId) ? globalThis.String(object.memberId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetFabricVniMemberRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.vniId !== "") {
      obj.vniId = message.vniId;
    }
    if (message.memberId !== "") {
      obj.memberId = message.memberId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricVniMemberRequest>, I>>(base?: I): GetFabricVniMemberRequest {
    return GetFabricVniMemberRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricVniMemberRequest>, I>>(object: I): GetFabricVniMemberRequest {
    const message = createBaseGetFabricVniMemberRequest();
    message.fabricId = object.fabricId ?? "";
    message.vniId = object.vniId ?? "";
    message.memberId = object.memberId ?? "";
    message.candidate = object.candidate ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseAddFabricVniMembersRequest(): AddFabricVniMembersRequest {
  return { fabricId: "", vniId: "", members: [] };
}

export const AddFabricVniMembersRequest = {
  encode(message: AddFabricVniMembersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.vniId !== "") {
      writer.uint32(18).string(message.vniId);
    }
    for (const v of message.members) {
      VlanMember.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddFabricVniMembersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddFabricVniMembersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vniId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.members.push(VlanMember.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddFabricVniMembersRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      vniId: isSet(object.vniId) ? globalThis.String(object.vniId) : "",
      members: globalThis.Array.isArray(object?.members) ? object.members.map((e: any) => VlanMember.fromJSON(e)) : [],
    };
  },

  toJSON(message: AddFabricVniMembersRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.vniId !== "") {
      obj.vniId = message.vniId;
    }
    if (message.members?.length) {
      obj.members = message.members.map((e) => VlanMember.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddFabricVniMembersRequest>, I>>(base?: I): AddFabricVniMembersRequest {
    return AddFabricVniMembersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddFabricVniMembersRequest>, I>>(object: I): AddFabricVniMembersRequest {
    const message = createBaseAddFabricVniMembersRequest();
    message.fabricId = object.fabricId ?? "";
    message.vniId = object.vniId ?? "";
    message.members = object.members?.map((e) => VlanMember.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDeleteFabricVniMemberRequest(): DeleteFabricVniMemberRequest {
  return { fabricId: "", vniId: "", memberId: "" };
}

export const DeleteFabricVniMemberRequest = {
  encode(message: DeleteFabricVniMemberRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.vniId !== "") {
      writer.uint32(18).string(message.vniId);
    }
    if (message.memberId !== "") {
      writer.uint32(26).string(message.memberId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteFabricVniMemberRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteFabricVniMemberRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vniId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.memberId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteFabricVniMemberRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      vniId: isSet(object.vniId) ? globalThis.String(object.vniId) : "",
      memberId: isSet(object.memberId) ? globalThis.String(object.memberId) : "",
    };
  },

  toJSON(message: DeleteFabricVniMemberRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.vniId !== "") {
      obj.vniId = message.vniId;
    }
    if (message.memberId !== "") {
      obj.memberId = message.memberId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteFabricVniMemberRequest>, I>>(base?: I): DeleteFabricVniMemberRequest {
    return DeleteFabricVniMemberRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteFabricVniMemberRequest>, I>>(object: I): DeleteFabricVniMemberRequest {
    const message = createBaseDeleteFabricVniMemberRequest();
    message.fabricId = object.fabricId ?? "";
    message.vniId = object.vniId ?? "";
    message.memberId = object.memberId ?? "";
    return message;
  },
};

function createBaseGetFabricVrfsRequest(): GetFabricVrfsRequest {
  return { fabricId: "", candidate: "", includeMetadata: false };
}

export const GetFabricVrfsRequest = {
  encode(message: GetFabricVrfsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.candidate !== "") {
      writer.uint32(18).string(message.candidate);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(24).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricVrfsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricVrfsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricVrfsRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetFabricVrfsRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricVrfsRequest>, I>>(base?: I): GetFabricVrfsRequest {
    return GetFabricVrfsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricVrfsRequest>, I>>(object: I): GetFabricVrfsRequest {
    const message = createBaseGetFabricVrfsRequest();
    message.fabricId = object.fabricId ?? "";
    message.candidate = object.candidate ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseGetFabricVrfRequest(): GetFabricVrfRequest {
  return { fabricId: "", vrfId: "", candidate: "", includeMetadata: false };
}

export const GetFabricVrfRequest = {
  encode(message: GetFabricVrfRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.vrfId !== "") {
      writer.uint32(18).string(message.vrfId);
    }
    if (message.candidate !== "") {
      writer.uint32(26).string(message.candidate);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(32).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricVrfRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricVrfRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vrfId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricVrfRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      vrfId: isSet(object.vrfId) ? globalThis.String(object.vrfId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetFabricVrfRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.vrfId !== "") {
      obj.vrfId = message.vrfId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricVrfRequest>, I>>(base?: I): GetFabricVrfRequest {
    return GetFabricVrfRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricVrfRequest>, I>>(object: I): GetFabricVrfRequest {
    const message = createBaseGetFabricVrfRequest();
    message.fabricId = object.fabricId ?? "";
    message.vrfId = object.vrfId ?? "";
    message.candidate = object.candidate ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseAddFabricVrfsRequest(): AddFabricVrfsRequest {
  return { fabricId: "", vrfs: [] };
}

export const AddFabricVrfsRequest = {
  encode(message: AddFabricVrfsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    for (const v of message.vrfs) {
      Vrf.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddFabricVrfsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddFabricVrfsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vrfs.push(Vrf.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddFabricVrfsRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      vrfs: globalThis.Array.isArray(object?.vrfs) ? object.vrfs.map((e: any) => Vrf.fromJSON(e)) : [],
    };
  },

  toJSON(message: AddFabricVrfsRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.vrfs?.length) {
      obj.vrfs = message.vrfs.map((e) => Vrf.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddFabricVrfsRequest>, I>>(base?: I): AddFabricVrfsRequest {
    return AddFabricVrfsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddFabricVrfsRequest>, I>>(object: I): AddFabricVrfsRequest {
    const message = createBaseAddFabricVrfsRequest();
    message.fabricId = object.fabricId ?? "";
    message.vrfs = object.vrfs?.map((e) => Vrf.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateFabricVrfRequest(): UpdateFabricVrfRequest {
  return { fabricId: "", vrfId: "", vrf: undefined };
}

export const UpdateFabricVrfRequest = {
  encode(message: UpdateFabricVrfRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.vrfId !== "") {
      writer.uint32(18).string(message.vrfId);
    }
    if (message.vrf !== undefined) {
      Vrf.encode(message.vrf, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateFabricVrfRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateFabricVrfRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vrfId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.vrf = Vrf.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateFabricVrfRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      vrfId: isSet(object.vrfId) ? globalThis.String(object.vrfId) : "",
      vrf: isSet(object.vrf) ? Vrf.fromJSON(object.vrf) : undefined,
    };
  },

  toJSON(message: UpdateFabricVrfRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.vrfId !== "") {
      obj.vrfId = message.vrfId;
    }
    if (message.vrf !== undefined) {
      obj.vrf = Vrf.toJSON(message.vrf);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateFabricVrfRequest>, I>>(base?: I): UpdateFabricVrfRequest {
    return UpdateFabricVrfRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateFabricVrfRequest>, I>>(object: I): UpdateFabricVrfRequest {
    const message = createBaseUpdateFabricVrfRequest();
    message.fabricId = object.fabricId ?? "";
    message.vrfId = object.vrfId ?? "";
    message.vrf = (object.vrf !== undefined && object.vrf !== null) ? Vrf.fromPartial(object.vrf) : undefined;
    return message;
  },
};

function createBaseDeleteFabricVrfRequest(): DeleteFabricVrfRequest {
  return { fabricId: "", vrfId: "" };
}

export const DeleteFabricVrfRequest = {
  encode(message: DeleteFabricVrfRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.vrfId !== "") {
      writer.uint32(18).string(message.vrfId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteFabricVrfRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteFabricVrfRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vrfId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteFabricVrfRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      vrfId: isSet(object.vrfId) ? globalThis.String(object.vrfId) : "",
    };
  },

  toJSON(message: DeleteFabricVrfRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.vrfId !== "") {
      obj.vrfId = message.vrfId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteFabricVrfRequest>, I>>(base?: I): DeleteFabricVrfRequest {
    return DeleteFabricVrfRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteFabricVrfRequest>, I>>(object: I): DeleteFabricVrfRequest {
    const message = createBaseDeleteFabricVrfRequest();
    message.fabricId = object.fabricId ?? "";
    message.vrfId = object.vrfId ?? "";
    return message;
  },
};

function createBaseFabricVrfsResponse(): FabricVrfsResponse {
  return { vrfs: [] };
}

export const FabricVrfsResponse = {
  encode(message: FabricVrfsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.vrfs) {
      Vrf.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FabricVrfsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFabricVrfsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.vrfs.push(Vrf.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FabricVrfsResponse {
    return { vrfs: globalThis.Array.isArray(object?.vrfs) ? object.vrfs.map((e: any) => Vrf.fromJSON(e)) : [] };
  },

  toJSON(message: FabricVrfsResponse): unknown {
    const obj: any = {};
    if (message.vrfs?.length) {
      obj.vrfs = message.vrfs.map((e) => Vrf.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FabricVrfsResponse>, I>>(base?: I): FabricVrfsResponse {
    return FabricVrfsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FabricVrfsResponse>, I>>(object: I): FabricVrfsResponse {
    const message = createBaseFabricVrfsResponse();
    message.vrfs = object.vrfs?.map((e) => Vrf.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetFabricStaticRoutesRequest(): GetFabricStaticRoutesRequest {
  return { fabricId: "", vrfId: "", candidate: "", includeMetadata: false };
}

export const GetFabricStaticRoutesRequest = {
  encode(message: GetFabricStaticRoutesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.vrfId !== "") {
      writer.uint32(18).string(message.vrfId);
    }
    if (message.candidate !== "") {
      writer.uint32(34).string(message.candidate);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(40).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricStaticRoutesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricStaticRoutesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vrfId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricStaticRoutesRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      vrfId: isSet(object.vrfId) ? globalThis.String(object.vrfId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetFabricStaticRoutesRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.vrfId !== "") {
      obj.vrfId = message.vrfId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricStaticRoutesRequest>, I>>(base?: I): GetFabricStaticRoutesRequest {
    return GetFabricStaticRoutesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricStaticRoutesRequest>, I>>(object: I): GetFabricStaticRoutesRequest {
    const message = createBaseGetFabricStaticRoutesRequest();
    message.fabricId = object.fabricId ?? "";
    message.vrfId = object.vrfId ?? "";
    message.candidate = object.candidate ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseGetFabricStaticRouteRequest(): GetFabricStaticRouteRequest {
  return { fabricId: "", vrfId: "", routeId: "", candidate: "", includeMetadata: false };
}

export const GetFabricStaticRouteRequest = {
  encode(message: GetFabricStaticRouteRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.vrfId !== "") {
      writer.uint32(18).string(message.vrfId);
    }
    if (message.routeId !== "") {
      writer.uint32(26).string(message.routeId);
    }
    if (message.candidate !== "") {
      writer.uint32(34).string(message.candidate);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(40).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricStaticRouteRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricStaticRouteRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vrfId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.routeId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricStaticRouteRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      vrfId: isSet(object.vrfId) ? globalThis.String(object.vrfId) : "",
      routeId: isSet(object.routeId) ? globalThis.String(object.routeId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetFabricStaticRouteRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.vrfId !== "") {
      obj.vrfId = message.vrfId;
    }
    if (message.routeId !== "") {
      obj.routeId = message.routeId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricStaticRouteRequest>, I>>(base?: I): GetFabricStaticRouteRequest {
    return GetFabricStaticRouteRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricStaticRouteRequest>, I>>(object: I): GetFabricStaticRouteRequest {
    const message = createBaseGetFabricStaticRouteRequest();
    message.fabricId = object.fabricId ?? "";
    message.vrfId = object.vrfId ?? "";
    message.routeId = object.routeId ?? "";
    message.candidate = object.candidate ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseAddFabricStaticRoutesRequest(): AddFabricStaticRoutesRequest {
  return { fabricId: "", vrfId: "", staticRoutes: [] };
}

export const AddFabricStaticRoutesRequest = {
  encode(message: AddFabricStaticRoutesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.vrfId !== "") {
      writer.uint32(18).string(message.vrfId);
    }
    for (const v of message.staticRoutes) {
      StaticRoutes.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddFabricStaticRoutesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddFabricStaticRoutesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vrfId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.staticRoutes.push(StaticRoutes.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddFabricStaticRoutesRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      vrfId: isSet(object.vrfId) ? globalThis.String(object.vrfId) : "",
      staticRoutes: globalThis.Array.isArray(object?.staticRoutes)
        ? object.staticRoutes.map((e: any) => StaticRoutes.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AddFabricStaticRoutesRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.vrfId !== "") {
      obj.vrfId = message.vrfId;
    }
    if (message.staticRoutes?.length) {
      obj.staticRoutes = message.staticRoutes.map((e) => StaticRoutes.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddFabricStaticRoutesRequest>, I>>(base?: I): AddFabricStaticRoutesRequest {
    return AddFabricStaticRoutesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddFabricStaticRoutesRequest>, I>>(object: I): AddFabricStaticRoutesRequest {
    const message = createBaseAddFabricStaticRoutesRequest();
    message.fabricId = object.fabricId ?? "";
    message.vrfId = object.vrfId ?? "";
    message.staticRoutes = object.staticRoutes?.map((e) => StaticRoutes.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDeleteFabricStaticRouteRequest(): DeleteFabricStaticRouteRequest {
  return { fabricId: "", vrfId: "", routeId: "" };
}

export const DeleteFabricStaticRouteRequest = {
  encode(message: DeleteFabricStaticRouteRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.vrfId !== "") {
      writer.uint32(18).string(message.vrfId);
    }
    if (message.routeId !== "") {
      writer.uint32(26).string(message.routeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteFabricStaticRouteRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteFabricStaticRouteRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vrfId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.routeId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteFabricStaticRouteRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      vrfId: isSet(object.vrfId) ? globalThis.String(object.vrfId) : "",
      routeId: isSet(object.routeId) ? globalThis.String(object.routeId) : "",
    };
  },

  toJSON(message: DeleteFabricStaticRouteRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.vrfId !== "") {
      obj.vrfId = message.vrfId;
    }
    if (message.routeId !== "") {
      obj.routeId = message.routeId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteFabricStaticRouteRequest>, I>>(base?: I): DeleteFabricStaticRouteRequest {
    return DeleteFabricStaticRouteRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteFabricStaticRouteRequest>, I>>(
    object: I,
  ): DeleteFabricStaticRouteRequest {
    const message = createBaseDeleteFabricStaticRouteRequest();
    message.fabricId = object.fabricId ?? "";
    message.vrfId = object.vrfId ?? "";
    message.routeId = object.routeId ?? "";
    return message;
  },
};

function createBaseUpdateFabricStaticRouteRequest(): UpdateFabricStaticRouteRequest {
  return { fabricId: "", vrfId: "", routeId: "", staticRoute: undefined };
}

export const UpdateFabricStaticRouteRequest = {
  encode(message: UpdateFabricStaticRouteRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.vrfId !== "") {
      writer.uint32(18).string(message.vrfId);
    }
    if (message.routeId !== "") {
      writer.uint32(26).string(message.routeId);
    }
    if (message.staticRoute !== undefined) {
      StaticRoutes.encode(message.staticRoute, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateFabricStaticRouteRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateFabricStaticRouteRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vrfId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.routeId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.staticRoute = StaticRoutes.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateFabricStaticRouteRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      vrfId: isSet(object.vrfId) ? globalThis.String(object.vrfId) : "",
      routeId: isSet(object.routeId) ? globalThis.String(object.routeId) : "",
      staticRoute: isSet(object.staticRoute) ? StaticRoutes.fromJSON(object.staticRoute) : undefined,
    };
  },

  toJSON(message: UpdateFabricStaticRouteRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.vrfId !== "") {
      obj.vrfId = message.vrfId;
    }
    if (message.routeId !== "") {
      obj.routeId = message.routeId;
    }
    if (message.staticRoute !== undefined) {
      obj.staticRoute = StaticRoutes.toJSON(message.staticRoute);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateFabricStaticRouteRequest>, I>>(base?: I): UpdateFabricStaticRouteRequest {
    return UpdateFabricStaticRouteRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateFabricStaticRouteRequest>, I>>(
    object: I,
  ): UpdateFabricStaticRouteRequest {
    const message = createBaseUpdateFabricStaticRouteRequest();
    message.fabricId = object.fabricId ?? "";
    message.vrfId = object.vrfId ?? "";
    message.routeId = object.routeId ?? "";
    message.staticRoute = (object.staticRoute !== undefined && object.staticRoute !== null)
      ? StaticRoutes.fromPartial(object.staticRoute)
      : undefined;
    return message;
  },
};

function createBaseFabricStaticRoutesResponse(): FabricStaticRoutesResponse {
  return { staticRoutes: [] };
}

export const FabricStaticRoutesResponse = {
  encode(message: FabricStaticRoutesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.staticRoutes) {
      StaticRoutes.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FabricStaticRoutesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFabricStaticRoutesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.staticRoutes.push(StaticRoutes.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FabricStaticRoutesResponse {
    return {
      staticRoutes: globalThis.Array.isArray(object?.staticRoutes)
        ? object.staticRoutes.map((e: any) => StaticRoutes.fromJSON(e))
        : [],
    };
  },

  toJSON(message: FabricStaticRoutesResponse): unknown {
    const obj: any = {};
    if (message.staticRoutes?.length) {
      obj.staticRoutes = message.staticRoutes.map((e) => StaticRoutes.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FabricStaticRoutesResponse>, I>>(base?: I): FabricStaticRoutesResponse {
    return FabricStaticRoutesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FabricStaticRoutesResponse>, I>>(object: I): FabricStaticRoutesResponse {
    const message = createBaseFabricStaticRoutesResponse();
    message.staticRoutes = object.staticRoutes?.map((e) => StaticRoutes.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetFabricDhcpRelaysRequest(): GetFabricDhcpRelaysRequest {
  return { fabricId: "", candidate: "" };
}

export const GetFabricDhcpRelaysRequest = {
  encode(message: GetFabricDhcpRelaysRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.candidate !== "") {
      writer.uint32(18).string(message.candidate);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricDhcpRelaysRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricDhcpRelaysRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.candidate = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricDhcpRelaysRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
    };
  },

  toJSON(message: GetFabricDhcpRelaysRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricDhcpRelaysRequest>, I>>(base?: I): GetFabricDhcpRelaysRequest {
    return GetFabricDhcpRelaysRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricDhcpRelaysRequest>, I>>(object: I): GetFabricDhcpRelaysRequest {
    const message = createBaseGetFabricDhcpRelaysRequest();
    message.fabricId = object.fabricId ?? "";
    message.candidate = object.candidate ?? "";
    return message;
  },
};

function createBaseFabricDhcpRelaysResponse(): FabricDhcpRelaysResponse {
  return { relays: [] };
}

export const FabricDhcpRelaysResponse = {
  encode(message: FabricDhcpRelaysResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.relays) {
      DhcpRelay.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FabricDhcpRelaysResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFabricDhcpRelaysResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.relays.push(DhcpRelay.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FabricDhcpRelaysResponse {
    return {
      relays: globalThis.Array.isArray(object?.relays) ? object.relays.map((e: any) => DhcpRelay.fromJSON(e)) : [],
    };
  },

  toJSON(message: FabricDhcpRelaysResponse): unknown {
    const obj: any = {};
    if (message.relays?.length) {
      obj.relays = message.relays.map((e) => DhcpRelay.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FabricDhcpRelaysResponse>, I>>(base?: I): FabricDhcpRelaysResponse {
    return FabricDhcpRelaysResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FabricDhcpRelaysResponse>, I>>(object: I): FabricDhcpRelaysResponse {
    const message = createBaseFabricDhcpRelaysResponse();
    message.relays = object.relays?.map((e) => DhcpRelay.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetFabricLoopbacksRequest(): GetFabricLoopbacksRequest {
  return { fabricId: "", candidate: "" };
}

export const GetFabricLoopbacksRequest = {
  encode(message: GetFabricLoopbacksRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.candidate !== "") {
      writer.uint32(18).string(message.candidate);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricLoopbacksRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricLoopbacksRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.candidate = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricLoopbacksRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
    };
  },

  toJSON(message: GetFabricLoopbacksRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricLoopbacksRequest>, I>>(base?: I): GetFabricLoopbacksRequest {
    return GetFabricLoopbacksRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricLoopbacksRequest>, I>>(object: I): GetFabricLoopbacksRequest {
    const message = createBaseGetFabricLoopbacksRequest();
    message.fabricId = object.fabricId ?? "";
    message.candidate = object.candidate ?? "";
    return message;
  },
};

function createBaseFabricLoopbacksResponse(): FabricLoopbacksResponse {
  return { loopbacks: [] };
}

export const FabricLoopbacksResponse = {
  encode(message: FabricLoopbacksResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.loopbacks) {
      Loopback.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FabricLoopbacksResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFabricLoopbacksResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.loopbacks.push(Loopback.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FabricLoopbacksResponse {
    return {
      loopbacks: globalThis.Array.isArray(object?.loopbacks)
        ? object.loopbacks.map((e: any) => Loopback.fromJSON(e))
        : [],
    };
  },

  toJSON(message: FabricLoopbacksResponse): unknown {
    const obj: any = {};
    if (message.loopbacks?.length) {
      obj.loopbacks = message.loopbacks.map((e) => Loopback.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FabricLoopbacksResponse>, I>>(base?: I): FabricLoopbacksResponse {
    return FabricLoopbacksResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FabricLoopbacksResponse>, I>>(object: I): FabricLoopbacksResponse {
    const message = createBaseFabricLoopbacksResponse();
    message.loopbacks = object.loopbacks?.map((e) => Loopback.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetNodeLoopbacksRequest(): GetNodeLoopbacksRequest {
  return { fabricId: "", nodeId: "", candidate: "", includeMetadata: false };
}

export const GetNodeLoopbacksRequest = {
  encode(message: GetNodeLoopbacksRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.candidate !== "") {
      writer.uint32(26).string(message.candidate);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(32).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNodeLoopbacksRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNodeLoopbacksRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNodeLoopbacksRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetNodeLoopbacksRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNodeLoopbacksRequest>, I>>(base?: I): GetNodeLoopbacksRequest {
    return GetNodeLoopbacksRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNodeLoopbacksRequest>, I>>(object: I): GetNodeLoopbacksRequest {
    const message = createBaseGetNodeLoopbacksRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.candidate = object.candidate ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseNodeLoopbacksResponse(): NodeLoopbacksResponse {
  return { loopbacks: [] };
}

export const NodeLoopbacksResponse = {
  encode(message: NodeLoopbacksResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.loopbacks) {
      Loopback.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NodeLoopbacksResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNodeLoopbacksResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.loopbacks.push(Loopback.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NodeLoopbacksResponse {
    return {
      loopbacks: globalThis.Array.isArray(object?.loopbacks)
        ? object.loopbacks.map((e: any) => Loopback.fromJSON(e))
        : [],
    };
  },

  toJSON(message: NodeLoopbacksResponse): unknown {
    const obj: any = {};
    if (message.loopbacks?.length) {
      obj.loopbacks = message.loopbacks.map((e) => Loopback.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NodeLoopbacksResponse>, I>>(base?: I): NodeLoopbacksResponse {
    return NodeLoopbacksResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NodeLoopbacksResponse>, I>>(object: I): NodeLoopbacksResponse {
    const message = createBaseNodeLoopbacksResponse();
    message.loopbacks = object.loopbacks?.map((e) => Loopback.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetNodeLoopbackRequest(): GetNodeLoopbackRequest {
  return { fabricId: "", nodeId: "", loopbackId: "", candidate: "", includeMetadata: false };
}

export const GetNodeLoopbackRequest = {
  encode(message: GetNodeLoopbackRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.loopbackId !== "") {
      writer.uint32(26).string(message.loopbackId);
    }
    if (message.candidate !== "") {
      writer.uint32(34).string(message.candidate);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(40).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNodeLoopbackRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNodeLoopbackRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.loopbackId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNodeLoopbackRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      loopbackId: isSet(object.loopbackId) ? globalThis.String(object.loopbackId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetNodeLoopbackRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.loopbackId !== "") {
      obj.loopbackId = message.loopbackId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNodeLoopbackRequest>, I>>(base?: I): GetNodeLoopbackRequest {
    return GetNodeLoopbackRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNodeLoopbackRequest>, I>>(object: I): GetNodeLoopbackRequest {
    const message = createBaseGetNodeLoopbackRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.loopbackId = object.loopbackId ?? "";
    message.candidate = object.candidate ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseAddNodeLoopbacksRequest(): AddNodeLoopbacksRequest {
  return { fabricId: "", nodeId: "", loopbacks: [] };
}

export const AddNodeLoopbacksRequest = {
  encode(message: AddNodeLoopbacksRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    for (const v of message.loopbacks) {
      Loopback.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddNodeLoopbacksRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddNodeLoopbacksRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.loopbacks.push(Loopback.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddNodeLoopbacksRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      loopbacks: globalThis.Array.isArray(object?.loopbacks)
        ? object.loopbacks.map((e: any) => Loopback.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AddNodeLoopbacksRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.loopbacks?.length) {
      obj.loopbacks = message.loopbacks.map((e) => Loopback.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddNodeLoopbacksRequest>, I>>(base?: I): AddNodeLoopbacksRequest {
    return AddNodeLoopbacksRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddNodeLoopbacksRequest>, I>>(object: I): AddNodeLoopbacksRequest {
    const message = createBaseAddNodeLoopbacksRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.loopbacks = object.loopbacks?.map((e) => Loopback.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateNodeLoopbackRequest(): UpdateNodeLoopbackRequest {
  return { fabricId: "", nodeId: "", loopbackId: "", loopback: undefined };
}

export const UpdateNodeLoopbackRequest = {
  encode(message: UpdateNodeLoopbackRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.loopbackId !== "") {
      writer.uint32(26).string(message.loopbackId);
    }
    if (message.loopback !== undefined) {
      Loopback.encode(message.loopback, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateNodeLoopbackRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateNodeLoopbackRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.loopbackId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.loopback = Loopback.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateNodeLoopbackRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      loopbackId: isSet(object.loopbackId) ? globalThis.String(object.loopbackId) : "",
      loopback: isSet(object.loopback) ? Loopback.fromJSON(object.loopback) : undefined,
    };
  },

  toJSON(message: UpdateNodeLoopbackRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.loopbackId !== "") {
      obj.loopbackId = message.loopbackId;
    }
    if (message.loopback !== undefined) {
      obj.loopback = Loopback.toJSON(message.loopback);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateNodeLoopbackRequest>, I>>(base?: I): UpdateNodeLoopbackRequest {
    return UpdateNodeLoopbackRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateNodeLoopbackRequest>, I>>(object: I): UpdateNodeLoopbackRequest {
    const message = createBaseUpdateNodeLoopbackRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.loopbackId = object.loopbackId ?? "";
    message.loopback = (object.loopback !== undefined && object.loopback !== null)
      ? Loopback.fromPartial(object.loopback)
      : undefined;
    return message;
  },
};

function createBaseDeleteNodeLoopbackRequest(): DeleteNodeLoopbackRequest {
  return { fabricId: "", nodeId: "", loopbackId: "" };
}

export const DeleteNodeLoopbackRequest = {
  encode(message: DeleteNodeLoopbackRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.loopbackId !== "") {
      writer.uint32(26).string(message.loopbackId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteNodeLoopbackRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteNodeLoopbackRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.loopbackId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteNodeLoopbackRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      loopbackId: isSet(object.loopbackId) ? globalThis.String(object.loopbackId) : "",
    };
  },

  toJSON(message: DeleteNodeLoopbackRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.loopbackId !== "") {
      obj.loopbackId = message.loopbackId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteNodeLoopbackRequest>, I>>(base?: I): DeleteNodeLoopbackRequest {
    return DeleteNodeLoopbackRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteNodeLoopbackRequest>, I>>(object: I): DeleteNodeLoopbackRequest {
    const message = createBaseDeleteNodeLoopbackRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.loopbackId = object.loopbackId ?? "";
    return message;
  },
};

function createBaseGetNodeSubInterfacesRequest(): GetNodeSubInterfacesRequest {
  return { fabricId: "", nodeId: "", candidate: "", includeMetadata: false };
}

export const GetNodeSubInterfacesRequest = {
  encode(message: GetNodeSubInterfacesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.candidate !== "") {
      writer.uint32(26).string(message.candidate);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(32).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNodeSubInterfacesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNodeSubInterfacesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNodeSubInterfacesRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetNodeSubInterfacesRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNodeSubInterfacesRequest>, I>>(base?: I): GetNodeSubInterfacesRequest {
    return GetNodeSubInterfacesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNodeSubInterfacesRequest>, I>>(object: I): GetNodeSubInterfacesRequest {
    const message = createBaseGetNodeSubInterfacesRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.candidate = object.candidate ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseNodeSubInterfacesResponse(): NodeSubInterfacesResponse {
  return { subInterfaces: [] };
}

export const NodeSubInterfacesResponse = {
  encode(message: NodeSubInterfacesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.subInterfaces) {
      SubInterface.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NodeSubInterfacesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNodeSubInterfacesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.subInterfaces.push(SubInterface.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NodeSubInterfacesResponse {
    return {
      subInterfaces: globalThis.Array.isArray(object?.subInterfaces)
        ? object.subInterfaces.map((e: any) => SubInterface.fromJSON(e))
        : [],
    };
  },

  toJSON(message: NodeSubInterfacesResponse): unknown {
    const obj: any = {};
    if (message.subInterfaces?.length) {
      obj.subInterfaces = message.subInterfaces.map((e) => SubInterface.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NodeSubInterfacesResponse>, I>>(base?: I): NodeSubInterfacesResponse {
    return NodeSubInterfacesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NodeSubInterfacesResponse>, I>>(object: I): NodeSubInterfacesResponse {
    const message = createBaseNodeSubInterfacesResponse();
    message.subInterfaces = object.subInterfaces?.map((e) => SubInterface.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetNodeSubInterfaceRequest(): GetNodeSubInterfaceRequest {
  return { fabricId: "", nodeId: "", subInterfaceId: "", candidate: "", includeMetadata: false };
}

export const GetNodeSubInterfaceRequest = {
  encode(message: GetNodeSubInterfaceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.subInterfaceId !== "") {
      writer.uint32(26).string(message.subInterfaceId);
    }
    if (message.candidate !== "") {
      writer.uint32(34).string(message.candidate);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(40).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNodeSubInterfaceRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNodeSubInterfaceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.subInterfaceId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNodeSubInterfaceRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      subInterfaceId: isSet(object.subInterfaceId) ? globalThis.String(object.subInterfaceId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetNodeSubInterfaceRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.subInterfaceId !== "") {
      obj.subInterfaceId = message.subInterfaceId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNodeSubInterfaceRequest>, I>>(base?: I): GetNodeSubInterfaceRequest {
    return GetNodeSubInterfaceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNodeSubInterfaceRequest>, I>>(object: I): GetNodeSubInterfaceRequest {
    const message = createBaseGetNodeSubInterfaceRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.subInterfaceId = object.subInterfaceId ?? "";
    message.candidate = object.candidate ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseAddNodeSubInterfacesRequest(): AddNodeSubInterfacesRequest {
  return { fabricId: "", nodeId: "", subInterfaces: [] };
}

export const AddNodeSubInterfacesRequest = {
  encode(message: AddNodeSubInterfacesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    for (const v of message.subInterfaces) {
      SubInterface.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddNodeSubInterfacesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddNodeSubInterfacesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.subInterfaces.push(SubInterface.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddNodeSubInterfacesRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      subInterfaces: globalThis.Array.isArray(object?.subInterfaces)
        ? object.subInterfaces.map((e: any) => SubInterface.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AddNodeSubInterfacesRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.subInterfaces?.length) {
      obj.subInterfaces = message.subInterfaces.map((e) => SubInterface.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddNodeSubInterfacesRequest>, I>>(base?: I): AddNodeSubInterfacesRequest {
    return AddNodeSubInterfacesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddNodeSubInterfacesRequest>, I>>(object: I): AddNodeSubInterfacesRequest {
    const message = createBaseAddNodeSubInterfacesRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.subInterfaces = object.subInterfaces?.map((e) => SubInterface.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateNodeSubInterfaceRequest(): UpdateNodeSubInterfaceRequest {
  return { fabricId: "", nodeId: "", subInterfaceId: "", subInterface: undefined };
}

export const UpdateNodeSubInterfaceRequest = {
  encode(message: UpdateNodeSubInterfaceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.subInterfaceId !== "") {
      writer.uint32(26).string(message.subInterfaceId);
    }
    if (message.subInterface !== undefined) {
      SubInterface.encode(message.subInterface, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateNodeSubInterfaceRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateNodeSubInterfaceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.subInterfaceId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.subInterface = SubInterface.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateNodeSubInterfaceRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      subInterfaceId: isSet(object.subInterfaceId) ? globalThis.String(object.subInterfaceId) : "",
      subInterface: isSet(object.subInterface) ? SubInterface.fromJSON(object.subInterface) : undefined,
    };
  },

  toJSON(message: UpdateNodeSubInterfaceRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.subInterfaceId !== "") {
      obj.subInterfaceId = message.subInterfaceId;
    }
    if (message.subInterface !== undefined) {
      obj.subInterface = SubInterface.toJSON(message.subInterface);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateNodeSubInterfaceRequest>, I>>(base?: I): UpdateNodeSubInterfaceRequest {
    return UpdateNodeSubInterfaceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateNodeSubInterfaceRequest>, I>>(
    object: I,
  ): UpdateNodeSubInterfaceRequest {
    const message = createBaseUpdateNodeSubInterfaceRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.subInterfaceId = object.subInterfaceId ?? "";
    message.subInterface = (object.subInterface !== undefined && object.subInterface !== null)
      ? SubInterface.fromPartial(object.subInterface)
      : undefined;
    return message;
  },
};

function createBaseDeleteNodeSubInterfaceRequest(): DeleteNodeSubInterfaceRequest {
  return { fabricId: "", nodeId: "", subInterfaceId: "" };
}

export const DeleteNodeSubInterfaceRequest = {
  encode(message: DeleteNodeSubInterfaceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.subInterfaceId !== "") {
      writer.uint32(26).string(message.subInterfaceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteNodeSubInterfaceRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteNodeSubInterfaceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.subInterfaceId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteNodeSubInterfaceRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      subInterfaceId: isSet(object.subInterfaceId) ? globalThis.String(object.subInterfaceId) : "",
    };
  },

  toJSON(message: DeleteNodeSubInterfaceRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.subInterfaceId !== "") {
      obj.subInterfaceId = message.subInterfaceId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteNodeSubInterfaceRequest>, I>>(base?: I): DeleteNodeSubInterfaceRequest {
    return DeleteNodeSubInterfaceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteNodeSubInterfaceRequest>, I>>(
    object: I,
  ): DeleteNodeSubInterfaceRequest {
    const message = createBaseDeleteNodeSubInterfaceRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.subInterfaceId = object.subInterfaceId ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
