// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: configd/rest_assertions.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Assertion } from "../assertions/assertion_types";

export const protobufPackage = "configd";

/** The response returned to a request to latch/unlatch a list of assertions of a specific fabric. */
export interface LatchUnlatchAssertionsResponse {
  /** The fabric id or name. */
  fabricId: string;
  /** The list of updated assertions with their new latch state. */
  assertions: Assertion[];
}

/** The request to latch/unlatch a list of assertions of a specific fabric. */
export interface LatchUnlatchAssertionsRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The list of assertions to be latched or unlatched. */
  assertions: Assertion[];
}

function createBaseLatchUnlatchAssertionsResponse(): LatchUnlatchAssertionsResponse {
  return { fabricId: "", assertions: [] };
}

export const LatchUnlatchAssertionsResponse = {
  encode(message: LatchUnlatchAssertionsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    for (const v of message.assertions) {
      Assertion.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LatchUnlatchAssertionsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLatchUnlatchAssertionsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.assertions.push(Assertion.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LatchUnlatchAssertionsResponse {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      assertions: globalThis.Array.isArray(object?.assertions)
        ? object.assertions.map((e: any) => Assertion.fromJSON(e))
        : [],
    };
  },

  toJSON(message: LatchUnlatchAssertionsResponse): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.assertions?.length) {
      obj.assertions = message.assertions.map((e) => Assertion.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LatchUnlatchAssertionsResponse>, I>>(base?: I): LatchUnlatchAssertionsResponse {
    return LatchUnlatchAssertionsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LatchUnlatchAssertionsResponse>, I>>(
    object: I,
  ): LatchUnlatchAssertionsResponse {
    const message = createBaseLatchUnlatchAssertionsResponse();
    message.fabricId = object.fabricId ?? "";
    message.assertions = object.assertions?.map((e) => Assertion.fromPartial(e)) || [];
    return message;
  },
};

function createBaseLatchUnlatchAssertionsRequest(): LatchUnlatchAssertionsRequest {
  return { fabricId: "", assertions: [] };
}

export const LatchUnlatchAssertionsRequest = {
  encode(message: LatchUnlatchAssertionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    for (const v of message.assertions) {
      Assertion.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LatchUnlatchAssertionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLatchUnlatchAssertionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.assertions.push(Assertion.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LatchUnlatchAssertionsRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      assertions: globalThis.Array.isArray(object?.assertions)
        ? object.assertions.map((e: any) => Assertion.fromJSON(e))
        : [],
    };
  },

  toJSON(message: LatchUnlatchAssertionsRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.assertions?.length) {
      obj.assertions = message.assertions.map((e) => Assertion.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LatchUnlatchAssertionsRequest>, I>>(base?: I): LatchUnlatchAssertionsRequest {
    return LatchUnlatchAssertionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LatchUnlatchAssertionsRequest>, I>>(
    object: I,
  ): LatchUnlatchAssertionsRequest {
    const message = createBaseLatchUnlatchAssertionsRequest();
    message.fabricId = object.fabricId ?? "";
    message.assertions = object.assertions?.map((e) => Assertion.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
