// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: refpoints/refpoints.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../google/protobuf/timestamp";
import { RefPointJobState, refPointJobStateFromJSON, refPointJobStateToJSON } from "../schema/schema";
import { SchemaType, schemaTypeFromJSON, schemaTypeToJSON } from "../schema_path/schema_path";
import { DataSeries } from "../syncfollow/syncfollow_threadlist";

export const protobufPackage = "refpoints";

/** REST request messages only needing a fabric id */
export interface RefPointByFabricRequest {
  /** The fabric id or name */
  fabricId: string;
}

/** REST request messages for a named reference point */
export interface RefPointByNameRequest {
  /** The fabric id or name */
  fabricId: string;
  /** The reference point name */
  refPointName: string;
}

/** REST Request to create a new reference point */
export interface CreateRefPointRequest {
  /** The fabric id or name */
  fabricId: string;
  /** Optional list of devices to include in the capture */
  deviceIds: string[];
}

/** REST RefPoint metadata */
export interface RefPoint {
  /** The reference point name */
  name: string;
  /** The fabric id */
  fabricId: string;
  /** The timestamp for when the reference point metadata was created */
  created:
    | Date
    | undefined;
  /** Optional list of devices to include in the capture */
  deviceIds: string[];
  /** The state of the reference point creation job */
  createJobState: RefPointJobState;
  /** When the create job was completed */
  createCompleted:
    | Date
    | undefined;
  /** The state of the reference point diff creation job */
  diffJobState: RefPointJobState;
}

/** REST list of refpoints */
export interface RefPointList {
  /** A repeated list of reference point */
  refPoints: RefPoint[];
}

/** REST response for a diff file (and also the format on disk) */
export interface DiffPart {
  /** The timestamp of the older refpoint */
  olderRefPointTime:
    | Date
    | undefined;
  /** The timestamp of the newer refpoint */
  newerRefPointTime:
    | Date
    | undefined;
  /** The device id */
  deviceId: string;
  /** The object type */
  objectType: SchemaType;
  /** Data only in the older refpoint or in both and different */
  older:
    | DataSeries
    | undefined;
  /** Data only in the newer refpoint or in both and different */
  newer: DataSeries | undefined;
}

/** Header written at the start of data and diff files for future compatibility */
export interface RefPointHeader {
  /** The version of the file format */
  version: number;
  /** The timestamp of when the file was created */
  created: Date | undefined;
}

function createBaseRefPointByFabricRequest(): RefPointByFabricRequest {
  return { fabricId: "" };
}

export const RefPointByFabricRequest = {
  encode(message: RefPointByFabricRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RefPointByFabricRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefPointByFabricRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RefPointByFabricRequest {
    return { fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "" };
  },

  toJSON(message: RefPointByFabricRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RefPointByFabricRequest>, I>>(base?: I): RefPointByFabricRequest {
    return RefPointByFabricRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RefPointByFabricRequest>, I>>(object: I): RefPointByFabricRequest {
    const message = createBaseRefPointByFabricRequest();
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseRefPointByNameRequest(): RefPointByNameRequest {
  return { fabricId: "", refPointName: "" };
}

export const RefPointByNameRequest = {
  encode(message: RefPointByNameRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.refPointName !== "") {
      writer.uint32(18).string(message.refPointName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RefPointByNameRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefPointByNameRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.refPointName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RefPointByNameRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      refPointName: isSet(object.refPointName) ? globalThis.String(object.refPointName) : "",
    };
  },

  toJSON(message: RefPointByNameRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.refPointName !== "") {
      obj.refPointName = message.refPointName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RefPointByNameRequest>, I>>(base?: I): RefPointByNameRequest {
    return RefPointByNameRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RefPointByNameRequest>, I>>(object: I): RefPointByNameRequest {
    const message = createBaseRefPointByNameRequest();
    message.fabricId = object.fabricId ?? "";
    message.refPointName = object.refPointName ?? "";
    return message;
  },
};

function createBaseCreateRefPointRequest(): CreateRefPointRequest {
  return { fabricId: "", deviceIds: [] };
}

export const CreateRefPointRequest = {
  encode(message: CreateRefPointRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    for (const v of message.deviceIds) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateRefPointRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateRefPointRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deviceIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateRefPointRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      deviceIds: globalThis.Array.isArray(object?.deviceIds)
        ? object.deviceIds.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: CreateRefPointRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.deviceIds?.length) {
      obj.deviceIds = message.deviceIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateRefPointRequest>, I>>(base?: I): CreateRefPointRequest {
    return CreateRefPointRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateRefPointRequest>, I>>(object: I): CreateRefPointRequest {
    const message = createBaseCreateRefPointRequest();
    message.fabricId = object.fabricId ?? "";
    message.deviceIds = object.deviceIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseRefPoint(): RefPoint {
  return {
    name: "",
    fabricId: "",
    created: undefined,
    deviceIds: [],
    createJobState: 0,
    createCompleted: undefined,
    diffJobState: 0,
  };
}

export const RefPoint = {
  encode(message: RefPoint, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.fabricId !== "") {
      writer.uint32(18).string(message.fabricId);
    }
    if (message.created !== undefined) {
      Timestamp.encode(toTimestamp(message.created), writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.deviceIds) {
      writer.uint32(34).string(v!);
    }
    if (message.createJobState !== 0) {
      writer.uint32(40).int32(message.createJobState);
    }
    if (message.createCompleted !== undefined) {
      Timestamp.encode(toTimestamp(message.createCompleted), writer.uint32(50).fork()).ldelim();
    }
    if (message.diffJobState !== 0) {
      writer.uint32(56).int32(message.diffJobState);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RefPoint {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefPoint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.created = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.deviceIds.push(reader.string());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.createJobState = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.createCompleted = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.diffJobState = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RefPoint {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      created: isSet(object.created) ? fromJsonTimestamp(object.created) : undefined,
      deviceIds: globalThis.Array.isArray(object?.deviceIds)
        ? object.deviceIds.map((e: any) => globalThis.String(e))
        : [],
      createJobState: isSet(object.createJobState) ? refPointJobStateFromJSON(object.createJobState) : 0,
      createCompleted: isSet(object.createCompleted) ? fromJsonTimestamp(object.createCompleted) : undefined,
      diffJobState: isSet(object.diffJobState) ? refPointJobStateFromJSON(object.diffJobState) : 0,
    };
  },

  toJSON(message: RefPoint): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.created !== undefined) {
      obj.created = message.created.toISOString();
    }
    if (message.deviceIds?.length) {
      obj.deviceIds = message.deviceIds;
    }
    if (message.createJobState !== 0) {
      obj.createJobState = refPointJobStateToJSON(message.createJobState);
    }
    if (message.createCompleted !== undefined) {
      obj.createCompleted = message.createCompleted.toISOString();
    }
    if (message.diffJobState !== 0) {
      obj.diffJobState = refPointJobStateToJSON(message.diffJobState);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RefPoint>, I>>(base?: I): RefPoint {
    return RefPoint.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RefPoint>, I>>(object: I): RefPoint {
    const message = createBaseRefPoint();
    message.name = object.name ?? "";
    message.fabricId = object.fabricId ?? "";
    message.created = object.created ?? undefined;
    message.deviceIds = object.deviceIds?.map((e) => e) || [];
    message.createJobState = object.createJobState ?? 0;
    message.createCompleted = object.createCompleted ?? undefined;
    message.diffJobState = object.diffJobState ?? 0;
    return message;
  },
};

function createBaseRefPointList(): RefPointList {
  return { refPoints: [] };
}

export const RefPointList = {
  encode(message: RefPointList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.refPoints) {
      RefPoint.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RefPointList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefPointList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.refPoints.push(RefPoint.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RefPointList {
    return {
      refPoints: globalThis.Array.isArray(object?.refPoints)
        ? object.refPoints.map((e: any) => RefPoint.fromJSON(e))
        : [],
    };
  },

  toJSON(message: RefPointList): unknown {
    const obj: any = {};
    if (message.refPoints?.length) {
      obj.refPoints = message.refPoints.map((e) => RefPoint.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RefPointList>, I>>(base?: I): RefPointList {
    return RefPointList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RefPointList>, I>>(object: I): RefPointList {
    const message = createBaseRefPointList();
    message.refPoints = object.refPoints?.map((e) => RefPoint.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDiffPart(): DiffPart {
  return {
    olderRefPointTime: undefined,
    newerRefPointTime: undefined,
    deviceId: "",
    objectType: 0,
    older: undefined,
    newer: undefined,
  };
}

export const DiffPart = {
  encode(message: DiffPart, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.olderRefPointTime !== undefined) {
      Timestamp.encode(toTimestamp(message.olderRefPointTime), writer.uint32(10).fork()).ldelim();
    }
    if (message.newerRefPointTime !== undefined) {
      Timestamp.encode(toTimestamp(message.newerRefPointTime), writer.uint32(18).fork()).ldelim();
    }
    if (message.deviceId !== "") {
      writer.uint32(26).string(message.deviceId);
    }
    if (message.objectType !== 0) {
      writer.uint32(32).int32(message.objectType);
    }
    if (message.older !== undefined) {
      DataSeries.encode(message.older, writer.uint32(42).fork()).ldelim();
    }
    if (message.newer !== undefined) {
      DataSeries.encode(message.newer, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DiffPart {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDiffPart();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.olderRefPointTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.newerRefPointTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.objectType = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.older = DataSeries.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.newer = DataSeries.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DiffPart {
    return {
      olderRefPointTime: isSet(object.olderRefPointTime) ? fromJsonTimestamp(object.olderRefPointTime) : undefined,
      newerRefPointTime: isSet(object.newerRefPointTime) ? fromJsonTimestamp(object.newerRefPointTime) : undefined,
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      objectType: isSet(object.objectType) ? schemaTypeFromJSON(object.objectType) : 0,
      older: isSet(object.older) ? DataSeries.fromJSON(object.older) : undefined,
      newer: isSet(object.newer) ? DataSeries.fromJSON(object.newer) : undefined,
    };
  },

  toJSON(message: DiffPart): unknown {
    const obj: any = {};
    if (message.olderRefPointTime !== undefined) {
      obj.olderRefPointTime = message.olderRefPointTime.toISOString();
    }
    if (message.newerRefPointTime !== undefined) {
      obj.newerRefPointTime = message.newerRefPointTime.toISOString();
    }
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.objectType !== 0) {
      obj.objectType = schemaTypeToJSON(message.objectType);
    }
    if (message.older !== undefined) {
      obj.older = DataSeries.toJSON(message.older);
    }
    if (message.newer !== undefined) {
      obj.newer = DataSeries.toJSON(message.newer);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DiffPart>, I>>(base?: I): DiffPart {
    return DiffPart.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DiffPart>, I>>(object: I): DiffPart {
    const message = createBaseDiffPart();
    message.olderRefPointTime = object.olderRefPointTime ?? undefined;
    message.newerRefPointTime = object.newerRefPointTime ?? undefined;
    message.deviceId = object.deviceId ?? "";
    message.objectType = object.objectType ?? 0;
    message.older = (object.older !== undefined && object.older !== null)
      ? DataSeries.fromPartial(object.older)
      : undefined;
    message.newer = (object.newer !== undefined && object.newer !== null)
      ? DataSeries.fromPartial(object.newer)
      : undefined;
    return message;
  },
};

function createBaseRefPointHeader(): RefPointHeader {
  return { version: 0, created: undefined };
}

export const RefPointHeader = {
  encode(message: RefPointHeader, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.version !== 0) {
      writer.uint32(8).uint32(message.version);
    }
    if (message.created !== undefined) {
      Timestamp.encode(toTimestamp(message.created), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RefPointHeader {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefPointHeader();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.version = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.created = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RefPointHeader {
    return {
      version: isSet(object.version) ? globalThis.Number(object.version) : 0,
      created: isSet(object.created) ? fromJsonTimestamp(object.created) : undefined,
    };
  },

  toJSON(message: RefPointHeader): unknown {
    const obj: any = {};
    if (message.version !== 0) {
      obj.version = Math.round(message.version);
    }
    if (message.created !== undefined) {
      obj.created = message.created.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RefPointHeader>, I>>(base?: I): RefPointHeader {
    return RefPointHeader.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RefPointHeader>, I>>(object: I): RefPointHeader {
    const message = createBaseRefPointHeader();
    message.version = object.version ?? 0;
    message.created = object.created ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
