import { Icon, Nav } from "@magnetic/nav";
import { useContext } from "react";
import "./navbar.scss";
import { LoginContext } from "./loginContext";
import { UserRoleType } from "../dataTypes/userRoleType";
import { UserRole } from "../gen/schema/models/auth";
import { UserContext } from "src/gen/schema/fred/fapi";
import "./orgSwitcher.scss";

interface TenantProps {
  readonly context: UserContext;
}

/**
 * Used to map the UserContext to
 * key by Org ID. this way the tenants
 * can be grouped in the drawer by
 * org.
 */
const mapUserContextToOrgs = (
  contexts: UserContext[]
): Map<string, UserContext[]> => {
  return contexts.reduce((out, context: UserContext) => {
    const tmp = out.get(context.orgId);
    if (tmp) {
      return out.set(context.orgId, [...tmp, context]);
    } else {
      return out.set(context.orgId, [context]);
    }
  }, new Map<string, UserContext[]>());
};

const Tenants = ({ context }: TenantProps) => {
  const { session, switchTenant } = useContext(LoginContext);
  if (session) {
    const { tenantId, tenantName, role } = context;
    const contextRole = new UserRoleType(
      role || UserRole.USER_ROLE_UNSPECIFIED
    );
    return (
      <Nav.Drawer.Item
        className="org-tenant"
        onClick={() => {
          switchTenant(tenantId);
        }}
        selected={session?.tenantId === tenantId}
      >
        {tenantName}
        {contextRole.render()}
      </Nav.Drawer.Item>
    );
  }
  return null;
};

const sortAlphabetically = (a: string = "", b: string = "") => {
  const nameA = a?.toUpperCase?.(); // ignore upper and lowercase
  const nameB = b?.toUpperCase?.(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};

export const OrgsSwitcherDrawer = () => {
  const { session, userContexts } = useContext(LoginContext);
  if (userContexts?.contexts && session) {
    const contexts = mapUserContextToOrgs(userContexts.contexts);
    const items = Array.from(contexts.entries())
      .sort((a, b) =>
        sortAlphabetically(a?.[1]?.[0]?.orgName, b?.[1]?.[0]?.orgName)
      )
      .map(([key, context]) => {
        return (
          <Nav.Drawer.ItemGroup className="org-drawer" key={key} label="">
            {context
              .sort((a, b) => sortAlphabetically(a?.tenantName, b?.tenantName))
              .map((c) => (
                <Tenants key={c.tenantId} context={c} />
              ))}
          </Nav.Drawer.ItemGroup>
        );
      });
    return (
      <Nav.Drawer.Content
        heading="Select an organization"
        id="organization"
        key="org"
      >
        {items}
      </Nav.Drawer.Content>
    );
  }
  return null;
};

export const OrgSwitcher = () => {
  const { session } = useContext(LoginContext);
  return (
    <Nav.Item
      drawerContentId="organization"
      heading="Organization"
      icon={<Icon kind="organization-switcher" />}
      label={session?.orgName}
      switcher={true}
    />
  );
};
